import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { ChevronRightIcon, Content, LogoMotorway } from '@motorway/motorway-storybook-cra';

import { Context } from '../../context/context';

import styles from './Header.module.scss';

const Header = () => {
  const { isVideoVisible, scrollDownHintSeen }: any = useContext(Context);

  // Full screen
  const [fullScreenOpen, toggleState] = useState(false);

  const listener = () => {
    toggleState(!!document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', listener);

    return () => {
      document.removeEventListener('fullscreenchange', listener);
    };
  }, []);
  // End full screen

  const [captureScreen, toggleCaptureScreen] = useState(false);

  useEffect(() => {
    const captureScreenVisible = scrollDownHintSeen && isVideoVisible;
    toggleCaptureScreen(captureScreenVisible);

    document.body.classList[(captureScreenVisible) ? 'add' : 'remove']('capture-screen');

    return () => {
      document.body.classList.remove('capture-screen');
    };
  }, [scrollDownHintSeen, isVideoVisible]);

  return (
    <Content
      className={
        cx(
          styles.component,
          {
            [styles.scroll]: captureScreen,
          },
        )
      }
      element="header"
      id="photo-header"
      wrapper={{ className: cx(styles.wrapper, { [styles.scrollDownHint]: !scrollDownHintSeen }) }}
    >
      {
        (scrollDownHintSeen && captureScreen) ? (
          <div>
            Scroll down
            <ChevronRightIcon />
          </div>
        ) : (
          <Link aria-label="Vehicle Photos | Motorway" to="/">
            <LogoMotorway className={styles.logo} />
          </Link>
        )
      }
    </Content>
  );
};

export default Header;
