import React, { Children } from 'react';

import { MDText } from 'i18n-react';

import { GA_EVENT_ACTIONS } from '../../../../../utilities/analytics';
import { PANEL_DAMAGE_GA_EVENTS } from '../../../DamagePanel/DamagePanel.helpers';
import { HelpModal } from '../HelpModal';

import LocalTexts from './SizingTips.json';

import styles from './SizingTips.module.scss';

const LocalT = new MDText(LocalTexts);

export const SizingTips = () => {
  const { sizingTips: { sections } } = LocalTexts;

  const gaEvents = {
    action: GA_EVENT_ACTIONS.BUTTON,
    category: PANEL_DAMAGE_GA_EVENTS.CATEGORY,
    label: PANEL_DAMAGE_GA_EVENTS.PANEL_DAMAGE_PAGE.HELP_BUTTON,
  };

  return (
    <HelpModal buttonText={LocalT.translate('sizingTips.button') as string} gaEvents={gaEvents}>
      <div className={styles.sections}>
        {Children.toArray(
          sections.map((section) => (
            <div className={styles.section}>
              <h1>{LocalT.translate(section.title)}</h1>
              <p>{LocalT.translate(section.description)}</p>
            </div>
          )),
        )}
      </div>
    </HelpModal>
  );
};
