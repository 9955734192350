import React from 'react';

import { TickCircleIcon } from '@motorway/mw-highway-code';

import styles from './InfoCheckbox.module.scss';

const InfoCheckbox = ({ label }: { label: string | React.ReactNode }) => {
  if (!label) {
    return null;
  }

  return (
    <div className={styles.component} data-testid="infoCheckBox">
      <TickCircleIcon />
      {label}
    </div>
  );
};

export default InfoCheckbox;
