/* eslint-disable import/prefer-default-export */
import React from 'react';

import { MDText } from 'i18n-react';

import { ArrowRightIcon, IconText } from '@motorway/mw-highway-code';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../../utilities/analytics';
import type { DamageCategoryType } from '../../../../utilities/Types/category.types';
import type { DamageKindType, ImageCategoryType } from '../../../../utilities/Types/vehiclePhotosCategories.types';
import { VEHICLE_DAMAGE_KIND_KEY, VEHICLE_PHOTOS_CATEGORY_KEY } from '../../../../utilities/vehiclePhotosCategories';
import type { ImageAssessmentsStatuses } from '../../../context/Socket/Socket.types';
import { PANEL_TYPES } from '../../Help/HelpPanel.helpers';
import LocalTexts from '../VehiclePhotos.json';

const LocalT = new MDText(LocalTexts);

export const getCardAction = (category: ImageCategoryType | DamageKindType) => {
  let action = null;

  if (category) {
    let iconTextProps = null;
    let href = null;
    let contentType = null;

    switch (category) {
      case VEHICLE_PHOTOS_CATEGORY_KEY.EXTERIOR:
      case VEHICLE_PHOTOS_CATEGORY_KEY.WHEELS:
      case VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE:
        iconTextProps = {
          icon: ArrowRightIcon,
          reverse: true,
          text: LocalT.translate('vehiclePhotos.category.tips.photo.action'),
        };
        href = `${PANEL_TYPES.HELP}`;
        contentType = 'photo';
        break;
      case VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WHEELS:
      case VEHICLE_DAMAGE_KIND_KEY.DAMAGE_TYRES:
        iconTextProps = {
          icon: ArrowRightIcon,
          reverse: true,
          text: LocalT.translate('vehiclePhotos.category.tips.help.action'),
        };
        href = `${PANEL_TYPES.EXAMPLE}`;
        contentType = 'help';
        break;
      case VEHICLE_DAMAGE_KIND_KEY.DAMAGE_MECHANICAL_OR_ELECTRICAL:
        iconTextProps = {
          icon: ArrowRightIcon,
          reverse: true,
          text: LocalT.translate('vehiclePhotos.category.tips.help.action'),
        };
        href = `${PANEL_TYPES.HELP}`;
        contentType = 'help';
        break;
      default:
        break;
    }

    if (iconTextProps) {
      action = { contentType, href, label: <IconText {...iconTextProps} variant="xsmall--regular" /> };
    }
  }

  return action;
};

export const CATEGORY_PAGE_GA_EVENT_LABEL = Object.freeze({
  AI_IMAGE_ASSIST: {
    CATEGORY: 'AI image assist',
    LOCKED_ICON_LABEL: '{kind} photo input locked icon - button clicked',
    PAGE_LOADER_LABEL: '{category} category page - page loaded',
    PHOTO_INPUT_LABEL: '{kind} photo input - button clicked',
  },
  BACK_TO_SUMMARY_BUTTON_LABEL: '{title} category page Back to Summary - button clicked',
  CATEGORY: '{title} category page',
  DONE_BUTTON_LABEL: '{title} category page Done - button clicked',
  MESSAGE_CARD_PHOTO_TIP_CLICK: '{title} category page message card photo tip - button clicked',
  PAGE_LOADER_COMPLETED_LABEL: '{title} category page completed - page loaded',
  PAGE_LOADER_INITIATED_LABEL: '{title} category page initiated - page loaded',
  PAGE_LOADER_INPROGRESS_LABEL: '{title} category page in-progress - page loaded',
  PHOTO_INPUT_BUTTON_CLICK: '{title} category page {description} photo input - button clicked',
  PHOTO_INPUT_BUTTON_CLICK_RETAKE: '{title} category page {description} retake photo input - button clicked',
  PHOTO_TIP_BUTTON_CLICK: '{title} category page photo tip - button clicked',
});

export const categoryPagePhotoGaEventHandler = (
  { category, isRetake, kindDescription }: { category: DamageCategoryType, isRetake: boolean, kindDescription: string },
) => {
  const { CATEGORY, PHOTO_INPUT_BUTTON_CLICK, PHOTO_INPUT_BUTTON_CLICK_RETAKE } = CATEGORY_PAGE_GA_EVENT_LABEL;

  const buttonLabel = isRetake ? PHOTO_INPUT_BUTTON_CLICK_RETAKE : PHOTO_INPUT_BUTTON_CLICK;
  const title = LocalT.translate(`vehiclePhotos.category.${category}.title`);
  const categoryEvent = LocalT.translate(CATEGORY, ({ title }));
  const description = kindDescription.toLowerCase();
  const buttonEventLabel = LocalT.translate(buttonLabel, ({ description, title }));

  GA_EVENTS.DEFAULT(GA_EVENT_ACTIONS.BUTTON, categoryEvent, buttonEventLabel);
};

export const aiPhotoInputGaHandler = ({
  autoAssessStatus,
  category,
  fireAiImageAssistGaEvent,
  kindDescription,
}: {
  autoAssessStatus?: ImageAssessmentsStatuses,
  category: DamageCategoryType,
  fireAiImageAssistGaEvent?: boolean,
  kindDescription: string,
}) => {
  const { AI_IMAGE_ASSIST } = CATEGORY_PAGE_GA_EVENT_LABEL;
  const { CATEGORY, PHOTO_INPUT_LABEL } = AI_IMAGE_ASSIST;
  if (fireAiImageAssistGaEvent) {
    const title = LocalT.translate(`vehiclePhotos.category.${category}.title`);
    const eventCategory = LocalT.translate(CATEGORY, ({ title }));
    const eventLabel = LocalT.translate(PHOTO_INPUT_LABEL, ({ kind: kindDescription }));

    GA_EVENTS.IMAGE_ASSESSMENT({
      eventAction: GA_EVENT_ACTIONS.BUTTON,
      eventCategory,
      eventLabel,
      eventValue: autoAssessStatus,
    });
  }
};
