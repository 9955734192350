import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../utilities/analytics';
import { IMAGE_CATEGORIES, VEHICLE_DAMAGE_KIND_KEY } from '../../../utilities/vehiclePhotosCategories';

export const WHEEL_DAMAGE_VALUE = 'hasDamagedWheels';
export const TYRE_PROBLEMS_VALUE = 'hasTyreProblems';

export const HAS_DAMAGE_FIELDS = {
  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_TYRES]: TYRE_PROBLEMS_VALUE,
  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WHEELS]: WHEEL_DAMAGE_VALUE,
} as const;

export const MARK_DAMAGE_IMAGE_CATEGORY = {
  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WHEELS]: IMAGE_CATEGORIES.WHEELS,
  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_TYRES]: IMAGE_CATEGORIES.TYRES,
} as const;

export const IMAGE_SIZE = Object.freeze({
  height: 256,
  width: 343,
} as const);

export const WHEELS_ORDER = Object.freeze({
  wheels_front_driver: 1,
  wheels_front_passenger: 0,
  wheels_rear_driver: 2,
  wheels_rear_passenger: 3,
} as const);

export const TYRES_ORDER = Object.freeze({
  tyre_tread_front_driver: 0,
  tyre_tread_front_passenger: 1,
  tyre_tread_rear_driver: 3,
  tyre_tread_rear_passenger: 2,
} as const);

export const GA_EVENTS_CONDITION_DAMAGE = (category?: string, label?: string, value?: boolean) => GA_EVENTS.DEFAULT(
  GA_EVENT_ACTIONS.CHECKBOX,
  `${category} damage`,
  label,
  value,
);
