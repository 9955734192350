import { ReactNode, useEffect } from 'react';

import { MDText } from 'i18n-react';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../../utilities/analytics';

import CATEGORY_DAMAGE_PAGE_GA_EVENT_LABEL from './CategoryDamage.helper';
import LocalTexts from './CategoryDamage.json';

const LocalT = new MDText(LocalTexts);

const useCategoryDamagePageLoaderGaEvent = ({ hasImages, title }: { hasImages: boolean, title: ReactNode }) => {
  useEffect(() => {
    const { PAGE_LOADER } = GA_EVENT_ACTIONS;

    const { GUIDANCE_CATEGORY, GUIDANCE_PAGE_LOADER_LABEL } = CATEGORY_DAMAGE_PAGE_GA_EVENT_LABEL.GUIDANCE_PAGE;
    const { DAMAGE_CATEGORY, DAMAGE_PAGE_LOADER_LABEL } = CATEGORY_DAMAGE_PAGE_GA_EVENT_LABEL.DAMAGE_CATEGORY_PAGE;

    const categoryLabel = LocalT.translate(hasImages ? DAMAGE_CATEGORY : GUIDANCE_CATEGORY, ({ title }));

    const pageLoaderLabel = LocalT.translate(hasImages
      ? DAMAGE_PAGE_LOADER_LABEL : GUIDANCE_PAGE_LOADER_LABEL, ({ title }));

    GA_EVENTS.DEFAULT(PAGE_LOADER, categoryLabel, pageLoaderLabel);
  }, [hasImages, title]);
};

export default useCategoryDamagePageLoaderGaEvent;
