import React from 'react';

import { MDText } from 'i18n-react';

import { AgentsBox } from '@motorway/motorway-storybook-cra';

import { formatLandline } from '../../../utilities/helpers';
import { EmailLink, PhoneLink } from '../ContactButtons/ContactButtons';

import LocalTexts from './HelpPanel.json';

import styles from './HelpPanel.module.scss';

const LocalT = new MDText(LocalTexts);

const ContactPanel = () => {
  const [landlineNoSpace, landlineWithSpace] = formatLandline(
    LocalT.translate('landlineNumber') as string,
  );

  const landlineCallUs = LocalT.translate('landlineNumber') as string;

  const email = LocalT.translate('email') as string;
  const openHours = Object.values(LocalTexts.openHours) as string[];

  return (
    <div className={styles.contactBlock}>
      <AgentsBox className={styles.agentsBox} />
      <p className={styles.info}>{LocalT.translate('strap')}</p>
      <ul className={styles.contactLinks}>
        <li><EmailLink email={email} /></li>
        <li>
          <PhoneLink {...{ landlineCallUs, landlineNoSpace, landlineWithSpace }} />
        </li>
      </ul>
      <ul>
        {openHours.map((i) => (<li key={i} className={styles.openHours}>{LocalT.translate(i)}</li>))}
      </ul>
    </div>
  );
};

export default ContactPanel;
