import React from 'react';

import styles from './SectionHeader.module.scss';

type SectionHeaderProps = {
  children: React.ReactNode;
  title: string;
}

export const SectionHeader = ({ children, title }: SectionHeaderProps) => (
  <div className={styles.component}>
    <h1>{title}</h1>
    {children}
  </div>
);
