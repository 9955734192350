import { handleCatchIfOnline } from '../../../utilities/api/helpers';
import { IMAGE_STATUSES, isRetake as retakeStatuses } from '../../../utilities/helpers';
import { archiveImage, uploadedImageUpdateDB, uploadImage } from '../../../utilities/imageActions';
import type { Offer } from '../../../utilities/Types/offer.types';
import type { Photo } from '../../../utilities/Types/photo.types';
import type { User } from '../../../utilities/Types/seller.types';
import type { CategoryType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { QueryableWorker } from '../../worker';
import Obsolete from '../Obsolete/Obsolete.json';

import { destructureStream, updateWorkerStreamState } from './Overlay.helpers';
import { CanvasData, RawCanvasData, Stream } from './Overlay.types';

type TimingGA = {
  timingCategory: string;
  timingLabel: string;
  timingValue: number;
  timingVar: string;
}

type ProcessImageHelpers = {
  $video: HTMLVideoElement;
  categoryItem: CategoryType;
  imageToReplace: Photo | null;
  offer: Offer;
  seller: User;
  setParentState: (state: Record<string, any>, cb?: () => void) => void;
  startCaptureImage: number;
  stream: Stream;
  updateContextPhoto: (photoObj: Partial<Photo>, toReplace?: boolean) => void;
}

type SaveImageHelpers = Pick<ProcessImageHelpers, 'imageToReplace' | 'seller' | 'setParentState' |'stream' >

let timingsGA: TimingGA[] = [];

export const addFinishGA = (startTakePhoto: number, startWorker: number) => {
  timingsGA.push({
    timingCategory: 'Image capture',
    timingLabel: 'captureImage',
    timingValue: Math.round(performance.now() - startTakePhoto),
    timingVar: 'takePhoto finish',
  });

  timingsGA.push({
    timingCategory: 'Image capture',
    timingLabel: 'captureImage',
    timingValue: Math.round(performance.now() - startWorker),
    timingVar: 'worker finish',
  });
};

const flushTimingsGA = () => {
  timingsGA.forEach((v) => {
    window?.dataLayer?.push?.({ event: 'UATiming', ...v });
  });

  timingsGA = [];
};

export const generateCanvas = (
  { bitmap, height, width }: RawCanvasData,
  stream: Stream | null,
): CanvasData | Error => {
  const startGenerateCanvas = performance.now();

  // https://developers.google.com/web/updates/2018/08/offscreen-canvas
  // Can put `offscreen-canvas` into the worker when iOS blobs become more reliable
  const canvas = document.createElement('canvas');
  canvas.setAttribute('height', `${height}px`);
  canvas.setAttribute('width', `${width}px`);
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return new Error('Could not get canvas context');
  }
  ctx.drawImage(bitmap, 0, 0);

  // iOS can be over 40 million pixels and take over 2 seconds to loop over
  const imageData = ctx.getImageData(0, 0, width, height)?.data?.slice(0, 5_000_000);

  // iOS doesn't seem to respect the event listeners on the stream,
  // so have to update it manually...
  if (stream) {
    updateWorkerStreamState(stream.current);
  }

  const dataURL = canvas.toDataURL('image/jpeg');
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  timingsGA.push({
    timingCategory: 'Image capture',
    timingLabel: 'captureImage',
    timingValue: Math.round(performance.now() - startGenerateCanvas),
    timingVar: 'generateCanvas finish',
  });

  return { dataURL, height, imageData, width };
};

const saveImage = async (photoObj: Partial<Photo>, startCaptureImage: number, {
  imageToReplace, seller, setParentState, stream,
}: SaveImageHelpers) => {
  QueryableWorker
    .postMessage(['putPhotos', { photoObj }])
    .catch(() => {
      setParentState({
        error: {
          buttonCopy: 'Retry',
          errorHappened: true,
          message: Obsolete.generalErrorMessage,
        },
      });
    });

  let detail;

  try {
    if (imageToReplace && !retakeStatuses().includes(imageToReplace?.status)) {
      if (imageToReplace?.platformId) {
        await archiveImage(
          {
            authToken: seller.auth_token,
            platformId: imageToReplace.platformId,
          },
        ).catch((err) => handleCatchIfOnline(err, 'fetch-archive-image'));
      }
    }

    const result = await uploadImage({
      authToken: seller.auth_token,
      ...photoObj,
    }, destructureStream(stream.current));

    await uploadedImageUpdateDB(result.detail);

    detail = result.detail;
  } catch (err) {
    console.error(err);
  }

  timingsGA.push({
    timingCategory: 'Image capture',
    timingLabel: 'captureImage',
    timingValue: Math.round(performance.now() - startCaptureImage),
    timingVar: 'captureImage finish',
  });

  flushTimingsGA();

  return { ...photoObj, ...detail };
};

export const processImage = async (
  { dataURL, height, imageData, width }: CanvasData,
  {
    $video, categoryItem, imageToReplace, offer, seller,
    setParentState, startCaptureImage, stream, updateContextPhoto,
  }: ProcessImageHelpers,
) => {
  const now = imageToReplace ? imageToReplace.id : Date.now();
  const { category, kind } = categoryItem;

  // The reason why we're storing the dataURL is that Safari can't render it's own blob URLs
  // `WebKitBlobResource error 1`
  // https://bugs.webkit.org/show_bug.cgi?id=190351
  const photoObj = {
    // blob, // blobs are too flakey to be useable in iOS13
    autoAssessStatus: null,
    category,
    createdAt: now,
    damageMeta: null,
    dataURL,
    enquiryId: offer.enquiryId,
    height,
    id: now,
    isAutoAssessStatusFalsePositive: false,
    isDeleted: false,
    // See db init in app.js
    kind,
    status: IMAGE_STATUSES.SELLER_SUPPLIED,
    uploaded: false,
    vehicleId: offer.id,
    width,
  };

  updateContextPhoto(photoObj);
  timingsGA.push({
    timingCategory: 'Image capture',
    timingLabel: 'captureImage',
    timingValue: Math.round(performance.now() - startCaptureImage),
    timingVar: 'thumbnail created',
  });
  let isFulfilled = false;

  // If height (videoHeight), width (videoWidth) are zero (this happens when video readyState is 0 by default)
  // If the height or width of the canvas is 0, the string "data:," is returned.
  // Then we submit that as `text/plain`. This explains the empty file errors on DP
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement/videoHeight
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
  return Promise.race([
    QueryableWorker.postMessage(['analyzeImage', {
      height,
      imageData,
      videoReadyState: $video?.readyState,
      width,
    }]).then((res) => {
      if (res?.isBrokenImage && !isFulfilled) {
        isFulfilled = true;
        updateContextPhoto({ ...photoObj, isBrokenImage: true, uploaded: true }, true);
        setParentState({ showGlobalMessage: 'blackCamera' });
        return null;
      }

      if (!isFulfilled) {
        isFulfilled = true;
        return saveImage(photoObj, startCaptureImage, {
          imageToReplace, seller, setParentState, stream,
        });
      }
      return null;
    }),
    new Promise((resolve) => {
      setTimeout(() => {
        if (!isFulfilled) {
          isFulfilled = true;
          resolve(saveImage(photoObj, startCaptureImage, {
            imageToReplace, seller, setParentState, stream,
          }));
        }
      }, 1000);
    }),
  ]);
};
