import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Input, LoadingSpinner, ToastStatuses, useToast } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';

import { login } from '../../../utilities/api';
import { isOnLine } from '../../../utilities/hooks';
import { captureDatadogException } from '../../../utilities/logger';
import Camera from '../Camera/Camera';
import Wrapper from '../Wrapper/Wrapper';

import LocalTexts from './NoUser.json';

import styles from './NoUser.module.scss';

const LocalT = new MDText(LocalTexts);

const apiErrorText = LocalT.translate('apiGeneralError');

const checkEmail = (email: string) => {
  if (email && email.split('@')[1]) {
    const isMobile = /(Android|iPhone|iPad)/.test(window.navigator.userAgent);
    const isIOS = /(iPhone|iPad)/.test(window.navigator.userAgent);
    const domain = email.split('@')[1].toLowerCase();

    let emailProvider;
    const emailOptions = [];

    switch (true) {
      case /(hotmail|outlook|live.co|msn.co|windowslive.co)/.test(domain):
        emailProvider = ['Outlook', 'https://outlook.live.com/mail/#/inbox', 'ms-outlook://'];
        break;
      case /(gmail|googlemail|google|motorway.co.uk)/.test(domain):
        emailProvider = ['Gmail', 'https://mail.google.com/mail/u/0/#inbox', 'googlegmail://'];
        break;
      case /(yahoo|ymail)/.test(domain):
        emailProvider = ['Yahoo', 'https://mail.yahoo.com/', 'ymail://'];
        break;
      case /btinternet/.test(domain):
        emailProvider = ['BT Internet', 'https://www.bt.com/email', null];
        break;
      case /aol.co/.test(domain):
        emailProvider = ['AOL', 'https://mail.aol.co.uk/', null];
        break;
      case /sky.com/.test(domain):
        emailProvider = ['Sky', 'https://skyid.sky.com/signin/email', null];
        break;
      default:
        break;
    }

    if (emailProvider) {
      const emailHref = (isIOS) ? emailProvider[2] : emailProvider[1];
      let displayProvider = true;

      if (isIOS === true && !emailProvider[2]) {
        displayProvider = false;
      }

      if (displayProvider === true) {
        emailOptions.push((
          <a
            key={emailProvider[0]}
            className={[styles.emailIcon, styles[`emailIcon${emailProvider[0]?.replace(' ', '')}`]].join(' ')}
            href={emailHref as string}
            rel="noopener noreferrer"
            target="_blank"
          >
            {emailProvider[0]}
          </a>
        ));
      }
    }

    if (isMobile === true) {
      if (isIOS) {
        emailOptions.push((
          <a
            key="ios"
            className={[styles.emailIcon, styles.emailIconMail].join(' ')}
            href="message:"
            rel="noopener noreferrer"
            target="_blank"
          >
            Mail
          </a>
        ));
      }
    }

    if (emailOptions.length > 0) {
      return (
        <div className={cx(styles.emailQuickLink, (isIOS) ? styles.emailIconSystemIOS : styles.emailIconSystemWeb)}>
          <p>{LocalT.translate(((isIOS) ? 'openIn' : 'goTo'))}</p>
          { emailOptions }
        </div>
      );
    }
  }

  return null;
};

export const NoUser = () => {
  const { add } = useToast();
  const { vrm } = useParams();
  const [dialog, setDialog] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [errorValue, setErrorValue] = useState<React.ReactNode>(null);
  const [apiMessage, setApiMessage] = useState<React.ReactNode | string>(null);

  useEffect(() => {
    if (dialog === 'failure' && apiMessage === apiErrorText) {
      add(
        (
          <div>
            <h6>{LocalT.translate('toast.heading')}</h6>
            <p>
              { LocalT.translate('toast.content', { link: (<a href={`/${vrm}`}>{ LocalT.translate('toast.linkText') }</a>) })}
            </p>
          </div>
        ), ToastStatuses.ERROR,

        {
          className: styles.toast,
          longMessage: true,
        },
      );
    }
  }, [add, apiMessage, dialog, vrm]);

  const onChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    const val = currentTarget.value;

    setErrorValue(null);
    setInputValue(val);
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const targetElem = e.target as HTMLElement;
    const input = targetElem.firstElementChild?.children?.[1]?.firstElementChild as HTMLInputElement;
    if (!inputValue) {
      return setErrorValue(LocalT.translate('error.yourEmail'));
    }

    if (!input?.checkValidity() || !inputValue.match('\\.')) {
      return setErrorValue(LocalT.translate('error.validEmail'));
    }

    setDialog('loading');
    setApiMessage(null);
    try {
      const res = await login(inputValue, vrm);
      const emailSent = (res && res.message === 'Sent login email') ? 'success' : 'failure';
      setDialog(emailSent);
      return setApiMessage(res.message);
    } catch (err) {
      captureDatadogException({
        context: { isOnline: isOnLine(), vrm },
        error: err,
        fingerprint: 'fetch-login-email',
      });
      setDialog('failure');
      return setApiMessage(apiErrorText);
    }
  };

  let content;

  switch (dialog) {
    case 'loading':
      content = (
        <Wrapper>
          <LoadingSpinner />
        </Wrapper>
      );
      break;
    case 'success':
      content = (
        <Camera cameraClassName={styles.cameraHide} className={styles.component}>
          <div>
            <h2>{ LocalT.translate('sentTitle') }</h2>
            <p className={styles.strap}>{ LocalT.translate('sentStrap', { email: inputValue, link: (<a href={`tel:${LocalT.translate('phoneNumber')}`}>{ LocalT.translate('phoneNumber') }</a>) })}</p>
            { checkEmail(inputValue) }
          </div>
        </Camera>
      );
      break;
    default:
      content = (
        <Camera cameraClassName={styles.camera} className={styles.component}>
          <h2 className={styles.noUserTitle}>{ LocalT.translate('title') }</h2>
          <p>{ LocalT.translate('strap', { link: (<a href={`tel:${LocalT.translate('phoneNumber')}`}>{ LocalT.translate('phoneNumber') }</a>) })}</p>
          <form noValidate className="mw-input mw-input-text" onSubmit={submit}>
            <Input
              className={styles.inputContainer}
              feedbackTooltip={{
                content: errorValue,
              }}
              inputProps={{
                id: 'link-expired',
                input: {
                  name: 'email',
                  onChange,
                  placeholder: 'e.g. johnsmith@gmail.com',
                  type: 'email',
                  value: inputValue,
                },
              }}
              intent={errorValue ? 'error' : null}
              isError={!!errorValue}
              label={LocalT.translate('label')}
            />
            {
              (dialog === 'failure') ? (
                <p className={styles.error}>{apiMessage || apiErrorText}</p>
              ) : (null)
            }
            <Button fullWidth label={LocalT.translate('cta')} type="submit" />
          </form>
        </Camera>
      );
  }

  useEffect(() => {
    window.dataLayer.push({
      event: 'Pageview',
      pageTitle: `Link expired - ${dialog || 'Resend email'}`,
    });
  }, [dialog]);

  return (
    <>
      {content}
    </>
  );
};

export default NoUser;
