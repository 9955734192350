import React from 'react';
import cx from 'classnames';

import { ReactComponent as CameraIcon } from '../../../assets/images/camera.svg';
import Wrapper from '../Wrapper/Wrapper';

import styles from './Camera.module.scss';

type CameraProps = {
  cameraClassName?: string;
  children: React.ReactElement | React.ReactElement[];
  className?: string;
}

const Camera = ({ cameraClassName, children, className }: CameraProps) => (
  <Wrapper className={cx(className, styles.component)}>
    <div className={styles.content}>
      <div className={cx(styles.img, cameraClassName)}>
        <CameraIcon />
      </div>
      { children }
    </div>
  </Wrapper>
);

export default Camera;
