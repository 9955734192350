import React, { forwardRef } from 'react';

type SideSVGProps = {
  bodyType?: string,
  useHref?: string,
} & React.SVGProps<SVGSVGElement>;

const DriverSideSVG = (props: SideSVGProps, ref: React.ForwardedRef<SVGSVGElement>) => {
  const { bodyType, useHref, ...rest } = props;

  return (
    <svg ref={ref} fill="none" height="236" viewBox="0 0 644 236" width="644" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_4029_101949)">
        <path d="M353.685 9.96075C358.479 13.0934 358.629 14.7442 354.621 17.4641" stroke="white"/>
        <path d="M479.512 76.7413C491.35 85.9141 498.767 89.8347 517.553 92.4984C549.731 95.8561 567.787 99.7954 599.965 107.505C607.888 107.505 613.039 108.312 626.187 114.071L626.356 113.827C620.362 107.599 609.742 98.1446 597.156 91.5604C571.552 82.1812 508.75 62.6724 462.299 59.6711C432.65 44.6643 370.709 14.088 360.22 11.837C335.553 3.71463 250.893 -6.84638 113.789 9.66106C241.172 1.61368 308.45 0.431903 355.557 17.4646C405.191 36.223 428.023 53.2932 479.531 76.7413H479.512Z" stroke="white"/>
        <path d="M38.0069 192.293C28.7168 123.168 143.083 78.8985 163.274 197.977L157.018 193.794C149.207 212.365 141.153 220.694 119.633 230.636L119.277 230.804C113.058 233.543 106.184 235.063 98.9546 235.063C72.6201 235.063 50.9869 214.897 48.6082 189.161L48.3834 189.104H45.5739L38.0069 192.293Z" stroke="white"/>
        <path d="M587.81 202.235L582.49 197.002C576.909 218.893 557.092 235.063 533.492 235.063C525.813 235.063 518.546 233.356 512.028 230.279L511.953 230.373C494.328 224.67 486.63 216.492 476.366 193.794H469.81H469.66L469.81 191.937C474.493 106.586 597.175 94.393 594.365 201.316L587.81 202.254V202.235Z" stroke="white"/>
        <path d="M585.09 155.296L585.108 155.295M585.108 155.295L586.232 155.258C616.013 154.001 643.265 146.104 642.891 146.048L637.272 133.855C637.211 133.146 637.094 132.269 636.907 131.288C636.697 134.376 634.534 136.688 629.779 136.688L618.541 137.626C599.811 134.812 595.128 108.55 611.985 112.302L626.033 116.991C629.665 118.476 632.624 121.095 634.536 123.949C633.395 121.591 631.844 119.387 629.78 117.91L626.034 114.158C612.885 108.399 607.734 107.612 599.811 107.593C567.633 99.8831 549.577 95.9438 517.399 92.5861C498.594 89.9224 491.271 86.0957 479.414 76.904L475.369 74.9907C470.742 79.1926 468.083 81.2935 464.505 81.8187L464.936 82.1564C471.567 88.3842 468.476 90.185 455.59 89.7723H454.653C469.787 115.152 465.049 142.09 454.653 192.006H469.637C473.477 122.037 556.601 101.234 584.902 154.902L585.108 155.295Z" data-panel="driver_side_wing" id={`${bodyType}-driver_side-driver_side_wing`} stroke="white" />
        <path d="M626.033 116.991L611.985 112.302C595.128 108.55 599.811 134.812 618.541 137.626L629.779 136.688C641.954 136.688 637.14 121.531 626.033 116.991Z" stroke="white"/>
        <path d="M533.492 235.062C505.562 235.062 482.92 212.386 482.92 184.414C482.92 156.442 505.562 133.767 533.492 133.767C561.421 133.767 584.063 156.442 584.063 184.414C584.063 212.386 561.421 235.062 533.492 235.062Z" stroke="white"/>
        <path d="M533.492 223.807C511.769 223.807 494.159 206.171 494.159 184.415C494.159 162.659 511.769 145.022 533.492 145.022C555.215 145.022 572.825 162.659 572.825 184.415C572.825 206.171 555.215 223.807 533.492 223.807Z" stroke="white"/>
        <path d="M98.9547 223.807C77.2316 223.807 59.6215 206.171 59.6215 184.415C59.6215 162.659 77.2316 145.022 98.9547 145.022C120.678 145.022 138.288 162.659 138.288 184.415C138.288 206.171 120.678 223.807 98.9547 223.807Z" stroke="white"/>
        <path d="M98.9539 235.062C71.0242 235.062 48.3827 212.386 48.3827 184.414C48.3827 156.442 71.0242 133.767 98.9539 133.767C126.884 133.767 149.525 156.442 149.525 184.414C149.525 212.386 126.884 235.062 98.9539 235.062Z" stroke="white"/>
        <path d="M480.112 77.4915C486.536 69.2378 480.112 65.6174 463.255 59.671" stroke="white"/>
        <path d="M585.262 155.189C615.043 153.932 643.232 145.265 643.045 145.959C642.857 146.653 641.172 153.463 641.172 153.463L639.299 170.345L633.68 187.228C623.191 196.982 603.093 200.678 594.347 201.297C594.852 182.219 591.35 166.95 585.262 155.189Z" data-panel="front_bumper" id={`${bodyType}-driver_side-front_bumper`} stroke="white"/>
        <path d="M105.51 70.926C98.9543 66.2363 114.875 14.6506 151.398 14.6506L257.223 11.8369C305.922 12.9248 332.912 15.476 378.969 33.4091L455.762 74.6776C472.732 86.9269 472.75 90.2472 455.762 89.6844L271.271 77.4914L105.51 70.926Z" stroke="white"/>
        <path d="M404.404 86.1007L422.048 86.8698C438.643 82.8555 437.969 74.6768 423.921 68.1114C414.743 63.8157 408.375 63.1404 405.041 63.1779C403.43 63.1966 402.288 64.5097 402.157 66.1042L400.827 82.0489C400.64 84.1686 402.269 86.0069 404.404 86.1007Z" data-panel="driver_side_wing_mirror" id={`${bodyType}-driver_side-driver_side_wing_mirror`} stroke="white"/>
        <path d="M270.334 77.4914L258.16 11.8369" stroke="white"/>
        <path d="M105.511 70.9262C105.511 70.9262 147.166 133.898 177.621 174.098C187.511 186.497 195.827 188.673 213.209 188.166L277.471 189.161H277.884L277.827 188.166C283.821 160.404 275.336 102.816 270.335 77.4917L105.511 70.9262Z" data-panel="driver_side_rear_door" id={`${bodyType}-driver_side-driver_side_rear_door`} stroke="white"/>
        <path d="M33.2879 79.461L20.2892 77.4913L14.6702 84.0568L13.228 110.863L13.9959 111.144C27.2006 112.269 34.5615 111.369 47.6725 107.974L47.7849 107.918L35.2545 84.9947C34.2618 82.6499 33.625 81.2618 33.2691 79.461H33.2879Z" stroke="white"/>
        <path d="M177.622 174.096C187.512 186.496 195.828 188.672 213.209 188.165L454.827 191.917H469.811L468.875 204.11L165.279 199.439C175.543 190.248 177.622 174.096 177.622 174.096Z" data-panel="driver_side_rocker_panel" id={`${bodyType}-driver_side-driver_side_rocker_panel`} stroke="white"/>
        <path d="M59.4712 31.5711C59.4712 31.5711 116.13 15.5889 151.398 14.6509C114.875 14.6509 98.9354 66.1804 105.491 70.87V70.9263C105.491 70.9263 147.184 133.898 177.62 174.098C177.62 174.098 175.56 190.249 165.277 199.44H163.573C150.743 120.505 97.3059 111.82 64.2661 134.818H64.2473L64.135 134.649C49.7503 111.482 47.7836 107.918 47.7836 107.918L35.2532 84.9951C34.2605 82.6503 33.6236 81.2622 33.2678 79.4614L20.2691 77.4917L59.4524 31.5711H59.4712Z" data-panel="driver_side_rear_quarter_panel" id={`${bodyType}-driver_side-driver_side_rear_quarter_panel`} stroke="white"/>
        <path d="M64.1357 134.648L64.248 134.798C46.5294 147.047 34.8044 168.301 38.0821 192.856C24.5965 193.606 11.86 176.911 7.17752 168.47L5.30451 158.152L1.55849 131.891C9.05052 126.638 12.1784 120.954 12.7965 118.76L13.2086 110.956V110.881L13.9953 111.163C27.2 112.288 34.5609 111.388 47.6719 107.992L47.7843 107.936C47.7843 107.936 49.751 111.5 64.1357 134.667V134.648Z" data-panel="rear_bumper" id={`${bodyType}-driver_side-rear_bumper`} stroke="white"/>
        <path d="M151.4 14.6498L257.224 11.836C305.923 12.924 332.913 15.4751 378.97 33.4082L455.763 74.6768C459.528 77.3968 462.469 79.6853 464.566 81.5799L464.679 81.7487C468.256 81.2235 470.916 79.1413 475.542 74.9394C426.807 52.4293 403.9 35.753 355.557 17.4823C304.462 -1.01355 229.654 1.93153 80.2253 11.836H79.2888L69.9237 19.3394L59.4724 31.5699C59.4724 31.5699 116.131 15.5877 151.4 14.6498Z" data-panel="driver_side_corner_post" id={`${bodyType}-driver_side-driver_side_corner_post`} stroke="white"/>
        <path d="M454.825 89.6843C469.959 115.064 465.221 142.002 454.825 191.918L277.882 189.179L277.826 188.166C283.82 160.404 275.335 102.815 270.334 77.4913H271.271L454.825 89.6843Z" data-panel="driver_side_front_door" id={`${bodyType}-driver_side-driver_side_front_door`} stroke="white"/>
      </g>
      <defs>
        <clipPath id="clip0_4029_101949">
          <rect fill="white" height="236" width="644"/>
        </clipPath>
      </defs>
      <use href={useHref ? `#${bodyType}-driver_side-${useHref}` : ''} id="mw-use-active-driver-side" />
    </svg>
  );
};

export default forwardRef(DriverSideSVG);
