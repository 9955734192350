import React, { useEffect, useState } from 'react';
import type { Argument } from 'classnames';
import cx from 'classnames';

import { Tooltip } from '../Tooltip/Tooltip';

import styles from './Skip.module.scss';

type SkipProps = {
  className?: Argument;
  delay?: number;
  label: string;
  message?: string;
  skip: () => void;
}

export const Skip = ({ className, delay = 10, label, message, skip }: SkipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const hideDelay = 4000;

  useEffect(() => {
    setShowTooltip(false);

    const showTimer = setTimeout(() => setShowTooltip(true), delay);

    const hideTimer = setTimeout(() => {
      setShowTooltip(false);
    }, hideDelay + delay);

    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    };
  }, [message, delay]);

  return (
    <>
      <button
        className={cx(styles.skipButton, className)}
        onClick={skip}
        type="button"
      >
        {label}
      </button>

      {message && (
        <Tooltip showTooltip={showTooltip}>
          {message}
        </Tooltip>
      )}
    </>
  );
};
