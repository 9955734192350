import React, { useEffect } from 'react';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../utilities/analytics';
import { isChrome } from '../../../utilities/helpers';
import Camera from '../Camera/Camera';

import NO_PERMISSIONS_GA_EVENT_LABELS from './NoPermissions.helper';
import LocalTexts from './NoPermissions.json';

import styles from './NoPermissions.module.scss';

const LocalT = new MDText(LocalTexts);

const NoPermissions = () => {
  const { instructions } = LocalTexts.chrome;
  useEffect(() => {
    const { PAGE_LOADER } = GA_EVENT_ACTIONS;
    const { CATEGORY, PAGE_LOADER_LABEL } = NO_PERMISSIONS_GA_EVENT_LABELS;
    GA_EVENTS.DEFAULT(PAGE_LOADER, CATEGORY, PAGE_LOADER_LABEL);
  }, []);
  return (
    isChrome() ? (
      <Camera cameraClassName={styles.camera} className={styles.component}>
        <div>
          <h1>{LocalT.translate('chrome.title')}</h1>
          <div className={styles.copy}>{LocalT.translate('chrome.copy')}</div>
          <ol>
            {Object.keys(instructions).map((step) => (
              <li key={`chrome-${step}`}>{LocalT.translate(`chrome.instructions.${step}`)}</li>
            ))}
          </ol>
        </div>
      </Camera>
    ) : (
      <Content center className={styles.fullHeight}>
        <div>
          <p>{LocalT.translate('default.enableCamera')}</p>
          <p>{LocalT.translate('default.refreshBrowser')}</p>
        </div>
      </Content>
    )
  );
};

export default NoPermissions;
