import React, { useEffect } from 'react';

import { MDText } from 'i18n-react';

import { Button } from '@motorway/mw-highway-code';
import { InfoBox } from '@motorway/mw-highway-code/alpha';

import { MECHANICAL_AND_ADDITIONAL_EVENTS } from '../../../../utilities/analytics/events/damage/MechanicalAndAdditionalEvents';
import { useFeatureFlags } from '../../../../utilities/hooks';
import LocalTexts from '../HelpPanel.json';

import styles from './ElectricalMechanicalHelpDamagePanel.module.scss';

const LocalT = new MDText(LocalTexts);

type ElectricalMechanicalHelpDamagePanelProps = {
  closeHelpPage: (buttonType: string) => void;
  kind: 'damage_mechanical_or_electrical';
};

export const ElectricalMechanicalHelpDamagePanel: React.FC<
ElectricalMechanicalHelpDamagePanelProps
> = ({ closeHelpPage, kind }) => {
  const { showMechElecGuidanceImprovements } = useFeatureFlags();
  const mechanicalFaults = LocalTexts.photos[kind].mechanical.faults;
  const electricalFaults = LocalTexts.photos[kind].electrical.faults;
  const version = showMechElecGuidanceImprovements ? 'v2' : 'v1';

  useEffect(() => {
    if (showMechElecGuidanceImprovements) {
      const title = LocalT.translate(`photos.${kind}.title`) as string;
      MECHANICAL_AND_ADDITIONAL_EVENTS.MECHANICAL_AND_ADDITIONAL_MODAL_PAGE_LOAD(title);
    }
  }, [showMechElecGuidanceImprovements, kind]);

  return (
    <div className={styles.component}>
      <h3 className={styles.heading}>
        {LocalT.translate(`photos.${kind}.title`)}
      </h3>
      <div className={styles.content}>
        <div className={styles.faults}>
          <div>
            <h4 className={styles.faultsHeading}>
              {LocalT.translate(`photos.${kind}.mechanical.title`)}
            </h4>
            <ul>
              {mechanicalFaults.map((fault: string) => (
                <li key={fault}>{fault}</li>
              ))}
            </ul>
          </div>
          <div >
            <h4 className={styles.faultsHeading}>
              {LocalT.translate(`photos.${kind}.electrical.title`)}
            </h4>
            <ul>
              {electricalFaults.map((fault: string) => (
                <li key={fault}>{fault}</li>
              ))}
            </ul>
          </div>
        </div>
        <InfoBox
          content={LocalTexts.photos[kind].infoBox[version].description}
          heading={LocalTexts.photos[kind].infoBox[version].title}
          variant="info"
        />
      </div>
      <div className={styles.footer}>
        <Button
          fullWidth
          label={LocalT.translate('footerBtn')}
          onClick={() => closeHelpPage(LocalT.translate('footerBtn') as string)}
        />
      </div>
    </div>
  );
};
