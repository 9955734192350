import React, { useContext, useEffect } from 'react';

import { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import { Context } from '../../context/context';
import { Loader } from '../Loader/Loader';
import Video from '../Video/Video';

const Start = () => {
  const { onboardingLoader } = useContext(Context) as ContextValueTypes;

  useEffect(() => {
    document.head.insertAdjacentHTML('beforeend', '<style id="loader-dirty-car-styles"></style>');
  }, []);

  if (onboardingLoader) {
    return (<Loader />);
  }

  return (<Video />);
};

export default Start;
