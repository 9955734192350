import React from 'react';

import { MDText } from 'i18n-react';

import { Button } from '@motorway/mw-highway-code';

import LocalTexts from './Obsolete.json';

import styles from './Obsolete.module.scss';

const LocalT = new MDText(LocalTexts);

const UnsupportedBrowserAndroid = ({ href }: { href: string }) => (
  <>
    <h2>{ LocalT.translate('unsupportedBrowserAndroid.title') }</h2>
    <p className={styles.strap}>{ LocalT.translate('unsupportedBrowserAndroid.strap', { br: (<br />) }) }</p>
    <a href={`googlechrome://navigate?url=${href}`}>
      <Button label={LocalT.translate('unsupportedBrowserAndroid.cta')} />
    </a>
  </>
);

export default UnsupportedBrowserAndroid;
