import { BODY_CATEGORIES } from '../../../utilities/bodyType';
import { importAll } from '../../../utilities/helpers';
import { EXTERIOR_KINDS, INTERIOR_KINDS, TYRES_KINDS, WHEELS_KINDS } from '../../../utilities/vehiclePhotosCategories';
import { IMAGE_ASSESSMENTS_STATUSES } from '../../context/Socket/Socket.helpers';
import { ImageAssessmentsStatuses } from '../../context/Socket/Socket.types';

import { MapBodyTypeToImageAssist } from './ImageAssistModal.types';

const imageAssistImages = importAll(
  require.context('../../../assets/images/imageAssist', false, /.jpeg$/),
) as Record<string, string>;

const FILE_TYPE = 'jpeg';

const IMAGE_ASSIST_IMAGE_EXAMPLES = {
  [EXTERIOR_KINDS.EXTERIOR_FRONT_DRIVER]: imageAssistImages[`${EXTERIOR_KINDS.EXTERIOR_FRONT_DRIVER}.${FILE_TYPE}`],
  [EXTERIOR_KINDS.EXTERIOR_FRONT_PASSENGER]: imageAssistImages[`${EXTERIOR_KINDS.EXTERIOR_FRONT_PASSENGER}.${FILE_TYPE}`],
  [EXTERIOR_KINDS.EXTERIOR_REAR_DRIVER]: imageAssistImages[`${EXTERIOR_KINDS.EXTERIOR_REAR_DRIVER}.${FILE_TYPE}`],
  [EXTERIOR_KINDS.EXTERIOR_REAR_PASSENGER]: imageAssistImages[`${EXTERIOR_KINDS.EXTERIOR_REAR_PASSENGER}.${FILE_TYPE}`],
  [WHEELS_KINDS.WHEELS_FRONT_DRIVER]: imageAssistImages[`wheels.${FILE_TYPE}`],
  [WHEELS_KINDS.WHEELS_FRONT_PASSENGER]: imageAssistImages[`wheels.${FILE_TYPE}`],
  [WHEELS_KINDS.WHEELS_REAR_DRIVER]: imageAssistImages[`wheels.${FILE_TYPE}`],
  [WHEELS_KINDS.WHEELS_REAR_PASSENGER]: imageAssistImages[`wheels.${FILE_TYPE}`],
  [TYRES_KINDS.TYRE_TREAD_FRONT_DRIVER]: imageAssistImages[`front_tyre.${FILE_TYPE}`],
  [TYRES_KINDS.TYRE_TREAD_FRONT_PASSENGER]: imageAssistImages[`front_tyre.${FILE_TYPE}`],
  [TYRES_KINDS.TYRE_TREAD_REAR_DRIVER]: imageAssistImages[`rear_tyre.${FILE_TYPE}`],
  [TYRES_KINDS.TYRE_TREAD_REAR_PASSENGER]: imageAssistImages[`rear_tyre.${FILE_TYPE}`],
  [INTERIOR_KINDS.INTERIOR_FRONT_SEATS]: imageAssistImages[`${INTERIOR_KINDS.INTERIOR_FRONT_SEATS}.${FILE_TYPE}`],
  [INTERIOR_KINDS.INTERIOR_REAR_SEATS]: imageAssistImages[`${INTERIOR_KINDS.INTERIOR_REAR_SEATS}.${FILE_TYPE}`],
  [INTERIOR_KINDS.INTERIOR_DASHBOARD]: imageAssistImages[`${INTERIOR_KINDS.INTERIOR_DASHBOARD}.${FILE_TYPE}`],
  [INTERIOR_KINDS.INTERIOR_BOOT]: imageAssistImages[`${INTERIOR_KINDS.INTERIOR_BOOT}.${FILE_TYPE}`],
  [BODY_CATEGORIES.COMMERCIAL_VEHICLE]: imageAssistImages[`van_interior.${FILE_TYPE}`],
  [BODY_CATEGORIES.CONVERTIBLE]: imageAssistImages[`convertible.${FILE_TYPE}`],
  [BODY_CATEGORIES.VAN]: imageAssistImages[`van_interior.${FILE_TYPE}`],
} as const as Record<string, string>;

export const mapBodyTypeToImageAssist = ({ bodyCategory, kind }: MapBodyTypeToImageAssist) => {
  if (bodyCategory === BODY_CATEGORIES.COMMERCIAL_VEHICLE && kind === INTERIOR_KINDS.INTERIOR_BOOT) {
    return IMAGE_ASSIST_IMAGE_EXAMPLES[bodyCategory];
  }
  if (bodyCategory === BODY_CATEGORIES.VAN && kind === INTERIOR_KINDS.INTERIOR_BOOT) {
    return IMAGE_ASSIST_IMAGE_EXAMPLES[bodyCategory];
  }
  return IMAGE_ASSIST_IMAGE_EXAMPLES[kind];
};

export const MAPPED_MODAL_IMAGE_ASSESSMENTS_STATUSES = [
  IMAGE_ASSESSMENTS_STATUSES.BLURRED_IMAGE,
  IMAGE_ASSESSMENTS_STATUSES.BOOT_CLOSED,
  IMAGE_ASSESSMENTS_STATUSES.EXTRACT_AREA_TOO_SMALL,
  IMAGE_ASSESSMENTS_STATUSES.HAS_FINGER,
  IMAGE_ASSESSMENTS_STATUSES.INCORRECT_ORIENTATION,
  IMAGE_ASSESSMENTS_STATUSES.INCORRECT_POSITION,
  IMAGE_ASSESSMENTS_STATUSES.NO_VEHICLE_DETECTED,
  IMAGE_ASSESSMENTS_STATUSES.NO_WHEEL_DETECTED,
  IMAGE_ASSESSMENTS_STATUSES.VEHICLE_CUT_OFF,
  IMAGE_ASSESSMENTS_STATUSES.WHEEL_CUT_OFF,
  IMAGE_ASSESSMENTS_STATUSES.NO_TYRE_DETECTED,
] as readonly ImageAssessmentsStatuses[];

export const isMappedModalImageAssessmentStatus = (autoAssessStatus: ImageAssessmentsStatuses) =>
  MAPPED_MODAL_IMAGE_ASSESSMENTS_STATUSES.includes(autoAssessStatus);
