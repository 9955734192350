import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import type { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import { Context } from '../../context/context';

import LocalTexts from './Toast.json';

import styles from './Toast.module.scss';

const LocalT = new MDText(LocalTexts);

type ToastBannerProps = {
  action?: 'refresh' | ''
};

export const ToastBanner = ({ action = '' }: ToastBannerProps) => {
  let content;
  const context = useContext(Context) as ContextValueTypes;

  const [show, toggleShow] = useState(false);

  useEffect(() => {
    toggleShow(true);
  }, []);

  const triggerDismiss = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    toggleShow(false);

    setTimeout(() => {
      context.setParentState({
        toast: null,
      });

      switch (true) {
        case action === 'refresh':
          window.location.reload();
          break;
        default:
          break;
      }
    }, 250);
  };

  switch (true) {
    case action === 'refresh':
      content = (
        <div className={styles.toastContent}>
          <h4>
            {LocalT.translate('updated')}&nbsp;
            <a className={styles.link} href="#" onClick={(e) => e.preventDefault()} role="button">
              {LocalT.translate('reload')}
            </a>
            .&nbsp;
          </h4>
          <p className="mw-i">{LocalT.translate('saved')}</p>
        </div>
      );
      break;
    default:
      return null;
  }

  if (!content) {
    return null;
  }

  return (
    <Content
      className={styles.componentContent}
      wrapper={{
        className: cx(styles.component, styles[action], {
          [styles.show]: show,
        }),
        onClick: triggerDismiss,
      }}
    >
      {content}
    </Content>
  );
};

export default ToastBanner;
