import React, {
  createContext, useContext,
} from 'react';
import PropTypes from 'prop-types';

import { useGetPhotos, useSortedPhotos } from '../components/PhotosHub/PhotosHub.hooks';

const PhotosContext = createContext({
  loading: false,
  photos: [],
  sortedPhotos: {
    damage: [],
    exterior: [],
    interior: [],
    serviceHistory: [],
    wheels: [],
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updatePhoto: (id, context) => null,
  updatePhotoDamageMeta: (id, meta, isReplace) => null,
});

const PhotosProvider = ({ children }) => {
  const { addPhoto, deletedPhotos, loading, photos, removePhoto, updatePhoto, updatePhotoDamageMeta } = useGetPhotos();

  const { sortedPhotos } = useSortedPhotos(photos);

  const { sortedPhotos: sortedDeletedPhotos } = useSortedPhotos(deletedPhotos);

  return (
    <PhotosContext.Provider
      value={{
        addPhoto,
        deletedPhotos,
        loading,
        photos,
        removePhoto,
        sortedDeletedPhotos,
        sortedPhotos,
        updatePhoto,
        updatePhotoDamageMeta,
      }}
    >
      {children}
    </PhotosContext.Provider>
  );
};

const usePhotosContext = () => useContext(PhotosContext);

PhotosProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {
  PhotosProvider, PhotosContext, usePhotosContext,
};
