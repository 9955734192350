import React from 'react';
import cx from 'classnames';

import { Content } from '@motorway/motorway-storybook-cra';

import styles from './Footer.module.scss';

type FooterProps = {
  children: React.ReactNode;
  className?: string;
  reverse?: boolean;
  withShadow?: boolean;
}

const Footer = ({ children, className = '', reverse = false, withShadow = true }: FooterProps) => (
  <Content
    className={cx(styles.content, { [styles.reverse]: reverse }, className)}
    wrapper={{ className: cx(styles.stickyBar, { [styles.noShadowBox]: !withShadow }) }}
  >
    {children}
  </Content>
);

export default Footer;
