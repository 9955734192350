import { useCallback, useContext, useEffect, useState } from 'react';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../utilities/analytics';
import { getVehiclePhotos } from '../../../utilities/api';
import { isOnLine, useCallFuncWithLoading } from '../../../utilities/hooks';
import { captureDatadogException } from '../../../utilities/logger';
import { INTERIOR_KINDS } from '../../../utilities/vehiclePhotosCategories';
import { Context } from '../../context/context';
import { useSocket } from '../../context/Socket/Socket';

import {
  filterPhotos, getDBPhotos,
  PHOTO_CATEGORY,
  PHOTOS_HUB_GA_EVENT_LABEL,
} from './PhotosHub.helpers';

export const useGetPhotos = () => {
  const [photos, setPhotos] = useState([]);
  const [deletedPhotos, setDeletedPhotos] = useState([]);
  const { isLandscape, isUploadingFinished, offer, videoInfo = {} } = useContext(Context);
  const { refetch, resetRefetch } = useSocket();

  const getPhotos = useCallback(async (id) => {
    try {
      const { data } = await getVehiclePhotos(id);
      const deleted = filterPhotos(data || [], true);
      const filteredImages = filterPhotos(data || [], false);

      const mergedPhotos = await getDBPhotos({
        deletedImages: deleted,
        filteredImages,
        imageCategories: videoInfo.imageCategories,
        isLandscape,
        offer,
        setPhotos,
      });

      setDeletedPhotos(deleted);
      setPhotos(mergedPhotos);
    } catch (err) {
      captureDatadogException({
        context: { isOnLine: isOnLine(), offerId: id },
        error: err,
        fingerprint: 'fetch-vehicle-images',
      });
    }
  }, [isLandscape, offer, videoInfo.imageCategories]);

  const { callFunction, loading } = useCallFuncWithLoading(getPhotos);

  useEffect(() => {
    if (!offer?.id) {
      return;
    }

    callFunction(offer?.id);
  }, [offer?.id]);

  useEffect(() => {
    if (refetch && isUploadingFinished) {
      getPhotos(offer?.id);
      resetRefetch();
    }
  }, [getPhotos, isUploadingFinished, offer?.id, refetch, resetRefetch]);

  const updatePhoto = useCallback((imageId, data) => {
    setPhotos((prevPhotos) => {
      const originalImage = prevPhotos.find(({ id }) => id === imageId);

      if (!originalImage && !data.kind) {
        return prevPhotos;
      }

      const updatedImage = { ...originalImage, ...data };

      return [...prevPhotos.filter(({ id }) => id !== imageId), updatedImage];
    });
  }, []);

  const addPhoto = useCallback((photo) => {
    setPhotos((prevPhotos) => prevPhotos.concat(photo));
  }, []);

  const removePhoto = useCallback((imageId) => {
    setPhotos((prevPhotos) => [...prevPhotos.filter(({ id }) => id !== imageId)]);
    setDeletedPhotos((prevDeletedPhotos) => [...prevDeletedPhotos.filter(({ id }) => id !== imageId)]);
  }, []);

  const updatePhotoDamageMeta = useCallback((imageId, meta, replace) => {
    setPhotos((prevPhotos) => {
      const originalImage = prevPhotos.find(({ id }) => id === imageId);
      const updatedImage = { ...originalImage, damageMeta: meta };

      if (!originalImage) {
        return prevPhotos;
      }

      if (replace) {
        return [...prevPhotos.map((v) => {
          if (v.id === imageId) {
            return updatedImage;
          }
          return v;
        })];
      }

      return [...prevPhotos.filter(({ id }) => id !== imageId), updatedImage];
    });
  }, []);

  return {
    addPhoto,
    deletedPhotos,
    loading,
    photos,
    removePhoto,
    updatePhoto,
    updatePhotoDamageMeta,
  };
};

const { DAMAGE, EXTERIOR, INTERIOR, SERVICE_HISTORY, TYRE, WHEELS } = PHOTO_CATEGORY;

export const useSortedPhotos = (photos) => {
  const [{
    sortedPhotos: {
      damage,
      exterior,
      interior,
      serviceHistory,
      wheels,
    },
  }, setPhotos] = useState({
    sortedPhotos: {
      damage: [],
      exterior: [],
      interior: [],
      serviceHistory: [],
      wheels: [],
    },
  });

  useEffect(() => {
    if (!photos) {
      return;
    }

    const sortedPhotos = photos.reduce((acc, image) => {
      switch (true) {
        case image.kind.includes(SERVICE_HISTORY):
          acc.sortedPhotos.serviceHistory.push(image);
          break;
        case image.kind.includes(EXTERIOR):
          acc.sortedPhotos.exterior.push(image);
          break;
        case (image.kind.includes(INTERIOR) || image.kind === INTERIOR_KINDS.INSTRUMENT_CLUSTER):
          acc.sortedPhotos.interior.push(image);
          break;
        case image.kind.includes(WHEELS):
        case image.kind.includes(TYRE):
          acc.sortedPhotos.wheels.push(image);
          break;
        case image.kind.includes(DAMAGE):
        default:
          acc.sortedPhotos.damage.push(image);
          break;
      }

      return acc;
    }, {
      sortedPhotos: {
        damage: [],
        exterior: [],
        interior: [],
        serviceHistory: [],
        wheels: [],
      },
    });

    sortedPhotos?.sortedPhotos?.damage?.sort?.((a, b) => (b.createdAt - a.createdAt));

    setPhotos(sortedPhotos);
  }, [photos]);

  return {
    sortedPhotos: {
      damage,
      exterior,
      interior,
      serviceHistory,
      wheels,
    },
  };
};

export const useHubPageLoaderGaEvent = ({ completed, gaEventLabel, initiated }) => {
  useEffect(() => {
    let sellerProgressionStateLabel;

    switch (true) {
      case initiated:
        sellerProgressionStateLabel = gaEventLabel?.PAGE_LOADER_INITIATED_LABEL;
        break;

      case completed:
        sellerProgressionStateLabel = gaEventLabel?.PAGE_LOADER_COMPLETED_LABEL;
        break;

      default:
        sellerProgressionStateLabel = gaEventLabel?.PAGE_LOADER_INPROGRESS_LABEL;
    }

    GA_EVENTS.DEFAULT(GA_EVENT_ACTIONS.PAGE_LOADER, gaEventLabel?.CATEGORY, sellerProgressionStateLabel);
  }, [completed, initiated, gaEventLabel]);
};

export const useAiCatPageGaLoader = ({ fireAiImageAssistGaEvent, totalOutStandingImageAssessments }) => {
  useEffect(() => {
    if (fireAiImageAssistGaEvent) {
      const { AI_IMAGE_ASSIST } = PHOTOS_HUB_GA_EVENT_LABEL;
      GA_EVENTS.IMAGE_ASSESSMENT({
        eventAction: GA_EVENT_ACTIONS.PAGE_LOADER,
        eventCategory: AI_IMAGE_ASSIST.CATEGORY,
        eventLabel: AI_IMAGE_ASSIST.PAGE_LOADER_LABEL,
        eventValue: totalOutStandingImageAssessments,
      });
    }
  }, [fireAiImageAssistGaEvent, totalOutStandingImageAssessments]);
};
