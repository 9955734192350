import { datadogRum, RumEvent } from '@datadog/browser-rum';

type DataResource = {
  url: string
};

type ExtendedRumEvent = RumEvent & {
  resource?: DataResource
};

export const initDatadogRum = (isProd: boolean, version: string) => {
  const ENVIRONMENT = isProd ? 'production' : 'staging';

  return datadogRum.init({
    applicationId: '27e18634-f344-43e1-9a85-1e8b4a4e5be9',
    beforeSend: (event: ExtendedRumEvent) => {
      event.view.url = event.view.url.replace(/token=[^&]*/g, 'token=REDACTED');
      if (event.resource?.url) {
        event.resource.url = event.resource.url.replace(/token=[^&]*/g, 'token=REDACTED');
      }

      return true;
    },
    clientToken: 'pube02b86cce33bd40c6945badc0fbecf40',
    defaultPrivacyLevel: 'mask-user-input',
    env: ENVIRONMENT,
    service: 'photos-webapp',
    sessionReplaySampleRate: 0,
    sessionSampleRate: 100,
    site: 'datadoghq.eu',
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version,
  });
};
