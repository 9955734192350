import { useCallback, useContext } from 'react';

import type { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import type { KindType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { Context } from '../../context/context';
import { getCurrentImageItem } from '../Overlay/Overlay.helpers';

export const useUpdateDamageDetails = ({ kind }: {kind: KindType}) => {
  const context = useContext(Context);

  const { offer, seller, setParentState, videoInfo: { imageCategories } } = context as ContextValueTypes;

  const { vehicles } = seller || {};

  const vehicle = (vehicles || []).find(({ enquiry }) => enquiry?.id === offer?.enquiryId);

  const currentDamageKind = imageCategories.damage.filter(({ kind: categoryKind }) => categoryKind === kind);

  const categoryItem = getCurrentImageItem(currentDamageKind);

  const localStorageKey = `details_${vehicle?.enquiry?.dpEnquiryId}_${categoryItem.kind}`;
  const localStorageDetails = localStorage.getItem(localStorageKey);

  const updateDamageDetails = useCallback((details) => {
    if (localStorageDetails) {
      const imgData = JSON.parse(localStorageDetails);
      const updatedImgData = { ...imgData, damageMeta: { ...imgData.damageMeta, ...details } };
      setParentState({ showDamageLocationOverlay: updatedImgData });
      localStorage.setItem(localStorageKey, JSON.stringify(updatedImgData));
    }
  }, [localStorageKey, localStorageDetails, setParentState]);

  const deleteLocalStorageDetails = useCallback(() => localStorage.removeItem(localStorageKey), [localStorageKey]);

  return { deleteLocalStorageDetails, localStorageDetails, updateDamageDetails };
};
