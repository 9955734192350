import React, { forwardRef, useContext, useEffect, useState } from 'react';

import { LoadingSpinner } from '@motorway/motorway-storybook-cra';

import { captureDatadogException } from '../../../../utilities/logger';
import type { ContextValueTypes } from '../../../../utilities/Types/contextTypes';
import type { DamageSide } from '../../../../utilities/Types/panelDamage.types';
import { Context } from '../../../context/context';

import { SIDE_IMAGE_FALLBACK } from './ImageComponent.helpers';

import styles from './ImageComponent.module.scss';

type SVGInfoType = {
  svgChildren: string,
  svgHeight: string,
  svgWidth: string,
  viewBox: string,
};

type ImageComponentProps = {
  active: string | null;
  bodyType: string;
  currentSide: DamageSide;
  setIsFullBack: () => void;
  setLoaded: () => void;
};

export const transformSVGString = (svgString: string): SVGInfoType => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, 'text/html');
  const svg = doc.querySelector('svg');

  return {
    svgChildren: doc.querySelector('svg')?.innerHTML || '',
    svgHeight: svg?.getAttribute('height') ?? '',
    svgWidth: svg?.getAttribute('width') ?? '',
    viewBox: svg?.getAttribute('viewBox') ?? '',
  };
};

const defaultSVGInfo = {
  svgChildren: '', svgHeight: 0, svgWidth: 0, viewBox: '',
};

const ImageComponent = ({
  active,
  bodyType,
  currentSide,
  setIsFullBack,
  setLoaded,
}: ImageComponentProps, ref: React.ForwardedRef<SVGSVGElement>) => {
  const { panelDamageInfo } = useContext(Context) as ContextValueTypes;
  const [showFullBack, setShowFullBack] = useState(false);
  const [allImages, setAllImages] = useState<Record<string, SVGInfoType>>();

  const sideImageOpts = allImages?.[currentSide.id] || defaultSVGInfo;

  useEffect(() => {
    if (panelDamageInfo) {
      const imageList = (panelDamageInfo?.sides || []).map(({ id, imageUrl }) => ({ id, imageUrl }));
      Promise.all(imageList
        .map(({ imageUrl }) => fetch(imageUrl, {
          credentials: 'omit',
          mode: 'cors',
        }).then((res) => res.text()))).then((result) => {
        setAllImages(imageList.reduce<Record<string, SVGInfoType>>((acc, { id }, i) => {
          acc[id] = transformSVGString(result[i]);
          return acc;
        }, {}));
      }).catch((err) => {
        setIsFullBack();
        setShowFullBack(true);
        captureDatadogException({
          error: err,
          errorTitle: 'Error during fetching images for DamagePanel',
          fingerprint: 'damage-panel-fetch-images',
        });
      }).finally(() => {
        setLoaded();
      });
    }
  }, [panelDamageInfo]);

  const SideImage = SIDE_IMAGE_FALLBACK[currentSide.id];

  if (showFullBack) {
    return (
      <SideImage
        ref={ref}
        bodyType={bodyType}
        useHref={active}
      />
    );
  }

  if (!allImages) {
    return (
      <div className={styles.loader}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <svg
      ref={ref}
      fill="none"
      height={sideImageOpts.svgHeight}
      viewBox={sideImageOpts.viewBox}
      width={sideImageOpts.svgWidth}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g dangerouslySetInnerHTML={{ __html: sideImageOpts.svgChildren }} />
      <use href={active ? `#${bodyType}-${currentSide.id}-${active}` : undefined} />
    </svg>
  );
};

export default forwardRef(ImageComponent);
