import React, {
  useContext, useEffect, useMemo,
  useRef, useState,
} from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Button } from '@motorway/mw-highway-code';

import { getBodyType } from '../../../utilities/bodyType';
import { useLockRootScroll, usePrevious } from '../../../utilities/hooks';
import { captureDatadogException } from '../../../utilities/logger';
import { VEHICLE_DAMAGE_KIND_KEY } from '../../../utilities/vehiclePhotosCategories';
import { Context } from '../../context/context';
import { usePhotosContext } from '../../context/photos';
import CarBackIcon from '../CarIcons/CarBackIcon';
import CarDriverSideIcon from '../CarIcons/CarDriverSideIcon';
import CarFrontIcon from '../CarIcons/CarFrontIcon';
import CarPassengerSideIcon from '../CarIcons/CarPassengerSideIcon';
import CarTopIcon from '../CarIcons/CarTopIcon';
import ErrorBlock from '../ErrorBoundary/ErrorBlock';
import HelpButton from '../HelpButton/HelpButton';

import { importAll } from './DamageLocationOverlay.helpers';
import LocalTexts from './DamageLocationOverlay.json';
import type {
  DamageLocationOverlayProps, OverlayImages, Point, Points, Side,
} from './DamageLocationOverlay.types';

import styles from './DamageLocationOverlay.module.scss';

const LocalT = new MDText(LocalTexts);

const reloadHandler = (e: React.SyntheticEvent<HTMLAnchorElement, Event>) => {
  e.preventDefault();
  window.location.reload();
};

const overlayImages: OverlayImages = importAll(
  require.context('../../../assets/images/vehicle_damage_overlays', false, /.png$/),
);

const DAMAGE = {
  DENTS: VEHICLE_DAMAGE_KIND_KEY.DAMAGE_DENTS,
  PAINTWORK: VEHICLE_DAMAGE_KIND_KEY.DAMAGE_PAINTWORK,
  SCRATCHES: VEHICLE_DAMAGE_KIND_KEY.DAMAGE_SCRATCHES,
  WINDSCREEN: VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WINDSCREEN,
};

const { DENTS, PAINTWORK, SCRATCHES, WINDSCREEN } = DAMAGE;

const damageWithSidesSelection: string[] = [DENTS, PAINTWORK, SCRATCHES];

const DamageLocationOverlay = ({
  cancelClick, category, onSubmit, openHelp,
}: DamageLocationOverlayProps) => {
  const imgRef = useRef<HTMLImageElement>(null);

  // TODO remove when we will change Context to ts
  const { offer, seller }: any = useContext(Context);
  const { photos } = usePhotosContext();

  const damageMetaExists = useMemo(() => photos.some(({ damageMeta }) => damageMeta), [photos]);
  const shouldShowTooltips = useMemo(() => !damageMetaExists, [damageMetaExists]);

  const vehicle = seller.vehicles.find(
    ({ enquiry }: { enquiry: { id: string } }) => enquiry.id === offer.enquiryId,
  );

  const bodyType = getBodyType(vehicle);
  const overlays = overlayImages[bodyType];

  const sizes: string[] = ['small', 'medium', 'large'];

  const sides: Side[] = useMemo(() => [
    {
      icon: <CarFrontIcon />,
      key: 'front',
      label: 'Front',
    },
    {
      icon: <CarPassengerSideIcon />,
      key: 'passenger_side',
      label: 'Passenger side',
    },
    {
      icon: <CarBackIcon />,
      key: 'back',
      label: 'Back',
    },
    {
      icon: <CarDriverSideIcon />,
      key: 'driver_side',
      label: 'Driver side',
    },
    {
      icon: <CarTopIcon />,
      key: 'top',
      label: 'Top',
    },
  ], []);

  const [showError, setShowError] = useState(false);

  const [points, setPoints] = useState<Points>({});

  const [selectedSide, setSelectedSide] = useState(sides[0]);

  const previousSelectedSide = usePrevious(selectedSide);
  const previousPosition = usePrevious(points[selectedSide?.key]);

  const [imageHeight, setImageHeight] = useState<number>();

  const [showSideTooltip, setShowSideTooltip] = useState(false);
  const [showSizeTooltip, setShowSizeTooltip] = useState(false);
  const [showLocationTooltip, setShowLocationTooltip] = useState(false);

  useEffect(() => {
    if (damageWithSidesSelection.includes(category.kind)) {
      setShowSideTooltip(true);
    }
  }, [category.kind]);

  const movePoint = (image: HTMLImageElement, clientX: number, clientY: number) => {
    const offset = image.getBoundingClientRect();
    const relativeX = clientX - offset.left;
    const relativeY = clientY - offset.top;
    const multiplier = Number(((imgRef?.current?.naturalWidth || 0) / image.clientWidth).toFixed(2));

    if (
      relativeX < 0
      || relativeX > offset.width
      || relativeY < 0
      || relativeY > offset.height
    ) {
      return;
    }

    const absolutePosition = {
      x: (relativeX * multiplier).toFixed(),
      y: (relativeY * multiplier).toFixed(),
    };

    setPoints({
      ...points,
      [selectedSide.key]: {
        absolutePosition,
        size: (points[selectedSide.key] as Point)?.size || 'medium',
        x: relativeX,
        y: relativeY,
      },
    });

    window.dataLayer.push({
      event: 'UAEvent',
      eventAction: 'Damage location editor - damage location click',
      eventCategory: 'Damage capture',
    });
  };

  const onImageClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    movePoint(e.currentTarget as HTMLImageElement, e.clientX, e.clientY);
    setShowSideTooltip(false);
  };

  const onImageTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    movePoint(e.currentTarget as HTMLImageElement, e.touches[0].clientX, e.touches[0].clientY);
  };

  useLockRootScroll();

  const onSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const size = sizes[Number(target.value)];
    setPoints({
      ...points,
      [selectedSide.key]: { ...points[selectedSide.key] as Point, size },
    });

    setShowSizeTooltip(false);

    window.dataLayer.push({
      event: 'UAEvent',
      eventAction: 'Damage location editor - size click',
      eventCategory: 'Damage capture',
      eventLabel: size,
    });
  };

  const onSizeLabelClick = (selectedSize: string) => {
    setPoints({
      ...points,
      [selectedSide.key]: { ...points[selectedSide.key] as Point, size: selectedSize },
    });

    setShowSizeTooltip(false);

    window.dataLayer.push({
      event: 'UAEvent',
      eventAction: 'Damage location editor - size click',
      eventCategory: 'Damage capture',
      eventLabel: selectedSize,
    });
  };

  const onImageLoad = ({ target: img }: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (imageHeight !== (img as HTMLImageElement).offsetHeight) {
      setImageHeight((img as HTMLImageElement).offsetHeight);
    }
  };

  const onImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const side = selectedSide?.key || sides[0].key;

    captureDatadogException({
      error: new Error(e.type),
      errorTitle: `Error during getting damage location image, side: ${side}`,
      fingerprint: 'damage-location-image',
      isWarning: true,
    });
    setShowError(true);
  };

  const selectSide = (side: Side) => {
    setSelectedSide(side);
    setShowSizeTooltip(false);
    setShowSideTooltip(false);

    window.dataLayer.push({
      event: 'UAEvent',
      eventAction: 'Damage location editor - car side click',
      eventCategory: 'Damage capture',
    });
  };

  const submitDamage = () => {
    onSubmit({
      overlayUrl: overlays[selectedSide.key].s3Url,
      side: selectedSide.key,
      size: (points[selectedSide.key] as Point).size,
      ...(points[selectedSide.key] as Point).absolutePosition,
    });

    window.dataLayer.push({
      event: 'UAEvent',
      eventAction: 'Damage location editor - submit damage',
      eventCategory: 'Damage capture',
    });
  };

  const sideTooltipClick = () => {
    setShowSideTooltip(false);
  };

  const locationTooltipClick = () => {
    setShowLocationTooltip(false);
  };

  const sizeTooltipClick = () => {
    setShowSizeTooltip(false);
  };

  useEffect(() => {
    if (!selectedSide) {
      return undefined;
    }

    const hasAnyPoint = Object.keys(points).length > 0;

    const timeout = setTimeout(() => {
      setShowLocationTooltip(false);
    }, 3000);

    if (!hasAnyPoint && previousSelectedSide === null) {
      setShowLocationTooltip(true);
    }

    if (hasAnyPoint) {
      setShowLocationTooltip(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [points, previousSelectedSide, selectedSide]);

  useEffect(() => {
    if (!shouldShowTooltips) {
      return undefined;
    }

    const timeout = setTimeout(() => {
      setShowSizeTooltip(false);
    }, 3000);

    const isFirstPoint = Object.keys(points).length === 1;
    const position = points[selectedSide?.key];

    if (position && !previousPosition && isFirstPoint) {
      setShowSizeTooltip(true);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [points, previousPosition, selectedSide, shouldShowTooltips]);

  useEffect(() => {
    window.dataLayer.push({
      event: 'Pageview',
      pageTitle: 'Damage location editor',
    });
  }, []);

  const point = points[selectedSide?.key] as Point;
  const size = point?.size;

  const { url: overlayUrl } = selectedSide ? overlays[selectedSide.key] : overlays[sides[0].key];

  return (
    <div>
      {showError && (
        <ErrorBlock className={styles.errorBlock}>
          <p>Sorry, there was a problem. Please check your network connection and try again.</p>
          <a
            className="mw-link mw-link-white"
            href="#error"
            onClick={reloadHandler}
          >
              Refresh the page
          </a>
        </ErrorBlock>
      )}
      <div className={styles.dimmer} />
      <div className={styles.content}>
        <div className={styles.damageEditor}>
          <div className={styles.col}>
            <h2>{LocalT.translate(`title.${category.kind}`)}</h2>
            <div className={styles.imageContainer}>

              <div
                className={cx(styles.imageWrapper, { [styles.disabled]: !selectedSide })}
                onClick={onImageClick}
                onTouchMove={onImageTouchMove}
                role="img"
              >
                {points[selectedSide?.key] && (
                  <div
                    className={cx(styles.point, styles[size])}
                    style={{ left: point?.x, top: point?.y }}
                  />
                )}
                <img
                  ref={imgRef}
                  alt="car"
                  onError={onImageError}
                  onLoad={onImageLoad}
                  src={overlayUrl}
                />
              </div>

              <div
                className={cx(styles.locationTooltip, { [styles.active]: showLocationTooltip })}
                onClick={locationTooltipClick}
                role="button"
                tabIndex={0}
              >
                <span className={styles.side}>
                  <span className={styles.arrow} />
                </span>
                  Tap on the car to select the location of the damage.
              </div>

              <div
                className={cx(styles.sideTooltip, { [styles.active]: showSideTooltip })}
                onClick={sideTooltipClick}
                role="button"
                tabIndex={0}
              >
                <span className={styles.side}>
                  <span className={styles.arrow} />
                </span>
                {LocalT.translate('tooltip.side')}
              </div>
            </div>

            <div className={cx(styles.sidePicker, { [styles.hideSidePicker]: category.kind === WINDSCREEN })}>
              {sides.map((side) => (
                <div key={side.key} className={styles.sideItem}>
                  <button
                    className={cx(styles.sideButton, {
                      [styles.active]: side.key === selectedSide?.key,
                    })}
                    id={side.key}
                    onClick={() => selectSide(side)}
                    type="button"
                  >
                    {side.icon}
                  </button>
                  <label htmlFor={side.key}>{side.label}</label>
                </div>
              ))}
            </div>
          </div>
          <div className={cx(styles.sidebar)}>
            <button className={styles.cancelButton} onClick={cancelClick} type="button">Cancel</button>
            <div className={cx(styles.sidebarContent, { [styles.inactive]: !point })}>
              <div className={styles.sizeSlider}>
                <input
                  max={2}
                  min={0}
                  onChange={onSizeChange}
                  style={{ width: '44vh' }}
                  type="range"
                  value={sizes.indexOf(size)}
                />
                <div className={styles.labels} style={{ height: '44vh' }}>
                  <div className={styles.label} onClick={() => onSizeLabelClick(sizes[0])} role="button" tabIndex={-1}>
                      Small
                    <span>0 - 5cm</span>
                  </div>
                  <div className={styles.label} onClick={() => onSizeLabelClick(sizes[1])} role="button" tabIndex={-1}>
                      Medium
                    <span>6 - 15cm</span>
                  </div>
                  <div className={styles.label} onClick={() => onSizeLabelClick(sizes[2])} role="button" tabIndex={-1}>
                      Large
                    <span>16cm +</span>
                  </div>
                </div>

                <div
                  className={cx(styles.sizeTooltip, { [styles.active]: showSizeTooltip })}
                  onClick={sizeTooltipClick}
                  role="button"
                  tabIndex={-1}
                >
                  <span className={styles.side}>
                    <span className={styles.arrow} />
                  </span>
                  {LocalT.translate('tooltip.size')}
                </div>
              </div>
            </div>
            <div className={styles.submitButton}>
              <Button data-thc-button fullWidth disabled={!point} label="Submit" onClick={submitDamage} variant="secondary" />
            </div>
          </div>
          <HelpButton className={styles.helpButton} onClick={openHelp} />
        </div>
      </div>
    </div>
  );
};

export default DamageLocationOverlay;
