import React, { useContext } from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';

import { Checkbox } from '@motorway/mw-highway-code/alpha';

import type { ContextValueTypes } from '../../../../utilities/Types/contextTypes';
import type { Photo } from '../../../../utilities/Types/photo.types';
import { VEHICLE_PHOTOS_CATEGORY_KEY } from '../../../../utilities/vehiclePhotosCategories';
import { Context } from '../../../context/context';
import InfoCheckbox from '../../InfoCheckbox/InfoCheckbox';
import { GA_EVENTS_CONDITION_DAMAGE } from '../MarkDamaged.helpers';

import styles from '../MarkDamaged.module.scss';

export type TyreDamageInputProps = {
  isApproved: boolean,
  item: Photo,
}

const TyreDamageInput: React.FC<TyreDamageInputProps> = ({ isApproved, item }) => {
  const { videoInfo: { imageCategories } } = useContext(Context) as ContextValueTypes;
  const value = imageCategories[VEHICLE_PHOTOS_CATEGORY_KEY.WHEELS]
    .find((i) => i.kind === item.kind);

  const label = value?.damageDescription || '';

  return (
    <div
      className={cx(styles.item, { [styles.disabled]: isApproved })}
    >
      <Field
        id={item.kind}
        name={item.kind}
        type="checkbox"
      >
        {(formProps) => {
          if (isApproved) {
            return formProps?.input?.checked ? <InfoCheckbox label={label} /> : null;
          }
          return (
            <Checkbox
              fullWidth
              formProps={{
                ...formProps,
                input: {
                  ...formProps.input,
                  onChange: (e) => {
                    formProps.input.onChange(e);
                    GA_EVENTS_CONDITION_DAMAGE(value?.category, value?.description, !formProps?.input?.checked);
                  },
                },
              }}
              {...{ label }}
              id={item.kind}
              variant="card"
            />
          );
        }}
      </Field>
    </div>
  );
};

export default TyreDamageInput;
