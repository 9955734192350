import React, { useContext, useEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import cx from 'classnames';

import { uploadQueue } from '../../../utilities/imageActions';
import { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import { DamageKindType, ImageCategoryType, MainCategoryType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import {
  INTERIOR_KINDS,
  VEHICLE_DAMAGE_KIND_ORDER,
  VEHICLE_DAMAGE_KIND_PATH,
  VEHICLE_PHOTO_CATEGORY_ORDER,
  VEHICLE_PHOTO_CATEGORY_PATH,
  VEHICLE_PHOTOS_CATEGORY_KEY,
} from '../../../utilities/vehiclePhotosCategories';
import { Context } from '../../context/context';
import CameraPage from '../CameraPage/CameraPage';
import { DamagePanelPage } from '../DamagePanel/DamagePanelPage';
import { DamageSize } from '../DamagePanel/DamageSize/DamageSize';
import { GlobalMessage } from '../GlobalMessage/GlobalMessage';
import HelpPanel from '../Help/HelpPanel';
import { PANEL_TYPES } from '../Help/HelpPanel.helpers';
import CategoryPage from '../PhotosHub/CategoryPage/CategoryPage';
import DamageHub from '../PhotosHub/DamageHub/DamageHub';
import PhotosHub from '../PhotosHub/PhotosHub';
import { CATEGORY_CAMERA_PATH, DAMAGE_PANEL_PATH, DAMAGE_SIZE_PATH } from '../PhotosHub/PhotosHub.helpers';
import { Status404 } from '../Status404/Status404';
import { WrapperWithQueryParams } from '../WrapperWithQueryParams/WrapperWithQueryParams';

import { getDamageComponent, isCategoryWithExample, isCategoryWithoutHelp, isVehiclePanelDamageKind } from './PhotosHubRouter.helpers';
import useUploadImagesWhenOnline from './PhotosHubRouter.hooks';

import styles from './PhotosHubRouter.module.scss';

export const PhotosHubRouter = () => {
  const scroller = useRef<HTMLDivElement>(null);
  const { isVideoVisible, setParentState, showGlobalMessage } = useContext(Context) as ContextValueTypes;
  const [uploadingQueue, setUploadingQueue] = useState({});

  useUploadImagesWhenOnline();

  useEffect(() => {
    const uploadListener = () => {
      setUploadingQueue({ ...uploadQueue });
    };
    window.addEventListener('upload', uploadListener);

    return () => {
      window.removeEventListener('upload', uploadListener);
    };
  }, []);

  useEffect(() => {
    if (scroller.current) {
      scroller.current.scrollTo(0, 0);
    }
  }, [scroller]);

  const requiredQueryParams = { kind: Object.values(INTERIOR_KINDS) };

  const renderCategoryRoutes = (category: DamageKindType | ImageCategoryType, isDamageCategory: boolean) => {
    const includePanelDamageRoutes = isVehiclePanelDamageKind(category);
    const includeHelpRoutes = isCategoryWithoutHelp(category);
    const includeExampleRoutes = isCategoryWithExample(category);

    const tipsType = includeExampleRoutes ? PANEL_TYPES.EXAMPLE : PANEL_TYPES.HELP;
    const path = isDamageCategory
      ? VEHICLE_DAMAGE_KIND_PATH[category as DamageKindType].name
      : VEHICLE_PHOTO_CATEGORY_PATH[category as MainCategoryType].name;

    return (
      <Route key={`path-${path}`} path={path}>
        {isDamageCategory
          ? <Route index element={getDamageComponent(category as DamageKindType, uploadingQueue)} />
          : <Route index element={
            <CategoryPage
              category={category as ImageCategoryType}
              uploadingQueue={uploadingQueue}
            />
          }
          />
        }

        <Route element={<CameraPage category={category} />} path={CATEGORY_CAMERA_PATH} />

        {includeHelpRoutes && (
          <Route element={
            <WrapperWithQueryParams {...{ requiredQueryParams }}>
              <HelpPanel category={category} panelType={PANEL_TYPES.HELP} />
            </WrapperWithQueryParams>
          }
          path={`${PANEL_TYPES.HELP}`}
          />
        )}

        {includePanelDamageRoutes && (
          <Route path={DAMAGE_PANEL_PATH} >
            <Route index element={<DamagePanelPage category={category as DamageKindType} />} />
            <Route element={<DamageSize kind={category as DamageKindType} />} path={DAMAGE_SIZE_PATH} />
          </Route>
        )}

        <Route element={<HelpPanel category={category} panelType={tipsType} />} path={`${tipsType}`} />
      </Route>
    );
  };

  const nonDamageCategories = VEHICLE_PHOTO_CATEGORY_ORDER.filter((category) =>
    category !== VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE);

  return (
    <div className={cx(styles.component, { [styles.isVideo]: isVideoVisible })}>
      {showGlobalMessage && (
        <GlobalMessage
          closePanel={() => setParentState({ showGlobalMessage: undefined })}
          textKey={showGlobalMessage}
        />
      )}
      <div ref={scroller}>
        <Routes>
          <Route index element={<PhotosHub {...{ uploadingQueue }} />} />
          {nonDamageCategories.map((category) => renderCategoryRoutes(category, false))}
          <Route path={`${VEHICLE_PHOTO_CATEGORY_PATH.damage.name}`}>
            <Route index element={<DamageHub {...{ uploadingQueue }} />} />
            {VEHICLE_DAMAGE_KIND_ORDER.map((kind) => renderCategoryRoutes(kind, true))}
          </Route>
          <Route element={<Status404 />} path='*' />
        </Routes>
      </div>
    </div>
  );
};
