import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import breakpoints from './Breakpoints.module.scss';

type QueryValue = {
  maxWidth: Record<string, boolean>,
  minWidth: Record<string, boolean>,
}

const defaultValue: QueryValue = {
  maxWidth: {},
  minWidth: {},
};

const BreakpointContext = createContext(defaultValue);

const queries = Object.entries(breakpoints).filter(([k]) => /^breakpoint/.test(k)).sort((a, b) => parseInt(a[1]) - parseInt(b[1]));

type BreakpointProps = {
  children: React.ReactElement | React.ReactElement[];
}

type QueryMatch = Array<[string, boolean]>;

type QueryStore = Record<string, {
  listener: (e: MediaQueryListEvent) => void;
  query: MediaQueryList;
}>

const BreakpointProvider = ({ children }: BreakpointProps) => {
  const [queryMatch, setQueryMatch] = useState<QueryMatch>([]);

  useEffect(() => {
    const resize = (key: string, e: MediaQueryListEvent) => {
      setQueryMatch((matches) => matches.map(([k, m]) => ((k === key) ? [k, e.matches] : [k, m])));
    };

    const match: QueryMatch = [];
    const store: QueryStore = {};

    queries.forEach(([key, maxWidth]) => {
      store[key] = {
        listener: resize.bind(this, key),
        query: window.matchMedia(`(max-width: ${maxWidth})`),
      };
      store[key].query.addListener(store[key].listener);
      match.push([key, store[key].query.matches]);
    });

    setQueryMatch(match);

    return () => Object.values(store).forEach((val) => {
      val.query.removeListener(val.listener);
    });
  }, []);

  const processValue = useMemo(() => {
    const value: QueryValue = {
      maxWidth: {},
      minWidth: {},
    };

    queryMatch.forEach(([k, v]) => {
      value.maxWidth[k] = v;
      value.minWidth[k] = !v;
    });

    return value;
  }, [queryMatch]);

  return (
    <BreakpointContext.Provider value={processValue}>
      {children}
    </BreakpointContext.Provider>
  );
};

const useBreakpoint = () => {
  const context = useContext(BreakpointContext);

  if (context === defaultValue) {
    throw new Error('useBreakpoint must be used within BreakpointProvider');
  }
  return context;
};

const withBreakpointContext = (Component: React.FC<any>) => {
  const Hoc = (props: any) => (
    <BreakpointProvider>
      <Component {...props} />
    </BreakpointProvider>
  );
  return Hoc;
};

export { useBreakpoint, BreakpointProvider, withBreakpointContext };
