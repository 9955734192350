import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { setCssVar } from '../../../../utilities/helpers';

import LocalTexts from './Screens.json';

import styles from './Screens.module.scss';

const LocalT = new MDText(LocalTexts);

type Screen6Props = {
  scrollX: number,
  startButton: React.ReactNode,
};

export const Screen6 = ({ scrollX, startButton }: Screen6Props) => {
  const [viewable, setViewable] = useState(false);

  const getViewable = useCallback(
    (innerRange, outerRange) =>
      scrollX >= window.innerWidth * innerRange
      && scrollX <= window.innerWidth * outerRange,
    [scrollX],
  );

  useEffect(() => {
    setViewable(getViewable(4, 5));
  }, [getViewable, scrollX]);

  useEffect(() => {
    setViewable(getViewable(4.7, 5.5));
  }, [getViewable, viewable]);

  useEffect(() => {
    setCssVar({
      '--loader-button-opacity': viewable ? '1' : '0',
    });
  }, [viewable]);

  return (
    <section className={cx(styles.screen, styles.screen6)} data-testid="screen-6">
      <h2>{LocalT.translate('screen6.title', { br: <br /> })}</h2>
      <div className={styles.content} />
      {ReactDOM.createPortal(startButton, document.body)}
    </section>
  );
};
