import { GENERIC_ANALYTICS_EVENTS } from '../../analyticsGenericEvents';
import { EVENT_CATEGORY } from '../events';

export const WHEEL_AND_TYRE_AND_TYRE_DAMAGE_EVENTS = {
  WHEEL_AND_TYRE_DAMAGE_DONE_BUTTON_CLICK: (title: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `${title} done button`,
  }),
  WHEEL_AND_TYRE_DAMAGE_NO_DAMAGE_BUTTON_CLICK: (title: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `${title} no damage button`,
  }),
  WHEEL_AND_TYRE_DAMAGE_PAGE_LOAD: (title: string) => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_CATEGORY,
    name: title,
  }),
  WHEEL_AND_TYRE_DAMAGE_SUBMIT_BUTTON_CLICK: (title: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `${title} continue button`,
  }),
} as const;
