const defaultTimings = {
  exterior_front_driver: [0, 0],
  exterior_front_passenger: [8.1, 12.37],
  exterior_rear_driver: [0.13, 3.83],
  exterior_rear_passenger: [3.87, 8.1],
  instrument_cluster: [64.5, 68.6],
  interior_boot: [42.2, 48.17],
  interior_dashboard: [54.97, 59.17],
  interior_front_seats: [59.17, 64.43],
  interior_rear_seats: [48.2, 54.93],
  tyre_tread_front_driver: [24.53, 28.07],
  tyre_tread_front_passenger: [16.57, 20.27],
  tyre_tread_rear_driver: [32.53, 35.77],
  tyre_tread_rear_passenger: [39.03, 42.23],
  wheels_front_driver: [20.27, 24.5],
  wheels_front_passenger: [12.4, 16.57],
  wheels_rear_driver: [28.27, 32.5],
  wheels_rear_passenger: [35.77, 39],
};

export default defaultTimings;
