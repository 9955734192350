import type { OverlayImages } from './DamageLocationOverlay.types';

const s3Url = 'https://motorway.s3.eu-west-2.amazonaws.com/photos-assets/vehicle_damage_overlays/';

export const importAll = (r: __WebpackModuleApi.RequireContext) => {
  const images: OverlayImages = {};

  r.keys().forEach((item) => {
    const version = item.match(/_v([0-9]+)/);

    const fileName = item.replace('./', '');

    const name = fileName.replace('.png', '').replace(version?.[0] || '', '');
    const bodyType = name.substring(0, name.indexOf('_'));
    const side = name.substring(name.indexOf('_') + 1, name.length);

    images[bodyType] = images[bodyType] || {};

    if (!images[bodyType][side] || images[bodyType][side]?.version < (version?.[1] || 0)) {
      images[bodyType][side] = {
        fileName,
        s3Url: `${s3Url}${fileName}`,
        url: r(item),
        version: version?.[1] || 0,
      };
    }
  });

  return images;
};
