import React, { useContext, useEffect } from 'react';
import { Form } from 'react-final-form';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';

import { WHEEL_AND_TYRE_AND_TYRE_DAMAGE_EVENTS } from '../../../utilities/analytics/events/damage/wheeAndTyreDamageEvents';
import { useIsStatusRestricted, useToggleHeadDisplay } from '../../../utilities/hooks';
import { VEHICLE_DAMAGE_KIND_KEY } from '../../../utilities/vehiclePhotosCategories';
import { Context } from '../../context/context';
import Footer from '../Footer/Footer';
import LocalTexts from '../PhotosHub/CategoryPage/CategoryDamage.json';
import CategoryPageHeader from '../PhotosHub/CategoryPage/CategoryPageHeader';

import TyreDamageInput from './Tyres/TyreDamageInput';
import WheelDamageInput from './Wheels/WheelDamageInput';
import DamageForm from './DamageForm';
import { TYRES_ORDER, WHEELS_ORDER } from './MarkDamaged.helpers';
import { AllowedTypes, useDamageForm } from './MarkDamaged.hooks';
import { TyreDamagedPhotoTypes, WheelsDamagedPhotoTypes } from './MarkDamaged.type';

import styles from './MarkDamaged.module.scss';

const LocalT = new MDText(LocalTexts);

type DamageFormProps = {
  handleSubmit: () => void;
  imageIDs: TyreDamagedPhotoTypes | WheelsDamagedPhotoTypes;
  isApproved?: boolean;
}

const { DAMAGE_TYRES, DAMAGE_WHEELS } = VEHICLE_DAMAGE_KIND_KEY;

const renderWheelDamageForm = ({ handleSubmit, imageIDs }: DamageFormProps) =>
  <DamageForm
    Component={WheelDamageInput}
    imageIDs={imageIDs as WheelsDamagedPhotoTypes}
    onSubmit={handleSubmit}
    order={WHEELS_ORDER}
    type={DAMAGE_WHEELS}
  />;

const renderTyreDamageForm = ({ handleSubmit, imageIDs, isApproved }: DamageFormProps) =>
  <DamageForm
    Component={TyreDamageInput}
    imageIDs={imageIDs as TyreDamagedPhotoTypes}
    isApproved={isApproved}
    onSubmit={handleSubmit}
    order={TYRES_ORDER}
    type={DAMAGE_TYRES}
  />;

const MarkDamaged = ({ category }: {category: AllowedTypes}) => {
  const title = LocalT.translate(`${category}.title`) as string;
  const description = LocalT.translate(`${category}.p`) as string;

  // @ts-expect-error: context will be described in another ticket
  const { vehicleDetails } = useContext(Context);
  const isApproved = useIsStatusRestricted();
  const { imageIDs, initialValues, onDone, onSubmit } = useDamageForm(category, title);

  useToggleHeadDisplay();
  const { vrm } = vehicleDetails || {};

  const wheelCategory = DAMAGE_WHEELS === category;
  const tyreCategory = DAMAGE_TYRES === category;

  useEffect(() => {
    WHEEL_AND_TYRE_AND_TYRE_DAMAGE_EVENTS.WHEEL_AND_TYRE_DAMAGE_PAGE_LOAD(title);
  }, [title]);

  return (
    <Form
      {...{ initialValues, onSubmit }}
      render={({ handleSubmit, values }) => (
        <>
          <Content className={styles.component}>
            <CategoryPageHeader
              isDone
              {...{ category, description, isApproved, title, vrm }}
            />

            {wheelCategory && renderWheelDamageForm({ handleSubmit, imageIDs })}
            {tyreCategory && renderTyreDamageForm({ handleSubmit, imageIDs, isApproved })}
          </Content>
          <Footer>
            <Button
              fullWidth
              reverse
              label={isApproved ? LocalT.translate('finish') : LocalT.translate(`${category}.button.${Object.values(values).some((v) => v) ? 'yes' : 'no'}`)}
              onClick={isApproved ? onDone : handleSubmit}
              type="submit"
              variant="primary"
            />
          </Footer>
        </>
      )}
    />
  );
};

export default MarkDamaged;
