import { useContext } from 'react';

import { getOutStandingUploads, PHOTO_CATEGORY } from '../../shared/components/PhotosHub/PhotosHub.helpers';
import { filterImageAssessment, renderDamagePhotos } from '../../shared/components/PhotosHub/Section/Section.helper';
import { Context } from '../../shared/context/context';
import { usePhotosContext } from '../../shared/context/photos';
import { useSocket } from '../../shared/context/Socket/Socket';
import { isRetake } from '../helpers';
import { useAiImageAssessmentFeatureFlag, useIsStatusRestricted } from '../hooks';
import type { ContextValueTypes } from '../Types/contextTypes';
import { Photo, PhotosType } from '../Types/photo.types';
import type { CategoryType, DamageCategoryType } from '../Types/vehiclePhotosCategories.types';
import { VEHICLE_PHOTO_CATEGORY_ORDER } from '../vehiclePhotosCategories';

import type { InitialProgressType, ProgressType, UseProgressPropsType } from './useProgress.types';

const getPhotoLength = (category: ReadonlyArray<CategoryType> = [], photos: PhotosType = []) => {
  const filled = category.filter((it) =>
    photos.find((photo) =>
      photo.kind === it.kind && !photo.isBrokenImage));

  return filled.length;
};

/**
 * This code addresses a particular and similar edge cases where the following situation occurs:
 * If a seller decides to skip taking a photo, but an agent uploads a photo on their behalf,
 * and subsequently deletes it, the code ensures that the seller is not prompted to retake the photo.
 * This is because the seller initially skipped taking the photo.
 */

const isPhotoDeletedAndAvailable = (deletedPhoto: Photo, photos: PhotosType) => {
  const isDeletedAndAvailable = photos?.some((photo) => photo?.kind === deletedPhoto?.kind);
  return isDeletedAndAvailable;
};

const filterRetakePhoto = (sortedDeletedPhoto: PhotosType, photos: PhotosType | boolean = false) => {
  const filteredRetakes = sortedDeletedPhoto?.filter((deletedPhoto) =>
    isRetake().includes(deletedPhoto?.status)
      && !isPhotoDeletedAndAvailable(deletedPhoto, photos as PhotosType));

  return filteredRetakes ? filteredRetakes.length : 0;
};

const initialProgress = VEHICLE_PHOTO_CATEGORY_ORDER.reduce<InitialProgressType>((acc, curr) => {
  acc[curr] = null;
  return acc;
}, {});

export const useProgress = ({ category, uploadingQueue = {} }: UseProgressPropsType) => {
  const { showImageAssessments } = useAiImageAssessmentFeatureFlag();
  const isApproved = useIsStatusRestricted();
  const { sortedDeletedPhotos, sortedPhotos }: any = usePhotosContext();
  const { vehicleDetails, videoInfo: { imageCategories } } = useContext(Context) as ContextValueTypes;
  const { imageAssessments } = useSocket();

  const progress = VEHICLE_PHOTO_CATEGORY_ORDER.reduce<ProgressType>((acc, currentCategory) => {
    const photos = sortedPhotos[currentCategory];
    const sortedDeletedPhoto = sortedDeletedPhotos[currentCategory];
    const imageAssessment = imageAssessments[currentCategory] || [];

    const categoryArr: ReadonlyArray<CategoryType> = imageCategories[currentCategory];

    const total = categoryArr.length;
    const current = getPhotoLength(categoryArr, photos);
    const outStandingImageAssessment = isApproved ? 0
      : filterImageAssessment(imageAssessment, photos, showImageAssessments);
    const outStandingRetake = isApproved ? 0 : filterRetakePhoto(sortedDeletedPhoto, photos);
    const outStandingUploads = isApproved ? 0 : getOutStandingUploads(photos, uploadingQueue);

    if (currentCategory === PHOTO_CATEGORY.DAMAGE) {
      const damageFilled = (categoryArr as DamageCategoryType[])
        .filter(({ conditionKind, kind }) => {
          const { hasDamage } = renderDamagePhotos({
            conditionKind,
            kind,
            photos,
            vehicleDetails,
          });
          return hasDamage;
        }).length;

      acc[currentCategory] = {
        current: damageFilled,
        isDone: damageFilled === total,
        outStandingImageAssessment,
        outStandingRetake: 0,
        outStandingUploads,
        progress: damageFilled / total,
        total,
      };
    } else {
      acc[currentCategory] = {
        current,
        isDone: current === total,
        outStandingImageAssessment,
        outStandingRetake,
        outStandingUploads,
        progress: current / total,
        total,
      };
    }

    return acc;
  }, initialProgress as ProgressType);

  return category ? progress?.[category] : progress;
};
