import React from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { useIsStatusRestricted } from '../../../utilities/hooks';
import { DamageKindType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { VEHICLE_DAMAGE_KIND_KEY } from '../../../utilities/vehiclePhotosCategories';
import InfoCheckbox from '../InfoCheckbox/InfoCheckbox';
import LocalTexts from '../PhotosHub/CategoryPage/CategoryDamage.json';

import { TyreDamageInputProps } from './Tyres/TyreDamageInput';
import { WheelDamageInputProps } from './Wheels/WheelDamageInput';
import { TyreDamagedPhotoTypes, WheelsDamagedPhotoTypes } from './MarkDamaged.type';

import styles from './MarkDamaged.module.scss';

const LocalT = new MDText(LocalTexts);

type DamageFormProps = {
  Component: React.FC<WheelDamageInputProps> | React.FC<TyreDamageInputProps>,
  imageIDs: WheelsDamagedPhotoTypes | TyreDamagedPhotoTypes;
  isApproved?: boolean;
  onSubmit: () => void;
  order: Record<string, number>;
  type: Partial<DamageKindType>;
};

type itemType = keyof(WheelsDamagedPhotoTypes | TyreDamagedPhotoTypes);

const DamageForm: React.FC<DamageFormProps> = ({ Component, imageIDs, onSubmit, order, type }) => {
  const isApproved: boolean = useIsStatusRestricted();

  const damageOrder = order as {[key: string]: number};
  const noneLabel = LocalT.translate('none') as string;
  const noneDamagedTyres = Object.values(imageIDs).every(({ isDamaged }) => !isDamaged);
  const isApprovedTyreDamage = isApproved && type === VEHICLE_DAMAGE_KIND_KEY.DAMAGE_TYRES && noneDamagedTyres;

  return (
    <form {...{ onSubmit }}>
      <fieldset className={cx(styles.items, styles[type], { [styles.isApproved]: isApproved })}>
        {isApprovedTyreDamage
          ? <InfoCheckbox label={noneLabel} />
          : (
            Object.keys(imageIDs).sort((a, b) => (damageOrder[a] - damageOrder[b]) || 0)
              .map((item: string) => (
                <Component
                  key={item}
                  isApproved={isApproved}
                  item={imageIDs[item as itemType]} />
              ))
          )}
      </fieldset>
    </form>
  );
};

export default DamageForm;
