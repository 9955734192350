import type { Photo, PhotosType } from './Types/photo.types';
import type { CategoryType, KindType } from './Types/vehiclePhotosCategories.types';
import { isRetake } from './helpers';

const getNextPhotoIndex = (images: PhotosType, currentCategory: CategoryType[]): number => {
  const requiredImages = images.filter((it) => !isRetake().includes(it.status));
  const notHaveImage = (kind: KindType) => !requiredImages.find((image) => image.kind === kind);

  const nextIndex = currentCategory.findIndex((it) => notHaveImage(it.kind));

  return nextIndex === -1 ? currentCategory.length - 1 : nextIndex;
};

const getIndexFromPhotoInCategory = (currentCategory: CategoryType[], image: Photo) => {
  const nextIndex = currentCategory.findIndex((it) => it.kind === image?.kind);

  return nextIndex === -1 ? null : nextIndex;
};

const getIsCategoryDone = (images: PhotosType, category: CategoryType[]) =>
  category.every(({ kind }) => images.find((image) => image.kind === kind));

export {
  getNextPhotoIndex,
  getIndexFromPhotoInCategory,
  getIsCategoryDone,
};
