import React, { Children, useContext, useEffect } from 'react';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';
import { Button, CameraIcon } from '@motorway/mw-highway-code';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../../utilities/analytics';
import { ONBOARDING_EVENTS } from '../../../../utilities/analytics/events/onboarding/onboardingEvents';
import { useToggleHeadDisplay } from '../../../../utilities/hooks';
import type { ContextValueTypes } from '../../../../utilities/Types/contextTypes';
import { Context } from '../../../context/context';
import Footer from '../../Footer/Footer';

import { REENTRY_SCREEN_CONTENT_ICONS, REENTRY_SCREEN_GA_EVENT_LABEL } from './ReentryScreen.helper';
import LocalTexts from './ReentryScreen.json';
import type { ContentsType } from './ReentryScreen.types';

import styles from './ReentryScreen.module.scss';

const LocalT = new MDText(LocalTexts);

export const ReentryScreen = () => {
  const { setParentState } = useContext(Context) as ContextValueTypes;

  useToggleHeadDisplay();

  const contents = LocalTexts.reentryScreen.contents as ContentsType;

  const text = {
    button: LocalT.translate('reentryScreen.button') as string,
    heading: LocalT.translate('reentryScreen.heading') as string,
  } as const;

  useEffect(() => {
    const { CATEGORY, PAGE_LOADER_LABEL } = REENTRY_SCREEN_GA_EVENT_LABEL;
    const { PAGE_LOADER } = GA_EVENT_ACTIONS;

    GA_EVENTS.DEFAULT(PAGE_LOADER, CATEGORY, PAGE_LOADER_LABEL);
  }, []);

  const enableCameraHandler = () => {
    setParentState({ onboardingLoader: false });

    const { BUTTON_LABEL, CATEGORY } = REENTRY_SCREEN_GA_EVENT_LABEL;
    const { BUTTON } = GA_EVENT_ACTIONS;

    GA_EVENTS.DEFAULT(BUTTON, CATEGORY, BUTTON_LABEL);
    ONBOARDING_EVENTS.REENTRY_ENABLE_CAMERA_CLICK();
  };

  return (
    <>
      <Content className={styles.component}>
        <div className={styles.heading}>
          <h1>{text.heading}</h1>
        </div>
        <div className={styles.content}>
          <ul>
            {Children.toArray(Object.keys(contents).map((content) => (
              <li className={styles.list}>
                <div className={styles.icon}>
                  {REENTRY_SCREEN_CONTENT_ICONS[contents[content].icon]}
                </div>
                <div>
                  <h2 className={styles.title}>{LocalT.translate(`reentryScreen.contents.${content}.title`)}</h2>
                  <p className={styles.description}>{LocalT.translate(`reentryScreen.contents.${content}.description`)}</p>
                </div>
              </li>
            )))}
          </ul>
        </div>
      </Content>
      <Footer>
        <Button
          fullWidth
          reverse
          icon={CameraIcon}
          label={text.button}
          onClick={enableCameraHandler}
          type="button"
        />
      </Footer>
    </>
  );
};
