import { datadogRum } from '@datadog/browser-rum';

const type = (v: any, expected: any) => typeof v === expected;
const isString = (value: any) => type(value, 'string') || value instanceof String;

type CaptureDatadogException = {
  context?: Record<string, any>;
  error: any;
  errorTitle?: string;
  fingerprint?: string;
  isWarning?: boolean;
  tags?: Record<string, string>;
} & Record<string, any>;

export const captureDatadogException = ({
  context,
  error,
  errorTitle,
  fingerprint,
  isWarning,
  tags = {},
  ...rest
}: CaptureDatadogException) => {
  let newError;
  if (errorTitle) {
    newError = new Error(errorTitle);
  } else if (isString(error)) {
    newError = new Error(error);
  } else {
    newError = error;
  }

  if (globalThis.location?.hostname === 'localhost') {
    console.error(newError);
  }

  datadogRum.addError(newError, {
    error,
    fingerprint,
    ...(isWarning ? { isWarning: true, level: 'warning' } : { isWarning: false, level: 'error' }),
    tags,
    ...context,
    ...rest,
  });
};
