import { datadogRum } from '@datadog/browser-rum';

import { worker } from '../shared/worker';

const { userAgent } = window?.navigator || {};

const deviceAV: Record<string, any> = { userAgent };

type ResolutionSize = {
  aspectRatio?: ConstrainDoubleRange;
  height: { ideal?: number, min?: number },
  width: { ideal?: number, min?: number }
};

type DataType = MediaDeviceInfo[] | (MediaTrackConstraints & ResolutionSize)
| MediaTrackSettings | PhotoSettings | MediaTrackSupportedConstraints | null;

export const setDataDogScope = (
  name: string,
  data: DataType,
  addToDataDog = true,
) => {
  deviceAV[name] = data;

  if (!addToDataDog) {
    return data;
  }

  const setContext = (n: string, d: DataType) => {
    datadogRum.setGlobalContextProperty(n, d);
    worker.postMessage(['setDeviceAV', [n, JSON.stringify(d)]]);
  };

  if (Array.isArray(data)) {
    if (data.length === 1) {
      setContext(name, data[0]);
    } else {
      data.forEach((value, i) => setContext(`${name} ${i + 1}`, value));
    }
  } else {
    setContext(name, data);
  }

  return data;
};

export const setDeviceEnumerateDevices = async () => (
  navigator.mediaDevices?.enumerateDevices?.()
  || Promise.resolve([])
)
  .then((audioVisualInputs) => {
    const devices = audioVisualInputs
      .filter(({ kind }) => (!kind.startsWith('audio')));

    return setDataDogScope('Video Input Devices', devices);
  })
  .catch((err) => {
    throw new Error(err);
  });

export const setDeviceSupportedConstraints = async () => {
  const constraints = navigator.mediaDevices?.getSupportedConstraints?.() || {};

  return setDataDogScope('Video Input Device Constraints', constraints, false);
};

export const setDeviceAppliedConstraints = async (constraints: (MediaTrackConstraints & ResolutionSize) | null) => setDataDogScope('Video Input Applied Constraints', constraints);

export const setDeviceSelectedCamera = async (track: MediaTrackSettings) => setDataDogScope('Video Input Device Track', track);

export const setDeviceTakePhotoCapabilities = async (capabilities: PhotoSettings) => setDataDogScope('Take Photo Capabilities', capabilities);

export const setDeviceTakePhotoAppliedConstraints = async (constraints: PhotoSettings) => setDataDogScope('Take Photo Applied Constraints', constraints);

export const grabFrameAndroidDevices = [
  /SM-G770F/.test(userAgent), // Samsung Galaxy S10 Lite
  /SM-A715F/.test(userAgent), // Samsung Galaxy A71
  /SM-A908B/.test(userAgent), // Samsung Galaxy A90 5G
].some((v) => v);

export default deviceAV;
