import defaultTimings from './default';
import saloonTimings from './saloon';
import suvTimings from './suv';

const timings = {
  default: defaultTimings,
  saloon: saloonTimings,
  suv: suvTimings,
};

export default timings;
