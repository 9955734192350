import { useEffect, useState } from 'react';

import { DamageSide } from '../../../utilities/Types/panelDamage.types';

import { PanelButtonPositionsType } from './DamagePanel.types';
import type { SideButtonProps } from './SideInfo';

export const windscreenId = 'windscreen';

// TODO: Remove this after the feature flag showExpandPanelDamage is removed
const movePosition = {
  back: {
    rear_bumper: { y: 0.59 },
  },
  driver_side: {
    driver_side_corner_post: { y: 0.06 },
    driver_side_rear_quarter_panel: { x: 0.32, y: 0.3 },
    driver_side_wing: { y: 0.28 },
    driver_side_wing_mirror: { x: 1 },
    front_bumper: { y: 0.4 },
    rear_bumper: { x: 0.4, y: 0.35 },
  },
  front: {
    driver_side_wing_mirror: { x: 0 },
    front_bumper: { y: 0.6 },
    passenger_side_wing_mirror: { x: 1.1 },
  },
  passenger_side: {
    front_bumper: { y: 0.4 },
    passenger_side_corner_post: { y: 0.06 },
    passenger_side_rear_quarter_panel: { x: 0.68, y: 0.3 },
    passenger_side_wing: { y: 0.28 },
    passenger_side_wing_mirror: { x: 0 },
    rear_bumper: { x: 0.6, y: 0.35 },
  },
} as Record<string, Record<string, { x?: number, y?: number }>>;

type ZoomInCoordinatesProps = {
  active: string | null;
  bodyType: string;
  containerRef: React.RefObject<HTMLDivElement>;
  currentSide: string;
  imgRef: React.RefObject<SVGSVGElement>;
  isWindscreen: boolean;
}

export const useGetZoomInCoordinates = ({
  active,
  bodyType,
  containerRef,
  currentSide,
  imgRef,
  isWindscreen,
}: ZoomInCoordinatesProps) => {
  const [zoomIn, setZoomIn] = useState<string[]>(isWindscreen ? ['1.85', '-2%', '20%'] : ['1']);

  useEffect(() => {
    if (isWindscreen) {
      return;
    }

    const panel: SVGPathElement | null = document.querySelector(`#${bodyType}-${currentSide}-${active}`);
    if (panel && imgRef?.current && containerRef?.current) {
      const box = panel.getBoundingClientRect() || {};
      const { height, width } = box;
      const imgSet = imgRef?.current?.getBoundingClientRect() || {};
      const { height: imgHeight, width: imgWidth } = imgSet || {};
      const boxSet = containerRef?.current?.getBoundingClientRect() || {};
      const { height: boxHeight, width: boxWidth } = boxSet || {};

      const zoomMinSide = Math.min(boxHeight / height, boxWidth / width);
      const zoomMultiplier = zoomMinSide >= 7 ? 0.3 : 0.55;
      const zoomH = Math.max(zoomMinSide * zoomMultiplier, 1.3) || 1.3;

      const posTop = ((boxHeight / 2) * 1.06) - (box.y + (box.height / 2));
      const posLeft = (boxWidth / 2) - (box.x + (box.width / 2));

      setZoomIn([`${Math.min(zoomH, 4.1)}`, `${posLeft / (imgWidth / 100)}%`, `${posTop / (imgHeight / 100)}%`]);
    } else {
      setZoomIn(['1']);
    }
  }, [active, imgRef, containerRef, isWindscreen, bodyType, currentSide]);

  return { setZoomIn, zoomIn };
};

// TODO: Remove this after the feature flag showExpandPanelDamage is removed
export const useGetButtonsPosition = (
  currentSide: SideButtonProps,
  imgRef: React.RefObject<SVGSVGElement>,
  isWindscreen: boolean,
  bodyType: string,
  loaded: boolean,
  showExpandPanelDamage: boolean,
) => {
  const [position, setPosition] = useState<{ id: string, x: string, y: string }[]>([]);

  useEffect(() => {
    if (imgRef?.current && loaded) {
      if (isWindscreen || showExpandPanelDamage) {
        return;
      }

      const idPrefix = `${bodyType}-${currentSide.id}`;
      const elements = document.querySelectorAll(`[id^='${idPrefix}']`);
      const offset = imgRef.current?.getBoundingClientRect() || {};
      const { height, width, x: offX, y: offY } = offset;

      const elementsPosition = Array.from(elements)
        .filter((el) => !isWindscreen && el.id !== `${idPrefix}-${windscreenId}`)
        .map((el) => {
          const box = el.getBoundingClientRect();
          const name = el.id.replace(`${idPrefix}-`, '');
          const sidePos = movePosition[currentSide.id] || {};
          const { x, y } = sidePos[name] || {};
          const posX = ((box.x - offX) + (box.width * (x ?? 0.5))) / (width / 100);
          const posY = ((box.y - offY) + (box.height * (y ?? 0.5))) / (height / 100);
          return { id: name, x: `${posX}%`, y: `${posY}%` };
        });

      setPosition(elementsPosition);
    }
  }, [imgRef, currentSide.id, showExpandPanelDamage, isWindscreen, loaded, bodyType]);

  return { position };
};

export const useGetPanelButtonsPosition = (
  currentSide: DamageSide,
  imgRef: React.RefObject<SVGSVGElement>,
  isWindscreen: boolean,
  bodyType: string,
  loaded: boolean,
  showExpandPanelDamage: boolean,
) => {
  const [panelButtonPositions, setPanelButtonPositions] = useState<PanelButtonPositionsType[]>([]);

  useEffect(() => {
    if (imgRef?.current && loaded) {
      if (isWindscreen || !showExpandPanelDamage) {
        return;
      }

      const { panels } = currentSide;
      const offset = imgRef.current?.getBoundingClientRect() || {};
      const { height, width, x: offX, y: offY } = offset;

      const elementPanelPosition = panels.map((panel) => {
        const element = document.querySelector(`#${bodyType}-${currentSide.id}-${panel.id}`);
        const box = element?.getBoundingClientRect() || { height: 0, width: 0, x: 0, y: 0 };
        const { x, y } = panel.buttonPosition;
        const posX = ((box.x - offX) + (box.width * (x ?? 0.5))) / (width / 100);
        const posY = ((box.y - offY) + (box.height * (y ?? 0.5))) / (height / 100);
        return { id: panel.id, name: panel.name, x: `${posX}%`, y: `${posY}%` };
      });

      setPanelButtonPositions(elementPanelPosition);
    }
  }, [imgRef, currentSide, isWindscreen, loaded, bodyType, showExpandPanelDamage]);

  return { panelButtonPositions };
};
