import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { ArrowRightIcon } from '@motorway/mw-highway-code';

import { DAMAGE_ICONS } from './Section.helper';
import type { SectionProps } from './Section.types';

import styles from './Section.module.scss';

const Section = ({
  className,
  current = 0,
  description,
  hasError = false,
  icon,
  kind,
  progress = null,
  redirectHandler = () => undefined,
  redirectPath,
  title,
}: SectionProps) => (
  <div className={cx(styles.box, className)} data-testid="section-component">
    <div className={styles.boxInner}>
      {kind && (
        <div className={cx({ [styles.iconBox]: kind })}>
          {DAMAGE_ICONS[kind]}
        </div>
      )}

      <div className={styles.infoBox}>
        <h2 className={styles.title}>{title}</h2>
        {description ? (
          <span
            className={cx(styles.progress, {
              [styles.started]: current > 0 || kind || hasError,
            })}
          >
            {icon}
            {description}
          </span>
        ) : null}
      </div>
      <Link
        className={styles.linkButton}
        onClick={redirectHandler}
        to={redirectPath}
      >
        <ArrowRightIcon direction="right" size="xsmall" />
      </Link>
    </div>

    {progress !== null ? (
      <div className={styles.progressBar}>
        {hasError ? (
          <div className={styles.retakeProgressLine} />
        ) : (
          <div
            className={styles.progressLine}
            style={{ width: `${progress * 100}%` }}
          />
        )}
      </div>
    ) : null}
  </div>
);

export default Section;
