import { GENERIC_ANALYTICS_EVENTS } from '../../analyticsGenericEvents';
import { EVENT_CATEGORY } from '../events';

export const PHOTOS_HUB_EVENTS = {
  PHOTOS_HUB_PAGE_LOAD: () => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_CATEGORY,
    name: 'Photos hub',
  }),
  PHOTOS_HUB_SECTION_CLICK: (category: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `Photos hub ${category} section`,
  }),
  PHOTOS_HUB_SUBMIT_BUTTON_CLICK: () => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: 'Photos hub submit button',
  }),
} as const;
