import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import { Button, CrossIcon } from '@motorway/mw-highway-code';

import { GA_EVENTS } from '../../../../utilities/analytics';

import styles from './HelpModal.module.scss';

type HelpModalProps = {
  buttonText: string;
  children: React.ReactNode;
  className?: cx.ArgumentArray | string
  gaEvents: Record<string, string>;
};

export const HelpModal = ({ buttonText, children, className, gaEvents }: HelpModalProps) => {
  const [open, setOpen] = useState(false);

  const handleOpenHelp = () => {
    setOpen(true);
    GA_EVENTS.DEFAULT(
      gaEvents.action,
      gaEvents.category,
      gaEvents.label,
    );
  };

  return (
    <div className={cx(styles.container, className)} data-testid="help-modal">
      <Button
        label={buttonText}
        onClick={handleOpenHelp}
        size="small"
        variant='secondary'
      />
      {ReactDOM.createPortal(
        (
          <>
            <div className={cx(styles.overlay, { [styles.isOpen]: open })} data-testid="help-modal-overlay" onClick={() => setOpen(false)} />
            <div className={cx(styles.helpPanel, { [styles.isOpen]: open })}>
              <div className={styles.close} onClick={() => setOpen(false)} >
                <Button
                  data-theme="dark"
                  icon={CrossIcon}
                  label="close"
                  onClick={() => setOpen(false)}
                  showLabel={false}
                  size="medium"
                  variant='tertiary'
                />
              </div>
              <div className={styles.children}>
                {children}
              </div>
            </div>
          </>
        ), document.body,
      )}
    </div>
  );
};
