import React from 'react';

import { EnvelopeIcon, PhoneIcon } from '@motorway/mw-highway-code';
import { Hyperlink } from '@motorway/mw-highway-code/alpha';
import { SIZE } from '@motorway/mw-highway-code/enums';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../utilities/analytics';

import styles from './ContactButtons.module.scss';

const CONTACT_BUTTONS_GA_EVENT_LABEL = Object.freeze({
  CATEGORY_EMAIL: `${GA_EVENT_ACTIONS.EMAIL} contact`,
  CATEGORY_PHONE: `${GA_EVENT_ACTIONS.PHONE} contact`,
  EMAIL_BUTTON_LABEL: `${GA_EVENT_ACTIONS.EMAIL} contact - clicked`,
  PHONE_BUTTON_LABEL: `${GA_EVENT_ACTIONS.PHONE} contact - clicked`,
});

export const EmailLink = ({ email }: {email: string}) => (
  <div
    className={styles.wrapper}
    onClick={() => GA_EVENTS.DEFAULT(
      GA_EVENT_ACTIONS.EMAIL,
      CONTACT_BUTTONS_GA_EVENT_LABEL.CATEGORY_EMAIL,
      CONTACT_BUTTONS_GA_EVENT_LABEL.EMAIL_BUTTON_LABEL,
    )}
    role="button"
    tabIndex={0}
  >
    <EnvelopeIcon size={SIZE.SMALL} />
    <Hyperlink href={`mailto:${email}`} label={email} />
  </div>
);

export const PhoneLink = ({
  landlineNoSpace,
  landlineWithSpace,
}: {
  landlineNoSpace: string,
  landlineWithSpace: string,
}) => (
  <div
    className={styles.wrapper}
    onClick={() => GA_EVENTS.DEFAULT(
      GA_EVENT_ACTIONS.PHONE,
      CONTACT_BUTTONS_GA_EVENT_LABEL.CATEGORY_PHONE,
      CONTACT_BUTTONS_GA_EVENT_LABEL.PHONE_BUTTON_LABEL,
    )}
    role="button"
    tabIndex={0}
  >
    <PhoneIcon size={SIZE.SMALL} />
    <Hyperlink href={`tel:${landlineNoSpace}`} label={landlineWithSpace} />
  </div>
);
