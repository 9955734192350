import React, { useEffect } from 'react';

import { MDText } from 'i18n-react';

import { RestartIcon } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';

import CameraSvg from '../../../assets/images/camera.svg';
import Modal from '../Modal/Modal';

import LocalTexts from './GeneralErrorScreen.json';

import styles from './GeneralErrorScreen.module.scss';

const LocalT = new MDText(LocalTexts);

type GeneralErrorScreenProps = {
  button?: React.ReactNode;
  errorCode: string;
  strap: string;
  title: string;
}

export const GeneralErrorScreen = ({ button, errorCode, strap, title }: GeneralErrorScreenProps) => {
  useEffect(() => {
    window.dataLayer.push({
      event: 'Pageview',
      pageTitle: 'General Error screen',
    });

    return () => {
      window.dataLayer.push({
        event: 'Pageview',
        pageTitle: 'General Error screen removed',
      });
    };
  }, []);

  const text = {
    message: LocalT.translate('generalErrorScreen.message', {
      link: (
        <a className={styles.phoneNumber} href="tel:02039882266">
          {LocalT.translate('generalErrorScreen.link')}
        </a>
      ),
    }) as string,
  };

  const analytics = (method: string) => {
    window.dataLayer.push({
      event: 'UAEvent',
      eventAction: 'Error',
      eventCategory: 'General',
      eventLabel: method,
    });
  };

  const RetryButton = () => (
    <a
      href={window.location.href}
      onClick={() => analytics('retry')}
    >
      <Button reverse icon={RestartIcon} label="Retry" />
    </a>
  );

  return (
    <Modal>
      <div className={styles.component}>
        <div className={styles.wrapper}>
          <section className={styles.errorIcon}>
            <img alt="Camera" src={CameraSvg} />
          </section>
          <section>
            <h1>{title}</h1>
            <p>{strap}</p>
            <p>
              {text.message}
              <span className={styles.marked}>{errorCode}</span>
            </p>
            <div className={styles.buttonWrapper}>
              {button || <RetryButton />}
            </div>
          </section>
        </div>
      </div>
    </Modal>
  );
};
