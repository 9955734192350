import React, { useEffect, useState } from 'react';

import { MDText } from 'i18n-react';

import { Input } from '@motorway/motorway-storybook-cra';

import { chromeVersion, copyToClipboard, toggleReadOnlyForIOS } from '../../../utilities/helpers';
import type { User } from '../../../utilities/Types/seller.types';
import Camera from '../Camera/Camera';

import CookiesBlockedIOS from './CookiesBlockedIOS';
import LocalTextsRaw from './Obsolete.json';
import PrivateBrowsing from './PrivateBrowsing';
import UnsupportedBrowserAndroid from './UnsupportedBrowserAndroid';

import styles from './Obsolete.module.scss';

const LocalT = new MDText(LocalTextsRaw);

let copiedTimeout: NodeJS.Timeout;

const LocalTexts: { [key: string]: any } = LocalTextsRaw;

type ObsoleteProps = {
  obsolete: Record<string, string | boolean>;
  seller: User;
  token: string;
};

type ShowCopyProps = {
  copyText?: string | null;
  href: string;
  mode: string;
  onClick: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
};

const ShowCopy = ({ copyText, href, mode, onClick }: ShowCopyProps) => {
  if (LocalTexts[mode]?.showCopy) {
    return (
      <div className={styles.copy} role="button" tabIndex={-1}>
        <div className={styles.inputPlaceholder}>
          { copyText ? <span className={styles.tooltip}>{copyText}</span> : (null)}
          <Input
            className={styles.inputToken}
            inputProps={{
              id: 'copy-test-only',
              input: {
                name: 'copy-test-only',
                onClick,
                readOnly: 'readOnly',
                value: `${href}`,
              },
            }}
            label={LocalT.translate('copyLink.label')}
          />
        </div>
      </div>
    );
  }

  return null;
};

const Obsolete = ({ obsolete = {}, seller, token }: ObsoleteProps) => {
  const [copyText, setCopyText] = useState<string | null>();

  const onClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let ok;
    e.preventDefault();

    const node = e.target;
    node.focus();

    // Use clipboard API if available
    // otherwise, use execCommand (with iOS fix)
    if (navigator.clipboard) {
      ok = copyToClipboard(node.value);
    } else {
      toggleReadOnlyForIOS(node);

      node.select();
      ok = document.execCommand('copy');

      toggleReadOnlyForIOS(node);
    }

    setTimeout(() => node.blur(), 100);

    setCopyText((ok)
      ? LocalT.translate('copyLink.copied') as string
      : LocalT.translate('copyLink.failed') as string);

    clearTimeout(copiedTimeout);
    copiedTimeout = setTimeout(() => setCopyText(null), 1500);
  };

  let mode = Object.keys(obsolete)[0] || 'default';

  switch (mode) {
    case 'obsolete':
      mode = 'default';
      break;
    case 'cookiesBlockedIOS':
      mode = 'cookiesBlockedIOS';
      break;
    case 'isPrivate':
      mode = 'private';
      break;
    case 'isWebView':
      mode = 'webview';
      break;
    case 'isAndroidChromeLTE77':
      // https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DAndroid&hl=en
      mode = `isAndroidChromeLTE77${chromeVersion() < 76 ? 'pre' : 'post'}`;
      break;
    default:
      break;
  }

  useEffect(() => {
    window.dataLayer.push({
      event: 'Pageview',
      pageTitle: `Obsolete - ${mode}`,
    });

    window.dataLayer.push({
      event: 'UAEvent',
      eventAction: 'Incompatible error',
      eventCategory: 'General',
      eventLabel: `${LocalTexts[mode].errorCode} ${mode} ${LocalTexts[mode].title}`,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newToken = token || seller?.login_token || '';
  let { href } = window.location;
  let queryKeys = 0;
  let queryToken;

  for (const key of new URLSearchParams(window.location.search).keys()) {
    queryKeys++;
    queryToken = (queryToken || (key === 'token'));
  }

  const newPath = (queryKeys > 0) ? `${href}&token=${newToken}` : `${href}?token=${newToken}`;
  href = (queryToken) ? href : newPath;

  const renderBlock = () => {
    if (mode === 'unsupportedBrowserAndroid') {
      return <UnsupportedBrowserAndroid {...{ href }} />;
    }

    return (
      <>
        <h2>{ LocalT.translate(`${mode}.title`) }</h2>
        <p className={styles.strap}>{ LocalT.translate(`${mode}.strap`, { br: (<br />) }) }</p>
        <ShowCopy { ...{ copyText, href, mode, onClick } } />
        {LocalTexts[mode]?.errorCode ? (
          <p className={styles.errorCode}>
            {LocalT.translate('errorLabel')}
            {' '}
            {LocalT.translate(`${mode}.errorCode`)}
          </p>
        ) : null}
      </>
    );
  };

  const renderErrorPage = () => {
    if (mode === 'private') {
      return (
        <PrivateBrowsing>
          <ShowCopy { ...{ copyText, href, mode, onClick } } />
        </PrivateBrowsing>
      );
    }

    if (mode === 'cookiesBlockedIOS') {
      return <CookiesBlockedIOS />;
    }

    return (null);
  };

  return (
    <>
      {
        mode === 'private' || mode === 'cookiesBlockedIOS' ? (
          <>
            {
              renderErrorPage()
            }
          </>
        ) : (
          <Camera className={styles.component}>
            {
              renderBlock()
            }
          </Camera>
        )
      }
    </>
  );
};

export default Obsolete;
