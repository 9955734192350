import {
  useContext, useEffect, useRef,
  useState,
} from 'react';

import { datadogRum } from '@datadog/browser-rum';

import Config from '../../../../config.json';
import { SNOWPLOW_ENQUIRY_CONTEXT, SNOWPLOW_USER_CUSTOMER_CONTEXT, SNOWPLOW_VEHICLE_CONTEXT } from '../../../utilities/analytics/snowplowGlobalContext';
import { getEnquiry, getFeatureContent, getPanelDamageInfo, getSeller } from '../../../utilities/api';
import { getBodyType } from '../../../utilities/bodyType';
import { setDeviceEnumerateDevices } from '../../../utilities/deviceHelpers';
import { isOnLine } from '../../../utilities/hooks';
import { captureDatadogException } from '../../../utilities/logger';
import timings from '../../../utilities/timings';
import type { ContextValueTypes, ImageCategoriesByKindsType, VehicleDetailsTypes } from '../../../utilities/Types/contextTypes';
import type { PanelDamageTypes } from '../../../utilities/Types/panelDamage.types';
import type { Vehicle } from '../../../utilities/Types/vehicle.types';
import { INTERIOR_KINDS, VEHICLE_PHOTOS_CATEGORIES } from '../../../utilities/vehiclePhotosCategories';
import { Context } from '../../context/context';
import { usePhotosContext } from '../../context/photos';
import { worker } from '../../worker';

const swAvailable = 'serviceWorker' in navigator;
const loaderTimeOut = (window.location.hostname === 'localhost' || !swAvailable) ? 100 : 4000;

const useOutlinesVideo = () => {
  const [outlinesVideoLoading, setOutlinesVideoLoading] = useState(false);
  const setOutlinesVideoLoadingTiming = useRef<number>();
  const setOutlinesVideoLoadingTimeout = useRef< NodeJS.Timeout>();

  const clearOutlinesVideoLoading = (newOutlinesVideoLoading: boolean) => {
    setOutlinesVideoLoading(newOutlinesVideoLoading);

    clearTimeout(setOutlinesVideoLoadingTimeout.current);

    window.dataLayer.push({
      event: 'UATiming',
      timingCategory: 'General',
      timingLabel: 'setOutlinesVideoLoading',
      timingValue: Math.round(performance.now() - (setOutlinesVideoLoadingTiming.current ?? 0)),
      timingVar: 'setOutlinesVideoLoading finish',
    });
  };

  useEffect(() => () => clearTimeout(setOutlinesVideoLoadingTimeout.current), []);

  return {
    clearOutlinesVideoLoading,
    outlinesVideoLoading,
    setOutlinesVideoLoading,
    setOutlinesVideoLoadingTimeout,
    setOutlinesVideoLoadingTiming,
  };
};

const cachePanelDamageImages = (panelDamage: PanelDamageTypes) => {
  const imageList = (panelDamage?.sides || []).map(({ imageUrl }) => imageUrl);

  if (swAvailable) {
    navigator.serviceWorker.ready.then(async () => {
      await caches.delete('panel-damage-cache');

      try {
        const cache = await caches.open('panel-damage-cache');
        cache.addAll(imageList);
      } catch (err) {
        captureDatadogException({
          context: {
            bodyType: panelDamage?.bodyType,
          },
          error: err,
          fingerprint: 'cache-panel-damage-info',
          isWarning: true,
        });
      }
    });
  }
};

export const useGetVRMData = (vrm?: string) => {
  const {
    seller, setParentState,
  } = useContext(Context) as ContextValueTypes;
  const [loading, setLoading] = useState(true);
  const [vehicle, setVehicle] = useState(null);

  const {
    clearOutlinesVideoLoading,
    outlinesVideoLoading,
    setOutlinesVideoLoading,
    setOutlinesVideoLoadingTimeout,
    setOutlinesVideoLoadingTiming,
  } = useOutlinesVideo();

  useEffect(() => {
    const getOfferData = async () => {
      try {
        const sellerData = (
          await getSeller().catch((err) => {
            captureDatadogException({
              context: { isOnline: isOnLine(), vrm },
              error: err,
              fingerprint: 'fetch-seller',
            });
            return [];
          })
        );

        const vehiclesByVrm = sellerData.vehicles.filter((item: Vehicle) =>
          (item.vrm?.toUpperCase() === (vrm ?? '').toUpperCase()))[0];
        const { dpEnquiryId } = vehiclesByVrm?.enquiry ?? {};

        const platformData = (
          await getEnquiry(dpEnquiryId).catch((err) => {
            captureDatadogException({
              context: { dpEnquiryId, isOnline: isOnLine(), vrm },
              error: err,
              fingerprint: 'fetch-enquiries',
            });
            return [];
          })
        );

        const flags = await getFeatureContent();

        let panelDamageInfo;
        if (flags?.showExpandPanelDamage) {
          panelDamageInfo = await getPanelDamageInfo(vehiclesByVrm.body, vehiclesByVrm.height, dpEnquiryId);
          cachePanelDamageImages(panelDamageInfo);
        }

        SNOWPLOW_USER_CUSTOMER_CONTEXT(sellerData);
        SNOWPLOW_VEHICLE_CONTEXT(vehiclesByVrm);
        SNOWPLOW_ENQUIRY_CONTEXT(vehiclesByVrm);

        if (sellerData && !platformData) {
          window.dataLayer.push({
            event: 'UAEvent',
            eventAction: 'Offer not found',
            eventCategory: 'General',
          });
        }

        const updateState: Partial<ContextValueTypes> = {
          featureFlags: flags,
          offer: undefined,
          panelDamageInfo,
        };

        // Offer ID is the DP ID, if there's no DP database entry then there's no offer
        if (platformData?.id) {
          updateState.offer = { ...platformData, enquiryId: vehiclesByVrm?.enquiry?.id, vrm };
          updateState.vehicleDetails = { ...platformData.vehicleDetails };

          const localData: VehicleDetailsTypes = JSON.parse(localStorage.getItem(`conditionState-${platformData.vehicleDetails?.id}`) ?? '{}');

          if (localData.updatedAt > new Date(platformData.vehicleDetails.updatedAt).getTime()) {
            Object.entries(localData).forEach(([key, val]) => {
              if (updateState.vehicleDetails) {
                updateState.vehicleDetails[key as keyof VehicleDetailsTypes] = val as never;
              }
            });
          }

          window.dataLayer.push({
            enquiryId: (dpEnquiryId || '').toString(),
            premiumId: (platformData.id || '').toString(),
          });
        }

        if (sellerData) {
          updateState.seller = sellerData;

          if (vehiclesByVrm) {
            setOutlinesVideoLoading(true);
            const bodyType = getBodyType(vehiclesByVrm);
            const timingType = timings[bodyType as keyof typeof timings] || timings.default;
            const videoLinks = Config.video[(flags.showInstrumentCluster ? 'v2' : 'v1') as keyof typeof Config.video];

            window.dataLayer.push({ bodyType });

            updateState.videoInfo = {
              imageCategories: Object.entries(VEHICLE_PHOTOS_CATEGORIES)
                .reduce((acc: Record<string, ImageCategoriesByKindsType>, [key, val]) => {
                  acc[key] = val.map((it) => ({ ...it, timings: timingType[it.kind as keyof typeof timingType] }))
                    .filter(({ kind }) => {
                      if (kind === INTERIOR_KINDS.INSTRUMENT_CLUSTER) {
                        return flags.showInstrumentCluster;
                      }
                      return true;
                    });
                  return acc;
                }, {}),
              mov: videoLinks[`${bodyType}Mov` as keyof typeof videoLinks] || videoLinks.defaultMov,
              webm: videoLinks[`${bodyType}WebM` as keyof typeof videoLinks] || videoLinks.defaultWebM,
            };

            setOutlinesVideoLoadingTiming.current = performance.now();
            // Force remove loading screen
            setOutlinesVideoLoadingTimeout.current = setTimeout(() => setOutlinesVideoLoading(false), loaderTimeOut);
            if (swAvailable) {
              navigator.serviceWorker.ready.then(() => {
              // Heroku can't serve the correct headers for iOS
              // to recognise this as non-streaming media, so it's hosted on S3 instead
                const video = document.createElement('video');
                const supportMov = (video.canPlayType('video/quicktime; codecs="hvc1"') === 'probably');
                const outlineSrc = (supportMov) ? updateState.videoInfo?.mov : updateState.videoInfo?.webm;
                caches.open('media').then((cache) => cache.addAll([
                  outlineSrc ?? '',
                ]).then(() => clearOutlinesVideoLoading(false)).catch((err) => {
                  clearOutlinesVideoLoading(false);
                  captureDatadogException({
                    context: {
                      bodyType, dpEnquiryId,
                    },
                    error: err,
                    fingerprint: 'cache-overlay-video',
                    isWarning: true,
                  });
                }));
              });
            }

            setVehicle(vehiclesByVrm);
          }

          const userData = {
            id: sellerData.id,
            premiumOffer: (platformData) ? {
              enquiryId: vehiclesByVrm?.enquiry?.id,
              id: platformData.id,
              vehicleDetailsId: platformData.vehicleDetailsId,
            } : null,
          };

          datadogRum.setUser(userData);
          worker.postMessage(['setUser', userData]);

          setDeviceEnumerateDevices(); // Since permissions haven't been granted this info is anonymised
        }

        setParentState(updateState, () => setLoading(false));
      } catch (err) {
        setLoading(false);
        captureDatadogException({
          error: err,
          fingerprint: 'user-car-data',
        });
      }
    };

    // @ts-expect-error compare
    if (vrm && seller !== false) {
      getOfferData();
    } else {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vrm]);

  return {
    loading,
    outlinesVideoLoading,
    vehicle,
  };
};

export const useSetUploadHandler = () => {
  const { updatePhoto } = usePhotosContext();

  useEffect(() => {
    const uploadHandler = ({ detail }: any) => {
      let status;
      if (detail.platformId) { // Image successfully uploaded
        updatePhoto(detail.id, {
          location: detail.location, platformId: detail.platformId, uploaded: true,
        });

        status = 'Success';
      } else if (detail.failed) {
        status = 'Failed';
      }

      if (status) {
        window.dataLayer.push({
          event: 'UAEvent',
          eventAction: 'Image upload',
          eventCategory: 'Image capture',
          eventLabel: status,
        });
      }
    };
    // Update images context when upload events are fired
    window.addEventListener('upload', uploadHandler);

    return () => {
      window.removeEventListener('upload', uploadHandler);
    };
  }, [updatePhoto]);
};
