import React from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import styles from './Loader.module.scss';

type PaginationProps = {
  itemLength: number;
  pageIndex: number;
};

export const Pagination = ({ itemLength, pageIndex }: PaginationProps) => (
  ReactDOM.createPortal(
    (
      <ul className={styles.pagination}>
        {Array.from({ length: itemLength }, (_, i) => (
          <li key={i} className={cx(styles.item, { [styles.active]: (i === pageIndex) })} />
        ))}
      </ul>
    ), document.body,
  )
);
