import { datadogRum } from '@datadog/browser-rum';

export const DEFAULT_ABORT_TIME = 8000;

type TimeoutFetchOptions = {
  abortController?: AbortController;
  logging?: string[];
  ms?: number;
} & RequestInit;

const timeoutFetch = (
  fetchUrl: string,
  {
    abortController = new AbortController(),
    logging = [],
    ms = DEFAULT_ABORT_TIME,
    ...fetchOptions
  }: TimeoutFetchOptions = {},
): Promise<Response> => {
  const { signal } = abortController;

  const timeout = setTimeout(() => abortController.abort(), ms);

  return new Promise((resolve, reject) => {
    const abortFetch = (res: Error) => {
      clearTimeout(timeout);

      const { aborted } = signal;

      if (aborted) {
        const { addError } = datadogRum || {};

        if (addError) {
          let connection = {};

          const navigator = window.navigator as Navigator & { connection?: Record<string, unknown> };

          if (navigator?.connection) {
            const {
              downlink, effectiveType, rtt, saveData,
            } = navigator.connection;

            connection = {
              downlink,
              effectiveType,
              rtt,
              saveData,
            };
          }

          const url = new URL(
            fetchUrl,
            window?.location?.origin ?? process?.env?.HOST ?? 'https://photos.motorway.co.uk',
          );

          const extraFingerprints = logging.map((k) => url[k as keyof URL]);
          addError(new Error(`Timed out in ${ms}ms. (${fetchUrl})`), {
            context: {
              connection,
              host: url.host,
              info: extraFingerprints,
              url: fetchUrl,
            },
            fingerprint: 'timeout-promise',
            level: 'warn',
          });
        }

        reject(res);
        return;
      }

      reject(res);
    };

    fetch(fetchUrl, { ...fetchOptions, signal })
      .then((res) => {
        clearTimeout(timeout);
        resolve(res);
      })
      .catch((res) => {
        abortFetch(res);
      });
  });
};

export default timeoutFetch;
