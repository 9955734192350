import React, { ErrorInfo } from 'react';

import { captureDatadogException } from '../../../utilities/logger';

import ErrorBlock from './ErrorBlock';

class ErrorBoundary extends React.Component<{ children: React.ReactNode }> {
  state: { error: Error | null } = { error: null };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: Error, info: ErrorInfo) {
    const { componentStack } = info;
    console.error(error);
    console.groupCollapsed('Component stack');
    console.log(info);
    console.groupEnd();

    captureDatadogException({
      context: { react: { componentStack } },
      error,
    });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <ErrorBlock>
          <h1>Sorry, there is an error:</h1>
          <div>{error.message}</div>
        </ErrorBlock>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
