import React from 'react';
import cx from 'classnames';

import styles from './Subtitle.module.scss';

type SubtitleProps = {
  className?: string | null;
  icon: React.ReactElement | null;
  isApproved: boolean,
  showNewContent: boolean;
  subtitleText: string
}

export const Subtitle = ({
  className,
  icon,
  isApproved,
  showNewContent,
  subtitleText,
}: SubtitleProps) => {
  const subtitleContentClasses = cx({
    [styles.slideOut]: !showNewContent && !isApproved,
    [styles.slideIn]: showNewContent && !isApproved,
  });

  return (
    <div className={cx(styles.subtitleIcon, className, subtitleContentClasses)}>
      {icon}
      {subtitleText}
    </div>
  );
};
