import { MDText } from 'i18n-react';

import type { KindType } from '../../../../utilities/Types/vehiclePhotosCategories.types';
import { VEHICLE_DAMAGE_KIND_KEY } from '../../../../utilities/vehiclePhotosCategories';
import LocalTexts from '../VehiclePhotos.json';

const LocalT = new MDText(LocalTexts);
const { DAMAGE_MECHANICAL_OR_ELECTRICAL, DAMAGE_OTHER, DAMAGE_TYRES, DAMAGE_WHEELS } = VEHICLE_DAMAGE_KIND_KEY;

const progressText = (damagePhotos: number) => ({
  damage: LocalT.translate(
    `vehiclePhotos.damagePhotosHub.progress.${damagePhotos > 1 ? 'photos' : 'photo'}`,
    { progress: damagePhotos },
  ),
  none: LocalT.translate('vehiclePhotos.damagePhotosHub.progress.none'),
  tyreDamage: LocalT.translate(
    'vehiclePhotos.damagePhotosHub.progress.damageTyre',
    { progress: damagePhotos },
  ),
  uploadError: (total: number) => LocalT.translate(`vehiclePhotos.vehiclePhotosHub.sections.damage.upload.${total > 1 ? 'plural' : 'singular'}`, { total }),
  wheelDamage: LocalT.translate(
    'vehiclePhotos.damagePhotosHub.progress.damageWheel',
    { progress: damagePhotos },
  ),
  yes: LocalT.translate('vehiclePhotos.damagePhotosHub.progress.yes'),
});

type RenderDescriptionProps = {
  damagePhotos: number,
  damageVal?: boolean | null,
  isDamageTouched: boolean,
  kind: KindType,
  outStandingUploads?: number,
}

export const renderDescription = ({
  damagePhotos, damageVal, isDamageTouched, kind, outStandingUploads,
}: RenderDescriptionProps) => {
  const { damage, none, tyreDamage, uploadError, wheelDamage, yes } = progressText(damagePhotos);
  const descDamage = (kind === DAMAGE_MECHANICAL_OR_ELECTRICAL || kind === DAMAGE_OTHER);

  if (isDamageTouched && (!damageVal || (damagePhotos === 0 && !descDamage))) {
    return none;
  }

  if (isDamageTouched && outStandingUploads) {
    return uploadError(outStandingUploads);
  }

  if (isDamageTouched && descDamage) {
    return yes;
  }

  if (damagePhotos) {
    if (kind === DAMAGE_WHEELS) {
      return wheelDamage;
    }

    if (kind === DAMAGE_TYRES) {
      return tyreDamage;
    }

    return damage;
  }

  return null;
};

export const DAMAGE_HUB_GA_EVENT_LABEL = Object.freeze({
  BACK_TO_SUMMARY_BUTTON_LABEL: 'Condition hub back to summary - button clicked',
  CATEGORY: 'Condition hub',
  DONE_BUTTON_LABEL: 'Condition hub done - button clicked',
  PAGE_LOADER_COMPLETED_LABEL: 'Condition hub seller completed - page loaded',
  PAGE_LOADER_INITIATED_LABEL: 'Condition hub seller initiated - page loaded',
  PAGE_LOADER_INPROGRESS_LABEL: 'Condition hub seller in-progress - page loaded',
  SECTION_BUTTON_DECLARED_LABEL: '{title} condition hub section (previously declared damage) - button clicked',
  SECTION_BUTTON_INITIATED_LABEL: '{title} condition hub section (initiated) - button clicked',
  SECTION_BUTTON_NONE_DECLARED_LABEL: '{title} condition hub section (previously declared no damage) - button clicked',
});

type DamageSectionButtonGaLabelProps = {
  damagePhotos: number,
  damageVal?: boolean | null,
  isDamageTouched: boolean,
}

export const damageSectionButtonGaLabel = ({
  damagePhotos, damageVal, isDamageTouched,
}: DamageSectionButtonGaLabelProps) => {
  if (isDamageTouched && !damageVal) {
    return DAMAGE_HUB_GA_EVENT_LABEL.SECTION_BUTTON_NONE_DECLARED_LABEL;
  }

  if (damagePhotos) {
    return DAMAGE_HUB_GA_EVENT_LABEL.SECTION_BUTTON_DECLARED_LABEL;
  }

  return DAMAGE_HUB_GA_EVENT_LABEL.SECTION_BUTTON_INITIATED_LABEL;
};
