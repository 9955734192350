import { isOnLine } from '../hooks';
import { captureDatadogException } from '../logger';

export const handleErrors = (response: Response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

const tryJSONParse = (data: string | null) => {
  try {
    const json = JSON.parse(data ?? '');
    return { data: json, success: true };
  } catch (err) {
    return { data, success: false };
  }
};

export const processResponse = async (res: Response, expectedErrorCodes?: number[]) => {
  const response = await res.text() || null;

  const { data, success } = tryJSONParse(response);

  if (!res.ok && !expectedErrorCodes?.includes?.(res.status)) {
    throw new Error(success ? (
      data?.message
      || data?.code
      || data?.statusMessage
      || data?.statusCode
      || JSON.stringify(data)
    ) : data);
  }

  return data;
};

export const handleCatchIfOnline = (err: any, fingerprint: string) => {
  if (isOnLine()) {
    captureDatadogException({
      context: { isOnLine: true },
      error: err,
      fingerprint,
    });
  }
  return err;
};

export const ENQUIRY_QUERY_STRING = 'state,seller,vehicle,vehicleDetails';
