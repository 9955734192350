import { useContext } from 'react';

import { updateVehicleData } from '../../../utilities/api';
import { handleCatchIfOnline } from '../../../utilities/api/helpers';
import {
  grabFrameAndroidDevices,
  setDeviceTakePhotoAppliedConstraints,
} from '../../../utilities/deviceHelpers';
import { isNumber } from '../../../utilities/helpers';
import { captureDatadogException } from '../../../utilities/logger';
import { OPTIONAL_CATEGORIES } from '../../../utilities/optionalCategories';
import getSkipPhoto from '../../../utilities/skipPhoto';
import type { CategoryType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { Context } from '../../context/context';
import { QueryableWorker } from '../../worker';

import {
  addDetailsToLocalStorage,
  DAMAGE_LOCATION_CATEGORIES,
  dataLayerPush,
} from './Overlay.helpers';
import { useUpdateContextPhoto } from './Overlay.hooks';
import { CanvasData } from './Overlay.types';
import { addFinishGA, generateCanvas, processImage } from './Photo.helper';

type useGetImageProps = {
  currentCategory: CategoryType[],
  goToCategoryPage: () => void,
  setDisabled: (value: boolean) => void,
  setUploadingPhoto: (value: Record<string, never> | null) => void
}

export const setInRange = (range: number | ImageCaptureSize | undefined, value: number) => {
  if (!range) {
    return value;
  }

  if (isNumber(range)) {
    return Math.min(range as number, value);
  }

  const imageRange = range as ImageCaptureSize;
  const min = Math.min(imageRange?.max ?? Infinity, Math.max(imageRange?.min ?? 0, value));
  const minStep = Math.round(min / (imageRange.step ?? 1)) * (imageRange.step ?? 1);
  return minStep;
};

export const useGetImage = ({
  currentCategory,
  goToCategoryPage,
  setDisabled,
  setUploadingPhoto,
}: useGetImageProps) => {
  const context: any = useContext(Context);

  const {
    imageToReplace, offer, seller, setParentState, updateVehicleCondition, video,
  } = context;

  const updateContextPhoto = useUpdateContextPhoto();

  const getImageFromStream = async ({
    categoryItem, imageCapture, imageCaptureCapabilities, imageCategoriesIndex, stream,
  }: {
    categoryItem: CategoryType,
    imageCapture: React.MutableRefObject<ImageCapture | null>,
    imageCaptureCapabilities: React.MutableRefObject<PhotoSettings | null>,
    imageCategoriesIndex: number | null,
    stream: React.MutableRefObject<MediaStreamTrack | null>
  }) => {
    let processImagePromise = null;
    const startCaptureImage = performance.now();

    const hasImageCapture = 'ImageCapture' in window && 'createImageBitmap' in window;
    const $video = video;

    if (hasImageCapture && grabFrameAndroidDevices) {
      if (imageCapture.current) {
        const frame = await imageCapture.current?.grabFrame();

        const data = generateCanvas({
          bitmap: frame,
          height: frame.height,
          width: Math.round(frame.height * (4 / 3)),
        }, stream) as CanvasData;

        processImagePromise = processImage(data, {
          $video,
          categoryItem,
          imageToReplace,
          offer,
          seller,
          setParentState,
          startCaptureImage,
          stream,
          updateContextPhoto,
        });
      }
    } else if (hasImageCapture) {
      // MacBook camera will fail this constraint as it can do only one resolution
      // so we want to test that the API can support steps and therefore various resolutions
      const { fillLightMode, imageHeight, imageWidth } = imageCaptureCapabilities.current ?? {};
      const constraints: PhotoSettings = (
        ((imageHeight as ImageCaptureSize)?.step || 0) > 1
            && ((imageWidth as ImageCaptureSize)?.step || 0) > 1)
        ? { imageHeight: setInRange(imageHeight, 1536), imageWidth: setInRange(imageWidth, 2048) } // 3 megapixel
        : {};

      if (fillLightMode?.includes('off')) {
        constraints.fillLightMode = 'off';
      }

      const startTakePhoto = performance.now();

      try {
        const blob = await imageCapture.current?.takePhoto(constraints);
        // If the image coming from the camera is 4/3, we don't need to do any processing
        // especially since on Android `canvas.toDataURL` can take over a second and `.toBlob` 10 seconds!!
        // So we're going to bung this into the worker, where getting a dataURL can take ~10ms
        // and keep the main thread unlocked

        const startWorker = performance.now();

        const data = await QueryableWorker.postMessage(['transformImage', { blob }]);

        addFinishGA(startTakePhoto, startWorker);

        processImagePromise = processImage((data.dataURL) ? data : generateCanvas(data, stream), {
          $video,
          categoryItem,
          imageToReplace,
          offer,
          seller,
          setParentState,
          startCaptureImage,
          stream,
          updateContextPhoto,
        });
      } catch (err) {
        const { track } = imageCapture.current ?? {};
        if (track?.muted || track?.readyState === 'ended') {
          const { category, kind } = categoryItem;
          const now = imageToReplace ? imageToReplace.id : Date.now();
          const fakePhoto = { ...getSkipPhoto(category, kind, now, offer.enquiryId, offer.id), skipped: false };
          updateContextPhoto({
            ...fakePhoto,
            dataURL: 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=',
            isBrokenImage: true,
            uploaded: true,
          });
          setParentState({ showGlobalMessage: 'blackCamera' });
        }

        setDeviceTakePhotoAppliedConstraints(constraints);
        captureDatadogException({
          context: {
            itemKind: categoryItem.kind,
            trackEnabled: track?.enabled,
            trackId: track?.id,
            trackLabel: track?.label,
            trackMuted: track?.muted,
            trackReadyState: track?.readyState,
          },
          error: err,
          errorTitle: 'Error during processImage and generateCanvas',
          fingerprint: 'taking-photo',
        });
      }
    } else {
      const data = generateCanvas({
        bitmap: $video,
        height: $video.videoHeight,
        width: Math.round($video.videoHeight * (4 / 3)),
      }, stream) as CanvasData;

      processImagePromise = processImage(data, {
        $video,
        categoryItem,
        imageToReplace,
        offer,
        seller,
        setParentState,
        startCaptureImage,
        stream,
        updateContextPhoto,
      });
    }

    const conditionKind = categoryItem.conditionKind as string;

    if (processImagePromise) {
      processImagePromise.then(async (details) => {
        if (details) {
          if (categoryItem.category === OPTIONAL_CATEGORIES.damage) {
            await updateVehicleData(offer.id, {
              [conditionKind]: true,
            }).catch((err) => handleCatchIfOnline(err, 'fetch-update-vehicle-data'));
            updateVehicleCondition({ [conditionKind]: true });
          }

          if (
            categoryItem.category === OPTIONAL_CATEGORIES.damage
                && DAMAGE_LOCATION_CATEGORIES.includes(categoryItem.kind)
          ) {
            addDetailsToLocalStorage(details);
            setParentState({
              showDamageLocationOverlay: details,
            });
          }
        }

        setDisabled(false);
        setUploadingPhoto(null);

        if ((!DAMAGE_LOCATION_CATEGORIES.includes(categoryItem.kind) && imageToReplace)
        || (categoryItem.category !== OPTIONAL_CATEGORIES.damage
        && currentCategory.length === (imageCategoriesIndex ?? -1) + 1)) {
          goToCategoryPage();
        }
      }).catch((err) => {
        setDisabled(false);
        setUploadingPhoto(null);
        captureDatadogException({
          error: err,
          errorTitle: 'Error during processImagePromise',
          fingerprint: 'process-image-promise',
        });
      });
    } else {
      setDisabled(false);
      setUploadingPhoto(null);
    }

    requestAnimationFrame(() => {
      dataLayerPush(`${categoryItem.kind}${categoryItem ? ' - retake' : ''}`, 'Shutter clicked');
    });
  };

  return getImageFromStream;
};
