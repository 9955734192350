import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { setCssVar } from '../../../../utilities/helpers';

import LocalTexts from './Screens.json';

import styles from './Screens.module.scss';

const LocalT = new MDText(LocalTexts);

export const Screen4 = ({ scrollX }: {scrollX: number}) => {
  const [viewable, setViewable] = useState(false);

  const getViewable = useCallback(
    () =>
      scrollX >= window.innerWidth * 2.75
      && scrollX <= window.innerWidth * 3.25,
    [scrollX],
  );

  useEffect(() => {
    setViewable(getViewable());
  }, [getViewable, scrollX]);

  useEffect(() => {
    setCssVar({
      '--loader-screen4-circle': viewable ? '1' : '0',
    });
  }, [viewable]);

  return (
    <section className={cx(styles.screen, styles.screen4)} data-testid="screen-4">
      <h2>{LocalT.translate('screen4.title', { br: <br /> })}</h2>
    </section>
  );
};
