import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Button, CameraIcon, PlusIcon } from '@motorway/mw-highway-code';
import { InfoBox } from '@motorway/mw-highway-code/alpha';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../../utilities/analytics';
import { DAMAGE_CATEGORY_EVENTS } from '../../../../utilities/analytics/events/damage/damageCategoryEvents';
import { updateVehicleData } from '../../../../utilities/api';
import { handleCatchIfOnline } from '../../../../utilities/api/helpers';
import { importAll, isTouched } from '../../../../utilities/helpers';
import { useAiImageAssessmentFeatureFlag, useIsStatusRestricted, useToggleHeadDisplay } from '../../../../utilities/hooks';
import type { ContextValueTypes } from '../../../../utilities/Types/contextTypes';
import type { UploadingQueue } from '../../../../utilities/Types/Types';
import type { DamageKindType } from '../../../../utilities/Types/vehiclePhotosCategories.types';
import {
  VEHICLE_DAMAGE_KIND_KEY,
  VEHICLE_PHOTO_CATEGORY_PATH,
  VEHICLE_PHOTOS_CATEGORY_KEY,
} from '../../../../utilities/vehiclePhotosCategories';
import { Context } from '../../../context/context';
import { usePhotosContext } from '../../../context/photos';
import { useSocket } from '../../../context/Socket/Socket';
import { useBreakpoint } from '../../Breakpoints/Breakpoints';
import Footer from '../../Footer/Footer';
import { CATEGORY_CAMERA_PATH, getOutStandingUploads } from '../PhotosHub.helpers';
import { filterImageAssessment } from '../Section/Section.helper';

import PhotoBlock from './PhotoBlock/PhotoBlock';
import CATEGORY_DAMAGE_PAGE_GA_EVENT_LABEL, { SHORT_ADD_COPY } from './CategoryDamage.helper';
import useCategoryDamagePageLoaderGaEvent from './CategoryDamage.hooks';
import LocalTexts from './CategoryDamage.json';
import { useAiCatPageGaLoader } from './CategoryPage.hooks';
import CategoryPageHeader from './CategoryPageHeader';

import styles from '../../Help/HelpPanel.module.scss';

const LocalT = new MDText(LocalTexts);

const explanationImages = importAll(
  require.context('../../../../assets/images/explanation', false, /.jpg$/),
);

type CategoryDamageProps = {
  category: DamageKindType;
  uploadingQueue?: UploadingQueue;
};

const CategoryDamage = ({ category, uploadingQueue = {} }: CategoryDamageProps) => {
  const {
    offer, updateVehicleCondition, vehicleDetails, videoInfo: { imageCategories },
  } = useContext(Context) as ContextValueTypes;
  const { sortedPhotos } = usePhotosContext();
  const navigate = useNavigate();
  const { maxWidth: { breakpointTinyMobile } } = useBreakpoint();
  const isApproved = useIsStatusRestricted();
  const { showImageAssessments } = useAiImageAssessmentFeatureFlag();
  const { imageAssessments } = useSocket();
  const { damage: damageImageAssessment } = imageAssessments;

  const { BUTTON } = GA_EVENT_ACTIONS;
  const { DAMAGE_CATEGORY_PAGE, GUIDANCE_PAGE } = CATEGORY_DAMAGE_PAGE_GA_EVENT_LABEL;
  const { GUIDANCE_CATEGORY, NO_DAMAGE_BUTTON_LABEL, TAKE_PHOTOS_BUTTON_LABEL } = GUIDANCE_PAGE;
  const { ADD_MORE_DAMAGE_BUTTON_LABEL, BACK_BUTTON_LABEL, DAMAGE_CATEGORY } = DAMAGE_CATEGORY_PAGE;

  useToggleHeadDisplay();

  const { vrm } = vehicleDetails || {};

  const imageList = Object.entries(explanationImages)
    .filter(([key]) => key.includes(category)).map(([, val]) => val);
  const title = LocalT.translate(`${category}.title`) as string;
  const fullCategory = imageCategories[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE].find((it) => it.kind === category);
  const categoryImages = sortedPhotos[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE].filter(({ kind }) => kind === category);
  const hasImages = categoryImages.length > 0;

  const showInfoBox = category === VEHICLE_DAMAGE_KIND_KEY.DAMAGE_SCRATCHES
    || category === VEHICLE_DAMAGE_KIND_KEY.DAMAGE_DENTS
    || category === VEHICLE_DAMAGE_KIND_KEY.DAMAGE_PAINTWORK;

  const finishButtonHandler = () => {
    DAMAGE_CATEGORY_EVENTS.DAMAGE_CATEGORY_DONE_BUTTON_CLICK(title);
    return goToCategory();
  };

  const goToCategory = () => {
    navigate(`/${vrm}${VEHICLE_PHOTO_CATEGORY_PATH[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE].path}`);
  };

  const goToCamera = () => navigate(CATEGORY_CAMERA_PATH);

  const setNoDamage = useCallback(() => {
    const data = { [fullCategory?.conditionKind ?? '']: false };

    updateVehicleData(offer.id, data).catch((err) => handleCatchIfOnline(err, 'fetch-update-vehicle-data'));
    updateVehicleCondition(data);
  }, [updateVehicleCondition, offer.id, fullCategory?.conditionKind]);

  const noDamageHandler = () => {
    setNoDamage();
    goToCategory();
  };

  useEffect(() => {
    const touched = isTouched(vehicleDetails[(fullCategory?.conditionKind ?? '') as keyof typeof vehicleDetails]);
    if (!hasImages && touched) {
      setNoDamage();
    }
  }, [hasImages, fullCategory?.conditionKind, setNoDamage]);

  useCategoryDamagePageLoaderGaEvent({ hasImages, title });

  useEffect(() => {
    if (hasImages) {
      DAMAGE_CATEGORY_EVENTS.DAMAGE_CATEGORY_PAGE_LOAD(title);
    } else {
      DAMAGE_CATEGORY_EVENTS.GUIDANCE_PAGE_LOAD(title);
    }
  }, [hasImages, title]);

  const damageCategoryPageCategoryLabel = LocalT.translate(DAMAGE_CATEGORY, ({ title }));
  const guidancePageCategoryLabel = LocalT.translate(GUIDANCE_CATEGORY, ({ title }));

  const outStandingUploads = useMemo(
    () => getOutStandingUploads(categoryImages, uploadingQueue),
    [categoryImages, uploadingQueue],
  );

  const outStandingImageAssessment = useMemo(
    () => (isApproved ? 0 : filterImageAssessment(damageImageAssessment, categoryImages, showImageAssessments)),
    [damageImageAssessment, categoryImages, showImageAssessments, isApproved],
  );

  const fireAiImageAssistGaEvent = outStandingImageAssessment > 0 && showImageAssessments.damage;
  useAiCatPageGaLoader({ fireAiImageAssistGaEvent, outStandingImageAssessment, title });

  const photoErrorTotal = outStandingUploads + outStandingImageAssessment;

  const topButtonHandler = () => {
    if (hasImages) {
      const damageCategoryButtonLabel = LocalT.translate(ADD_MORE_DAMAGE_BUTTON_LABEL, ({ title }));
      GA_EVENTS.DEFAULT(BUTTON, damageCategoryPageCategoryLabel, damageCategoryButtonLabel);
      DAMAGE_CATEGORY_EVENTS.DAMAGE_CATEGORY_ADD_PHOTO_BUTTON_CLICK(title);
      return goToCamera();
    }
    const guidanceButtonLabel = LocalT.translate(TAKE_PHOTOS_BUTTON_LABEL, ({ title }));
    GA_EVENTS.DEFAULT(BUTTON, guidancePageCategoryLabel, guidanceButtonLabel);
    DAMAGE_CATEGORY_EVENTS.GUIDANCE_PAGE_TAKE_PHOTO_BUTTON_CLICK(title);
    return goToCamera();
  };

  const bottomButtonHandler = () => {
    if (hasImages) {
      const damageCategoryButtonLabel = LocalT.translate(BACK_BUTTON_LABEL, ({ title }));
      GA_EVENTS.DEFAULT(BUTTON, damageCategoryPageCategoryLabel, damageCategoryButtonLabel);
      DAMAGE_CATEGORY_EVENTS.DAMAGE_CATEGORY_BACK_BUTTON_CLICK(title);
      return goToCategory();
    }
    const guidanceButtonLabel = LocalT.translate(NO_DAMAGE_BUTTON_LABEL, ({ title }));
    GA_EVENTS.DEFAULT(BUTTON, guidancePageCategoryLabel, guidanceButtonLabel);
    DAMAGE_CATEGORY_EVENTS.GUIDANCE_PAGE_NO_DAMAGE_BUTTON_CLICK(title);
    return noDamageHandler();
  };

  const addMoreCopy = breakpointTinyMobile && SHORT_ADD_COPY.includes(category) ? 'addTinyMobile' : 'add';
  const section = LocalTexts[category] as Record<string, string | Record<string, string>>;

  return (
    <>
      <div className={styles.component}>
        <div className={cx(styles.content, styles.explanation, {
          [styles.hasImages]: hasImages,
          [styles.isDone]: isApproved,
        })}
        >
          { hasImages ? (
            <>
              <CategoryPageHeader
                isDone
                category={VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE}
                {...{ photoErrorTotal, title }}
              />
              <PhotoBlock
                category={VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE}
                categoryKinds={[]}
                kind={category}
                uploadingQueue={uploadingQueue}
              />
            </>
          ) : (
            <>
              <h3 className={styles.header}>
                {title}
              </h3>
              {section.p && (
                <p className={styles.description}>
                  {LocalT.translate(`${category}.p`)}
                </p>
              )}
              {section.subP && (
                <p className={styles.subParagraph}>
                  {LocalT.translate(`${category}.subP`)}
                </p>
              )}
              <div className={styles.contentBox}>
                <div className={cx(styles.columns, { [styles.one]: imageList.length === 1 })}>
                  {imageList.map((image, i) => (
                    <div key={image} className={styles.column}>
                      {section[`subTitle${i + 1}`] && (
                        <h3>{LocalT.translate(`${category}.subTitle${i + 1}`)}</h3>
                      )}
                      {section[`p${i + 1}`] && (
                        <p>{LocalT.translate(`${category}.p${i + 1}`)}</p>
                      )}
                      <div className={styles.imageWrapper}>
                        <img alt={category} src={image} />
                      </div>
                    </div>
                  ))}
                </div>
                {showInfoBox && (
                  <InfoBox
                    content={LocalT.translate('infoBox')}
                    variant="info"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer>
        {isApproved ? (
          <Button
            fullWidth
            reverse
            label={LocalT.translate('finish')}
            onClick={finishButtonHandler}
          />
        ) : (
          <>
            <Button
              fullWidth
              reverse
              icon={hasImages ? PlusIcon : CameraIcon}
              label={LocalT.translate(`${category}.button.${hasImages ? addMoreCopy : 'yes'}`)}
              onClick={topButtonHandler}
              variant='primary'
            />
            <Button
              fullWidth
              icon={hasImages ? 'chevron' : undefined}
              label={LocalT.translate(`${category}.button.${hasImages ? 'back' : 'no'}`)}
              onClick={bottomButtonHandler}
              variant='secondary'
            />
          </>
        )}
      </Footer>
    </>
  );
};

export default CategoryDamage;
