import React, { useEffect } from 'react';

import { MDText } from 'i18n-react';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../../utilities/analytics';
import LocalTexts from '../VehiclePhotos.json';

import { CATEGORY_PAGE_GA_EVENT_LABEL } from './CategoryPage.helpers';

const LocalT = new MDText(LocalTexts);

export const useCategoryPageLoaderGaEvent = (
  { completed, initiated, title }: { completed: boolean, initiated: boolean, title: string },
) => {
  useEffect(() => {
    const {
      CATEGORY,
      PAGE_LOADER_COMPLETED_LABEL,
      PAGE_LOADER_INITIATED_LABEL,
      PAGE_LOADER_INPROGRESS_LABEL,
    } = CATEGORY_PAGE_GA_EVENT_LABEL;

    const eventCategory = LocalT.translate(CATEGORY, { title });

    let sellerProgressionStateLabel;

    switch (true) {
      case initiated:
        sellerProgressionStateLabel = LocalT.translate(PAGE_LOADER_INITIATED_LABEL, { title });
        break;

      case completed:
        sellerProgressionStateLabel = LocalT.translate(PAGE_LOADER_COMPLETED_LABEL, { title });
        break;

      default:
        sellerProgressionStateLabel = LocalT.translate(PAGE_LOADER_INPROGRESS_LABEL, { title });
    }

    GA_EVENTS.DEFAULT(GA_EVENT_ACTIONS.PAGE_LOADER, eventCategory, sellerProgressionStateLabel);
  }, [completed, initiated, title]);
};

export const useAiCatPageGaLoader = (
  { fireAiImageAssistGaEvent, outStandingImageAssessment, title }: {
    fireAiImageAssistGaEvent: boolean,
    outStandingImageAssessment: number,
    title: React.ReactNode,
  },
) => {
  useEffect(() => {
    if (fireAiImageAssistGaEvent) {
      const { AI_IMAGE_ASSIST } = CATEGORY_PAGE_GA_EVENT_LABEL;
      const eventLabel = LocalT.translate(AI_IMAGE_ASSIST.PAGE_LOADER_LABEL, { category: title });
      GA_EVENTS.IMAGE_ASSESSMENT({
        eventAction: GA_EVENT_ACTIONS.PAGE_LOADER,
        eventCategory: AI_IMAGE_ASSIST.CATEGORY,
        eventLabel,
        eventValue: outStandingImageAssessment,
      });
    }
  }, [fireAiImageAssistGaEvent, outStandingImageAssessment, title]);
};
