import { isRetake, s3toImgix } from '../../../utilities/helpers';
import { isOptionalCategorySub } from '../../../utilities/optionalCategories';
import getSkipPhoto from '../../../utilities/skipPhoto';
import db from '../../db';
import { QueryableWorker } from '../../worker';

const MAX_PHOTO_WITHOUT_SKIP = 14;

export const filterPhotos = (photos, onlyDeleted = false) => photos.filter(({ status }) => {
  if (onlyDeleted) {
    return isRetake().includes(status);
  }

  return !isRetake().includes(status);
});

export const PHOTO_CATEGORY = Object.freeze({
  DAMAGE: 'damage',
  EXTERIOR: 'exterior',
  INTERIOR: 'interior',
  SERVICE_HISTORY: 'service_history',
  TYRE: 'tyre',
  WHEELS: 'wheels',
});

export const CATEGORY_CAMERA_PATH = 'camera';
export const DAMAGE_PANEL_PATH = 'damage-panel';
export const DAMAGE_SIZE_PATH = 'damage-size';

export const getOutStandingUploads = (sortedPhotos, uploadingQueue = {}) => {
  const images = Object.values(sortedPhotos).reduce((acc, v) => acc.concat(v), []);

  return images.filter((img) => !img.uploaded && !img.isBrokenImage && !uploadingQueue?.[img.id]).length;
};

export const getOutStandingDamageMetaUploads = (sortedPhotos) => {
  const images = Object.values(sortedPhotos).reduce((acc, v) => acc.concat(v), []);
  const filtered = images.filter((img) => img.damageMeta?.uploaded === false);
  return filtered.length;
};

export const fetchPlatformImgDataURIs = (setPhotos, contextImages, platformOnlyImages) => {
  QueryableWorker
    .postMessage(['fetchDataURIs', { data: platformOnlyImages }])
    .then((data) => {
      // Deep clone the array and img object to avoid any state pollution
      const images = [...contextImages].map(({ ...img }) => {
        img.dataURL = data[img.platformId] || img.dataURL;

        return img;
      });

      setPhotos(images);
    });

  // Populate the serviceWorker with the thumbnail
  document.head.insertAdjacentHTML('beforeend', platformOnlyImages
    .map(({ location }) => `<link rel="preload" href="${s3toImgix(location)}" as="image"/>`)
    .join(''));
};

export const getPlatformOnlyImages = (offerId, isLandscape, localImages, filteredImages, imageCategories = []) =>
  filteredImages.filter(({ id, lastUpdated }) => {
    const imageInLocal = localImages.find(({ platformId }) => platformId === id);
    if (!imageInLocal) {
      return true;
    }

    return new Date(lastUpdated).getTime() > imageInLocal.createdAt;
  })
    .map((image) => {
      const { meta, url, ...img } = image;
      const born = new Date(img.lastUpdated).getTime();

      // Agent webapp can upload images for damage without the user filling out the premium form,
      // therefore the `neededDamageImages` wouldn't be populated as the category hasn't been flagged
      // so in this case we'll fall back to damage and add to `imageCategories` so it'll appear in `<Summary/>`
      // TODO: handle historical data properly

      const localImage = localImages.find((localImg) => localImg?.platformId === image.id);
      const type = imageCategories.find(({ kind }) => kind === img.kind) || {};
      const { category } = type;

      const { innerHeight, innerWidth } = window;

      const imgixURL = s3toImgix(url, {
        h: (isLandscape) ? innerHeight : 'auto',
        meta,
        w: (isLandscape) ? 'auto' : innerWidth,
      });

      const damageMeta = img.damageMeta ? {
        ...img.damageMeta,
        uploaded: true,
      } : null;

      return {
        ...img,
        category,
        createdAt: born,
        damageMeta,
        dataURL: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAApJREFUCNdjYAAAAAIAAeIhvDMAAAAASUVORK5CYII=',
        // 1x1 blank placeholder
        height: parseInt(meta.height),
        isAutoAssessStatusFalsePositive: localImage?.isAutoAssessStatusFalsePositive ?? false,
        location: imgixURL,
        platformId: img.id,
        uploaded: true,
        vehicleId: offerId,
        width: parseInt(meta.width),
      };
    });

export const removeDeletedAndDamagedImagesFromDB = async (offerEnquiryId, deletedImages) => {
  const deletedImagesPlatformIds = deletedImages.map(({ id }) => id);
  const damagedImagesId = (await db.photos.where({ enquiryId: offerEnquiryId }).toArray())
    .filter(({ kind, uploaded }) => isOptionalCategorySub(kind) && uploaded).map(({ platformId }) => platformId);

  await db.photos
    .where('platformId')
    .anyOf(deletedImagesPlatformIds.concat(damagedImagesId))
    .and(({ enquiryId, uploaded }) => (enquiryId === offerEnquiryId && uploaded === true))
    .delete();
};

export const getDBPhotos = async ({
  deletedImages, filteredImages, imageCategories, isLandscape, offer, setPhotos,
}) => {
  let platformOnlyImages = [];
  const sortedImages = [];

  if (offer && offer?.id && (deletedImages && filteredImages)) {
    await removeDeletedAndDamagedImagesFromDB(offer?.enquiryId, deletedImages);

    // Offer ID is the DP ID, if there's no DP database entry then there's no offer
    const localImages = await db.photos
      .where('enquiryId').equals(offer?.enquiryId)
      .or('enquiryId').equals(offer?.id)
      .toArray();
    const mergedCategories = Object.values(imageCategories).reduce((acc, v) => acc.concat(v), []);
    platformOnlyImages = getPlatformOnlyImages(offer?.id, isLandscape, localImages, filteredImages, mergedCategories);

    const platformOnlyImagesIds = platformOnlyImages.map(({ platformId }) => platformId);

    const images = localImages
      .filter(({ platformId }) => !platformOnlyImagesIds.includes(platformId))
      .concat(platformOnlyImages);

    // Add skipped photos if IndexedDB cache gets deleted
    if (platformOnlyImages.length >= MAX_PHOTO_WITHOUT_SKIP) {
      const now = Date.now();

      const skippedCategory = mergedCategories
        .filter(({ kind, skip }) => skip && !images.find((it) => it.kind === kind));

      const notDeletedSkippedCategory = skippedCategory.filter(({ kind }) =>
        !deletedImages.find((deletedImage) => deletedImage.kind === kind));

      if (notDeletedSkippedCategory.length) {
        images.unshift(...notDeletedSkippedCategory
          .map(({ category, kind }) => getSkipPhoto(category, kind, now, offer?.enquiryId, offer?.id)));
      }
    }

    // Now we've updated `imageCategories.json` with the dynamic damage, we can map all the categories
    // Sort by latest as there can be multiples in the IndexedDB and from platform
    images.sort((a, b) => (a.createdAt - b.createdAt));

    // Sort images into the same expected order as in `imageCategories.json`
    // Grab the index of each image cat and put into array
    const imagesIndex = images.reduce((acc, val, i) => {
      const imageCategory = mergedCategories.find(({ kind }) => kind === val.kind) || {};

      if (imageCategory.multiple) { // Damage can have multiple
        acc[val.kind] = acc[val.kind] || [];
        acc[val.kind].push(i);
      } else { // Overwrite any non-damage dupes
        acc[val.kind] = [i];
      }

      return acc;
    }, {});

    mergedCategories.forEach((c, i) => {
      const index = imagesIndex[c.kind];

      if (Array.isArray(index) && index.length > 0) {
        // Insert into `sortedImages` at the correct category index the resolved array
        // from imagesIndex, ready to be flattened
        sortedImages.splice(i, 0, index.map((imagesLocation) => images[imagesLocation]));
      }
    });

    return sortedImages?.flat();
  }

  if (platformOnlyImages.length) {
    fetchPlatformImgDataURIs(setPhotos, sortedImages?.flat(), platformOnlyImages);
  }

  return null;
};

export const getDescription = ({
  category, current, isPhotosUploading, outStandingImageAssessment, outStandingRetake, outStandingUploads, text, total,
}) => {
  const hasError = Boolean(outStandingRetake) || Boolean(outStandingUploads) || Boolean(outStandingImageAssessment);
  const { attentionDescription, defaultDescription } = text;

  let description = defaultDescription(current, total, category);
  const combinedOutstanding = outStandingRetake + outStandingUploads + outStandingImageAssessment;

  if (outStandingRetake) {
    description = attentionDescription(combinedOutstanding, category, 'retake');
  }

  if (outStandingImageAssessment) {
    description = attentionDescription(combinedOutstanding, category, 'retake');
  }

  if (outStandingUploads) {
    description = attentionDescription(outStandingUploads, category, 'upload');
  }

  if (outStandingRetake && outStandingUploads) {
    description = attentionDescription(combinedOutstanding, category, 'retake');
  }

  if (isPhotosUploading) {
    description = attentionDescription(outStandingUploads, category, 'uploading');
  }

  return { description, hasError };
};

export const checkIfPhotoUploading = (photos, uploadingQueue = {}) =>
  photos.some((it) => uploadingQueue[it.id] && uploadingQueue[it.id]?.percentage > 0);

export const PHOTOS_HUB_GA_EVENT_LABEL = Object.freeze({
  AI_IMAGE_ASSIST: {
    CATEGORY: 'AI image assist',
    PAGE_LOADER_LABEL: 'photo hub page - page loaded',
    SECTION_BUTTON_LABEL: '{category} photo hub section - button clicked',
    SUBMIT_BUTTON_LABEL: 'photo hub submit - button clicked',
  },
  CATEGORY: 'Photos hub',
  PAGE_LOADER_COMPLETED_LABEL: 'Photos hub seller completed - page loaded',
  PAGE_LOADER_INITIATED_LABEL: 'Photos hub seller initiated - page loaded',
  PAGE_LOADER_INPROGRESS_LABEL: 'Photos hub seller in-progress - page loaded',
  SECTION_BUTTON_LABEL: '{title} photos hub section ({current}/{total}) - button clicked',
  SUBMIT_BUTTON_LABEL: 'Photos hub submit - button clicked',
  UPLOADED_OUTSTANDING_DAMAGE_META: 'Photos hub submit (outstanding damage meta uploaded) - button clicked',
  UPLOADED_OUTSTANDING_IMAGES: 'Photos hub submit (outstanding images uploaded) - button clicked',
});
