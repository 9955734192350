import { useContext } from 'react';
import { useNavigate } from 'react-router';

import { WHEEL_AND_TYRE_AND_TYRE_DAMAGE_EVENTS } from '../../../utilities/analytics/events/damage/wheeAndTyreDamageEvents';
import { updatePhotoDamage, updateVehicleData } from '../../../utilities/api';
import { handleCatchIfOnline } from '../../../utilities/api/helpers';
import { updateIsDamageDB } from '../../../utilities/imageActions';
import type { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import type { Photo, PhotosType } from '../../../utilities/Types/photo.types';
import { ImageCategoryType, TyresKindType, WheelsKindType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { VEHICLE_PHOTO_CATEGORY_PATH, VEHICLE_PHOTOS_CATEGORY_KEY } from '../../../utilities/vehiclePhotosCategories';
import { Context } from '../../context/context';
import { usePhotosContext } from '../../context/photos';
import { QueryableWorker } from '../../worker';

import { HAS_DAMAGE_FIELDS, MARK_DAMAGE_IMAGE_CATEGORY } from './MarkDamaged.helpers';
import { TyreDamagedPhotoTypes, WheelsDamagedPhotoTypes } from './MarkDamaged.type';

export type AllowedTypes = 'damage_tyres' | 'damage_wheels';

type TyresSubmitType = {
  [key in TyresKindType]: boolean
}
type WheelsSubmitType = {
  [key in WheelsKindType]: boolean
}

type SubmitType = TyresSubmitType | WheelsSubmitType;

const useGetMarkDamageImages = (type: AllowedTypes) => {
  const { photos } = usePhotosContext();

  const images: PhotosType = photos?.filter(({ category }) =>
    category === MARK_DAMAGE_IMAGE_CATEGORY?.[type]) || [];

  const imagesWithDamage: PhotosType = images?.filter(({ isDamaged }) => isDamaged);

  return { images, imagesWithDamage };
};

const updateOrAddIsDamagePhoto = async (
  image: Partial<Photo>,
  categoryWithDamage: Partial<ImageCategoryType>,
  id: number,
  updatePhoto: any,
) => {
  const updateData = {
    id: image.id,
    isDamaged: image.isDamaged,
    location: image.location,
    vehicleImageId: image.platformId,
  };
  updatePhotoDamage(updateData, categoryWithDamage)
    .catch((err) => handleCatchIfOnline(err, 'fetch-update-photo-damage'));
  updatePhoto(image.id, { isDamaged: image.isDamaged });

  const res = await updateIsDamageDB(updateData);
  if (res?.status === 'failed') {
    QueryableWorker
      .postMessage(['putPhotos', { photoObj: { ...image, createdAt: Date.now(), enquiryId: id } }])
      .catch((err) => {
        console.error(err);
      });
  }
};

const useDamageForm = (category: AllowedTypes, title: string) => {
  const context = useContext(Context) as ContextValueTypes;
  const { updatePhoto } = usePhotosContext();

  const { offer, updateVehicleCondition, vehicleDetails } = context;
  const navigate = useNavigate();

  const { images }: {images: Photo[]} = useGetMarkDamageImages(category);

  const goToCategory = () => {
    const url = (`/${vehicleDetails.vrm}${VEHICLE_PHOTO_CATEGORY_PATH[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE].path}`);

    navigate(url);
  };

  const onDone = () => {
    WHEEL_AND_TYRE_AND_TYRE_DAMAGE_EVENTS.WHEEL_AND_TYRE_DAMAGE_DONE_BUTTON_CLICK(title);
    goToCategory();
  };

  const initialValues = images?.reduce<SubmitType>((a, v) =>
    (v && { ...a, [v.kind]: Boolean(v.isDamaged) }), {} as SubmitType);

  const imageIDs = images?.reduce<TyreDamagedPhotoTypes | WheelsDamagedPhotoTypes>((a, v) =>
    (v && { ...a, [v.kind]: { ...v } }), {} as TyreDamagedPhotoTypes | WheelsDamagedPhotoTypes);

  const onSubmit = (values: SubmitType) => {
    const keys = Object.keys(values) as (keyof SubmitType)[];
    const data = keys.reduce<Partial<Photo>[]>((acc, curr) => {
      if (values[curr] === initialValues[curr]) {
        return acc;
      }

      const currImageId: Photo = imageIDs[curr];

      return [...acc, {
        ...currImageId,
        isDamaged: values[curr],
      }];
    }, []);

    const updateHasDamage = (val: boolean) => {
      updateVehicleData(
        offer.id,
        { [HAS_DAMAGE_FIELDS[category]]: val },
      ).catch((err) => handleCatchIfOnline(err, 'fetch-update-vehicle-data'));
      updateVehicleCondition({ [HAS_DAMAGE_FIELDS[category]]: val });
    };

    const withoutDamage = Object.values(values).every((v) => !v);

    if (withoutDamage) {
      updateHasDamage(false);
      WHEEL_AND_TYRE_AND_TYRE_DAMAGE_EVENTS.WHEEL_AND_TYRE_DAMAGE_NO_DAMAGE_BUTTON_CLICK(title);
    }

    const withDamage = Object.values(values).some((v) => v) && !vehicleDetails?.[HAS_DAMAGE_FIELDS[category]];

    if (withDamage) {
      updateHasDamage(true);
      WHEEL_AND_TYRE_AND_TYRE_DAMAGE_EVENTS.WHEEL_AND_TYRE_DAMAGE_SUBMIT_BUTTON_CLICK(title);
    }

    const categoryWithDamage = MARK_DAMAGE_IMAGE_CATEGORY[category];

    for (const it of data) {
      updateOrAddIsDamagePhoto(it, categoryWithDamage, offer.enquiryId, updatePhoto);
    }

    window.dataLayer.push({
      event: 'UAEvent',
      eventAction: `Confirm of ${categoryWithDamage} damage button clicked`,
      eventCategory: 'Damage capture',
    });

    goToCategory();
  };

  return { imageIDs, initialValues, onDone, onSubmit };
};

export { useDamageForm, useGetMarkDamageImages };
