export const HATCHBACK_TYPE = 'hatchback';
export const SUV_TYPE = 'suv';
export const SALOON_TYPE = 'saloon';
export const SUV_MIN_HEIGHT = 1529;

export const BODY_CATEGORIES = {
  COMMERCIAL_VEHICLE: 'Commercial vehicle',
  CONVERTIBLE: 'Convertible',
  COUPE: 'Coupe',
  ESTATE: 'Estate',
  HATCHBACK: 'Hatchback',
  MOTORCYCLE: 'Motorcycle',
  MOTORHOME: 'Motorhome',
  MPV: 'MPV',
  OTHER: 'Other',
  SALOON: 'Saloon',
  SUV: 'SUV',
  VAN: 'Van',
} as const as Record<string, string>;

export const BODY_TYPES_MAPPING = {
  'commercial vehicle': SALOON_TYPE,
  convertible: SALOON_TYPE,
  coupe: SALOON_TYPE,
  estate: SALOON_TYPE,
  hatchback: HATCHBACK_TYPE,
  motorcycle: HATCHBACK_TYPE,
  motorhome: SUV_TYPE,
  mpv: SUV_TYPE,
  saloon: SALOON_TYPE,
  van: SUV_TYPE,
} as Readonly<Record<string, string>>;

export const getBodyType = ({ body, height }: { body?: string, height?: number }) => {
  const bodyType = (body || '').toLowerCase();

  if (bodyType === SUV_TYPE) {
    return (height || 0) >= SUV_MIN_HEIGHT ? SUV_TYPE : HATCHBACK_TYPE;
  }

  return BODY_TYPES_MAPPING[bodyType] || HATCHBACK_TYPE;
};
