import React from 'react';

import { MDText } from 'i18n-react';

import { TickList } from '@motorway/motorway-storybook-cra';
import { CrossIcon } from '@motorway/mw-highway-code';

import { sellerAppUrl } from '../../../utilities/helpers';
import Modal from '../Modal/Modal';

import { IconList } from './GlobalMessage.helpers';
import LocalTextsRaw from './GlobalMessage.json';
import type { GlobalMessageProps, LocalTextsType } from './GlobalMessage.types';

import styles from './GlobalMessage.module.scss';

const LocalT = new MDText(LocalTextsRaw);
const LocalTexts = LocalTextsRaw as LocalTextsType;

export const GlobalMessage = ({ closePanel, textKey = '' }: GlobalMessageProps) => {
  const listItem = LocalTexts[textKey]?.list || {};

  const text = {
    subTitle: LocalT.translate(`${textKey}.subTitle`),
    title: LocalT.translate(`${textKey}.title`),
  } as Record<string, string>;

  return (
    <Modal>
      <div className={styles.overlay}>
        <div className={styles.block}>
          <button
            aria-label="close-button"
            className={styles.close}
            onClick={closePanel}
            tabIndex={-1}
          >
            <CrossIcon />
          </button>
          <h2 className={styles.title}>
            {IconList[textKey]}
            {text.title}
          </h2>
          <p>{text.subTitle}</p>
          <TickList
            className={styles.list}
            data={Object.keys(listItem).map((it) => LocalT.translate(`${textKey}.list.${it}`))}
            iconColor="grey"
          />
          <footer className={styles.footer}>
            {LocalT.translate(`${textKey}.info`, {
              link: (
                <a href={`${sellerAppUrl}/contact`} rel="noreferrer noopener" target="_blank">
                  {LocalT.translate(`${textKey}.link`)}
                </a>
              ),
            })}
          </footer>
        </div>
      </div>
    </Modal>
  );
};
