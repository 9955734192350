import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Textarea } from '@motorway/mw-highway-code/pre-alpha';

import LocalTexts from '../PhotosHub/CategoryPage/CategoryDamage.json';

import styles from './DescriptionDamage.module.scss';

const LocalT = new MDText(LocalTexts);

type DescriptionProps = {
  category: string;
  fieldName: string;
  intent: 'error' | 'success' | 'warning' | undefined
  isApproved?: boolean;
  show: boolean;
  showMechElecGuidanceImprovements: boolean
};

const Description = ({
  category,
  fieldName,
  intent,
  isApproved,
  show,
  showMechElecGuidanceImprovements,
}: DescriptionProps) => {
  if (!show) {
    return null;
  }

  return (
    <div className={cx(
      showMechElecGuidanceImprovements ? styles.textareaBox : styles.textareaBoxOld,
      { [styles.approved]: isApproved },
    )}>
      <Field
        id={`textarea-${fieldName}`}
        maxLength={280}
        name={fieldName}
      >
        {(props) => (
          <>
            {isApproved ? (<p className={styles.desc}>{props.input.value}</p>) : <Textarea
              fullWidth
              showLabel
              formProps={props}
              id={props.input.id}
              intent={intent}
              label={LocalT.translate(`${category}.desc`, { star: <sup className={styles.supStar}>*</sup> }) as string}
            />}
          </>
        )}
      </Field>
    </div>
  );
};

export default Description;
