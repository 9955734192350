import React, { Children, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';
import { InfoBox } from '@motorway/mw-highway-code/alpha';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../../utilities/analytics';
import { DAMAGE_HUB_EVENTS } from '../../../../utilities/analytics/events/damageHub/damageHubEvents';
import { useIsStatusRestricted, useToggleHeadDisplay } from '../../../../utilities/hooks';
import { useProgress } from '../../../../utilities/hooks/useProgress';
import type { ProgressCategoryType } from '../../../../utilities/hooks/useProgress.types';
import type { ContextValueTypes } from '../../../../utilities/Types/contextTypes';
import type { UploadingQueue } from '../../../../utilities/Types/Types';
import type { ConditionKindType, KindType } from '../../../../utilities/Types/vehiclePhotosCategories.types';
import {
  VEHICLE_DAMAGE_KIND_KEY,
  VEHICLE_DAMAGE_KIND_ORDER,
  VEHICLE_DAMAGE_KIND_PATH,
  VEHICLE_PHOTO_CATEGORY_PATH,
  VEHICLE_PHOTOS_CATEGORY_KEY,
} from '../../../../utilities/vehiclePhotosCategories';
import { Context } from '../../../context/context';
import { usePhotosContext } from '../../../context/photos';
import Footer from '../../Footer/Footer';
import { PHOTO_CATEGORY } from '../PhotosHub.helpers';
import { useHubPageLoaderGaEvent } from '../PhotosHub.hooks';
import Section from '../Section/Section';
import {
  renderDamagePhotos,
  sectionIcon,
} from '../Section/Section.helper';
import LocalTexts from '../VehiclePhotos.json';

import {
  DAMAGE_HUB_GA_EVENT_LABEL,
  damageSectionButtonGaLabel,
  renderDescription,
} from './DamageHub.helpers';

import styles from './DamageHub.module.scss';

const LocalT = new MDText(LocalTexts);
const category = VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE;

const DamageHub = ({ uploadingQueue }: { uploadingQueue: UploadingQueue }) => {
  const navigate = useNavigate();
  const { vehicleDetails, videoInfo: { imageCategories } } = useContext(Context) as ContextValueTypes;
  const { sortedPhotos } = usePhotosContext();
  const isApproved = useIsStatusRestricted();
  const { vrm } = vehicleDetails;

  const photos = sortedPhotos[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE];
  const wheelPhotos = sortedPhotos[VEHICLE_PHOTOS_CATEGORY_KEY.WHEELS].filter(
    ({ kind }: { kind: KindType }) => kind.includes(PHOTO_CATEGORY.WHEELS),
  );
  const tyrePhotos = sortedPhotos[VEHICLE_PHOTOS_CATEGORY_KEY.WHEELS].filter(
    ({ kind }: { kind: KindType }) => kind.includes(PHOTO_CATEGORY.TYRE),
  );

  const filteredCategory = imageCategories[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE] || [];

  const { BACK_TO_SUMMARY_BUTTON_LABEL, CATEGORY, DONE_BUTTON_LABEL } = DAMAGE_HUB_GA_EVENT_LABEL;
  const { BUTTON } = GA_EVENT_ACTIONS;

  useToggleHeadDisplay();
  const text = {
    damageHubDescription: LocalT.translate('vehiclePhotos.damagePhotosHub.description'),
    damageHubTitle: LocalT.translate('vehiclePhotos.damagePhotosHub.title'),
    errorsDesc: LocalT.translate('vehiclePhotos.vehiclePhotosHub.errors.desc'),
    errorsTitle: LocalT.translate('vehiclePhotos.vehiclePhotosHub.errors.title'),
    footerBtnComplete: LocalT.translate(
      'vehiclePhotos.category.footerBtn.complete',
    ),
    footerBtnIncomplete: LocalT.translate(
      'vehiclePhotos.category.footerBtn.incomplete',
    ),
  };

  const {
    current, isDone: completed, outStandingUploads,
  } = useProgress({ category, uploadingQueue }) as ProgressCategoryType;

  const initiated = current === 0;

  useHubPageLoaderGaEvent({
    completed,
    gaEventLabel: DAMAGE_HUB_GA_EVENT_LABEL,
    initiated,
  });

  useEffect(() => {
    DAMAGE_HUB_EVENTS.DAMAGE_HUB_PAGE_LOAD();
  }, []);

  const footerButtonHandler = () => {
    // GA event
    const footerGaEventLabel = completed ? DONE_BUTTON_LABEL : BACK_TO_SUMMARY_BUTTON_LABEL;
    GA_EVENTS.DEFAULT(BUTTON, CATEGORY, footerGaEventLabel);

    // Snowplow event
    if (completed) {
      DAMAGE_HUB_EVENTS.DAMAGE_HUB_DONE_BUTTON_CLICK();
    } else {
      DAMAGE_HUB_EVENTS.DAMAGE_HUB_BACK_BUTTON_CLICK();
    }

    navigate(`/${vrm}`);
  };

  return (
    <>
      <Content className={styles.component}>
        <div className={styles.heading}>
          <h1>{text.damageHubTitle}</h1>
          <p>{text.damageHubDescription}</p>
        </div>
        <div className={styles.messagingCardSection}>
          {outStandingUploads > 0 && (
            <InfoBox
              content={text.errorsDesc}
              heading={text.errorsTitle}
              variant="error"
            />
          )}
        </div>
        {Children.toArray(
          VEHICLE_DAMAGE_KIND_ORDER.map((kind) => {
            const redirectPath = VEHICLE_DAMAGE_KIND_PATH[kind].name;
            const title = LocalT.translate(
              `vehiclePhotos.damagePhotosHub.sections.${kind}.title`,
            ) as string;

            const { conditionKind } = filteredCategory.find(
              (item) => item.kind === kind,
            ) as { conditionKind: ConditionKindType };

            const damageVal = vehicleDetails[conditionKind || ''];

            const {
              damagePhotos,
              hasDamage: isDone,
              isDamageTouched,
              outStandingDamageUploads,
            } = renderDamagePhotos({
              conditionKind,
              isApproved,
              kind,
              photos,
              tyrePhotos,
              uploadingQueue,
              vehicleDetails,
              wheelPhotos,
            });

            const description = renderDescription({
              damagePhotos,
              damageVal,
              isDamageTouched,
              kind,
              outStandingUploads: outStandingDamageUploads,
            }) as string;

            const icon = sectionIcon(isDone, Boolean(outStandingDamageUploads), false);

            const redirectHandler = (ev: React.UIEvent) => {
              const label = damageSectionButtonGaLabel({
                damagePhotos,
                damageVal,
                isDamageTouched,
              });
              const sectionButtonLabel = LocalT.translate(label, { title });
              GA_EVENTS.DEFAULT(BUTTON, CATEGORY, sectionButtonLabel);
              DAMAGE_HUB_EVENTS.DAMAGE_HUB_SECTION_CLICK(title);
              if (
                (VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WHEELS === kind
                  && wheelPhotos.length < 4)
                || (VEHICLE_DAMAGE_KIND_KEY.DAMAGE_TYRES === kind && tyrePhotos.length < 4)
              ) {
                const copy = {
                  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WHEELS]: 'wheels',
                  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_TYRES]: 'tyres',
                };

                ev.preventDefault();
                if (window.confirm(
                  LocalT.translate(`vehiclePhotos.alerts.${copy[kind]}`) as string,
                )) {
                  navigate(
                    `/${vrm}${VEHICLE_PHOTO_CATEGORY_PATH[
                      VEHICLE_PHOTOS_CATEGORY_KEY.WHEELS
                    ].path
                    }`,
                  );
                }
              }
            };

            return (
              <Section
                {...{
                  description,
                  icon,
                  kind,
                  progress: null,
                  redirectHandler,
                  redirectPath,
                  title,
                }}
              />
            );
          }),
        )}
      </Content>
      <Footer>
        <Button
          fullWidth
          reverse
          icon={completed ? undefined : 'chevron'}
          label={completed ? text.footerBtnComplete : text.footerBtnIncomplete}
          onClick={footerButtonHandler}
          variant={completed ? 'primary' : 'secondary'}
        />
      </Footer>
    </>
  );
};

export default DamageHub;
