import { DamageKindType, ImageCategoryType, KindType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { CATEGORIES_WITH_EXAMPLE_ROUTE, CATEGORIES_WITHOUT_HELP_ROUTE, VEHICLE_DAMAGE_KIND_KEY, VEHICLE_PANEL_DAMAGE_KINDS } from '../../../utilities/vehiclePhotosCategories';
import { DescriptionDamage } from '../DescriptionDamage/DescriptionDamage';
import MarkDamaged from '../MarkDamaged/MarkDamaged';
import CategoryDamage from '../PhotosHub/CategoryPage/CategoryDamage';

const { DAMAGE_MECHANICAL_OR_ELECTRICAL, DAMAGE_OTHER, DAMAGE_TYRES, DAMAGE_WHEELS } = VEHICLE_DAMAGE_KIND_KEY;

export const isVehiclePanelDamageKind = (kind: ImageCategoryType | KindType) =>
  VEHICLE_PANEL_DAMAGE_KINDS.some((panelDamageKind) => panelDamageKind === kind);

export const isCategoryWithoutHelp = (category: ImageCategoryType | KindType) =>
  CATEGORIES_WITHOUT_HELP_ROUTE.some((helpCategory) => helpCategory === category);

export const isCategoryWithExample = (category: ImageCategoryType | KindType) =>
  CATEGORIES_WITH_EXAMPLE_ROUTE.some((helpCategory) => helpCategory === category);

export const getDamageComponent = (kind: DamageKindType, uploadingQueue = {}) => {
  if (kind === DAMAGE_WHEELS || kind === DAMAGE_TYRES) {
    return <MarkDamaged category={kind}/>;
  }

  if (kind === DAMAGE_MECHANICAL_OR_ELECTRICAL || kind === DAMAGE_OTHER) {
    return <DescriptionDamage category={kind}/>;
  }

  return <CategoryDamage category={kind} uploadingQueue={uploadingQueue}/>;
};
