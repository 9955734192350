import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

const Modal = ({ children }: { children: ReactNode }) => {
  const modalRoot: HTMLElement | null = document.getElementById('modal-root');
  const el = document.createElement('div');

  useEffect(() => {
    if (modalRoot) {
      modalRoot.appendChild(el);
    }

    return () => {
      modalRoot?.removeChild(el);
    };
  }, [el, modalRoot]);

  return createPortal(
    children,
    el,
  );
};

export default Modal;
