import * as React from 'react';

const CarPassengerSideIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg height={24} width={24} {...props} viewBox="0 0 24 24">
    <path
      className="motorway-svg-fill"
      d="M18 12.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-12 0a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm12 1.5a.75.75 0 110 1.5.75.75 0 010-1.5zm-12 0a.75.75 0 110 1.5.75.75 0 010-1.5zM10.34 6L4.88 9.5h-2L1 11.53v3.97h1.5v-3.39L3.53 11H18.3l1.23-1.93 1.98 1.23v5.2H23V9.46L17.42 6h-7.08zm-.84 8v1.5h5V14h-5zm5.3-6.5h2.19l1.1.69-.78 1.31H14.8v-2zm-4.02 0h2.51v2H7.66l3.12-2z"
      fillRule="evenodd"
    />
  </svg>
);

export default CarPassengerSideIcon;
