import React from 'react';

import { GlitterIcon, SunIcon, VehicleDriverSideIcon, VehicleInFrameIcon } from '@motorway/mw-highway-code';

import type { ReentryScreenContentIconsTypes, ReentryScreenGaEventTypes } from './ReentryScreen.types';

export const REENTRY_SCREEN_CONTENT_ICONS = {
  GLITTER: <GlitterIcon direction="right" size="small" />,
  SUN: <SunIcon size="small" />,
  VEHICLE_DRIVER_SIDE: <VehicleDriverSideIcon size="small" />,
  VEHICLE_IN_FRAME: <VehicleInFrameIcon size="small" />,
} as Readonly<ReentryScreenContentIconsTypes>;

export const REENTRY_SCREEN_GA_EVENT_LABEL = {
  BUTTON_LABEL: 'Reentry enable camera - button clicked',
  CATEGORY: 'Reentry photo capture',
  PAGE_LOADER_LABEL: 'Reentry photo capture - page loaded',
} as Readonly<ReentryScreenGaEventTypes>;
