import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { ChevronRightThinIcon } from '@motorway/motorway-storybook-cra';

import { setCssVar } from '../../../../utilities/helpers';

import LocalTexts from './Screens.json';

import styles from './Screens.module.scss';

const LocalT = new MDText(LocalTexts);

export const Screen1 = ({ scrollX }: {scrollX: number}) => {
  const [viewable, setViewable] = useState(false);

  const getViewable = useCallback(
    (outerRange) => scrollX >= 0 && scrollX <= window.innerWidth * outerRange,
    [scrollX],
  );

  useEffect(() => {
    setViewable(getViewable(0.75));
  }, [getViewable, scrollX]);

  useEffect(() => {
    setCssVar({
      '--loader-swipe-opacity': viewable ? '1' : '0',
    });
  }, [viewable]);

  useEffect(() => {
    setViewable(getViewable(0.5));
  }, [getViewable, viewable]);

  return (
    <section className={cx(styles.screen, styles.screen1) } data-testid="screen-1">
      <h2>{LocalT.translate('screen1.title')}</h2>
      {ReactDOM.createPortal(
        <div className={styles.swipe}>
          Swipe to continue
          <ChevronRightThinIcon height={16} width={16} />
        </div>,
        document.body,
      )}
    </section>
  );
};
