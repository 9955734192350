import React, { forwardRef } from 'react';

type SideSVGProps = {
  bodyType?: string,
  useHref?: string,
} & React.SVGProps<SVGSVGElement>;

const PassengerSideSVG = (props: SideSVGProps, ref: React.ForwardedRef<SVGSVGElement>) => {
  const { bodyType, useHref, ...rest } = props;

  return (
    <svg ref={ref} fill="none" height="236" viewBox="0 0 644 236" width="644" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_4029_101873)">
        <path d="M290.315 9.96075C285.52 13.0934 285.371 14.7442 289.379 17.4641" stroke="white"/>
        <path d="M164.488 76.7413C152.65 85.9141 145.233 89.8347 126.447 92.4984C94.2688 95.8561 76.213 99.7954 44.0347 107.505C36.1119 107.505 30.9612 108.312 17.8126 114.071L17.6441 113.827C23.6377 107.599 34.2577 98.1446 46.8443 91.5604C72.4483 82.1812 135.25 62.6724 181.701 59.6711C211.351 44.6643 273.291 14.088 283.78 11.837C308.447 3.71463 393.107 -6.84638 530.211 9.66106C402.828 1.61368 335.55 0.431903 288.444 17.4646C238.809 36.223 215.977 53.2932 164.469 76.7413H164.488Z" stroke="white"/>
        <path d="M605.993 192.293C615.283 123.168 500.917 78.8985 480.726 197.977L486.982 193.794C494.793 212.365 502.846 220.694 524.367 230.636L524.723 230.804C530.942 233.543 537.816 235.063 545.045 235.063C571.38 235.063 593.013 214.897 595.392 189.161L595.617 189.104H598.426L605.993 192.293Z" stroke="white"/>
        <path d="M56.1905 202.235L61.5098 197.002C67.0914 218.893 86.9078 235.063 110.508 235.063C118.187 235.063 125.454 233.356 131.972 230.279L132.047 230.373C149.672 224.67 157.37 216.492 167.634 193.794H174.19H174.34L174.19 191.937C169.507 106.586 46.8254 94.393 49.6349 201.316L56.1905 202.254V202.235Z" stroke="white"/>
        <path d="M58.9104 155.296L58.892 155.295M58.892 155.295L57.7679 155.258C27.9871 154.001 0.734807 146.104 1.10941 146.048L6.72843 133.855C6.78862 133.146 6.90636 132.269 7.09317 131.288C7.30281 134.376 9.4664 136.688 14.221 136.688L25.459 137.626C44.1891 134.812 48.8716 108.55 32.0145 112.302L17.967 116.991C14.3352 118.476 11.3763 121.095 9.46414 123.949C10.6051 121.591 12.1559 119.387 14.2205 117.91L17.9665 114.158C31.115 108.399 36.2658 107.612 44.1886 107.593C76.3669 99.8831 94.4226 95.9438 126.601 92.5861C145.406 89.9224 152.729 86.0957 164.585 76.904L168.631 74.9907C173.258 79.1926 175.917 81.2935 179.495 81.8187L179.064 82.1564C172.433 88.3842 175.524 90.185 188.41 89.7723H189.347C174.213 115.152 178.951 142.09 189.347 192.006H174.363C170.523 122.037 87.3989 101.234 59.0977 154.902L58.892 155.295Z" data-panel="passenger_side_wing" id={`${bodyType}-passenger_side-passenger_side_wing`} stroke="white"/>
        <path d="M17.967 116.991L32.0145 112.302C48.8716 108.55 44.1891 134.812 25.459 137.626L14.221 136.688C2.04642 136.688 6.86005 121.531 17.967 116.991Z" stroke="white"/>
        <path d="M110.508 235.062C138.438 235.062 161.08 212.386 161.08 184.414C161.08 156.442 138.438 133.767 110.508 133.767C82.5787 133.767 59.9372 156.442 59.9372 184.414C59.9372 212.386 82.5787 235.062 110.508 235.062Z" stroke="white"/>
        <path d="M110.508 223.807C132.231 223.807 149.841 206.171 149.841 184.415C149.841 162.659 132.231 145.022 110.508 145.022C88.7845 145.022 71.1745 162.659 71.1745 184.415C71.1745 206.171 88.7845 223.807 110.508 223.807Z" stroke="white"/>
        <path d="M545.045 223.807C566.768 223.807 584.378 206.171 584.378 184.415C584.378 162.659 566.768 145.022 545.045 145.022C523.322 145.022 505.712 162.659 505.712 184.415C505.712 206.171 523.322 223.807 545.045 223.807Z" stroke="white"/>
        <path d="M545.046 235.062C572.976 235.062 595.617 212.386 595.617 184.414C595.617 156.442 572.976 133.767 545.046 133.767C517.116 133.767 494.475 156.442 494.475 184.414C494.475 212.386 517.116 235.062 545.046 235.062Z" stroke="white"/>
        <path d="M163.888 77.4915C157.464 69.2378 163.888 65.6174 180.745 59.671" stroke="white"/>
        <path d="M58.7375 155.189C28.9567 153.932 0.767927 145.265 0.955228 145.959C1.14253 146.653 2.82824 153.463 2.82824 153.463L4.70124 170.345L10.3203 187.228C20.8091 196.982 40.9065 200.678 49.6534 201.297C49.1477 182.219 52.6502 166.95 58.7375 155.189Z" data-panel="front_bumper" id={`${bodyType}-passenger_side-front_bumper`} stroke="white"/>
        <path d="M538.49 70.926C545.046 66.2363 529.125 14.6506 492.602 14.6506L386.777 11.8369C338.078 12.9248 311.088 15.476 265.031 33.4091L188.238 74.6776C171.268 86.9269 171.25 90.2472 188.238 89.6844L372.729 77.4914L538.49 70.926Z" stroke="white"/>
        <path d="M239.596 86.1007L221.952 86.8698C205.357 82.8555 206.031 74.6768 220.079 68.1114C229.257 63.8157 235.625 63.1404 238.959 63.1779C240.569 63.1966 241.712 64.5097 241.843 66.1042L243.173 82.0489C243.36 84.1686 241.731 86.0069 239.596 86.1007Z" data-panel="passenger_side_wing_mirror" id={`${bodyType}-passenger_side-passenger_side_wing_mirror`} stroke="white"/>
        <path d="M373.665 77.4914L385.84 11.8369" stroke="white"/>
        <path d="M538.489 70.9262C538.489 70.9262 496.834 133.898 466.379 174.098C456.489 186.497 448.173 188.673 430.791 188.166L366.529 189.161H366.116L366.173 188.166C360.179 160.404 368.664 102.816 373.665 77.4917L538.489 70.9262Z" data-panel="passenger_side_rear_door" id={`${bodyType}-passenger_side-passenger_side_rear_door`} stroke="white" />
        <path d="M610.712 79.461L623.711 77.4913L629.33 84.0568L630.772 110.863L630.004 111.144C616.799 112.269 609.438 111.369 596.327 107.974L596.215 107.918L608.745 84.9947C609.738 82.6499 610.375 81.2618 610.731 79.461H610.712Z" stroke="white"/>
        <path d="M466.378 174.097C456.488 186.496 448.172 188.672 430.791 188.165L189.173 191.917H174.189L175.125 204.11L478.721 199.439C468.457 190.248 466.378 174.097 466.378 174.097Z" data-panel="passenger_side_rocker_panel" id={`${bodyType}-passenger_side-passenger_side_rocker_panel`} stroke="white"/>
        <path d="M584.529 31.5711C584.529 31.5711 527.87 15.5889 492.602 14.6509C529.125 14.6509 545.065 66.1804 538.509 70.87V70.9263C538.509 70.9263 496.816 133.898 466.38 174.098C466.38 174.098 468.44 190.249 478.723 199.44H480.427C493.257 120.505 546.694 111.82 579.734 134.818H579.753L579.865 134.649C594.25 111.482 596.216 107.918 596.216 107.918L608.747 84.9951C609.74 82.6503 610.376 81.2622 610.732 79.4614L623.731 77.4917L584.548 31.5711H584.529Z" data-panel="passenger_side_rear_quarter_panel" id={`${bodyType}-passenger_side-passenger_side_rear_quarter_panel`} stroke="white"/>
        <path d="M579.864 134.648L579.752 134.798C597.471 147.047 609.196 168.301 605.918 192.856C619.404 193.606 632.14 176.911 636.823 168.47L638.696 158.152L642.442 131.891C634.95 126.638 631.822 120.954 631.204 118.76L630.791 110.956V110.881L630.005 111.163C616.8 112.288 609.439 111.388 596.328 107.992L596.216 107.936C596.216 107.936 594.249 111.5 579.864 134.667V134.648Z" data-panel="rear_bumper" id={`${bodyType}-passenger_side-rear_bumper`} stroke="white"/>
        <path d="M492.6 14.6498L386.775 11.836C338.077 12.924 311.087 15.4751 265.03 33.4082L188.237 74.6768C184.472 77.3968 181.531 79.6853 179.434 81.5799L179.321 81.7487C175.744 81.2235 173.084 79.1413 168.458 74.9394C217.193 52.4293 240.1 35.753 288.443 17.4823C339.538 -1.01355 414.346 1.93153 563.775 11.836H564.711L574.076 19.3394L584.528 31.5699C584.528 31.5699 527.869 15.5877 492.6 14.6498Z" data-panel="passenger_side_corner_post" id={`${bodyType}-passenger_side-passenger_side_corner_post`} stroke="white"/>
        <path d="M189.175 89.6843C174.041 115.064 178.779 142.002 189.175 191.918L366.118 189.179L366.174 188.166C360.18 160.404 368.665 102.815 373.666 77.4913H372.729L189.175 89.6843Z" data-panel="passenger_side_front_door" id={`${bodyType}-passenger_side-passenger_side_front_door`} stroke="white"/>
      </g>
      <defs>
        <clipPath id="clip0_4029_101873">
          <rect fill="white" height="236" width="644"/>
        </clipPath>
      </defs>
      <use href={useHref ? `#${bodyType}-passenger_side-${useHref}` : ''} id="mw-use-active-passenger-side" />
    </svg>
  );
};

export default forwardRef(PassengerSideSVG);
