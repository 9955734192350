/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import type { Photo } from '../../../utilities/Types/photo.types';
import db from '../../db';

import type { ResolutionSize } from './Video.hooks';

import styles from './Debug.module.scss';

const FrameRate = ({ $video }: { $video: HTMLVideoElement }) => {
  const [, setCount] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => prevCount + 1);
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const fps = ($video && $video.srcObject)
    ? (($video.srcObject as MediaStream).getTracks()[0].getSettings().frameRate) : null;

  return (<>{ fps }</>);
};

type DebugProps = {
  $video: HTMLVideoElement;
  constraints: MediaTrackConstraints & ResolutionSize | null;
}

const Debug = ({ $video, constraints }: DebugProps) => {
  const [open, toggle] = useState(true);
  const [records, updateRecords] = useState<Photo[]>([]);

  useEffect(() => {
    const get = async () => {
      const results: Photo[] = (await db.photos.toArray()).map((r) => {
        const { blob, ...record } = r; // eslint-disable-line no-unused-vars
        return record;
      });

      updateRecords(results);
    };

    get();
  }, [open]);

  return (
    <div className={cx(styles.debug, { [styles.open]: open })}>
      <div className={styles.toggle} onClick={() => toggle(!open)}>
        { (open) ? (<>&#187;</>) : (<>&#171;</>) }
      </div>
      <div className={styles.scroll}>
        <table>
          <thead>
            <tr>
              <th colSpan={2}>Native Resolution</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Width</td>
              <td>{$video.videoWidth}</td>
              <td>Height</td>
              <td>{$video.videoHeight}</td>
            </tr>
          </tbody>
        </table>
        <p />
        <table>
          <thead>
            <tr>
              <th colSpan={2}>Frame rate</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>FPS</td>
              <td>{ FrameRate({ $video }) }</td>
            </tr>
          </tbody>
        </table>
        <p />
        <table>
          <thead>
            <tr>
              <th colSpan={2}>Active Constraints</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.entries(constraints || {}).map(([k, v]) => (
                <tr key={k}>
                  <td>{k}</td>
                  <td>{JSON.stringify(v, null, 2)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <p />
        <table>
          <thead>
            <tr>
              <th colSpan={2}>Allowed Constraints</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.entries(navigator.mediaDevices.getSupportedConstraints()).map(([k, v]) => (
                <tr key={k}>
                  <td>{k}</td>
                  <td>{JSON.stringify(v, null, 2)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>

        <p />
        <table>
          <thead>
            <tr>
              <th colSpan={2}>Stored images</th>
            </tr>
          </thead>
          <tbody>
            {
              records.map((r, i) => {
                const { dataURL, ...record } = r; // eslint-disable-line no-unused-vars

                const rows = [(<tr key={`dataURL-${i.toString()}`}><td colSpan={2}><a download={`${record.vehicleId}-${record.kind}-${Date.now()}`} href={dataURL}>Download</a></td></tr>)];

                Object.entries(record).forEach(([k, v]) => {
                  rows.push((
                    <tr key={k} className={styles.small}>
                      <td>{k}</td>
                      <td>{v}</td>
                    </tr>
                  ));
                });

                rows.push((<tr key={record.id}><td colSpan={2}>&nbsp;</td></tr>));

                return rows;
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Debug;
