import { ImageCategoryType, KindType } from './Types/vehiclePhotosCategories.types';

const getSkipPhoto = (
  category: ImageCategoryType,
  kind: KindType,
  now: number,
  enquiryId: number,
  vehicleId: number,
) => ({
  category,
  createdAt: now,
  enquiryId,
  id: now,
  kind,
  skipped: true,
  uploaded: true,
  vehicleId,
});

export default getSkipPhoto;
