/* eslint-disable no-underscore-dangle, prefer-promise-reject-errors */
import mime from 'mime';

import { datadogRum } from '@datadog/browser-rum';

import { QueryableWorker } from '../shared/worker';

import { processResponse } from './api/helpers';
import { login } from './api';
import deviceAV from './deviceHelpers';
import { s3toImgix } from './helpers';
import { captureDatadogException } from './logger';

const GATEWAY_URL = window.__ENV__.gateway;
const PHOTO_UPLOADER_URL = window.__ENV__.photoUploaderUrl;
const APP_VERSION = window.__ENV__.appVersion;
const APP_NAME = window.__ENV__.appName;
const fileIsEmpty = 'File is empty';
const jsType = 'application/javascript';
export const uploadQueue = {};

export const updateAutoAssessmentStatus = async (vehicleImageId, authToken) => {
  const res = await fetch(`${GATEWAY_URL}/classifier/assessments/${vehicleImageId}`, {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': authToken,
      'x-mway-app': APP_NAME,
      'x-mway-version': APP_VERSION,
    },
    method: 'POST',
  }).then(processResponse);

  return res;
};

export const uploadImage = async (photoObj, stream) => {
  const startUploadImage = performance.now();

  const formatErrorXHR = ({ blob, xhr }) => ({
    blob: {
      size: blob?.size,
      type: blob?.type,
    },
    enquiryId: photoObj.vehicleId,
    kind: photoObj.kind,
    stream,
    xhr: {
      contentLength: xhr?.headers?.get('Content-Length'),
      responseText: xhr?.responseText,
      status: xhr?.status,
    },
  });

  return new Promise((resolve, reject) => {
    QueryableWorker.postMessage(['fetchBlob', { dataURL: photoObj.dataURL }])
      .then(({ blob }) => {
        if (!blob?.size || blob?.type === jsType) {
          const error = blob?.type === jsType ? new Error('Blob is not an image') : new Error(fileIsEmpty);
          reject(error);

          captureDatadogException({
            context: formatErrorXHR({ blob }),
            error,
            errorTitle: 'Error during fetchBlob',
            fingerprint: 'uploaded-image',
          });
          return;
        }

        const extension = mime.getExtension(blob.type);
        const form = new FormData();
        const now = Date.now();
        form.append('file', blob);
        form.append('name', `${photoObj.vehicleId}-${photoObj.kind}-${now}.${extension}`);
        form.append('kind', photoObj.kind);
        form.append('enquiryId', photoObj.vehicleId);
        form.append('vehicleId', photoObj.vehicleId);
        form.append('device', JSON.stringify({ ...deviceAV, stream }));

        const xhr = new XMLHttpRequest();

        xhr.upload.addEventListener('progress', (e) => {
          if (e.lengthComputable) {
            const percentage = Math.round((e.loaded * 100) / e.total);
            const stats = { id: photoObj.id, percentage };
            uploadQueue[photoObj.id] = stats;

            const event = new CustomEvent('upload', { detail: stats });
            window.dispatchEvent(event);
          }
        }, false);

        xhr.addEventListener('readystatechange', async () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              let location;
              let platformId;

              try {
                const res = JSON.parse(xhr.responseText);
                location = res.location;
                platformId = res.id;
              } catch (e) {
                reject(e);

                captureDatadogException({
                  context: formatErrorXHR({ blob, xhr }),
                  error: e,
                  fingerprint: 'uploaded-image',
                });
              }

              delete uploadQueue[photoObj.id];

              const uploadedImage = await fetch(location, { method: 'HEAD' })
                .catch((err) => {
                  // Can test this catch by changing `location` to `http://the-internet.herokuapp.com/status_codes/500`
                  captureDatadogException({
                    error: err,
                    errorTitle: 'Error during fetching of image location',
                    fingerprint: 'uploaded-image',
                    isWarning: true,
                  });
                });

              if (uploadedImage?.status !== 200 || parseInt(uploadedImage?.headers?.get('Content-Length')) === 0) {
                reject(`Image ${location}; ${uploadedImage?.status || 'no'} status. ${uploadedImage?.headers?.get('Content-Length') || 0} content-length`);

                captureDatadogException({
                  context: { uploadedImage },
                  error: new Error('Image is not available on s3'),
                  fingerprint: 'uploaded-image',
                  isWarning: true,
                });
              } else {
                document.head.insertAdjacentHTML('beforeend', `<link rel='preload' as='image' href='${s3toImgix(location)}'/>`); // Prepopulate caches
              }
              const detail = {
                detail: {
                  id: photoObj.id, kind: photoObj.kind, location, platformId,
                },
              };
              const event = new CustomEvent('upload', detail);
              window.dispatchEvent(event);
              resolve(detail);
            } else if (xhr.status !== 200) {
              delete uploadQueue[photoObj.id];

              const detail = { detail: { failed: true, id: photoObj.id } };
              const event = new CustomEvent('upload', detail);
              window.dispatchEvent(event);

              if (xhr.status === 403) {
                // eslint-disable-next-line no-restricted-globals, no-alert
                if (confirm(`
                Your session may of expired.\nPressing OK will send you an email with a link to sign in again.
                `.trim())) {
                  const seller = JSON.parse(localStorage.getItem('seller'));
                  login(seller.email);
                }
              } else {
                const statusZero = xhr?.status === 0;
                const statusEmptyFile = (/File is empty/i).test(xhr?.responseText || '');
                const isWarning = statusZero || statusEmptyFile;

                captureDatadogException({
                  context: formatErrorXHR({ blob, xhr }),
                  error: new Error('Image failed to upload'),
                  fingerprint: 'uploaded-image',
                  isWarning,
                  tags: {
                    ...(statusZero ? { statusCode: 0 } : {}),
                    ...(statusEmptyFile ? { statusMessage: fileIsEmpty } : {}),
                  },
                });
              }

              reject('Image failed to upload');
            }

            window.dataLayer.push({
              event: 'UATiming',
              timingCategory: 'Image capture',
              timingLabel: 'captureImage',
              timingValue: Math.round(performance.now() - startUploadImage),
              timingVar: 'uploadImage finish',
            });
          }
        });

        xhr.open('POST', `${PHOTO_UPLOADER_URL}/api/v1/photos`, true);
        xhr.setRequestHeader('x-access-token', photoObj.authToken);
        xhr.setRequestHeader('x-mway-version', APP_VERSION);
        xhr.setRequestHeader('x-mway-app', APP_NAME);
        xhr.setRequestHeader('Request-Id', `${APP_NAME}-${APP_VERSION}-${photoObj.vehicleId}-${photoObj.kind}-${now}`);
        xhr.send(form);
        datadogRum.addAction('uploadedImageSuccessfully');
      }).catch((err) => {
        captureDatadogException({
          error: err,
          fingerprint: 'indexedDB-get-fetchBlob',
        });
      });
  });
};

export const archiveImage = async (details) => {
  const res = await fetch(`${GATEWAY_URL}/premium-v3/vehicleImages/${details.platformId}/archive`, {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': details.authToken,
      'x-mway-app': APP_NAME,
      'x-mway-version': APP_VERSION,
    },
    method: 'POST',
  }).then(processResponse);

  return res;
};

const updateRecord = ([action, detail]) => new Promise((resolve, reject) => {
  QueryableWorker.postMessage([action, { detail }])
    .then(({ success }) => {
      const { id } = detail;
      return (success) ? resolve({ id }) : reject({ id, status: 'failed' });
    });
});

const updateDBCatch = (err, fingerprint) => {
  captureDatadogException({
    error: err,
    fingerprint: `indexedDB-${fingerprint}`,
    isWarning: true,
  });
  return err;
};

export const uploadedImageUpdateDB = (detail) => updateRecord(['uploadedImageUpdateDB', detail]).catch((err) => updateDBCatch(err, 'uploadedImageUpdateDB'));

export const autoAssessStatusUpdateDB = (detail) => updateRecord(['autoAssessStatusUpdateDB', detail]).catch((err) => updateDBCatch(err, 'autoAssessStatusUpdateDB'));

export const deletedImageUpdateDB = (detail) => updateRecord(['deletedImageUpdateDB', detail]).catch((err) => updateDBCatch(err, 'deletedImageUpdateDB'));

export const updateDamageMetaDB = (detail) => updateRecord(['updateDamageMetaDB', detail]).catch((err) => updateDBCatch(err, 'updateDamageMetaDB'));

export const updateIsDamageDB = (detail) => updateRecord(['updateIsDamageDB', detail]).catch((err) => updateDBCatch(err, 'updateIsDamageDB'));

export const deleteImageFromLocalStorage = (image) => {
  const key = `photos-${image?.enquiryId}`;
  const cachedImages = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : [];
  const filteredCachedImages = cachedImages.filter(({ id }) => image?.id !== id);
  localStorage.setItem(key, JSON.stringify(filteredCachedImages));
};
