import { trackSelfDescribingEvent } from '@snowplow/browser-tracker';

export type GA_EVENTS_TYPE = {
  event?: string;
  eventAction: string;
  eventCategory: string | React.ReactNode;
  eventLabel?: string | React.ReactNode;
  eventValue: any;
};

const GAEventInit = {
  eventAction: undefined,
  eventCategory: undefined,
  eventLabel: undefined,
  eventValue: undefined,
};

export const GAEvent = (payload: GA_EVENTS_TYPE, cleanup?: boolean) => {
  window?.dataLayer?.push?.({
    ...GAEventInit,
    ...payload,
    event: payload.event ?? 'UAEvent',
  });

  if (cleanup) { // https://www.simoahava.com/gtm-tips/remember-to-flush-unused-data-layer-variables/
    const { event, ...rest } = payload;

    window?.dataLayer?.push?.(
      Object.keys(rest)
        .reduce((acc, key) => ({
          ...acc, [key]: undefined,
        }), {}),
    );
  }
};

export type SnowplowEventType = {
  [key: string]: any;
  schema: string;
  version?: string;
}

export const snowplowEvent = ({ schema, version = '1-0-0', ...payload }: SnowplowEventType) => {
  trackSelfDescribingEvent({
    event: {
      data: {},
      schema: `iglu:uk.co.motorway/${schema}/jsonschema/${version}`,
      ...payload,
    },
  });
};

export const GA_EVENT_ACTIONS = Object.freeze({
  BUTTON: 'button',
  CHECKBOX: 'checkbox',
  EMAIL: 'email',
  PAGE_LOADER: 'page loader - custom event',
  PHONE: 'telephone',
  RADIO: 'radio',
});

export const GA_EVENTS = Object.freeze({
  DEFAULT: (
    eventAction: string,
    eventCategory: string | React.ReactNode,
    eventLabel?: string | React.ReactNode,
    eventValue?: any,
  ) => GAEvent({
    eventAction,
    eventCategory,
    eventLabel,
    eventValue,
  }),
  IMAGE_ASSESSMENT: ({ eventAction, eventCategory, eventLabel, eventValue }: GA_EVENTS_TYPE) => GAEvent({
    eventAction,
    eventCategory,
    eventLabel,
    eventValue,
  }),
});
