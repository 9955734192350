import React from 'react';
import cx, { type Argument } from 'classnames';

import styles from './Wrapper.module.scss';

type WrapperProps = {
  children: React.ReactChild | React.ReactChild[];
  className?: Argument;
};

const Wrapper = ({
  children, className,
}: WrapperProps) => (
  <div className={cx(className, styles.component)}>
    <div className={styles.content}>
      {children}
    </div>
  </div>
);

export default Wrapper;
