import { MDText } from 'i18n-react';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../../utilities/analytics';
import { DAMAGE_CATEGORY_EVENTS } from '../../../../utilities/analytics/events/damage/damageCategoryEvents';
import type { DamageKindType, KindType } from '../../../../utilities/Types/vehiclePhotosCategories.types';
import { VEHICLE_DAMAGE_KIND_KEY } from '../../../../utilities/vehiclePhotosCategories';
import type { ImageAssessmentsStatuses } from '../../../context/Socket/Socket.types';

import LocalTexts from './CategoryDamage.json';
import { CATEGORY_PAGE_GA_EVENT_LABEL } from './CategoryPage.helpers';

const LocalT = new MDText(LocalTexts);

const CATEGORY_DAMAGE_PAGE_GA_EVENT_LABEL = Object.freeze({
  DAMAGE_CATEGORY_PAGE: {
    ADD_MORE_DAMAGE_BUTTON_LABEL: '{title} damage category page add more damage - button clicked',
    BACK_BUTTON_LABEL: '{title} damage category page back - button clicked',
    DAMAGE_CATEGORY: '{title} damage category page',
    DAMAGE_PAGE_LOADER_LABEL: '{title} damage category page - page loaded',
    DAMAGE_PHOTO_TIP_BUTTON_CLICK: '{title} damage category page photo tip button - button clicked',
    DELETE_DAMAGE_PHOTO_BUTTON_CLICK: '{title} damage category delete photo - button clicked',
  },
  GUIDANCE_PAGE: {
    GUIDANCE_CATEGORY: '{title} guidance page',
    GUIDANCE_PAGE_LOADER_LABEL: '{title} guidance page - page loaded',
    NO_DAMAGE_BUTTON_LABEL: '{title} guidance page no damage - button clicked',
    TAKE_PHOTOS_BUTTON_LABEL: '{title} guidance page take photos - button clicked',
  },
});

export default CATEGORY_DAMAGE_PAGE_GA_EVENT_LABEL;

export const SHORT_ADD_COPY: Partial<DamageKindType>[] = [
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_SCRATCHES,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_PAINTWORK,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_MISSING_TRIMS,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WARNING_LIGHTS,
];

export const damageCategoryPhotoGaEventHandler = ({ kind }: { kind: KindType }) => {
  const { DAMAGE_CATEGORY_PAGE } = CATEGORY_DAMAGE_PAGE_GA_EVENT_LABEL;
  const { DAMAGE_CATEGORY, DELETE_DAMAGE_PHOTO_BUTTON_CLICK } = DAMAGE_CATEGORY_PAGE;

  const title = LocalT.translate(`${kind}.title`) as string;
  const categoryEvent = LocalT.translate(DAMAGE_CATEGORY, ({ title }));
  const buttonEventLabel = LocalT.translate(DELETE_DAMAGE_PHOTO_BUTTON_CLICK, ({ title }));

  GA_EVENTS.DEFAULT(GA_EVENT_ACTIONS.BUTTON, categoryEvent, buttonEventLabel);
  DAMAGE_CATEGORY_EVENTS.DAMAGE_CATEGORY_DELETE_PHOTO_BUTTON_CLICK(title);
};

type AiDamagedPhotoGaHandlerProps = {
  autoAssessStatus?: ImageAssessmentsStatuses;
  fireAiImageAssistGaEvent?: boolean;
  kind: string;
};

export const aiDamagedPhotoGaHandler = ({
  autoAssessStatus,
  fireAiImageAssistGaEvent,
  kind,
}: AiDamagedPhotoGaHandlerProps) => {
  const { AI_IMAGE_ASSIST } = CATEGORY_PAGE_GA_EVENT_LABEL;
  const { CATEGORY, PHOTO_INPUT_LABEL } = AI_IMAGE_ASSIST;
  if (fireAiImageAssistGaEvent) {
    const title = LocalT.translate(`${kind}.title`);
    const eventCategory = LocalT.translate(CATEGORY, ({ title }));
    const eventLabel = LocalT.translate(PHOTO_INPUT_LABEL, ({ kind: title }));

    GA_EVENTS.IMAGE_ASSESSMENT({
      eventAction: GA_EVENT_ACTIONS.BUTTON,
      eventCategory,
      eventLabel,
      eventValue: autoAssessStatus,
    });
  }
};
