import { addGlobalContexts, removeGlobalContexts } from '@snowplow/browser-tracker';

import type { User } from '../Types/seller.types';
import type { Vehicle } from '../Types/vehicle.types';

type SnowplowContext = {
  enquiry: any;
  user: any;
  vehicle: any;
}

const SNOWPLOW_CONTEXTS: SnowplowContext = {
  enquiry: {},
  user: {},
  vehicle: {},
};

// Set global snowplow context
export const SNOWPLOW_USER_CUSTOMER_CONTEXT = (user?: User) => {
  removeGlobalContexts([SNOWPLOW_CONTEXTS.user]);

  if (!user) {
    return;
  }

  const data = {
    data: {
      logged_in: true,
      login_type: 'token',
      user_customer_id: user.id,
    },
    schema: 'iglu:uk.co.motorway/user_customer/jsonschema/1-0-0',
  };

  addGlobalContexts([data]);
  SNOWPLOW_CONTEXTS.user = data;
};

export const SNOWPLOW_VEHICLE_CONTEXT = (vehicle?: Vehicle) => {
  removeGlobalContexts([SNOWPLOW_CONTEXTS.vehicle]);

  if (!vehicle) {
    return;
  }

  const data = {
    data: {
      body_type: vehicle.body,
      colour: vehicle.colour,
      fuel_type: vehicle.fuel,
      model: vehicle.model,
      plt_vehicle_id: vehicle.id,
      vin: 'N/A',
      vrm: vehicle.vrm,
    },
    schema: 'iglu:uk.co.motorway/vehicle/jsonschema/1-0-1',
  };

  addGlobalContexts([data]);
  SNOWPLOW_CONTEXTS.vehicle = data;
};

export const SNOWPLOW_ENQUIRY_CONTEXT = (vehicle?: Vehicle) => {
  removeGlobalContexts([SNOWPLOW_CONTEXTS.enquiry]);

  if (!vehicle?.enquiry?.id) {
    return;
  }

  const { enquiry } = vehicle;

  const data = {
    data: {
      dp_enquiry_id: enquiry.dpEnquiryId,
      dp_enquiry_status: enquiry.dpStateSlug,
      plt_enquiry_id: enquiry.id,
      plt_enquiry_status: enquiry.status,
    },
    schema: 'iglu:uk.co.motorway/enquiry/jsonschema/1-0-0',
  };

  addGlobalContexts([data]);
  SNOWPLOW_CONTEXTS.enquiry = data;
};
