import React, { useContext, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router';
import { FORM_ERROR } from 'final-form';

import { MDText } from 'i18n-react';

import { datadogRum } from '@datadog/browser-rum';
import { Content } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';
import { InfoBox, Radio } from '@motorway/mw-highway-code/alpha';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../../utilities/analytics';
import { useFeatureFlags, useToggleHeadDisplay } from '../../../../utilities/hooks';
import type { VehiclePhotoCategoryPathType } from '../../../../utilities/Types/vehiclePhotosCategories.types';
import { VEHICLE_DAMAGE_KIND_PATH, VEHICLE_PHOTO_CATEGORY_PATH } from '../../../../utilities/vehiclePhotosCategories';
import { Context } from '../../../context/context';
import { usePhotosContext } from '../../../context/photos';
import Footer from '../../Footer/Footer';
import { DATA_DOG_RUM_PANEL_DAMAGE, PANEL_DAMAGE_GA_EVENTS } from '../DamagePanel.helpers';
import { useUpdateDamageDetails } from '../DamagePanelPage.hooks';

import { onDamageMetaSubmit } from './DamageSize.helpers';
import LocalTexts from './DamageSize.json';
import type { DamageSizeOption, DamageSizeProps, FieldValues } from './DamageSize.types';

import styles from './DamageSize.module.scss';

const LocalT = new MDText(LocalTexts);

export const DamageSize = ({ kind }: DamageSizeProps) => {
  useToggleHeadDisplay();
  const navigate = useNavigate();

  // TODO: Define type for vehicleDetails
  const { setParentState, vehicleDetails: { vrm } }: any = useContext(Context);

  const { showExpandPanelDamage } = useFeatureFlags();

  const panelDamageVersion = showExpandPanelDamage ? 'v2' : 'v1';

  const { updatePhotoDamageMeta } = usePhotosContext();

  const { deleteLocalStorageDetails, localStorageDetails, updateDamageDetails } = useUpdateDamageDetails({ kind });

  const text = {
    backButton: LocalT.translate('damageSize.buttons.back'),
    infoBoxContent: LocalT.translate('damageSize.infoBox.content'),
    submitButton: LocalT.translate('damageSize.buttons.submit'),
    subTitle: LocalT.translate('damageSize.subTitle'),
    title: LocalT.translate('damageSize.title'),
  } as Record<string, string>;

  const { options }: { options: DamageSizeOption[] } = LocalTexts.damageSize;

  useEffect(() => {
    datadogRum.startView(`${DATA_DOG_RUM_PANEL_DAMAGE.DAMAGE_SIZE_PAGE.VIEW}-${panelDamageVersion}`);
    GA_EVENTS.DEFAULT(
      GA_EVENT_ACTIONS.PAGE_LOADER,
      PANEL_DAMAGE_GA_EVENTS.CATEGORY,
      PANEL_DAMAGE_GA_EVENTS.SIZE_DAMAGE_PAGE.PAGE_LOADED,
    );
  }, []);

  const submitHandler = (values: FieldValues) => {
    if (!values.damageSizeField) {
      return { [FORM_ERROR]: text.infoBoxContent };
    }

    if (localStorageDetails) {
      const damageImageDetails = JSON.parse(localStorageDetails);
      const damageMeta = { ...damageImageDetails.damageMeta, size: values.damageSizeField };
      updateDamageDetails(damageMeta);
      const updatedDamageImageDetails = { ...damageImageDetails, damageMeta: { ...damageMeta } };
      onDamageMetaSubmit({ deleteLocalStorageDetails, updatedDamageImageDetails, updatePhotoDamageMeta });
    }
    const currentDamageKindPath = (VEHICLE_DAMAGE_KIND_PATH as VehiclePhotoCategoryPathType)[kind]?.path;
    const currentCategoryPath = VEHICLE_PHOTO_CATEGORY_PATH.damage.path;

    setParentState({ showDamageLocationOverlay: false });

    datadogRum.addAction(DATA_DOG_RUM_PANEL_DAMAGE.DAMAGE_SIZE_PAGE.ACTION_SUBMIT, { version: panelDamageVersion });
    return navigate(`/${vrm}${currentCategoryPath}${currentDamageKindPath}`, { replace: true });
  };

  const backHandler = () => {
    navigate(-1);
    datadogRum.addAction(DATA_DOG_RUM_PANEL_DAMAGE.DAMAGE_SIZE_PAGE.ACTION_BACK, { version: panelDamageVersion });
    GA_EVENTS.DEFAULT(
      GA_EVENT_ACTIONS.BUTTON,
      PANEL_DAMAGE_GA_EVENTS.CATEGORY,
      PANEL_DAMAGE_GA_EVENTS.SIZE_DAMAGE_PAGE.BACK_BUTTON,
    );
  };

  return (
    <Form
      onSubmit={submitHandler}
      render={({ handleSubmit, submitError }) => (
        <form onSubmit={handleSubmit}>
          <Content className={styles.component}>
            <div className={styles.container}>
              <div className={styles.header}>
                <h1>{text.title}</h1>
                {submitError && (
                  <InfoBox
                    content={text.infoBoxContent}
                    data-testid="info-box"
                    variant="error"
                  />
                )}
                <p>{text.subTitle}</p>
              </div>
              <fieldset className={styles.fieldSet}>
                {options.map((option) => (
                  <Field
                    key={option.value}
                    id={`radio-${option.value}`}
                    name={'damageSizeField'}
                    type="radio"
                    value={option.value}
                  >
                    {(props) => (
                      <Radio
                        fullWidth
                        formProps={{
                          ...props,
                          input: {
                            ...props.input,
                            onChange: (e) => {
                              props.input.onChange(e);
                            },
                          },
                        }}
                        id={props.input.id}
                        label={option.title}
                        subtext={option.subText}
                        variant="card"
                      />
                    )}
                  </Field>
                ))
                }
              </fieldset>
            </div>
          </Content>
          <Footer>
            <Button
              fullWidth
              reverse
              data-testid="submit-button"
              label={text.submitButton}
              onClick={handleSubmit}
              variant={'primary'}
            />
            <Button
              fullWidth
              reverse
              data-testid="back-button"
              icon={'chevron'}
              label={text.backButton}
              onClick={backHandler}
              variant={'secondary'}
            />
          </Footer>
        </form>
      )}
    />
  );
};
