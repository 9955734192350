import React from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import SafariSrc from '../../../assets/images/private/safari.svg';
import SafariTabSrc from '../../../assets/images/private/safari_tab.svg';
import { useToggleHeadDisplay } from '../../../utilities/hooks';
import Wrapper from '../Wrapper/Wrapper';

import LocalTexts from './Obsolete.json';

import styles from './Obsolete.module.scss';

const LocalT = new MDText(LocalTexts);

const CookiesBlockedIOS = () => {
  useToggleHeadDisplay();

  const listItems = Object.keys(LocalTexts.cookiesBlockedIOS.list);

  return (
    <Wrapper className={cx(styles.component, styles.cookies)}>
      <img
        alt="cookies blocked"
        className={styles.illustration}
        height={160}
        src={SafariSrc}
        width={113}
      />
      <h1>{ LocalT.translate('cookiesBlockedIOS.title') }</h1>
      <ol className={styles.steps}>
        {
          listItems.map((key) => (
            <li key={key}>
              { LocalT.translate(`cookiesBlockedIOS.list.${key}`, {
                icon: <img alt="tab" src={SafariTabSrc} />,
              }) }

            </li>
          ))
        }
      </ol>
    </Wrapper>
  );
};

export default CookiesBlockedIOS;
