import Dexie from 'dexie';

/**
 * NOTE: Do not store an auto-incrementation key for id
 * https://github.com/dfahlander/Dexie.js/issues/618#issuecomment-504407061
 */
interface PhotoDexie extends Dexie {
  photos: Dexie.Table;
}

const db = new Dexie('motorway-photos-database');

db.version(2).stores({
  photos: 'id, kind, enquiryId, platformId',
});

export default db as PhotoDexie;
