/* eslint-disable func-names */
import Config from '../../config.json';

import type { Preset } from './Types/photo.types';

export const isKindValid = <T>(kind: T, kindsList: T[]): boolean => kindsList.includes(kind);

export const IMAGE_STATUSES = {
  AGENT_SUPPLIED: 'agentSupplied',
  ARCHIVED: 'archived',
  DELETED: 'deleted',
  HISTORICAL: 'historical',
  RESUPPLY: 'resupplyRequested',
  SELLER_SUPPLIED: 'sellerSupplied',
} as Readonly<Record<string, string>>;

export const isRetake = () => (Object.freeze([
  IMAGE_STATUSES.DELETED,
  IMAGE_STATUSES.RESUPPLY,
  IMAGE_STATUSES.ARCHIVED,
]));

const sellerSuppliedStatuses = [IMAGE_STATUSES.SELLER_SUPPLIED, IMAGE_STATUSES.HISTORICAL];

export const isSellerSupplied = (status?: string) => sellerSuppliedStatuses.includes(status ?? '');

export const vrmPath = [
  '/:vrm(',
  '[A-Z]{2}[0-9]{2}[A-Z]{3}|',
  '[A-Z][0-9]{1,3}[A-Z]{3}|',
  '[A-Z]{3}[0-9]{1,3}[A-Z]|',
  '[0-9]{1,4}[A-Z]{1,2}|',
  '[0-9]{1,3}[A-Z]{1,3}|',
  '[A-Z]{1,2}[0-9]{1,4}|',
  '[A-Z]{1,3}[0-9]{1,3}|',
  '[A-Z]{1,3}[0-9]{1,4})',
].join('');

export const removeQueryStringParameter = (key: string) => {
  const query = new URLSearchParams(window.location.search);
  query.delete(key);

  const newQuery = query.toString();

  return window.location.href.replace(window.location.search, (newQuery) ? `?${newQuery}` : '');
};

export const s3toImgix = (
  imgURL?: string,
  opts: {
    fit?: string, h: number, meta?: { height: number, rotate: string, width: number, x: string, y: string }, w: number
  } = { ...Config.summary.thumbnailSize },
) => {
  if (!imgURL) {
    return null;
  }

  const s3URL = new URL(imgURL);
  let { host } = s3URL;

  switch (true) { // eslint-disable-line default-case
    case /^(?=.*stage).*\.amazonaws\.com$/.test(host):
      host = 'motorway-photos-stage.imgix.net';
      break;
    case /\.amazonaws\.com$/.test(host):
      host = 'motorway-photos.imgix.net';
      break;
  }

  const queryParams = new URLSearchParams(s3URL.search);
  const params = Object.fromEntries(queryParams);

  queryParams.set('auto', 'format');
  queryParams.set('dpr', String(window.devicePixelRatio));
  queryParams.set('fit', opts.fit ?? params.fit ?? 'crop');
  queryParams.set('h', String(opts.h || params.h || Config.summary.thumbnailSize.h));
  queryParams.set('w', String(opts.w || params.w || Config.summary.thumbnailSize.w));

  if (Number.isInteger(parseInt(opts?.meta?.x ?? ''))) {
    queryParams.set('rect', `${opts?.meta?.x},${opts?.meta?.y},${opts?.meta?.width},${opts?.meta?.height}`);
    queryParams.set('or', opts?.meta?.rotate ?? '');
  }

  return `https://${host}${s3URL.pathname}?${queryParams.toString()}`;
};

export const dataURItoBlob = (dataURI: string) => {
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: mime });
};

export const chromeVersion = () => {
  const pattern = 'Chrom(e|ium)\\/([0-9]+)\\.';
  const regex = new RegExp(pattern);
  const raw = navigator.userAgent.match(regex);
  return raw ? parseInt(raw[2], 10) : NaN;
};

export const isIOS = () => /(iPhone|iPad)/.test(window.navigator.userAgent);
export const isAndroid = () => /Android/.test(window.navigator.userAgent);
export const isChrome = () => /Chrome/i.test(window.navigator.userAgent);

export const isTouched = (v: any) => v !== null && v !== undefined;

export const getHeaderHeight = () => document.getElementById('photo-header')?.getBoundingClientRect()?.height;

// Safari hack for private browsing
// If an input is readOnly it will not allow you copy the value
export const toggleReadOnlyForIOS = (node: HTMLElement & { readOnly: boolean }) => {
  if (isIOS()) {
    node.readOnly = !node.readOnly;
  }
};

export const copyToClipboard = async (value: string) => {
  try {
    await navigator.clipboard.writeText(value);
    return true;
  } catch (err) {
    return false;
  }
};

/**
 * @param {string} phone "07400xxxxxx"
 * @output {string} whatsapp link https://wa.me/00447400xxxxxx
 */

/**
 * @param {string} unformatted "0203 988 xxxx"
 * @output {array} ["0203988xxxx", "0203 988 xxxx"]
 */
export const formatLandline = (unformatted: string) => {
  const landline = unformatted ?? '';
  const noSpace = landline.replace(/\D/g, '');
  const withSpace = noSpace.replace(/^(\d{4})(\d{3})(\d*)$/, '$1 $2 $3');

  return [noSpace, withSpace];
};

// You can't use `arguments` inside arrow functions
export const debounce = <T extends (...args: any[]) => any>(callback: T, wait: number, immediate = false) => {
  let timeout: NodeJS.Timeout;

  return (...args: any[]) => {
    const callNow = immediate && !timeout;
    const next = () => callback(...args);

    clearTimeout(timeout);
    timeout = setTimeout(next, wait);

    if (callNow) {
      next();
    }
  };
};

export const APPROVED_WEB_APP_PAGES = {
  CONFIRM_DETAILS_PAGE: 'confirm-details',
} as Readonly<Record<string, string>>;

export const isApprovedWebAppPage = (page: string) => Object.values(APPROVED_WEB_APP_PAGES).includes(page);

export const importAll = (r: __WebpackModuleApi.RequireContext) => {
  const images: Record<string, string> = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
};

export const sellerAppUrl = window.__ENV__.sellerAppUrl || 'https://motorway.co.uk'; // eslint-disable-line no-underscore-dangle

export const PRESETS = Object.freeze({
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
  THUMBNAIL: 'thumbnail',
  THUMBNAIL_TINY: 'thumbnailTiny',
}) as Readonly<Record<string, string>>;

export const getPresetImage = (presets?: Preset[], presetName = PRESETS.MOBILE) => {
  if (!presets?.length) {
    return undefined;
  }

  const preset = presets?.find((p) => p.name === presetName);
  return preset?.url;
};

export const setCssVar = (cssVar: Record<string, string> = {}) => {
  const root = document.documentElement;
  Object.entries(cssVar).forEach(([key, value]) => {
    root.style.setProperty(key, value);
  });
};

export const deleteCssVars = (cssVars: string[] | string) => {
  const root = document.documentElement;

  if (Array.isArray(cssVars) && cssVars?.length) {
    cssVars.forEach((key) => {
      root.style.removeProperty(key);
    });
  } else {
    root.style.removeProperty(cssVars as string);
  }
};

export const PHOTOS_PATH = '/photos';

const getProfilingFormUrl = (vrm = '', path = '') => `${sellerAppUrl}/${vrm}/profile${path}`;

export const redirectToWebApp = (vrm: string, token: string, additionalPath = '') => {
  const webAppPage = new URLSearchParams(window.location.search).get('motorway_page');

  const renderPath = () => {
    if (additionalPath) {
      return additionalPath;
    }

    if (webAppPage) {
      return `/${webAppPage}`;
    }

    return '';
  };

  const path = renderPath();
  const profilingFormUrl = getProfilingFormUrl(vrm, path);

  const userToken = token ? `/?token=${token}` : '';
  const link = `${profilingFormUrl}${userToken}`;

  return window.open(link, '_self');
};

const VRM_REGEX = /(^[A-Z]{2}[0-9]{2}[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)/i;

export const getVRM = (str: string) => str.split('/').filter((val) => VRM_REGEX.test(val)).reverse()?.[0];

export const IS_LOCALHOST = window.location.hostname === 'localhost';

// eslint-disable-next-line valid-typeof
export const type = (v: any, expected: any) => typeof v === expected;
export const isNumber = (v: any) => type(v, 'number');
export const isNull = (v: any): v is null => v === null;
export const isObject = (v: any) => !isNull(v) && type(v, 'object') && !Array.isArray(v);
export const isBoolean = (v: any) => !isNull(v) && type(v, 'boolean');
export const titleCaseFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.substring(1);
