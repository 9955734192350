import React, { useCallback, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { MDText } from 'i18n-react';

import { datadogRum } from '@datadog/browser-rum';
import { Modal } from '@motorway/motorway-storybook-cra';

import { getBodyType, HATCHBACK_TYPE } from '../../../utilities/bodyType';
import { isBoolean, isNull, isObject } from '../../../utilities/helpers';
import { useFeatureFlags, useToggleHeadDisplay } from '../../../utilities/hooks';
import type { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import { DamageKindType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { Context } from '../../context/context';
import { usePhotosContext } from '../../context/photos';
import DamageLocationOverlay from '../DamageLocationOverlay/DamageLocationOverlay';
import LandscapeWarning from '../LandscapeWarning/LandscapeWarning';
import { getCurrentImageItem, getHelpSearch } from '../Overlay/Overlay.helpers';

import DamagePanel from './DamagePanel';
import { DAMAGE_GA_EVENT_LABEL, DATA_DOG_RUM_PANEL_DAMAGE, handleOpenHelp, onDamageMetaSubmit } from './DamagePanel.helpers';
import { useUpdateDamageDetails } from './DamagePanelPage.hooks';

import styles from './DamagePanelPage.module.scss';

const LocalT = new MDText({});

type DamagePanelPageProps = {
  category: DamageKindType;
};

export const DamagePanelPage: React.FC<DamagePanelPageProps> = ({
  category,
}) => {
  useToggleHeadDisplay();

  const navigate = useNavigate();
  const location = useLocation();
  const { updatePhotoDamageMeta } = usePhotosContext();

  const { showExpandPanelDamage, showHatchBackPanelDamage } = useFeatureFlags();

  const panelDamageVersion = showExpandPanelDamage ? 'v2' : 'v1';

  const context = useContext(Context);
  const {
    imageToReplace, isLandscape, offer, seller, setParentState, showDamageLocationOverlay,
    vehicleDetails: { vrm }, videoInfo: { imageCategories },
  } = context as ContextValueTypes;

  const currentCategory = imageCategories.damage.filter(({ kind }) => kind === category);

  const categoryItem = getCurrentImageItem(currentCategory);

  const {
    deleteLocalStorageDetails,
    localStorageDetails,
    updateDamageDetails,
  } = useUpdateDamageDetails({ kind: category });

  const goToCategoryPage = useCallback(() => {
    window.dataLayer.push({
      event: 'UAEvent',
      eventAction: 'Damage panel - close',
      eventCategory: 'Damage panel',
      eventLabel: categoryItem.kind,
    });

    setParentState({ imageToReplace: null });
    navigate(-1);
  }, [categoryItem.kind, navigate, setParentState]);

  const vehicle = seller.vehicles.find(
    ({ enquiry }) => enquiry?.id === offer?.enquiryId,
  );

  const bodyType = getBodyType(vehicle ?? {});

  const handleClosePage = useCallback(() => {
    datadogRum.addAction(DATA_DOG_RUM_PANEL_DAMAGE.DAMAGE_PANEL_PAGE.ACTION_CANCEL, { version: panelDamageVersion });
    setParentState({
      showDamageLocationOverlay: undefined,
    });
    deleteLocalStorageDetails();
    const doesAnyHistoryEntryExist = location.key;
    if (doesAnyHistoryEntryExist) {
      goToCategoryPage();
    } else {
      setParentState({ imageToReplace: null });
      navigate(`/${vrm}`);
    }
  }, [deleteLocalStorageDetails, goToCategoryPage, location.key, navigate, setParentState, vrm]);

  const areEmptyContextDetails = isBoolean(showDamageLocationOverlay) || !isObject(showDamageLocationOverlay);
  const areEmptyLocalStorageDetails = isNull(localStorageDetails);

  useEffect(() => {
    if (areEmptyContextDetails && areEmptyLocalStorageDetails) {
      handleClosePage();
    }
  }, [areEmptyContextDetails, areEmptyLocalStorageDetails, handleClosePage]);

  const renderDetails = () => {
    if (isObject(showDamageLocationOverlay)) {
      return showDamageLocationOverlay;
    }

    if (localStorageDetails) {
      return JSON.parse(localStorageDetails);
    }

    return null;
  };

  const eventCategory = LocalT.translate(DAMAGE_GA_EVENT_LABEL.CATEGORY, {
    category: categoryItem.category,
  }) as string;

  const eventLabel = LocalT.translate(DAMAGE_GA_EVENT_LABEL.HELP_BUTTON_LABEL, {
    kind: categoryItem.description,
  }) as string;

  if (showExpandPanelDamage) {
    return (
      <>
        {!isLandscape && <LandscapeWarning /> }
        <DamagePanel
          bodyType={bodyType}
          cancelClick={handleClosePage}
          category={categoryItem}
          setDetails={updateDamageDetails}
        />
      </>
    );
  }

  if (showHatchBackPanelDamage && (vehicle?.body || '').toLowerCase() === HATCHBACK_TYPE) {
    return (
      <>
        {!isLandscape && <LandscapeWarning /> }
        <DamagePanel
          bodyType={bodyType}
          cancelClick={handleClosePage}
          category={categoryItem}
          setDetails={updateDamageDetails}
        />
      </>
    );
  }

  return (
    <>
      {!isLandscape && <LandscapeWarning /> }
      <Modal
        contentClass={styles.content}
        dialogClass={styles.dialog}
        withCloseButton={false}>
        <DamageLocationOverlay
          cancelClick={handleClosePage}
          category={categoryItem}
          onSubmit={(meta) => {
            onDamageMetaSubmit({
              callback: handleClosePage,
              damagePhotoDetails: renderDetails(),
              goToCategoryPage,
              imageToReplace,
              meta,
              setParentState,
              updatePhotoDamageMeta,
            });
          }}
          openHelp={() => handleOpenHelp({ categoryItem, eventCategory, eventLabel, getHelpSearch, navigate })}
        />
      </Modal>
    </>
  );
};
