import React from 'react';

import { MDText } from 'i18n-react';

import Camera from '../Camera/Camera';

import LocalTexts from './Home.json';

const LocalT = new MDText(LocalTexts);

const Home = () => (
  <Camera>
    <h2>{LocalT.translate('title')}</h2>
    <p>
      {LocalT.translate('strap', {
        link: (
          <a href={`tel:${LocalT.translate('phoneNumber')}`}>
            {LocalT.translate('phoneNumber')}
          </a>
        ),
      })}
    </p>
  </Camera>
);

export default Home;
