import { GENERIC_ANALYTICS_EVENTS } from '../../analyticsGenericEvents';
import { EVENT_CATEGORY } from '../events';

export const ONBOARDING_EVENTS = {
  ALLOW_CAMERA_PERMISSION_CLICK: () => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: 'Enable camera Allow',
  }),
  DENY_CAMERA_PERMISSION_CLICK: () => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: 'Enable camera Cancel',
  }),
  ENABLE_CAMERA_CLICK: () => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: 'Enable camera',
  }),
  ONBOARDING_PAGE_LOAD: (screenNumber: number) => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_CATEGORY,
    name: `Onboarding photo capture screen ${screenNumber}`,
  }),
  REENTRY_ENABLE_CAMERA_CLICK: () => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: 'Reentry Enable camera',
  }),
} as const;
