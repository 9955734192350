import { useContext, useEffect } from 'react';

import { updateVehicleData } from '../../../utilities/api';
import { handleCatchIfOnline } from '../../../utilities/api/helpers';
import { useNavigatorOnLine, usePrevious } from '../../../utilities/hooks';
import type { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import { VEHICLE_PHOTOS_CATEGORY_KEY } from '../../../utilities/vehiclePhotosCategories';
import { Context } from '../../context/context';
import { usePhotosContext } from '../../context/photos';
import { uploadOutstandingDamageMeta, uploadOutstandingImages } from '../PhotosHub/HubFooter/HubFooter.helper';
import { getOutStandingDamageMetaUploads, getOutStandingUploads } from '../PhotosHub/PhotosHub.helpers';

const useUploadImagesWhenOnline = () => {
  const internetStatus = useNavigatorOnLine();
  const prevInternetStatus = usePrevious(internetStatus);
  const { sortedPhotos, updatePhoto, updatePhotoDamageMeta } = usePhotosContext();
  const {
    isUploadingFinished, offer, seller, setParentState, updateVehicleCondition, videoInfo: { imageCategories },
  } = useContext(Context) as ContextValueTypes;

  const uploadImages = async () => {
    const outStandingUploads = getOutStandingUploads(sortedPhotos);

    if (outStandingUploads > 0) {
      await uploadOutstandingImages({
        sortedPhotos,
        token: seller.auth_token,
        updatePhoto,
        updatePhotoDamageMeta,
      });
    }

    const outStandingDamageMetaUploads = getOutStandingDamageMetaUploads(sortedPhotos);

    if (outStandingDamageMetaUploads > 0) {
      await uploadOutstandingDamageMeta({
        images: sortedPhotos[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE],
        updatePhotoDamageMeta,
      });
    }

    const damageCategories = imageCategories[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE];
    const updatedData = damageCategories.reduce((acc, categoryItem) => {
      const hasImages = sortedPhotos[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE]
        .filter(({ kind }) => kind === categoryItem.kind).length > 0;
      if (hasImages) {
        return { ...acc, [categoryItem.conditionKind as string]: true };
      }
      return acc;
    }, {});

    if (Object.keys(updatedData).length > 0) {
      updateVehicleData(offer.id, updatedData)
        .catch((err) => handleCatchIfOnline(err, 'fetch-update-vehicle-data'));
      updateVehicleCondition(updatedData);
    }

    setParentState({ isUploadingFinished: true });
  };

  useEffect(() => {
    if (isUploadingFinished) {
      setParentState({ isUploadingFinished: false });
    }

    if (prevInternetStatus === false && internetStatus) {
      uploadImages();
    }
  }, [internetStatus, prevInternetStatus]);
};

export default useUploadImagesWhenOnline;
