import {
  setDeviceTakePhotoCapabilities,
} from '../../../utilities/deviceHelpers';
import { captureDatadogException } from '../../../utilities/logger';
import type { CategoryType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import Obsolete from '../Obsolete/Obsolete.json';

import {
  calculateVisibleVideoSize,
  setCurrentVideoTimings,
  STREAM_LISTENERS,
  updateWorkerStreamState,
} from './Overlay.helpers';

const PLAYBACK_RATE = 1;

export const onVideoError = async (
  $videoOutlines: React.MutableRefObject<HTMLVideoElement | null>,
  setParentState: (obj: object) => void,
) => {
  const networkState: number = $videoOutlines?.current?.networkState || -1;
  const networkStateCodes = ['NETWORK_EMPTY', 'NETWORK_IDLE', 'NETWORK_LOADING', 'NETWORK_NO_SOURCE'];

  captureDatadogException({
    context: {
      networkState: `${networkState}: ${networkStateCodes[networkState]}`,
    },
    error: new Error('outlineVideo network error'),
    fingerprint: 'network-video-outline',
    isWarning: true,
  });

  if (networkState === 3) {
    setParentState({
      error: {
        buttonCopy: 'Retry',
        errorHappened: true,
        message: Obsolete.generalErrorMessage,
      },
    });
  }
};

type FirstFrameProps = {
  $outlines: HTMLVideoElement;
  currentCategory: CategoryType[];
  imageCategoriesIndex: number | null;
  setActivatePlayOverlay: (bool: boolean) => void;
  setVideoReady: (bool: boolean) => void;
}

export const displayFirstFrame = ({
  $outlines, currentCategory, imageCategoriesIndex, setActivatePlayOverlay, setVideoReady,
}: FirstFrameProps) => {
  // iOS inline videos don't display any content if not playing,
  // so it's set to autoplay, but then pauses as soon as it loads
  // https://stackoverflow.com/questions/47580647/html-video-first-frame-poster-on-ipad
  setCurrentVideoTimings($outlines, imageCategoriesIndex, currentCategory);

  if ($outlines.paused) {
    $outlines.play().then(() => {
      $outlines.pause();
      setVideoReady(true);
    }).catch((err: string) => {
      setCurrentVideoTimings($outlines, imageCategoriesIndex, currentCategory);
      setActivatePlayOverlay(true);

      captureDatadogException({
        error: err,
        errorTitle: 'Error during video play, autoplay was prevented',
        fingerprint: 'low-power-mode',
        isWarning: true,
      });
    });
  } else {
    $outlines.pause();
    setVideoReady(true);
  }
};

type ActivatePlayProps = {
  $outlines: HTMLVideoElement;
  setActivatePlayOverlay: (bool: boolean) => void;
  setShowHintTooltip: (bool: boolean) => void;
  setVideoReady: (bool: boolean) => void;
};

export const activatePlay = ({
  $outlines, setActivatePlayOverlay, setShowHintTooltip, setVideoReady,
}: ActivatePlayProps) => {
  setShowHintTooltip(true);
  $outlines.play().then(() => {
    $outlines.pause();
    setVideoReady(true);
    setActivatePlayOverlay(false);
  }).catch((err: string) => {
    captureDatadogException({
      error: err,
      errorTitle: 'Error during play car videoOutlines',
      fingerprint: 'play-video-outline',
    });
  });
};

export type DimensionsType = {
  height: number;
  letterBoxSide: boolean;
  width: number;
}

type setUpVideoProps = {
  $outline: HTMLVideoElement | null;
  $video: HTMLVideoElement;
  currentCategory: CategoryType[];
  imageCapture: React.MutableRefObject<ImageCapture | null>;
  imageCaptureCapabilities: React.MutableRefObject<PhotoSettings | null>;
  imageCategoriesIndex: number | null;
  setDimensions: (obj: DimensionsType | null) => void;
  stream: React.MutableRefObject<MediaStreamTrack | null>;
  videoInterval: React.MutableRefObject<NodeJS.Timeout | undefined>;
};

export const setUpVideo = ({
  $outline, $video, currentCategory, imageCapture, imageCaptureCapabilities,
  imageCategoriesIndex, setDimensions, stream, videoInterval,
}: setUpVideoProps) => () => {
  let newDimensions: DimensionsType | null = null;
  videoInterval.current = setInterval(() => {
    if (newDimensions) {
      if (videoInterval.current) {
        clearInterval(videoInterval.current);
      }
      // Set needed inline attributes for Safari to play inline
      ['autoplay', 'muted', 'playsinline'].forEach((v) => {
        if ($outline) {
          $outline.setAttribute(v, '');
        }
      });
      if ($outline) {
        $outline.playbackRate = PLAYBACK_RATE;
      }

      setCurrentVideoTimings($outline, imageCategoriesIndex, currentCategory);

      stream.current = ($video.srcObject as MediaStream)?.getVideoTracks?.()[0];
      updateWorkerStreamState(stream.current);

      STREAM_LISTENERS.forEach((e: string) => {
        // @ts-ignore type Event
        stream.current?.addEventListener?.(e, updateWorkerStreamState);
      });

      if (!('ImageCapture' in window)) {
        return;
      }
      imageCapture.current = new ImageCapture(stream.current);

      imageCapture.current.getPhotoCapabilities()
        .then((c: PhotoSettings) => {
          imageCaptureCapabilities.current = c;

          const {
            fillLightMode,
            imageHeight,
            imageWidth,
            redEyeReduction,
          } = c; // photoCapabilities isn't iterable or stringable

          const formatMediaSettingsRange = (axis: ImageCaptureSize = {}) => ({
            max: axis?.max,
            min: axis?.min,
            step: axis?.step,
          });

          setDeviceTakePhotoCapabilities({
            fillLightMode,
            imageHeight: formatMediaSettingsRange(imageHeight as ImageCaptureSize),
            imageWidth: formatMediaSettingsRange(imageWidth as ImageCaptureSize),
            redEyeReduction,
          });
        }).catch((err: string) => {
          captureDatadogException({
            error: err,
            fingerprint: 'get-photo-capabilities',
          });
        });
    }
    newDimensions = calculateVisibleVideoSize({ $video });
    setDimensions(newDimensions);
  }, 100);
};
