import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { datadogRum } from '@datadog/browser-rum';
import { Button, PlusIcon } from '@motorway/mw-highway-code';
import { Chip } from '@motorway/mw-highway-code/pre-alpha';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../utilities/analytics';
import { HATCHBACK_TYPE } from '../../../utilities/bodyType';
import { useFeatureFlags, useLockRootScroll, useToggleCssProperty, useToggleHeadDisplay } from '../../../utilities/hooks';
import { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import type { CategoryType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { VEHICLE_DAMAGE_KIND_KEY } from '../../../utilities/vehiclePhotosCategories';
import { Context } from '../../context/context';
import { useBreakpoint } from '../Breakpoints/Breakpoints';
import { SizingTips } from '../Help/HelpModal/SizingTips/SizingTips';
import { DAMAGE_SIZE_PATH } from '../PhotosHub/PhotosHub.helpers';

import ImageComponent from './ImageComponent/ImageComponent';
import { DATA_DOG_RUM_PANEL_DAMAGE, PANEL_DAMAGE_GA_EVENTS, panelDamageSideIcon } from './DamagePanel.helpers';
import { useGetButtonsPosition, useGetPanelButtonsPosition, useGetZoomInCoordinates, windscreenId } from './DamagePanel.hooks';
import LocalTexts from './DamagePanel.json';
import { getSideButtons, SideButtonProps } from './SideInfo';

import styles from './DamagePanel.module.scss';

const LocalT = new MDText(LocalTexts);

type details = {
  overlayUrl: string,
  panelType: string,
  side: string,
  x?: string,
  y?: string
}

export type DamagePanelProps = {
  bodyType?: string;
  cancelClick: () => void;
  category: CategoryType;
  setDetails: (details: details) => void;
};

// Multiplier to get the correct size of the image preview
const multiplierForImgPreview = 1.9658;
const regex = /\d+(x\d+)/gi;

export const getCurrentMultiplier = (url: string, w: number, h: number) => {
  const match = (url || '').match(regex);

  if (match) {
    const [width, height] = match[match.length - 1].split('x');

    return {
      mH: (Number(height) / h) || 1,
      mW: (Number(width) / w) || 1,
    };
  }

  return {
    mH: multiplierForImgPreview,
    mW: multiplierForImgPreview,
  };
};

const getCurrentOverlayUrl = (
  showExpandPanelDamage: boolean,
  isFullBack: boolean,
  currentSide: any,
  sideButtons: SideButtonProps[],
) => {
  if (isFullBack) {
    return sideButtons.find(({ id }) => id === currentSide.id)?.overlayUrl;
  }

  if (showExpandPanelDamage) {
    return currentSide.imageUrl;
  }

  return currentSide.overlayUrl;
};

const DamagePanel = ({
  bodyType = HATCHBACK_TYPE,
  cancelClick,
  category,
  setDetails,
}: DamagePanelProps) => {
  const navigate = useNavigate();

  const { showExpandPanelDamage } = useFeatureFlags();
  const { panelDamageInfo } = useContext(Context) as ContextValueTypes;
  const { sides } = panelDamageInfo || { bodyType: '', sides: [] };
  const sideButtons = getSideButtons();

  const panelSides = showExpandPanelDamage ? sides : sideButtons;

  const isWindscreen = category.kind === VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WINDSCREEN;
  const { minWidth: { breakpointTablet } } = useBreakpoint();

  const panelDamageVersion = showExpandPanelDamage ? 'v2' : 'v1';

  const imgRef = useRef<SVGSVGElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [childrenLoaded, setChildrenLoaded] = useState(!showExpandPanelDamage);
  const [isFullBack, setIsFullBack] = useState(false);
  const [currentSide, setCurrentSide] = useState<any>(panelSides[0]);
  const [active, setActive] = useState<string | null>(null);

  const [position, setPosition] = useState<{ x: string, y: string } | null>(null);
  const [absPosition, setAbsPosition] = useState<{ x: string, y: string } | null>(null);

  useToggleHeadDisplay();

  useLockRootScroll();

  useToggleCssProperty('--body-bg-color', '#000');

  const { setZoomIn, zoomIn } = useGetZoomInCoordinates({
    active, bodyType, containerRef, currentSide: currentSide.id, imgRef, isWindscreen,
  });

  // TODO: Remove this after the feature flag showExpandPanelDamage is removed
  const {
    position: buttonsPosition,
  } = useGetButtonsPosition(currentSide, imgRef, isWindscreen, bodyType, childrenLoaded, showExpandPanelDamage);

  const {
    panelButtonPositions,
  } = useGetPanelButtonsPosition(currentSide, imgRef, isWindscreen, bodyType, childrenLoaded, showExpandPanelDamage);

  const panelButtons = showExpandPanelDamage ? panelButtonPositions : buttonsPosition;

  useEffect(() => {
    datadogRum.startView(`${DATA_DOG_RUM_PANEL_DAMAGE.DAMAGE_PANEL_PAGE.VIEW}-${panelDamageVersion}`);
    GA_EVENTS.DEFAULT(
      GA_EVENT_ACTIONS.PAGE_LOADER,
      PANEL_DAMAGE_GA_EVENTS.CATEGORY,
      PANEL_DAMAGE_GA_EVENTS.PANEL_DAMAGE_PAGE.PAGE_LOADED,
    );
  }, [panelDamageVersion]);

  useEffect(() => {
    if (childrenLoaded && isWindscreen) {
      setActive(windscreenId);
    }
  }, [childrenLoaded, isWindscreen]);

  useEffect(() => {
    if (!active) {
      return;
    }

    const el = document.getElementById(`${bodyType}-${currentSide.id}-${active}`);
    el?.classList?.add?.(styles.activePanel);

    // eslint-disable-next-line consistent-return
    return () => {
      const elements = document.querySelectorAll(`[id^='${bodyType}-${currentSide.id}-']`);
      Array.from(elements).forEach((element) => element.classList?.remove?.(styles.activePanel));
    };
  }, [active]);

  const movePoint = (box: HTMLImageElement, clientX: number, clientY: number) => {
    const offset = box.getBoundingClientRect();
    const relativeX = clientX - offset.left;
    const relativeY = clientY - offset.top;
    const { mH, mW } = getCurrentMultiplier(
      currentSide.imageUrl,
      imgRef.current?.width?.baseVal?.value ?? 0,
      imgRef.current?.height?.baseVal?.value ?? 0,
    );

    const multiplierW = Number((
      ((imgRef.current?.width?.baseVal?.value ?? 0) * mW) / box.clientWidth).toFixed(2));
    const multiplierH = Number((
      ((imgRef.current?.height?.baseVal?.value ?? 0) * mH) / box.clientHeight).toFixed(2));
    const scale = parseFloat(zoomIn[0]);

    const finalX = relativeX / scale;
    const finalY = relativeY / scale;

    const percentageX = box.clientWidth / 100;
    const percentageY = box.clientHeight / 100;

    setPosition({ x: `${finalX / percentageX}%`, y: `${finalY / percentageY}%` });
    setAbsPosition({ x: (finalX * multiplierW).toFixed(), y: (finalY * multiplierH).toFixed() });
  };

  const onImageClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (active && (e.target as SVGUseElement)?.href?.baseVal.includes(active)) {
      movePoint(e.currentTarget as HTMLImageElement, e.clientX, e.clientY);
    }
    datadogRum.addAction(DATA_DOG_RUM_PANEL_DAMAGE.DAMAGE_PANEL_PAGE.ACTION_POINT, { version: panelDamageVersion });
  };

  const onImageTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (active) {
      const currentTarget = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY);
      if ((currentTarget as SVGUseElement)?.href?.baseVal.includes(active)) {
        movePoint(e.currentTarget as HTMLImageElement, e.touches[0].clientX, e.touches[0].clientY);
      }
    }
  };

  const backHandler = () => {
    setActive(null);
    setZoomIn(['1']);
    setPosition(null);
    datadogRum.addAction(DATA_DOG_RUM_PANEL_DAMAGE.DAMAGE_PANEL_PAGE.ACTION_BACK, { version: panelDamageVersion });
  };

  const SideImage = currentSide?.image;

  return ReactDOM.createPortal(
    (
      <div
        ref={containerRef}
        className={cx(styles.container, { [styles.active]: active })}
        data-testid='damage-panel'
        // @ts-ignore css4 variables
        style={{ '--car-scale': zoomIn[0], '--car-translate-x': zoomIn[1], '--car-translate-y': zoomIn[2] }}
      >
        <div className={styles.actions}>
          <Button
            label={(active && !isWindscreen) ? LocalT.translate('actions.back') : LocalT.translate('actions.exit')}
            onClick={(active && !isWindscreen) ? backHandler : cancelClick}
            size={breakpointTablet ? 'medium' : 'small'}
            variant='tertiary'
          />
          <div className={styles.hintBox}>
            <span className={styles.hintNum}>{(active && !isWindscreen) ? '2' : '1'}</span>
            {LocalT.translate(`info.${active ? 'section' : 'panel'}`)}
          </div>
          <SizingTips />
        </div>
        <div className={styles.carBox} data-testid='damage-img-box' onClick={onImageClick} onTouchMove={onImageTouchMove}>
          {position && (
            // @ts-ignore css4 variables
            <div className={styles.point} style={{ '--car-point-scale': zoomIn[0], left: position?.x, top: position?.y }} />
          )}
          {!isWindscreen && panelButtons.map((panelButton) => (
            <button
              key={panelButton.id}
              className={styles.plusIcon}
              data-testid="plus-button"
              onClick={() => {
                setActive(panelButton.id);
                datadogRum.addAction(
                  DATA_DOG_RUM_PANEL_DAMAGE.DAMAGE_PANEL_PAGE.ACTION_PANEL,
                  { version: panelDamageVersion },
                );
              }}
              style={{ left: panelButton.x, top: panelButton.y }} type="button">
              <PlusIcon size='xsmall' />
            </button>
          ))}
          {showExpandPanelDamage
            ? (<ImageComponent
              ref={imgRef}
              active={active}
              bodyType={bodyType}
              currentSide={currentSide}
              setIsFullBack={() => setIsFullBack(true)}
              setLoaded={() => setChildrenLoaded(true)}
            />)
            : (<SideImage ref={imgRef} bodyType={bodyType} useHref={active} />)
          }
        </div>
        <div className={cx(styles.imgSwitch, { [styles.hidden]: active })}>
          {(!isWindscreen && !showExpandPanelDamage) && (
            // TODO: remove this condition after the feature flag showExpandPanelDamage is removed
            sideButtons.map((button) => (
              <Chip
                key={button.id}
                icon={button.svg}
                label={button.label}
                onClick={() => {
                  setCurrentSide(button);
                  setPosition(null);
                  datadogRum.addAction(
                    DATA_DOG_RUM_PANEL_DAMAGE.DAMAGE_PANEL_PAGE.ACTION_SIDE,
                    { version: panelDamageVersion },
                  );
                }}
                reverse={true}
                selected={button.id === currentSide.id}
                size={breakpointTablet ? 'medium' : 'small'}
              />
            ))
          )}
          {(!isWindscreen && showExpandPanelDamage) && (
            sides.map((side) => {
              const sideIcon = panelDamageSideIcon(side);
              return (
                <Chip
                  key={side.id}
                  icon={sideIcon}
                  label={side.name}
                  onClick={() => {
                    setCurrentSide(side);
                    setPosition(null);
                    datadogRum.addAction(
                      DATA_DOG_RUM_PANEL_DAMAGE.DAMAGE_PANEL_PAGE.ACTION_SIDE,
                      { version: panelDamageVersion },
                    );
                  }}
                  reverse={true}
                  selected={side.id === currentSide.id}
                  size={breakpointTablet ? 'medium' : 'small'}
                />
              );
            })
          )}
        </div>
        {active && (
          <div className={styles.infoBox}>
            {active.split('_').join(' ')}
            {position && (
              <div className={styles.continue}>
                <Button
                  icon="chevron"
                  label={LocalT.translate('actions.continue')}
                  onClick={() => {
                    setDetails({
                      overlayUrl: getCurrentOverlayUrl(showExpandPanelDamage, isFullBack, currentSide, sideButtons),
                      panelType: active,
                      side: currentSide.id,
                      ...absPosition,
                    });
                    datadogRum.addAction(
                      DATA_DOG_RUM_PANEL_DAMAGE.DAMAGE_PANEL_PAGE.ACTION_CONTINUE,
                      { version: panelDamageVersion },
                    );
                    navigate(`./${DAMAGE_SIZE_PATH}`);
                  }}
                  variant="primary"
                />
              </div>
            )}
          </div>
        )}
      </div>
    ), document.body,
  );
};

export default DamagePanel;
