import React, { ReactNode } from 'react';
import type { Argument } from 'classnames';
import cx from 'classnames';

import styles from './Tooltip.module.scss';

type ToolTipsProps = {
  children: ReactNode;
  className?: Argument;
  showTooltip: boolean;
}

export const Tooltip = ({ children, className, showTooltip }: ToolTipsProps) => (
  <div className={cx(styles.skipTooltip, className, { [styles.showTooltip]: showTooltip })}>
    {children}
  </div>
);
