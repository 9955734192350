import * as React from 'react';

const CarTopIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg height={24} width={24} {...props} viewBox="0 0 24 24">
    <path
      className="motorway-svg-fill"
      d="M8.68 2.6L10.64 6h4.14l-1.41-2.67 1.33-.7L16.47 6h5.14L23 7.44v9.2L21.6 18h-3.47v.01L18.1 18h-1.75l-1.65 3.14-1.33-.7L14.66 18H10.5l-1.83 3.17-1.3-.75L8.78 18H6.4l-.03.01V18h-4L1 16.62v-9.2L2.39 6H8.9L7.38 3.35l1.3-.74zm-2.9 4.9H3.01l-.52.53V16l.5.5h3l4.09-2.35.2-.04h4.04l4.17 2.39H21l.5-.5V8.06l-.53-.55h-2.25l-4.39 2.51h-4.05l-.2-.04-4.3-2.47zm5.74 8.12h-.96L9 16.5h2.5v-.89zm2.41 0h-.91v.88h2.46l-1.55-.89zm5.78-7.75l.28.96c.6 2.09.6 4.3 0 6.38l-.27.93-2.97-1.75.02-2.3-.02-2.48 2.96-1.74zm-.94 2.3l-.52.3.02 1.61-.02 1.46.52.3c.22-1.2.22-2.44 0-3.64v-.04zM15.7 7.5h-2.68v1.01h.91L15.7 7.5zm-4.18 0H8.79l1.77 1.01h.96V7.5z"
      fillRule="evenodd"
    />
  </svg>
);

export default CarTopIcon;
