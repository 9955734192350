import React from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import ChromeSrc from '../../../assets/images/private/chrome.svg';
import ChromeTabSrc from '../../../assets/images/private/chrome_tab.svg';
import FirefoxSrc from '../../../assets/images/private/firefox.svg';
import SafariSrc from '../../../assets/images/private/safari.svg';
import SafariTabSrc from '../../../assets/images/private/safari_tab.svg';
import { useToggleHeadDisplay } from '../../../utilities/hooks';
import Wrapper from '../Wrapper/Wrapper';

import LocalTexts from './Obsolete.json';

import styles from './Obsolete.module.scss';

const LocalT = new MDText(LocalTexts);

const images = {
  chrome: {
    height: 160,
    tabUrl: ChromeTabSrc,
    url: ChromeSrc,
    width: 182,
  },
  firefox: {
    height: 92,
    tabUrl: ChromeTabSrc,
    url: FirefoxSrc,
    width: 160,
  },
  safari: {
    height: 160,
    tabUrl: SafariTabSrc,
    url: SafariSrc,
    width: 113,
  },
};

const getBrowser = () => {
  if (/CriOS/i.test(navigator.userAgent)) {
    return 'chrome';
  }

  if (/FxiOS/i.test(navigator.userAgent)) {
    return 'firefox';
  }

  return 'safari';
};

const PrivateBrowsing = ({ children }: { children: React.ReactChild }) => {
  useToggleHeadDisplay();

  const agentKey = getBrowser();
  const listItems = Object.keys(LocalTexts.private.list[agentKey]);

  return (
    <Wrapper className={cx(styles.component, styles.private)}>
      <img
        alt="private browsing"
        className={styles.illustration}
        height={images[agentKey].height}
        src={images[agentKey].url}
        width={images[agentKey].width}
      />
      <h1>{ LocalT.translate(`private.title.${agentKey}`) }</h1>
      <ol className={styles.steps}>
        {
          listItems.map((key) => (
            <li key={key}>
              { LocalT.translate(`private.list.${agentKey}.${key}`, {
                icon: <img alt="tab" src={images[agentKey].tabUrl} />,
              }) }

            </li>
          ))
        }
      </ol>
      {children}
    </Wrapper>
  );
};

export default PrivateBrowsing;
