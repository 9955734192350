/* eslint-disable consistent-return */
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';

import type { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import { Context } from '../../context/context';
import { usePhotosContext } from '../../context/photos';
import Loading from '../Loading/Loading';
import NoUser from '../NoUser/NoUser';
import Start from '../Start/Start';

import { useGetVRMData, useSetUploadHandler } from './VRM.hooks';

import styles from './VRM.module.scss';

const VRM = () => {
  const { vrm } = useParams();
  const { offer, seller } = useContext(Context) as ContextValueTypes;

  const { loading, outlinesVideoLoading, vehicle } = useGetVRMData(vrm);

  const { loading: photoLoading } = usePhotosContext();

  useSetUploadHandler();

  useEffect(() => {
    window.addEventListener('unload', () => undefined); // Disable back-forward cache
  }, []);

  if (loading || outlinesVideoLoading || photoLoading) {
    return <Loading />;
  }

  if (!seller) {
    return (<NoUser />);
  }

  if (!offer || !vehicle) {
    return (<div className={styles.center}>Cannot find an offer for that vehicle</div>);
  }

  return (
    <div className={styles.component}>
      <Start />
    </div>
  );
};

export default VRM;
