import React, { Children, useEffect } from 'react';

import { MDText } from 'i18n-react';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../../../utilities/analytics';
import { importAll } from '../../../../../utilities/helpers';
import type { DamageKindType } from '../../../../../utilities/Types/vehiclePhotosCategories.types';
import { PANEL_DAMAGE_GA_EVENTS } from '../../../DamagePanel/DamagePanel.helpers';
import { HelpModal } from '../HelpModal';

import LocalTexts from './PhotoTips.json';

import styles from './PhotoTips.module.scss';

const LocalT = new MDText(LocalTexts);

const explanationImages = importAll(
  require.context('../../../../../assets/images/panel-examples', false, /.jpg$/),
);

type PhotoTipsProps = {
  kind: DamageKindType;
};

export const PhotoTips = ({ kind }: PhotoTipsProps) => {
  const text = {
    button: LocalT.translate('button'),
    description: LocalT.translate(`${kind}.p`),
  } as Record<string, string>;

  useEffect(() => {
    GA_EVENTS.DEFAULT(
      GA_EVENT_ACTIONS.PAGE_LOADER,
      PANEL_DAMAGE_GA_EVENTS.CATEGORY,
      PANEL_DAMAGE_GA_EVENTS.CAMERA_PAGE.PAGE_LOADED,
    );
  }, []);

  const imageList = Object.entries(explanationImages || {}).filter(([key]) => key.includes(kind)).map(([, val]) => val);
  const captions = Object.keys(LocalTexts[kind as keyof typeof LocalTexts]).filter((key) => key.includes('caption'));

  const gaEvents = {
    action: GA_EVENT_ACTIONS.BUTTON,
    category: PANEL_DAMAGE_GA_EVENTS.CATEGORY,
    label: PANEL_DAMAGE_GA_EVENTS.CAMERA_PAGE.PHOTO_TIPS_BUTTON,
  };

  return (
    <HelpModal buttonText={text.button} className={styles.damageHelp} gaEvents={gaEvents}>
      <p>{text.description}</p>
      {Children.toArray(
        captions.map((key, i) => (
          <figure>
            <img alt={kind} src={imageList[i]} />
            <figcaption>
              {LocalT.translate(`${kind}.${key}`)}
            </figcaption>
          </figure>
        )),
      )}
    </HelpModal>
  );
};
