import { MDText } from 'i18n-react';

import { isKindValid, setCssVar } from '../../../utilities/helpers';
import { isOptionalCategory } from '../../../utilities/optionalCategories';
import getSkipPhoto from '../../../utilities/skipPhoto';
import type { Offer } from '../../../utilities/Types/offer.types';
import type { Photo } from '../../../utilities/Types/photo.types';
import type { CategoryType, KindType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { DAMAGE_KIND_LIST, INTERIOR_KINDS_LIST, TYRES_KINDS_LIST, WHEELS_KINDS_LIST } from '../../../utilities/vehiclePhotosCategories';
import db from '../../db';
import { QueryableWorker } from '../../worker';
import { DAMAGE_GA_EVENT_LABEL } from '../DamagePanel/DamagePanel.helpers';

const LocalT = new MDText({});

export const DAMAGE_LOCATION_CATEGORIES = Object.freeze(['damage_scratches', 'damage_dents', 'damage_paintwork', 'damage_windscreen']);
export const STREAM_LISTENERS: readonly string[] = Object.freeze(['ended', 'muted', 'unmute']);

export const getHelpSearch = (kind: KindType) => {
  const sign = window?.location?.search ? '&' : '?';
  if (isKindValid(kind, INTERIOR_KINDS_LIST) || isKindValid(kind, DAMAGE_KIND_LIST)) {
    return `${sign}kind=${kind}`;
  }

  if (isKindValid(kind, WHEELS_KINDS_LIST)) {
    return `${sign}kind=wheel`;
  }

  if (isKindValid(kind, TYRES_KINDS_LIST)) {
    return `${sign}kind=tyres`;
  }

  return '';
};

export const destructureStream = (data: MediaStreamTrack | null) => {
  // @ts-ignore property does not exist
  const stream = data?.currentTarget || data;
  const { enabled, muted, readyState } = stream || {};

  return { enabled, muted, readyState };
};

export const updateWorkerStreamState = (stream: MediaStreamTrack | null) => {
  QueryableWorker.postMessage(['setStreamState', destructureStream(stream)]);
};

export const calculateVisibleVideoSize = ({ $video }: { $video?: HTMLVideoElement }) => {
  if (!$video) {
    return null;
  }

  const rect = $video.getBoundingClientRect();

  const { height, width } = rect;
  const aspectRatio = $video.videoWidth / $video.videoHeight;

  const dimensions = {
    height: width / aspectRatio,
    letterBoxSide: false,
    width,
  };

  if (dimensions.height > height) {
    dimensions.height = height;
    dimensions.letterBoxSide = true;
  }

  const sideBarWidth = Math.ceil((width - Math.round(height * (4 / 3))) / 2);

  setCssVar({
    '--overlay-bg-size-width': `${Math.ceil(dimensions.width - (sideBarWidth * 2))}px`,
    '--sidebar-width': `${sideBarWidth}px`,
  });

  return dimensions;
};

export const getCurrentImageItem = (
  currentCategory: CategoryType[] = [],
  imageCategoriesIndex: number | null = null,
): CategoryType =>
  currentCategory[imageCategoriesIndex || 0] || {};

export const setCurrentVideoTimings = (
  $video: HTMLVideoElement | null,
  imageCategoriesIndex: number | null,
  currentCategory: CategoryType[],
) => {
  const { timings: videoTimings } = getCurrentImageItem(currentCategory, imageCategoriesIndex);

  if (videoTimings && $video) {
    const [, currentTime] = videoTimings;
    $video.currentTime = currentTime;
  }
};

type SkipPhotoProps = {
  currentCategory: CategoryType[];
  imageCategoriesIndex: number | null;
  offer: Offer;
  setImageCategoriesIndex: (ind: number | null) => void;
  updateContextPhoto: (photo: object, toReplace?: boolean) => void;
};

export const skipPhoto = ({
  currentCategory, imageCategoriesIndex, offer, setImageCategoriesIndex, updateContextPhoto,
}: SkipPhotoProps) => {
  const now = Date.now();
  const { category, kind } = getCurrentImageItem(currentCategory, imageCategoriesIndex);
  const isOptional = isOptionalCategory(category);

  setImageCategoriesIndex((imageCategoriesIndex || 0) + 1);

  // The user is moving on to the next step, no need to save
  if (isOptional) {
    return;
  }

  const photoObj = getSkipPhoto(category, kind, now, offer.enquiryId, offer.id);

  updateContextPhoto(photoObj);

  db.photos.put(photoObj, { kind }).then(() => {
    console.log(`IndexedDB record saved: ${photoObj.kind}`);
  }).catch((err: string) => {
    /* eslint-disable no-alert */
    alert('Could not save your image');
    throw new Error(err);
  });
};

type DisplaySkipProps = {
  categoryItem: CategoryType;
  currentCategory: CategoryType[];
  imageCategoriesIndex: number | null;
  imageToReplace?: Photo;
  offer: Offer;
  setImageCategoriesIndex: (ind: number | null) => void;
  updateContextPhoto: (photo: object, toReplace?: boolean) => void;
};

export type DisplaySkipReturn = {
  category: string;
  delay?: number;
  description: string;
  label: string;
  message?: string;
  skip: () => void;
};

export const displaySkip = ({
  categoryItem, currentCategory, imageCategoriesIndex,
  imageToReplace, offer, setImageCategoriesIndex, updateContextPhoto,
}: DisplaySkipProps): DisplaySkipReturn | null => {
  // Don't allow to proceed if adding damage photo via Summary panel
  if (imageToReplace) {
    return null;
  }

  if (categoryItem.skip) {
    const { timings } = currentCategory[imageCategoriesIndex ?? -1] || {};
    const [startTime, endTime] = timings || [];
    const animationDuration = Math.round((endTime - startTime) * 1000);

    return {
      category: 'skip',
      delay: animationDuration,
      description: categoryItem.description,
      label: 'Skip',
      message: categoryItem.message,
      skip: () => {
        skipPhoto({
          currentCategory,
          imageCategoriesIndex,
          offer,
          setImageCategoriesIndex,
          updateContextPhoto,
        });
      },
    };
  }

  return null;
};

export const renderEventCategory = (categoryItem: CategoryType) =>
  LocalT.translate(DAMAGE_GA_EVENT_LABEL.CATEGORY, {
    category: categoryItem.category,
  }) as string;

export const renderEventLabel = (categoryItem: CategoryType) =>
  LocalT.translate(DAMAGE_GA_EVENT_LABEL.HELP_BUTTON_LABEL, {
    kind: categoryItem.description,
  }) as string;

export const addDetailsToLocalStorage = (details: Photo) => {
  const localStorageKey = `details_${details.vehicleId}_${details.kind}`;

  localStorage.setItem(localStorageKey, JSON.stringify(details));
};

export const dataLayerPush = (eventLabel: string, eventAction?: string | number) => {
  window.dataLayer.push({
    event: 'UAEvent',
    ...eventAction && { eventAction },
    eventCategory: 'Image capture',
    eventLabel,
  });
};
