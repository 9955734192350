const saloonTimings = {
  exterior_front_driver: [0, 0],
  exterior_front_passenger: [8.1, 12.33],
  exterior_rear_driver: [0.13, 3.83],
  exterior_rear_passenger: [3.83, 8.1],
  instrument_cluster: [64.6, 68.4],
  interior_boot: [41.6, 47.53],
  interior_dashboard: [53.67, 57.83],
  interior_front_seats: [58.97, 64.5],
  interior_rear_seats: [47.6, 53.4],
  tyre_tread_front_driver: [24.1, 27.5],
  tyre_tread_front_passenger: [16.57, 19.83],
  tyre_tread_rear_driver: [31.87, 35.1],
  tyre_tread_rear_passenger: [38.4, 41.6],
  wheels_front_driver: [19.87, 24.1],
  wheels_front_passenger: [12.33, 16.57],
  wheels_rear_driver: [27.63, 31.87],
  wheels_rear_passenger: [35.13, 38.37],
};

export default saloonTimings;
