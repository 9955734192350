import React, { useContext, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';
import { InfoBox, Radio } from '@motorway/mw-highway-code/alpha';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../utilities/analytics';
import { MECHANICAL_AND_ADDITIONAL_EVENTS } from '../../../utilities/analytics/events/damage/MechanicalAndAdditionalEvents';
import { useFeatureFlags, useIsStatusRestricted, useToggleHeadDisplay } from '../../../utilities/hooks';
import type { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import { Context } from '../../context/context';
import Footer from '../Footer/Footer';
import InfoCheckbox from '../InfoCheckbox/InfoCheckbox';
import LocalTexts from '../PhotosHub/CategoryPage/CategoryDamage.json';
import CategoryPageHeader from '../PhotosHub/CategoryPage/CategoryPageHeader';

import OtherDescription from './Description';
import useDescriptionDamageForm, { type DescriptionAllowedCategoryType } from './DescriptionDamage.hook';

import styles from './DescriptionDamage.module.scss';

const LocalT = new MDText(LocalTexts);

export const GA_EVENTS_DAMAGE = (category?: string, value?: boolean) => GA_EVENTS.DEFAULT(
  GA_EVENT_ACTIONS.RADIO,
  `${category} damage`,
  undefined,
  value,
);

type DescriptionDamageProps = {
  category: DescriptionAllowedCategoryType;
};

export const DescriptionDamage = ({ category }: DescriptionDamageProps) => {
  const { offer: { vehicle: { manufactureYear } }, vehicleDetails } = useContext(Context) as ContextValueTypes;

  const isApproved = useIsStatusRestricted();
  const navigate = useNavigate();

  const { showMechElecGuidanceImprovements } = useFeatureFlags();

  const renderMechElecGuidanceImprovements = showMechElecGuidanceImprovements && category === 'damage_mechanical_or_electrical';

  const currentYear = new Date().getFullYear();
  const vehicleAge = currentYear - manufactureYear;
  const vehicleAgeStatus = vehicleAge > 3 ? 'older' : 'younger';
  const mechanElecInfoBoxWarnings = LocalTexts.damage_mechanical_or_electrical.infoBox[vehicleAgeStatus].warnings;

  useToggleHeadDisplay();

  const { vrm } = vehicleDetails || {};

  const text = {
    title: LocalT.translate(`${category}.title`) as string,
  } as const;

  const {
    fieldDescName,
    fieldName,
    initialValues,
    name,
    onDone,
    onSubmit,
  } = useDescriptionDamageForm(category, text.title);

  const [showDesc, setShowDesc] = useState(initialValues[fieldName] === 'true');
  const [isInitValNull, setIsInitValNull] = useState(initialValues[fieldName] === null);

  useEffect(() => {
    MECHANICAL_AND_ADDITIONAL_EVENTS.MECHANICAL_AND_ADDITIONAL_PAGE_LOAD(text.title);
  }, [text.title]);

  const showExampleHandler = () => {
    MECHANICAL_AND_ADDITIONAL_EVENTS.MECHANICAL_AND_ADDITIONAL_SHOW_EXAMPLE_BUTTON_CLICK(text.title);
    navigate('./help');
  };

  return (
    <Form
      {...{ initialValues, onSubmit }}
      render={({ dirtySinceLastSubmit, handleSubmit, submitError, values }) => (
        <>
          <Content className={styles.component}>
            <CategoryPageHeader
              isDone
              category={category}
              title={text.title}
              {...{ isApproved, vrm }}
            />
            <p className={styles.desc}>{LocalT.translate(`${category}.p`)}</p>
            <form className={styles.inputBox} onSubmit={handleSubmit}>
              {isApproved ? (
                <InfoCheckbox label={LocalT.translate(`${category}.questions.${values[fieldName]}`)} />
              ) : (
                <>
                  {[false, true].map((key) => (
                    <Field
                      key={String(key)}
                      id={`radio-${category}-${key}`}
                      name={fieldName}
                      type="radio"
                      value={String(key)}
                    >
                      {(props) => (
                        <Radio
                          fullWidth
                          formProps={{
                            ...props,
                            input: {
                              ...props.input,
                              onChange: (e) => {
                                props.input.onChange(e);
                                setShowDesc(e.target.value === 'true');
                                setIsInitValNull(false);
                                GA_EVENTS_DAMAGE(name, e.target.value === 'true');
                              },
                            },
                          }}
                          id={props.input.id}
                          label={LocalT.translate(`${category}.questions.${key}`)}
                          variant="card"
                        >
                          {LocalT.translate(`${category}.questions.${key}`)}
                        </Radio>
                      )}
                    </Field>
                  ))}
                </>
              )}
              {(renderMechElecGuidanceImprovements && submitError && !dirtySinceLastSubmit) && (
                <div className={styles.errorMessage}>
                  <InfoBox
                    content={submitError}
                    variant="error"
                  />
                </div>
              )}
              <OtherDescription
                fieldName={fieldDescName}
                intent={(renderMechElecGuidanceImprovements && submitError) && 'error'}
                show={showDesc}
                showMechElecGuidanceImprovements={showMechElecGuidanceImprovements}
                {...{ category, isApproved }}
              />
              {(!renderMechElecGuidanceImprovements && submitError && !dirtySinceLastSubmit) && (
                <div className={styles.errorMessageOld}>
                  <InfoBox
                    content={submitError}
                    variant="error"
                  />
                </div>
              )}
              {(renderMechElecGuidanceImprovements && !showDesc && !isInitValNull) && (
                <div className={styles.errorMessageOld}>
                  <InfoBox
                    content={(
                      <div className={styles.warningBox}>
                        <div className={styles.warningBoxContent}>
                          <ul>
                            {mechanElecInfoBoxWarnings.map((warning) => (
                              <li key={warning}>{LocalT.translate(warning)}</li>
                            ))}
                          </ul>
                        </div>
                        <div className={styles.infoBoxButton}>
                          <Button
                            reverse
                            label={LocalT.translate(`${category}.infoBox.button`)}
                            onClick={showExampleHandler}
                            size='small'
                            variant='secondary'
                          />
                        </div>
                      </div>
                    )}
                    heading={LocalT.translate(`${category}.infoBox.older.title`)}
                    variant="warning"
                  />
                </div>
              )}
              {(renderMechElecGuidanceImprovements && showDesc) && (
                <div className={styles.errorMessageOld}>
                  <InfoBox
                    content={(
                      <div>
                        <p>{LocalT.translate(`${category}.infoBox.information.description`)}</p>
                        <div className={styles.infoBoxButton}>
                          <Button
                            reverse
                            label={LocalT.translate(`${category}.infoBox.button`)}
                            onClick={showExampleHandler}
                            size='small'
                            variant='secondary'
                          />
                        </div>
                      </div>
                    )}
                    variant="info"
                  />
                </div>
              )}

            </form>
          </Content>
          <Footer>
            <Button
              fullWidth
              reverse
              label={isApproved ? LocalT.translate('finish') : LocalT.translate(`${category}.button`)}
              onClick={isApproved ? onDone : handleSubmit}
              type="submit"
              variant="primary"
            />
          </Footer>
        </>
      )}
    />
  );
};
