import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { filterDataProps } from '@motorway/mw-highway-code/src/utils/helpers';

import type { MessagingCardProps } from './MessagingCard.types';

import styles from './MessagingCard.module.scss';

const MessagingCard = ({
  action,
  className,
  description,
  heading,
  href,
  image,
  onClick,
  ...props
}: MessagingCardProps): React.ReactElement => {
  const card = (
    <div
      className={cx(styles.messagingCard, className)}
      {...filterDataProps(props)}
      data-testid="messaging-card"
    >
      <div className={styles.text}>
        <h6 className={styles.header}>{heading}</h6>
        {description && <p className={styles.description}>{description}</p>}
        {action && <span className={styles.action}>{action}</span>}
      </div>
      {image && (
        <div className={styles.image}>
          {image}
        </div>
      )}
    </div>
  );

  const messageCard = href
    ? (
      <Link
        className={styles.messageCardLink}
        data-testid="messaging-link"
        onClick={onClick}
        state={href?.state}
        to={href?.pathname}
      >
        {card}
      </Link>
    )
    : card;

  return messageCard;
};

export default MessagingCard;
