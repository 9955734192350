import * as React from 'react';

const CarFrontIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg height={24} width={24} {...props} viewBox="0 0 24 24">
    <path
      className="motorway-svg-fill"
      d="M16.48 17.07l.6 1.29-1.36.64-.2-.42H8.47l-.2.42-1.35-.64.6-1.29h8.95zM12.02 5c1.79 0 3.58.14 5.37.43l.49.08.46.08 1.08 3.67 1.23-.65.7 1.33-1.27.67.42.85v7.37H19v-7.01l-.58-1.17-.1.02a33 33 0 01-6.31.7c-2.04.01-4.2-.19-6.48-.6L5 11.8v7.02H3.5v-7.37l.42-.85-1.27-.67.7-1.33 1.23.65 1.09-3.67.45-.08a34.6 34.6 0 015.9-.51zM8.5 13.3v1.5h-2v-1.5h2zm9 0v1.5h-2v-1.5h2zm-3.5 0v1.5h-4v-1.5h4zm-1.98-6.8c-1.56 0-3.13.12-4.7.35l-.49.07-.71 2.42c2.07.36 4.03.53 5.88.52 1.86-.02 3.81-.23 5.85-.63l-.69-2.3a32.45 32.45 0 00-4.68-.42h-.46z"
      fillRule="evenodd"
    />
  </svg>
);

export default CarFrontIcon;
