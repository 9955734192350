import React, { useContext, useRef, useState } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { IconText, LockClosedIcon, TickCircleIcon } from '@motorway/mw-highway-code';
import { Checkbox } from '@motorway/mw-highway-code/alpha';

import type { ContextValueTypes } from '../../../../utilities/Types/contextTypes';
import type { Photo } from '../../../../utilities/Types/photo.types';
import { VEHICLE_DAMAGE_KIND_KEY, VEHICLE_PHOTOS_CATEGORY_KEY } from '../../../../utilities/vehiclePhotosCategories';
import { Context } from '../../../context/context';
import LocalTexts from '../../PhotosHub/CategoryPage/CategoryDamage.json';
import { useImageToURL } from '../../PhotosHub/CategoryPage/PhotoBlock/PhotoBlock.hooks';
import { GA_EVENTS_CONDITION_DAMAGE, IMAGE_SIZE } from '../MarkDamaged.helpers';

import styles from '../MarkDamaged.module.scss';

const LocalT = new MDText(LocalTexts);

export type WheelDamageInputProps = {
  isApproved: boolean,
  item: Photo,
}

const WheelDamageInput: React.FC<WheelDamageInputProps> = ({ isApproved, item }) => {
  const { videoInfo: { imageCategories } } = useContext(Context) as ContextValueTypes;
  const imageURL = useRef<string>();
  const [showApprovedOverlay, setShowApprovedOverlay] = useState(false);
  const { imageToURL } = useImageToURL(imageURL);

  const value = imageCategories[VEHICLE_PHOTOS_CATEGORY_KEY.WHEELS]
    .find((i) => i.kind === item.kind);
  const label = value?.damageDescription || '';

  const getFigCaption = () => {
    if (showApprovedOverlay) {
      return null;
    }

    return (
      <figcaption className={styles.label}>
        <div className={styles.desc}>
          {label}
          {isApproved && (
            <div className={styles.isApproved}>
              <IconText
                icon={TickCircleIcon}
                text={LocalT.translate(`${VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WHEELS}.isApproved.label${item.isDamaged ? 'Damaged' : 'NotDamaged'}`)}
                variant="xsmall--regular"
              />
            </div>
          )}
        </div>
        {isApproved && (
          <div className={styles.locked}>
            <LockClosedIcon size="small" />
          </div>
        )}
      </figcaption>
    );
  };

  const inputOnClick = () => {
    if (!isApproved) {
      return;
    }

    setShowApprovedOverlay(!showApprovedOverlay);
  };

  const addGaTags = (formProps: FieldRenderProps<unknown, HTMLElement, unknown>) =>
    GA_EVENTS_CONDITION_DAMAGE(value?.category, value?.description, !formProps?.input?.checked);

  return (
    <div
      className={cx(styles.item, { [styles.disabled]: isApproved })}
      onClick={inputOnClick}
      role="button"
      tabIndex={0}
    >
      {(isApproved && showApprovedOverlay) && (
        <div className={styles.overlay}>
          <span>{LocalT.translate(`${VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WHEELS}.isApproved.lockReason`)}</span>
        </div>
      )}
      <Field
        disabled="true"
        id={item.kind}
        name={item.kind}
        type="checkbox"
      >
        {(formProps) => (
          <div
            className={styles.inputContainer}
          >
            <figure
              className={cx(styles.image, { [styles.noCheckbox]: isApproved })}
              onClick={() => {
                if (!isApproved) {
                  formProps.input.onChange(!formProps.input.checked);
                  addGaTags(formProps);
                }
              }}
            >
              <img
                alt={label}
                height={IMAGE_SIZE.height}
                src={imageToURL(item) ?? ''}
                width={IMAGE_SIZE.width}
              />
              {getFigCaption()}
            </figure>
            {!isApproved && (
              <Checkbox
                fullWidth
                formProps={{
                  ...formProps,
                  input: {
                    ...formProps.input,
                    onChange: (e) => {
                      formProps.input.onChange(e);
                      addGaTags(formProps);
                    },
                  },
                }}
                id={item.kind}
                label={LocalT.translate(`${VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WHEELS}.inputLabel`)}
                variant="card"
              />
            )}
          </div>
        )}
      </Field>
    </div>
  );
};

export default WheelDamageInput;
