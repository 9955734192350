import React, { useEffect, useState } from 'react';

import { MDText } from 'i18n-react';

import WhiteCarSideSVG from '../../../assets/images/loaders/white-car-side';
import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../utilities/analytics';
import { ONBOARDING_EVENTS } from '../../../utilities/analytics/events/onboarding/onboardingEvents';
import { setCssVar } from '../../../utilities/helpers';

import { Screen1 } from './Screens/Screen1';
import { Screen2 } from './Screens/Screen2';
import { Screen3 } from './Screens/Screen3';
import { Screen4 } from './Screens/Screen4';
import { Screen5 } from './Screens/Screen5';
import { Screen6 } from './Screens/Screen6';
import { LOADER_GA_EVENT_LABEL } from './Loader.helper';
import { Pagination } from './Pagination';

import styles from './Loader.module.scss';

const LocalT = new MDText({});

let carScaledDown = false;

export const Scroller = ({ startButton }: {startButton: React.ReactNode}) => {
  const [scrollX, setScrollX] = useState(0);

  const [pageIndex, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(1);

  const screens = [
    (<Screen1 key="screen1" scrollX={scrollX} />),
    (<Screen2 key="screen2" scrollX={scrollX} />),
    (<Screen3 key="screen3" />),
    (<Screen4 key="screen4" scrollX={scrollX} />),
    (<Screen5 key="screen5" scrollX={scrollX} />),
    (<Screen6 key="screen6" scrollX={scrollX} startButton={startButton} />),
  ];

  const calculatePage = (e: React.UIEvent<HTMLElement>) => {
    const scrollPosition = e.currentTarget.scrollLeft;
    let currentPage = Math.floor(scrollPosition / (window.innerWidth * 0.9));
    currentPage = currentPage < 0 ? 0 : currentPage;

    if (currentPage !== pageIndex) {
      setPage(currentPage);

      if (currentPage >= nextPage) {
        // Stop users from swiping through all the pages in one swipe
        // by only adding pages per swipe

        if (nextPage + 1 === screens.length) {
          return;
        }

        e.currentTarget.scrollLeft = window.innerWidth * currentPage;
        // Allow animations to finish
        const timings: { [key: number]: number } = {
          1: 1500,
          4: 800,
        };

        setCssVar({
          '--loader-pointer-events': 'none',
        });

        setTimeout(() => {
          setCssVar({
            '--loader-pointer-events': 'all',
          });

          setNextPage(nextPage + 1);
        }, timings[currentPage] || 250);
      }
    }

    setScrollX(scrollPosition);
  };

  useEffect(() => {
    const { innerWidth } = window;
    const scaleDown = (scrollX >= innerWidth * 2.75 && scrollX <= innerWidth * 4.95);

    if (scaleDown !== carScaledDown) {
      carScaledDown = scaleDown;
      setCssVar({
        '--loader-screen4-car': (scaleDown) ? '0.5' : '1',
      });
    }
  }, [scrollX]);

  useEffect(() => {
    if (pageIndex === screens.length - 1) { // User has reached last screen, set it so they don't have to repeat
      localStorage.setItem('skipLoaders', JSON.stringify({
        skip: true,
        time: Date.now(),
      }));
    }
  }, [pageIndex, screens.length]);

  useEffect(() => {
    const { PAGE_LOADER } = GA_EVENT_ACTIONS;
    const { CATEGORY, PAGE_LOADER_ONBOARDING_SCREEN } = LOADER_GA_EVENT_LABEL;
    const onBoardingScreenLabel = LocalT.translate(PAGE_LOADER_ONBOARDING_SCREEN, { pageIndex: pageIndex + 1 });
    GA_EVENTS.DEFAULT(
      PAGE_LOADER,
      CATEGORY,
      onBoardingScreenLabel,
    );
    ONBOARDING_EVENTS.ONBOARDING_PAGE_LOAD(pageIndex + 1);
  }, [pageIndex]);

  return (
    <>
      <div className={styles.carousel} data-testid="carousel" onScroll={calculatePage}>
        { screens.slice(0, nextPage + 1) }
      </div>
      <div className={styles.carImg}>
        <WhiteCarSideSVG className={styles.original} />
        <WhiteCarSideSVG className={styles.dirty} id="loader-dirty-car" />
        <div className={styles.circle}>
          <em>2m</em>
          <em>2m</em>
        </div>
      </div>
      <span className={styles.circleItem} />
      <Pagination itemLength={screens.length} pageIndex={pageIndex} />
    </>
  );
};
