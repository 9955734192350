import { GENERIC_ANALYTICS_EVENTS } from '../../analyticsGenericEvents';
import { EVENT_CATEGORY } from '../events';

export const DAMAGE_HUB_EVENTS = {
  DAMAGE_HUB_BACK_BUTTON_CLICK: () => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: 'Damage hub back to summary button',
  }),
  DAMAGE_HUB_DONE_BUTTON_CLICK: () => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: 'Damage hub done button',
  }),
  DAMAGE_HUB_PAGE_LOAD: () => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_CATEGORY,
    name: 'Damage hub',
  }),
  DAMAGE_HUB_SECTION_CLICK: (section: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `Damage hub ${section} section`,
  }),
} as const;
