import { snowplowEvent } from '../analytics';

import type {
  API_TRIGGERED_EVENT_PAYLOAD,
  CLICK_EVENT_PAYLOAD,
  CTA_CLICK_EVENT_PAYLOAD,
  HOVER_EVENT_PAYLOAD,
  LOAD_EVENT_PAYLOAD,
  TOGGLE_CLICK_EVENT_PAYLOAD,
} from './analyticsGenericEvents.types';

export const GENERIC_ANALYTICS_EVENTS = {
  API_TRIGGERED: (payload: API_TRIGGERED_EVENT_PAYLOAD) => snowplowEvent({
    data: payload,
    schema: 'backend_trigger',
    version: '1-0-1',
  }),
  CLICK_EVENT: (payload: CLICK_EVENT_PAYLOAD) => snowplowEvent({
    data: payload,
    schema: 'click',
    version: '2-0-0',
  }),
  CTA_CLICK: (payload: CTA_CLICK_EVENT_PAYLOAD) => snowplowEvent({
    data: payload,
    schema: 'cta_clicked',
    version: '5-0-1',
  }),
  HOVER_EVENT: (payload: HOVER_EVENT_PAYLOAD) => snowplowEvent({
    data: payload,
    schema: 'hover',
    version: '2-0-1',
  }),
  POPUP_LOAD_EVENT: (payload: LOAD_EVENT_PAYLOAD) => snowplowEvent({
    data: payload,
    schema: 'popup_appear',
  }),
  SECTION_LOAD_EVENT: (payload: LOAD_EVENT_PAYLOAD) => snowplowEvent({
    data: payload,
    schema: 'load',
  }),
  TOGGLE_CLICK_EVENT: (payload: TOGGLE_CLICK_EVENT_PAYLOAD) => snowplowEvent({
    data: payload,
    schema: 'toggle_click',
    version: '2-0-1',
  }),
} as const;
