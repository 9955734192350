import React from 'react';
import ReactDOM from 'react-dom';

import 'react-hot-loader';

import { newTracker, trackSelfDescribingEvent } from '@snowplow/browser-tracker';

import Config from '../config.json';

import App from './shared/components/App';
import { initDatadogRum } from './utilities/datadogRum';
import { IS_LOCALHOST } from './utilities/helpers';

import './assets/scss/index.scss';

declare global {
  interface Window {
    __ENV__: Record<string, any>,
    cameraStream: MediaStream | null;
    dataLayer: Record<string, any>[] | {
      push(event: any): ()=> void;
    };
  }
}

// https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

window.addEventListener('beforeinstallprompt', (e) => {
  // https://developers.google.com/web/fundamentals/app-install-banners#mini-info-bar
  e.preventDefault(); // We don't want Chrome to prompt the user to install the app
});

const IS_PROD = window.location.hostname === Config.env.production;

if (!IS_LOCALHOST) {
  // eslint-disable-next-line no-underscore-dangle
  initDatadogRum(IS_PROD, window.__ENV__.releaseVersion);
}
const SNOWPLOW_COLLECTOR_URL = IS_PROD ? 'https://laptime.motorway.co.uk' : 'https://laptime.stage.motorway.co.uk';

newTracker('sp1', SNOWPLOW_COLLECTOR_URL, {
  appId: `customer-website-${IS_PROD ? 'prod' : 'test'}`,
  cookieDomain: '.motorway.co.uk',
  cookieSameSite: 'Lax',
  discoverRootDomain: false,
  eventMethod: 'beacon',
  onSessionUpdateCallback() {
    trackSelfDescribingEvent({
      event: {
        data: {},
        schema: 'iglu:uk.co.motorway/new_session_start/jsonschema/1-0-0',
      },
    });
  },
  plugins: [],
});

ReactDOM.render(<App />, document.getElementById('root'));
