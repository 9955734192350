export const PANEL_TYPES = {
  EXAMPLE: 'example',
  HELP: 'help',
} as const;

export const HELP_PANEL_GA_EVENT_LABEL = Object.freeze({
  CATEGORY: '{title} {panel} page',
  CLOSE_BUTTON: '{title} {panel} page {button} - button clicked',
  PAGE_LOADER_LABEL: '{title} {panel} page - page loaded',
});
