import * as React from 'react';

const CarDriverSideIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg height={24} width={24} {...props} viewBox="0 0 24 24">
    <path
      className="motorway-svg-fill"
      d="M6 12.75a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5zm12 0a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5zm-12 1.5a.75.75 0 100 1.5.75.75 0 000-1.5zm12 0a.75.75 0 100 1.5.75.75 0 000-1.5zM13.66 6l5.46 3.5h2L23 11.53v3.97h-1.5v-3.39L20.47 11H5.7L4.48 9.07 2.5 10.3v5.2H1V9.46L6.58 6h7.08zm.84 8v1.5h-5V14h5zM9.2 7.5H7.02l-1.1.69.78 1.31H9.2v-2zm4.02 0h-2.51v2h5.63l-3.12-2z"
      fillRule="evenodd"
    />
  </svg>
);

export default CarDriverSideIcon;
