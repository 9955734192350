export type FeatureContent = {
  content: {
    [key: string]: {
      description: string;
      enabled: boolean;
    }
  }
};

export const PROFILING_FEATURE_FLAGS = 'mw-profiling-feature-flags-public';
