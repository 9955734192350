import React, { useContext, useState } from 'react';

import { MDText } from 'i18n-react';

import { ToastStatuses, useToast } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../../utilities/analytics';
import { PHOTOS_HUB_EVENTS } from '../../../../utilities/analytics/events/photosHub/photosHubEvents';
import { redirectToWebApp } from '../../../../utilities/helpers';
import { useAiImageAssessmentFeatureFlag } from '../../../../utilities/hooks';
import { ContextValueTypes } from '../../../../utilities/Types/contextTypes';
import { VEHICLE_DAMAGE_KIND_KEY, VEHICLE_PHOTOS_CATEGORY_KEY } from '../../../../utilities/vehiclePhotosCategories';
import { Context } from '../../../context/context';
import { usePhotosContext } from '../../../context/photos';
import Footer from '../../Footer/Footer';
import { useGetMarkDamageImages } from '../../MarkDamaged/MarkDamaged.hooks';
import { getOutStandingDamageMetaUploads, getOutStandingUploads, PHOTOS_HUB_GA_EVENT_LABEL } from '../PhotosHub.helpers';
import LocalTexts from '../VehiclePhotos.json';

import { updateActiveDamageCategories, uploadOutstandingDamageMeta, uploadOutstandingImages } from './HubFooter.helper';

import styles from './HubFooter.module.scss';

const LocalT = new MDText(LocalTexts);

type HubFooterProps = {
  isUploading?: boolean;
  show?: boolean;
  toUpload?: boolean;
  totalOutStandingImageAssessments?: number;
};

const HubFooter = ({
  isUploading = false,
  show = false,
  totalOutStandingImageAssessments = 0,
  toUpload = false,
}: HubFooterProps) => {
  const { showAiImageAssist } = useAiImageAssessmentFeatureFlag();
  const { sortedPhotos, updatePhoto, updatePhotoDamageMeta } = usePhotosContext();
  const { images: wheelImages } = useGetMarkDamageImages(VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WHEELS);
  const { images: tyreImages } = useGetMarkDamageImages(VEHICLE_DAMAGE_KIND_KEY.DAMAGE_TYRES);

  const { add } = useToast();
  const {
    offer, seller, updateVehicleCondition, vehicleDetails, videoInfo: { imageCategories },
  } = useContext(Context) as ContextValueTypes;

  const [loading, setLoading] = useState(false);

  const onError = () => {
    add(
      (
        <div>
          <h6>{LocalT.translate('vehiclePhotos.errors.upload')}</h6>
          <p>{LocalT.translate('vehiclePhotos.errors.noInternet')}</p>
        </div>
      ), ToastStatuses.ERROR,

      {
        className: styles.toast,
        longMessage: true,
      },
    );
  };

  const onSuccess = () => {
    add(
      (
        <div>
          <h6>{LocalT.translate('vehiclePhotos.success.title')}</h6>
          <p>{LocalT.translate('vehiclePhotos.success.action')}</p>
        </div>
      ), ToastStatuses.SUCCESS,

      {
        className: styles.toast,
        longMessage: true,
      },
    );
  };

  const submitPhotos = async () => {
    const {
      AI_IMAGE_ASSIST,
      CATEGORY,
      SUBMIT_BUTTON_LABEL,
      UPLOADED_OUTSTANDING_DAMAGE_META,
      UPLOADED_OUTSTANDING_IMAGES,
    } = PHOTOS_HUB_GA_EVENT_LABEL;

    const { BUTTON } = GA_EVENT_ACTIONS;

    GA_EVENTS.DEFAULT(BUTTON, CATEGORY, SUBMIT_BUTTON_LABEL);
    PHOTOS_HUB_EVENTS.PHOTOS_HUB_SUBMIT_BUTTON_CLICK();

    if (showAiImageAssist) {
      GA_EVENTS.IMAGE_ASSESSMENT({
        eventAction: GA_EVENT_ACTIONS.BUTTON,
        eventCategory: AI_IMAGE_ASSIST.CATEGORY,
        eventLabel: AI_IMAGE_ASSIST.SUBMIT_BUTTON_LABEL,
        eventValue: totalOutStandingImageAssessments,
      });
    }

    setLoading(true);

    let doneUploading = true;
    const errorHandler = () => {
      if (doneUploading) {
        onError();
      }
      doneUploading = false;
    };
    const outStandingUploads = getOutStandingUploads(sortedPhotos);
    const outStandingDamageMetaUploads = getOutStandingDamageMetaUploads(sortedPhotos);
    const damagePhotos = wheelImages.concat(
      sortedPhotos[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE],
      tyreImages,
    );
    const isUpdatedDamageCategories = await updateActiveDamageCategories({
      damageCategories: imageCategories[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE],
      images: damagePhotos,
      offer,
      onError: errorHandler,
      tyreImages,
      updateVehicleCondition,
      vehicleDetails,
      wheelImages,
    });

    if (outStandingUploads === 0 && outStandingDamageMetaUploads === 0 && isUpdatedDamageCategories) {
      return redirectToWebApp(vehicleDetails?.vrm, seller.auth_token);
    }

    if (outStandingUploads > 0) {
      await uploadOutstandingImages({
        onError: errorHandler,
        sortedPhotos,
        token: seller.auth_token,
        updatePhoto,
        updatePhotoDamageMeta,
      });
      GA_EVENTS.DEFAULT(BUTTON, CATEGORY, UPLOADED_OUTSTANDING_IMAGES);
    }

    if (outStandingDamageMetaUploads > 0) {
      await uploadOutstandingDamageMeta({
        images: sortedPhotos[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE],
        onError: errorHandler,
        updatePhotoDamageMeta,
      });
      GA_EVENTS.DEFAULT(BUTTON, CATEGORY, UPLOADED_OUTSTANDING_DAMAGE_META);
    }

    if (doneUploading) {
      onSuccess();
    }
    setLoading(false);
    return null;
  };

  if (!show) {
    return null;
  }

  return (
    <Footer>
      <Button
        fullWidth
        label={toUpload ? LocalT.translate('vehiclePhotos.upload') : LocalT.translate('vehiclePhotos.submit')}
        loading={loading || isUploading}
        onClick={submitPhotos}
        type="button"
      />
    </Footer>
  );
};

export default HubFooter;
