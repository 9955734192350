import { GENERIC_ANALYTICS_EVENTS } from '../../analyticsGenericEvents';
import { EVENT_CATEGORY } from '../events';

export const MECHANICAL_AND_ADDITIONAL_EVENTS = {
  MECHANICAL_AND_ADDITIONAL_CONTINUE_BUTTON_CLICK: (title: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `${title} continue button`,
  }),
  MECHANICAL_AND_ADDITIONAL_DONE_BUTTON_CLICK: (title: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `${title} done button`,
  }),
  MECHANICAL_AND_ADDITIONAL_MODAL_PAGE_LOAD: (title: string) => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_CATEGORY,
    name: `${title} example modal page load`,
  }),
  MECHANICAL_AND_ADDITIONAL_PAGE_LOAD: (title: string) => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_CATEGORY,
    name: title,
  }),
  MECHANICAL_AND_ADDITIONAL_SHOW_EXAMPLE_BUTTON_CLICK: (title: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `${title} show example button`,
  }),

} as const;
