import React, { Children } from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Button, CheckboxIcon, CrossCircleIcon, TickCircleIcon } from '@motorway/mw-highway-code';
import { SIZE } from '@motorway/mw-highway-code/enums';

import { importAll } from '../../../utilities/helpers';

import ContactPanel from './ContactPanel';
import type { categoriesType } from './Help.types';
import LocalTexts from './HelpPanel.json';

import styles from './HelpPanel.module.scss';

const LocalT = new MDText(LocalTexts);

const images = importAll(
  require.context('../../../assets/images/help', false, /.jpg$/),
) as Record<string, string>;

type HelpProps = {
  closeHelpPage: (buttonType: string) => void;
  sectionKey: categoriesType;
}

const Help: React.FC<HelpProps> = ({ closeHelpPage, sectionKey }) => {
  const section = LocalTexts.photos[sectionKey] as Record<string, string | string[]>;

  const { cons, pros } = section || [];

  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <>
          <h3 className={cx(styles.header)}>
            {LocalT.translate(`photos.${sectionKey}.title`)}
          </h3>
          {section.p && (
            <p>{section.p}</p>
          )}
          <div className={styles.columns}>
            <div className={styles.column}>
              <div className={styles.imageWrapper}>
                <img alt="good" src={images[`photos_${sectionKey}_good.jpg`]} />
              </div>
              <h5>
                <span className={styles.iconTick}>
                  <TickCircleIcon size="small" />
                </span>
                <span>Good</span>
              </h5>
              <ul>
                {Children.toArray((pros as string[]).map((pro: string) => (
                  <li>
                    <CheckboxIcon size="small" />
                    <span>{pro}</span>
                  </li>
                )))}
              </ul>
            </div>

            <div className={styles.column}>
              <div className={styles.imageWrapper}>
                <img alt="bad" src={images[`photos_${sectionKey}_bad.jpg`]} />
              </div>
              <h5>
                <span className={styles.iconBad}>
                  <CrossCircleIcon size={SIZE.SMALL} />
                </span>
                <span>Bad</span>
              </h5>
              <ul>
                {Children.toArray((cons as string[]).map((con: string) => (
                  <li>
                    <CheckboxIcon size={SIZE.SMALL} />
                    <span>{con}</span>
                  </li>
                )))}
              </ul>
            </div>
          </div>
        </>
        <ContactPanel />
      </div>
      <div className={styles.stickyButton}>
        <div className={styles.footer}>
          <Button
            fullWidth
            label={LocalT.translate('footerBtn')}
            onClick={() => closeHelpPage(LocalT.translate('footerBtn') as string)}
          />
        </div>
      </div>
    </div>
  );
};

export default Help;
