import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import LocalTexts from './Screens.json';

import styles from './Screens.module.scss';

const LocalT = new MDText(LocalTexts);

export const Screen5 = ({ scrollX }: {scrollX: number}) => {
  const [viewable, setViewable] = useState(false);
  const [animate, setAnimate] = useState(false);

  const getViewable = useCallback(
    (innerRange, outerRange) =>
      scrollX >= window.innerWidth * innerRange
      && scrollX <= window.innerWidth * outerRange,
    [scrollX],
  );

  useEffect(() => {
    setViewable(getViewable(2.9, 5));
  }, [getViewable, scrollX]);

  useEffect(() => {
    if (viewable !== animate) {
      setAnimate(!animate);
    } else {
      setViewable(getViewable(3.01, 4.999));
    }
  }, [viewable, getViewable, animate]);

  return (
    <section
      className={cx(styles.screen, styles.screen5, {
        [styles.animate]: animate,
      })}
      data-testid="screen-5"
    >
      <h2>{LocalT.translate('screen5.title', { br: <br /> })}</h2>
      <div className={styles.content}>
        <div className={styles.phone} />
      </div>
    </section>
  );
};
