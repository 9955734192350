import React from 'react';
import cx from 'classnames';

import { HelpIcon } from '@motorway/mw-highway-code';

import styles from './HelpButton.module.scss';

type HelpButtonProps = {
  className?: cx.Argument;
  onClick: () => void;
};

const HelpButton = ({
  className = '',
  onClick,
}: HelpButtonProps) => (
  <button
    className={cx(styles.button, className)}
    onClick={onClick}
    type="button"
  >
    Help
    <HelpIcon filled={false} />
  </button>
);

export default HelpButton;
