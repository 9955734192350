import React from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import SunSrc from '../../../../assets/images/loaders/sun.svg';

import LocalTexts from './Screens.json';

import styles from './Screens.module.scss';

const LocalT = new MDText(LocalTexts);

export const Screen3 = () => (
  <section className={cx(styles.screen, styles.screen3)} data-testid="screen-3">
    <img alt="sun" className={styles.sun} src={SunSrc} />
    <h2>{LocalT.translate('screen3.title', { br: <br /> })}</h2>
  </section>
);
