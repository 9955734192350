// Base URL for the vehicle damage overlay images
export const vehicleDamageOverlayS3Url = 'https://motorway.s3.eu-west-2.amazonaws.com/photos-assets/vehicle_damage_overlays/';
// Current version of the vehicle damage overlay images
export const version = 'v2.1';

// Vehicle damage overlay images
export const VEHICLE_DAMAGE_OVERLAYS = {
  hatchback: {
    hatchback_driver_side: `hatchback_driver_side_${version}.png`,
    hatchback_front: `hatchback_front_${version}.png`,
    hatchback_passenger_side: `hatchback_passenger_side_${version}.png`,
    hatchback_rear: `hatchback_rear_${version}.png`,
    hatchback_roof: `hatchback_roof_${version}.png`,
  },
} as Record<string, Record<string, string>>;
