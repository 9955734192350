/* eslint-disable no-alert */
/* eslint-disable no-underscore-dangle */
import qs from 'qs';

import { transformSVGString } from '../../shared/components/DamagePanel/ImageComponent/ImageComponent';
import { FeatureContent, PROFILING_FEATURE_FLAGS } from '../featureConstants';
import { removeQueryStringParameter } from '../helpers';
import { captureDatadogException } from '../logger';
import type { VehicleDetailsTypes } from '../Types/contextTypes';
import type { DamageMetaType } from '../Types/damage.types';
import { PanelDamageTypes } from '../Types/panelDamage.types';
import type { Photo } from '../Types/photo.types';
import type { ImageCategoryType } from '../Types/vehiclePhotosCategories.types';
import { IMAGE_CATEGORIES } from '../vehiclePhotosCategories';

import { ENQUIRY_QUERY_STRING, handleCatchIfOnline, handleErrors, processResponse } from './helpers';
import timeoutFetch from './timeoutFetch';

const contentArr = [PROFILING_FEATURE_FLAGS].join();
const PLATFORM_URL = window.__ENV__.platform;
const DEALERSHIP_PLATFORM_URL = window.__ENV__.dealerPlatform;
const GATEWAY_URL = window.__ENV__.gateway;
const BODY_PANEL_URL = window.__ENV__.bodyPanelApiPath;
const SELLER_LOCAL_KEY = 'seller';

let sellerToken: string; // TODO - Move into a store
let sellerID: string;

export const login = async (email = '', deeplink = '') => fetch(`${PLATFORM_URL}/login-email`, {
  body: JSON.stringify({
    deeplink,
    email,
    host: window.location.host,
  }),
  headers: { 'content-type': 'application/json', 'sec-fetch-mode': 'cors' },
  method: 'POST',
  mode: 'cors',
}).then((res) => res.json());

export const authenticate = async (token: string) => {
  let seller;

  if (token) {
    window.history.replaceState({}, document.title, removeQueryStringParameter('token'));

    seller = await timeoutFetch(
      `${PLATFORM_URL}/authenticate`,
      {
        body: JSON.stringify({ loginToken: token }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        mode: 'cors',
      } as RequestInit,
    )
      .then((res) => res.json())
      .then((res) => {
        const customer = res?.customer?.id ? res.customer : null;

        if (customer) {
          localStorage.setItem(SELLER_LOCAL_KEY, JSON.stringify(customer));
        }

        return customer;
      })
      .catch((err) => {
        captureDatadogException({
          error: err,
          fingerprint: 'fetch-authenticate',
        });
      });
  }

  if (!seller && !token) {
    const local = localStorage.getItem(SELLER_LOCAL_KEY);
    seller = (local) ? JSON.parse(local) : null;
  }

  if (seller) {
    sellerID = seller.id;
    sellerToken = seller.auth_token;
  }

  return seller;
};

export const getFeatureContent = async () => fetch(`${GATEWAY_URL}/content/contentItems/public?names=${contentArr}`, {
  credentials: 'same-origin',
  headers: { 'Content-Type': 'application/json' },
  method: 'GET',
  mode: 'cors',
}).then((res) => res.json()).then((res) => {
  const data = (res?.data || []).reduce((acc: Record<string, boolean>, { content }: FeatureContent) => {
    const reducedFlags = Object.entries(content)
      .reduce((prevFlag, [flagName, flag]) => ({ ...prevFlag, [flagName]: flag.enabled }), {});
    return { ...acc, ...reducedFlags };
  }, {});
  if (data) {
    localStorage.setItem('featureFlags', JSON.stringify(data));
    return data;
  }

  return null;
}).catch((err) => {
  handleCatchIfOnline(err, 'get-feature-flags');
  const cachedFeatureFlags = localStorage.getItem('featureFlags');
  return (cachedFeatureFlags) ? JSON.parse(cachedFeatureFlags) : null;
});

export const getPanelDamageIcons = async (panelDamageInfo: PanelDamageTypes): Promise<PanelDamageTypes> => {
  const updatedSides = await Promise.all(
    panelDamageInfo.sides.map(async (side) => {
      try {
        const response = await fetch(side.iconUrl, {
          credentials: 'omit',
          mode: 'cors',
        });
        const svgString = await response.text();
        const transformedSVG = transformSVGString(svgString);
        return { ...side, icon: transformedSVG };
      } catch (err) {
        console.error(`Failed to fetch icon for ${side.id}:`, err);
        handleCatchIfOnline(err, 'get-panel-damage-icon');
        return side; // Return the original side object if fetch fails
      }
    }),
  );

  return {
    ...panelDamageInfo,
    sides: updatedSides,
  };
};

export const getPanelDamageInfo = async (bodyType: string, height: number, dpEnquiryId: number) => {
  const body = (bodyType || 'hatchback').toLowerCase();
  const storageKey = `panelDamage-${body}`;

  return fetch(`${BODY_PANEL_URL}/getPanelAsset/?bodyType=${body}&height=${height}&enquiryId=${dpEnquiryId}`, {
    credentials: 'omit',
    headers: { 'Content-Type': 'application/json' },
    method: 'GET',
    mode: 'cors',
  }).then((res) => res.json()).then((panelDamageInfo) => {
    if (panelDamageInfo) {
      return getPanelDamageIcons(panelDamageInfo).then((panelDamageInfoWithIcons) => {
        localStorage.setItem(storageKey, JSON.stringify(panelDamageInfoWithIcons));
        return { ...panelDamageInfoWithIcons };
      });
    }

    return null;
  }).catch((err) => {
    handleCatchIfOnline(err, 'get-panel-damage-info');
    const cachedFeatureFlags = localStorage.getItem(storageKey);
    return (cachedFeatureFlags) ? JSON.parse(cachedFeatureFlags) : null;
  });
};

export const getSeller = async () => fetch(
  `${PLATFORM_URL}/user`,
  {
    credentials: 'omit',
    headers: { 'x-access-token': sellerToken },
    method: 'GET',
    mode: 'cors',
  },
)
  .then((res) => res.json())
  .then((res) => {
    const seller = (res.id) ? res : null;

    if (seller) {
      localStorage.setItem(SELLER_LOCAL_KEY, JSON.stringify(seller));
    } else if (res.message === 'Auth token expired.') {
      localStorage.clear();
      alert('Your session has expired');
      window.location.reload();
    }

    return seller;
  })
  .catch(() => {
    const cachedSeller = localStorage.getItem(SELLER_LOCAL_KEY);
    return cachedSeller ? JSON.parse(cachedSeller) : null;
  });

type PhotoDamageBody = {
  damageMeta?: DamageMetaType | null;
  isDamaged?: boolean | null;
  url?: string;
  vehicleImageId?: number;
};

export const updatePhotoDamage = async (
  payload: Partial<Photo> & { vehicleImageId?: number },
  category?: Partial<ImageCategoryType>,
) => {
  const { damageMeta, location, originalUrl } = payload || {};

  let body: PhotoDamageBody = { damageMeta, url: originalUrl ?? location };

  const wheelsAndTyres: Partial<ImageCategoryType>[] = [IMAGE_CATEGORIES.WHEELS, IMAGE_CATEGORIES.TYRES];

  if (wheelsAndTyres.includes(category ?? '' as Partial<ImageCategoryType>)) {
    const { isDamaged, vehicleImageId } = payload || {};
    body = { isDamaged, vehicleImageId };
  }

  return fetch(`${DEALERSHIP_PLATFORM_URL}/v2/photos/update`, {
    body: JSON.stringify(body),
    headers: {
      'content-type': 'application/json',
      credentials: 'omit',
      'sec-fetch-mode': 'cors',
      'x-access-token': sellerToken,
    },
    method: 'POST',
    mode: 'cors',
  }).then(handleErrors);
};

export const getEnquiry = async (enquiryId: number, query = { include: ENQUIRY_QUERY_STRING }) =>
  fetch(`${GATEWAY_URL}/premium-v3/enquiries/${enquiryId}?${qs.stringify(query)}`, {
    headers: {
      'Cache-Control': 'no-cache',
      'x-access-token': sellerToken,
      'x-mway-requester': 'motorway-webapp',
    },
    method: 'GET',
    mode: 'cors',
  }).then(processResponse).then((res) => {
    if (res) {
      localStorage.setItem(`offerData-${sellerID}-${enquiryId}`, JSON.stringify(res));
      return res;
    }

    return null;
  }).catch(() => {
    const cachedOffer = localStorage.getItem(`offerData-${sellerID}-${enquiryId}`);
    return (cachedOffer) ? JSON.parse(cachedOffer) : null;
  });

export const updateVehicleData = async (enquiryId: number, data: Partial<VehicleDetailsTypes>) => {
  const res = fetch(`${GATEWAY_URL}/premium-v3/enquiries/${enquiryId}/vehicleDetails/update`, {
    body: JSON.stringify({
      ...data,
    }),
    headers: {
      'content-type': 'application/json',
      credentials: 'omit',
      'sec-fetch-mode': 'cors',
      'x-access-token': sellerToken,
    },
    method: 'POST',
    mode: 'cors',
  }).then(handleErrors);

  return res;
};

export const getVehiclePhotos = async (enquiryId: number, params = { includeDeleted: 'true' }) => fetch(`${GATEWAY_URL}/premium-v3/enquiries/${enquiryId}/vehicleImages?${qs.stringify(params)}`, {
  headers: {
    'content-type': 'application/json',
    credentials: 'omit',
    'sec-fetch-mode': 'cors',
    'x-access-token': sellerToken,
  },
  method: 'GET',
  mode: 'cors',
}).then(processResponse).then((res) => {
  if (res) {
    const { data } = res;
    localStorage.setItem(`photos-${enquiryId}`, JSON.stringify(data));
    return res;
  }

  const cachedPhotos = localStorage.getItem(`photos-${enquiryId}`);

  if (cachedPhotos) {
    return { data: JSON.parse(cachedPhotos) };
  }

  return null;
}).catch(() => {
  const cachedPhotos = localStorage.getItem(`photos-${enquiryId}`);
  return (cachedPhotos) ? { data: JSON.parse(cachedPhotos) } : null;
});
