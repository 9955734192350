import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { trackPageView } from '@snowplow/browser-tracker';

export const ScrollToTop = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    trackPageView();
  }, [location]);

  return <>{children}</> || (null);
};
