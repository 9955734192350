import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import styles from './OnboardingMessage.module.scss';

type OnboardingMessageProps = {
  children: React.ReactNode;
  delay?: number;
  isVisible: boolean;
  show: boolean;
};

export const OnboardingMessage = ({ children, delay, isVisible, show }: OnboardingMessageProps) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!isVisible) {
      setVisible(false);
    }
  }, [isVisible]);

  useEffect(() => {
    if (!show) {
      return undefined;
    }

    setVisible(false);

    const showTimer = setTimeout(() => setVisible(true), delay ?? 0);
    const fadeOutTimeout = setTimeout(() => {
      setVisible(false);
    }, (delay ?? 0) + 5000);

    return () => {
      clearTimeout(showTimer);
      clearTimeout(fadeOutTimeout);
    };
  }, [children, show, delay]);

  return (
    <div className={cx(styles.component, { [styles.visible]: visible })}>
      {children}
    </div>
  );
};
