import React, { useContext, useEffect, useRef } from 'react';
import cx from 'classnames';

import { Content } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';

import type { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import { Context } from '../../context/context';
import NoPermissions from '../NoPermissions/NoPermissions';
import { PhotosHubRouter } from '../PhotosHubRouter/PhotosHubRouter';

import Debug from './Debug';
import { useCreateVideo } from './Video.hooks';

import styles from './Video.module.scss';

const Video = () => {
  const { debug, isStatus404, isVideoVisible, setParentState } = useContext(Context) as ContextValueTypes;

  const $video = useRef<HTMLVideoElement | null>(null);

  const {
    dataAvailable,
    deviceConstraints,
    enableCamera,
    error,
    permissionsDenied,
    permissionsGranted,
  } = useCreateVideo({
    $video,
  });

  useEffect(() => () => {
    setParentState({
      video: null,
    });
  }, [setParentState]);

  if (permissionsDenied) { // User has denied permissions
    return <NoPermissions />;
  }

  if (permissionsGranted === false) { // User has yet to grant any permissions
    return (
      <Content center className={styles.fullHeight}>
        <div className="mw-container-50">
          <p>To get started we need to access your camera</p>
          <p><Button label="Enable permissions" onClick={() => enableCamera()} /></p>
        </div>
      </Content>
    );
  }

  return (
    <div className={cx(styles.component, { [styles.withStatus404]: isStatus404 })}>
      <video
        ref={$video}
        autoPlay
        muted
        playsInline
        className={cx(styles.videoBox, { [styles.show]: isVideoVisible })}
        data-testid='video-stream'
        id="motorway-video"
      />

      { error && (
        <div className={styles.errorBox}>
          <h3>{error.name}</h3>
          <p>{error.message}</p>
        </div>
      )}
      { dataAvailable ? (
        <PhotosHubRouter />
      ) : (null)}

      { ($video?.current) && debug ? (
        <Debug
          $video={$video.current}
          constraints={deviceConstraints}
        />
      ) : (null)}
    </div>
  );
};

export default Video;
