import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { MDText } from 'i18n-react';

import { Button, LightbulbIcon, LockClosedIcon } from '@motorway/mw-highway-code';
import { InfoBox } from '@motorway/mw-highway-code/alpha';

import pictureFrame from '../../../../assets/images/messagingCard/pictureframe.svg';
import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../../utilities/analytics';
import { CATEGORY_PAGE_EVENTS } from '../../../../utilities/analytics/events/categoryPage/categoryPageEvents';
import type { DamageKindType, ImageCategoryType } from '../../../../utilities/Types/vehiclePhotosCategories.types';
import { VEHICLE_DAMAGE_KIND_KEY, VEHICLE_PHOTOS_CATEGORY_KEY } from '../../../../utilities/vehiclePhotosCategories';
import MessagingCard from '../../MessagingCard/MessagingCard';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import LocalTexts from '../VehiclePhotos.json';

import CATEGORY_DAMAGE_PAGE_GA_EVENT_LABEL from './CategoryDamage.helper';
import DamageLocalTexts from './CategoryDamage.json';
import { CATEGORY_PAGE_GA_EVENT_LABEL, getCardAction } from './CategoryPage.helpers';

import styles from './CategoryPage.module.scss';

const LocalT = new MDText(LocalTexts);
const LocalTDamage = new MDText(DamageLocalTexts);
const allowedCategories: Partial<ImageCategoryType | DamageKindType>[] = [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_TYRES,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_OTHER, VEHICLE_DAMAGE_KIND_KEY.DAMAGE_MECHANICAL_OR_ELECTRICAL];

type CategoryPageHeaderProps = {
  category: ImageCategoryType | DamageKindType;
  description?: string;
  isApproved?: boolean;
  isDone?: boolean;
  photoErrorTotal?: number;
  title?: string;
};

const CategoryPageHeader = ({
  category,
  description = '',
  isApproved = false,
  isDone = false,
  photoErrorTotal = 0,
  title = '',
}: CategoryPageHeaderProps) => {
  const location = useLocation();

  const cardAction = getCardAction(category);
  const cardImg = <img alt="frame" src={pictureFrame} />;

  const text = {
    errorInfoBoxContent: LocalT.translate('vehiclePhotos.category.tips.attention.content') as string,
    errorInfoBoxHeading: (total: number) => LocalT.translate(`vehiclePhotos.category.tips.attention.heading.${total > 1 ? 'plural' : 'singular'}`, ({ total })) as string,
    photoTipDesc: LocalT.translate(`vehiclePhotos.category.tips.${cardAction?.contentType ?? 'photo'}.description`) as string,
    photoTipHeading: LocalT.translate(`vehiclePhotos.category.tips.${cardAction?.contentType ?? 'photo'}.heading`) as string,
    title: LocalT.translate(`vehiclePhotos.category.${category}.title`) as string,
  } as const;

  const { BUTTON } = GA_EVENT_ACTIONS;

  const isCategoryDamage = category === VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE;

  const photoTipButtonHandler = () => {
    const { CATEGORY, PHOTO_TIP_BUTTON_CLICK } = CATEGORY_PAGE_GA_EVENT_LABEL;
    const { DAMAGE_CATEGORY_PAGE } = CATEGORY_DAMAGE_PAGE_GA_EVENT_LABEL;
    const { DAMAGE_CATEGORY, DAMAGE_PHOTO_TIP_BUTTON_CLICK } = DAMAGE_CATEGORY_PAGE;

    const photoTipButtonEventLabel = isCategoryDamage ? DAMAGE_PHOTO_TIP_BUTTON_CLICK : PHOTO_TIP_BUTTON_CLICK;
    const photoTipButtonEventCategory = isCategoryDamage ? DAMAGE_CATEGORY : CATEGORY;

    const buttonLabel = LocalT.translate(photoTipButtonEventLabel, ({ title: title || text.title }));
    const categoryLabel = LocalT.translate(photoTipButtonEventCategory, ({ title: title || text.title }));

    GA_EVENTS.DEFAULT(BUTTON, categoryLabel, buttonLabel);
    CATEGORY_PAGE_EVENTS.CATEGORY_PAGE_PHOTO_TIP_BUTTON_CLICK(title || text.title);
  };

  const photoTipMessageCardHandler = () => {
    const { CATEGORY, MESSAGE_CARD_PHOTO_TIP_CLICK } = CATEGORY_PAGE_GA_EVENT_LABEL;

    const buttonLabel = LocalT.translate(MESSAGE_CARD_PHOTO_TIP_CLICK, ({ title: title || text.title }));
    const categoryLabel = LocalT.translate(CATEGORY, ({ title: title || text.title }));

    GA_EVENTS.DEFAULT(BUTTON, categoryLabel, buttonLabel);
    CATEGORY_PAGE_EVENTS.CATEGORY_PAGE_PHOTO_TIP_MESSAGE_CARD_CLICK(title || text.title);
  };

  const isMessagingCardVisible = !isDone && cardAction && !photoErrorTotal;

  const isNotEditable = isApproved && allowedCategories.includes(category);

  return (
    <>
      <div className={styles.content}>
        <SectionHeader
          title={title || text.title as string}
        >
          {((isDone || !!photoErrorTotal) && cardAction) && (
            <Link
              className={styles.tipBtn}
              onClick={photoTipButtonHandler}
              state={location.pathname}
              to={cardAction.href ?? ''}
            >
              <Button reverse icon={LightbulbIcon} label={text.photoTipHeading} size="small" variant="secondary" />
            </Link>
          )}
        </SectionHeader>
        {isNotEditable && (
          <div className={styles.infoBox}>
            <LockClosedIcon />
            <p>
              {LocalTDamage.translate('approved')}
            </p>
          </div>
        )}
        {description && (
          <div className={styles.info}>
            <p className={styles.description}>{description}</p>
          </div>
        )}
        {isMessagingCardVisible && (
          <MessagingCard
            action={cardAction.label}
            className={styles.message}
            description={text.photoTipDesc}
            heading={text.photoTipHeading}
            href={{
              pathname: cardAction.href ?? '',
              state: location.pathname,
            }}
            image={cardImg}
            onClick={photoTipMessageCardHandler}
          />
        )}
        {(photoErrorTotal > 0) && (
          <div className={styles.retakePhoto}>
            <InfoBox
              content={text.errorInfoBoxContent}
              heading={text.errorInfoBoxHeading(photoErrorTotal)}
              variant="error"
            />
          </div>
        )}
      </div>
      {isMessagingCardVisible && <hr className={styles.divider} />}
    </>
  );
};

export default CategoryPageHeader;
