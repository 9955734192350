import { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { FORM_ERROR } from 'final-form';

import { MDText } from 'i18n-react';

import { MECHANICAL_AND_ADDITIONAL_EVENTS } from '../../../utilities/analytics/events/damage/MechanicalAndAdditionalEvents';
import { updateVehicleData } from '../../../utilities/api';
import { handleCatchIfOnline } from '../../../utilities/api/helpers';
import { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import type { CategoryType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { VEHICLE_PHOTO_CATEGORY_PATH, VEHICLE_PHOTOS_CATEGORY_KEY } from '../../../utilities/vehiclePhotosCategories';
import { Context } from '../../context/context';
import LocalTexts from '../PhotosHub/CategoryPage/CategoryDamage.json';

const LocalT = new MDText(LocalTexts);

export type DescriptionAllowedCategoryType = 'damage_other' | 'damage_mechanical_or_electrical';

const useDescriptionDamageForm = (category: DescriptionAllowedCategoryType, title: string) => {
  const context = useContext(Context);
  const navigate = useNavigate();
  const {
    offer, updateVehicleCondition, vehicleDetails, videoInfo: { imageCategories },
  } = context as ContextValueTypes;

  const categoryProps: CategoryType | undefined = imageCategories[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE]
    .find(({ kind }) => kind === category);
  const fieldName = (categoryProps?.conditionKind || '') as keyof typeof vehicleDetails;
  const fieldDescName = (categoryProps?.conditionDesc || '') as keyof typeof vehicleDetails;

  const initialValues = useMemo(() => {
    const hasDesc = Boolean(vehicleDetails[fieldDescName]);
    const hasDamage = hasDesc ? String(true) : null;

    return {
      [fieldDescName]: vehicleDetails[fieldDescName],
      [fieldName]: vehicleDetails[fieldName] === null ? hasDamage : String(vehicleDetails[fieldName]),
    };
  }, [vehicleDetails, fieldDescName, fieldName]);

  const goToCategory = useCallback(() => {
    navigate(`/${vehicleDetails.vrm}${VEHICLE_PHOTO_CATEGORY_PATH[VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE].path}`);
  }, [vehicleDetails, navigate]);

  const onSubmit = useCallback((values) => {
    const hasDamage = values[fieldName] === 'true';
    if (!values[fieldName]) {
      return { [FORM_ERROR]: LocalT.translate(`${category}.error.select`) };
    }

    if (hasDamage && !values[fieldDescName]?.trim()) {
      return { [FORM_ERROR]: LocalT.translate(`${category}.error.descEmpty`) };
    }

    const data = {
      [fieldDescName]: hasDamage ? values[fieldDescName]?.trim() : null,
      [fieldName]: hasDamage,
    };

    updateVehicleData(offer.id, data).catch((err) => handleCatchIfOnline(err, 'fetch-update-vehicle-data'));
    updateVehicleCondition(data);
    MECHANICAL_AND_ADDITIONAL_EVENTS.MECHANICAL_AND_ADDITIONAL_CONTINUE_BUTTON_CLICK(title);

    return goToCategory();
  }, [fieldName, fieldDescName, goToCategory, offer.id, updateVehicleCondition, category, title]);

  const onDone = useCallback(() => {
    MECHANICAL_AND_ADDITIONAL_EVENTS.MECHANICAL_AND_ADDITIONAL_DONE_BUTTON_CLICK(title);
    return goToCategory();
  }, [title, goToCategory]);

  return { fieldDescName, fieldName, goToCategory, initialValues, name: categoryProps?.description, onDone, onSubmit };
};

export default useDescriptionDamageForm;
