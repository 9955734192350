import React from 'react';

import { LogoMotorway } from '@motorway/motorway-storybook-cra';

import styles from './AppLoading.module.scss';

const AppLoading = () => (
  <div className={styles.component}>
    <div className={styles.chevron} />
    <LogoMotorway className={styles.logo} />
  </div>
);

export default AppLoading;
