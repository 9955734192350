import React, { useContext, useEffect, useState } from 'react';

import { Button, CameraIcon } from '@motorway/mw-highway-code';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../utilities/analytics';
import { ONBOARDING_EVENTS } from '../../../utilities/analytics/events/onboarding/onboardingEvents';
import { IS_LOCALHOST, setCssVar } from '../../../utilities/helpers';
import type { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import { Context } from '../../context/context';
import { usePhotosContext } from '../../context/photos';

import { ReentryScreen } from './ReentryScreen/ReentryScreen';
import { LOADER_GA_EVENT_LABEL } from './Loader.helper';
import { Scroller } from './Scroller';

import styles from './Loader.module.scss';

export const Loader = () => {
  const { setParentState } = useContext(Context) as ContextValueTypes;
  const { photos } = usePhotosContext();
  const { BUTTON } = GA_EVENT_ACTIONS;

  const [skipLoaders] = useState(() => {
    const storedData = localStorage.getItem('skipLoaders');

    return storedData !== null ? JSON.parse(storedData) : (photos.length > 0);
  });

  const skipped = skipLoaders || IS_LOCALHOST;

  useEffect(() => {
    if (skipped) {
      setCssVar({
        '--loader-button-opacity': '1',
        '--loader-pagination-display': 'none',
      });
    }
  }, [skipped]);

  const startVideo = () => {
    setParentState({ onboardingLoader: false });
    const { CATEGORY, ENABLE_CAMERA_BUTTON_LABEL } = LOADER_GA_EVENT_LABEL;

    GA_EVENTS.DEFAULT(BUTTON, CATEGORY, ENABLE_CAMERA_BUTTON_LABEL);
    ONBOARDING_EVENTS.ENABLE_CAMERA_CLICK();
  };

  return (
    <>
      {skipped ? (
        <ReentryScreen />
      ) : (
        <div className={styles.component}>
          <Scroller
            startButton={(
              <div className={styles.startButton}>
                <Button
                  fullWidth
                  reverse
                  icon={CameraIcon}
                  label="Enable camera"
                  onClick={startVideo}
                />
              </div>
            )}
          />
        </div>
      )}
    </>
  );
};
