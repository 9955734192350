const suvTimings = {
  exterior_front_driver: [0, 0],
  exterior_front_passenger: [8.1, 12.33],
  exterior_rear_driver: [0.13, 3.83],
  exterior_rear_passenger: [3.83, 8.07],
  instrument_cluster: [64.3, 69.2],
  interior_boot: [41.6, 47.53],
  interior_dashboard: [54.4, 58.6],
  interior_front_seats: [58.8, 64.2],
  interior_rear_seats: [47.6, 54.33],
  tyre_tread_front_driver: [24.27, 27.63],
  tyre_tread_front_passenger: [16.6, 19.93],
  tyre_tread_rear_driver: [31.9, 35.13],
  tyre_tread_rear_passenger: [38.4, 41.6],
  wheels_front_driver: [20, 24.23],
  wheels_front_passenger: [12.33, 16.57],
  wheels_rear_driver: [27.67, 31.9],
  wheels_rear_passenger: [35.17, 38.4],
};

export default suvTimings;
