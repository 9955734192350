import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';

import sign from '../../../assets/images/sign.svg';
import { Context } from '../../context/context';
import Footer from '../Footer/Footer';

import LocalTexts from './Status404.json';

import styles from './Status404.module.scss';

const LocalT = new MDText(LocalTexts);

export const Status404 = () => {
  const context = useContext(Context);
  const { offer, setParentState }: any = context;

  const vrm = offer?.vrm;

  useEffect(() => {
    setParentState({
      isStatus404: true,
    });

    return () => {
      setParentState({
        isStatus404: false,
      });
    };
  }, [setParentState]);

  useEffect(() => {
    window.dataLayer.push({
      event: 'Pageview',
      eventLabel: vrm,
      pageTitle: 'Page 404 opened',
    });
  }, [vrm]);

  const onClick = () => {
    window.dataLayer.push({
      event: 'Pageview',
      eventLabel: vrm,
      pageTitle: 'Page 404 closed',
    });
  };

  return (
    <>
      <Content className={styles.component} element="section">
        <div className={styles.sign}>
          <img alt="sign" src={sign} />
          <h2 className={styles.title}>{LocalT.translate('title')}</h2>
          <p className={styles.detail}>{LocalT.translate('description', { br: (<br />) })}</p>
        </div>

      </Content>
      <Footer className={styles.footer} withShadow={false}>
        <div className={styles.footerInner}>
          <Button
            fullWidth
            as={Link}
            className={styles.button}
            icon="chevron"
            label={LocalT.translate('button')}
            onClick={onClick}
            to={`/${vrm}`}
          />
        </div>
      </Footer>
    </>
  );
};
