import React, { useContext } from 'react';
import cx from 'classnames';

import { Content, VrmBadge } from '@motorway/motorway-storybook-cra';

import { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import { Context } from '../../context/context';

import type { VehicleCenterHeaderProps } from './VehicleCenterHeader.types';

import styles from './VehicleCenterHeader.module.scss';

export const VehicleCenterHeader = ({
  amount,
  amountLabel,
  className,
  description,
  vrm,
}: VehicleCenterHeaderProps) => {
  const { vehicleDetails } = useContext(Context) as ContextValueTypes;

  return (
    <div className={cx(styles.vehicleCenterHeader, className)}>
      <Content className={cx(styles.innerHeader)}>
        <VrmBadge
          white
          size="small"
          vrm={vrm || vehicleDetails?.prettyVrm || vehicleDetails?.vrm}
        />
        <div className={styles.estimated}>
          {amountLabel && (
            <span>{amountLabel}</span>
          )}
          {amount && (
            <span className={styles.offer}>
              £{amount.toLocaleString()}
            </span>
          )}
        </div>
        {description && (
          <p>{description}</p>
        )}
      </Content>
    </div>
  );
};
