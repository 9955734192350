import { MDText } from 'i18n-react';

import type { IconComponentFunction } from '@motorway/mw-highway-code';
import {
  VehicleBackIcon,
  VehicleDriverSideIcon, VehicleFrontIcon, VehiclePassengerSideIcon, VehicleTopIcon,
} from '@motorway/mw-highway-code';

import * as CarSVG from '../../../assets/images/vehicle_damage_panel';
import { HATCHBACK_TYPE } from '../../../utilities/bodyType';
import { titleCaseFirstLetter } from '../../../utilities/helpers';

import { VEHICLE_DAMAGE_OVERLAYS, vehicleDamageOverlayS3Url } from './DamageSize/DamagePanel.helpers';
import LocalTexts from './DamagePanel.json';

const LocalT = new MDText(LocalTexts);

export type SideButtonProps = {
  id: string;
  image: React.ForwardRefExoticComponent<Omit<any, 'ref'> & React.RefAttributes<any>>;
  label: string;
  overlayUrl: string;
  svg: IconComponentFunction;
};

const getSideButtons = (): SideButtonProps[] => {
  const overlayLinks = VEHICLE_DAMAGE_OVERLAYS[HATCHBACK_TYPE] || {};
  const bodyTypeSVG = titleCaseFirstLetter(HATCHBACK_TYPE);

  return [
    {
      id: 'front',
      image: CarSVG[`${bodyTypeSVG}FrontSideSVG` as keyof typeof CarSVG],
      label: LocalT.translate('buttons.front') as string,
      overlayUrl: `${vehicleDamageOverlayS3Url}${overlayLinks.hatchback_front}`,
      svg: VehicleFrontIcon,
    },
    {
      id: 'passenger_side',
      image: CarSVG[`${bodyTypeSVG}PassengerSideSVG` as keyof typeof CarSVG],
      label: LocalT.translate('buttons.passenger') as string,
      overlayUrl: `${vehicleDamageOverlayS3Url}${overlayLinks.hatchback_passenger_side}`,
      svg: VehiclePassengerSideIcon,
    },
    {
      id: 'back',
      image: CarSVG[`${bodyTypeSVG}RearSideSVG` as keyof typeof CarSVG],
      label: LocalT.translate('buttons.rear') as string,
      overlayUrl: `${vehicleDamageOverlayS3Url}${overlayLinks.hatchback_rear}`,
      svg: VehicleBackIcon,
    },
    {
      id: 'driver_side',
      image: CarSVG[`${bodyTypeSVG}DriverSideSVG` as keyof typeof CarSVG],
      label: LocalT.translate('buttons.driver') as string,
      overlayUrl: `${vehicleDamageOverlayS3Url}${overlayLinks.hatchback_driver_side}`,
      svg: VehicleDriverSideIcon,
    },
    {
      id: 'top',
      image: CarSVG[`${bodyTypeSVG}TopSideSVG` as keyof typeof CarSVG],
      label: LocalT.translate('buttons.top') as string,
      overlayUrl: `${vehicleDamageOverlayS3Url}${overlayLinks.hatchback_roof}`,
      svg: VehicleTopIcon,
    },
  ];
};

export { getSideButtons };
