import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Status404 } from '../Status404/Status404';

export const WrapperWithQueryParams = ({ children, fallback = <Status404 />, requiredQueryParams }: {
  children: React.ReactNode,
  fallback?: React.ReactNode,
  requiredQueryParams: Record<string, string[]>
}) => {
  const [search] = useSearchParams();

  const checkQueryParams = useMemo(() => Object.keys(requiredQueryParams).some((x) => {
    if (!search.has(x)) {
      return true;
    }

    if (!requiredQueryParams[x].includes(search.get(x) || '')) {
      return true;
    }

    return false;
  }), [requiredQueryParams, search]);

  if (checkQueryParams) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
};
