import { GENERIC_ANALYTICS_EVENTS } from '../../analyticsGenericEvents';
import { EVENT_CATEGORY } from '../events';

export const DAMAGE_CATEGORY_EVENTS = {
  DAMAGE_CATEGORY_ADD_PHOTO_BUTTON_CLICK: (kind: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `${kind} category add photo button`,
  }),
  DAMAGE_CATEGORY_BACK_BUTTON_CLICK: (kind: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `${kind} category back to summary button`,
  }),
  DAMAGE_CATEGORY_DELETE_PHOTO_BUTTON_CLICK: (kind: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `${kind} category delete photo button`,
  }),
  DAMAGE_CATEGORY_DONE_BUTTON_CLICK: (kind: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `${kind} category done button`,
  }),
  DAMAGE_CATEGORY_PAGE_LOAD: (kind: string) => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_CATEGORY,
    name: `${kind} category`,
  }),
  GUIDANCE_PAGE_LOAD: (kind: string) => GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
    category: EVENT_CATEGORY,
    name: `${kind} guidance page`,
  }),
  GUIDANCE_PAGE_NO_DAMAGE_BUTTON_CLICK: (kind: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `${kind} guidance page no damage button`,
  }),
  GUIDANCE_PAGE_TAKE_PHOTO_BUTTON_CLICK: (kind: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    category: EVENT_CATEGORY,
    name: `${kind} guidance page take photos button`,
  }),
} as const;
