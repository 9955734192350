import React from 'react';

import { LoadingSpinner } from '@motorway/motorway-storybook-cra';

import styles from './Loading.module.scss';

const Loading = () => (
  <div className={styles.component}>
    <LoadingSpinner />
  </div>
);

export default Loading;
