import React, { Children, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../../utilities/analytics';
import { CATEGORY_PAGE_EVENTS } from '../../../../utilities/analytics/events/categoryPage/categoryPageEvents';
import { useAiImageAssessmentFeatureFlag, useToggleHeadDisplay } from '../../../../utilities/hooks';
import { useProgress } from '../../../../utilities/hooks/useProgress';
import type { ProgressCategoryType } from '../../../../utilities/hooks/useProgress.types';
import type { DamageCategoryType } from '../../../../utilities/Types/category.types';
import { ContextValueTypes } from '../../../../utilities/Types/contextTypes';
import { IMAGE_CATEGORIES } from '../../../../utilities/vehiclePhotosCategories';
import { Context } from '../../../context/context';
import Footer from '../../Footer/Footer';
import { ImageAssistModal } from '../../ImageAssistModal/ImageAssistModal';
import { isMappedModalImageAssessmentStatus } from '../../ImageAssistModal/ImageAssistModal.helpers';
import LocalTexts from '../VehiclePhotos.json';

import PhotoBlock from './PhotoBlock/PhotoBlock';
import { CATEGORY_PAGE_GA_EVENT_LABEL } from './CategoryPage.helpers';
import { useAiCatPageGaLoader, useCategoryPageLoaderGaEvent } from './CategoryPage.hooks';
import type { CategoryPageProps, ImageAssistModalHandler } from './CategoryPage.types';
import CategoryPageHeader from './CategoryPageHeader';

import styles from './CategoryPage.module.scss';

const LocalT = new MDText(LocalTexts);

const { CATEGORY } = CATEGORY_PAGE_GA_EVENT_LABEL;

const CategoryPage = ({ category, uploadingQueue = {} }: CategoryPageProps) => {
  const [open, setOpen] = useState(false);
  const [selectedImageAssist, setSelectedImageAssist] = useState({});

  useToggleHeadDisplay();
  const { vehicleDetails, videoInfo: { imageCategories } } = useContext(Context) as ContextValueTypes;
  const navigate = useNavigate();
  const { showAiImageAssistV2, showImageAssessments } = useAiImageAssessmentFeatureFlag();
  const { vrm } = vehicleDetails || {};

  const text = {
    footerBtnComplete: LocalT.translate('vehiclePhotos.category.footerBtn.complete'),
    footerBtnIncomplete: LocalT.translate('vehiclePhotos.category.footerBtn.incomplete'),
    title: LocalT.translate(`vehiclePhotos.category.${category}.title`),
  } as Record<string, string>;

  const {
    current, isDone, outStandingImageAssessment, outStandingRetake, outStandingUploads,
  } = useProgress({ category, uploadingQueue }) as ProgressCategoryType;

  const initiated = current === 0;

  const showImageAssessment = category === IMAGE_CATEGORIES.WHEELS
    ? showImageAssessments.wheels || showImageAssessments.tyres
    : showImageAssessments[category];

  const fireAiImageAssistGaEvent = outStandingImageAssessment > 0 && showImageAssessment;

  useEffect(() => {
    CATEGORY_PAGE_EVENTS.CATEGORY_PAGE_LOAD(text.title);
  }, [text.title]);

  useCategoryPageLoaderGaEvent({ completed: isDone, initiated, title: text.title });
  useAiCatPageGaLoader({ fireAiImageAssistGaEvent, outStandingImageAssessment, title: text.title });

  const categoryKinds = imageCategories[category].map(({ kind }) => kind);
  const photoErrorTotal = outStandingRetake + outStandingUploads + outStandingImageAssessment;

  const handleClick = () => {
    const { BACK_TO_SUMMARY_BUTTON_LABEL, DONE_BUTTON_LABEL } = CATEGORY_PAGE_GA_EVENT_LABEL;

    // GA event
    const eventCategory = LocalT.translate(CATEGORY, { title: text.title });
    const gaLabel = isDone ? DONE_BUTTON_LABEL : BACK_TO_SUMMARY_BUTTON_LABEL;
    const eventLabel = LocalT.translate(gaLabel, { title: text.title });

    GA_EVENTS.DEFAULT(GA_EVENT_ACTIONS.BUTTON, eventCategory, eventLabel);

    // Snowplow event
    if (isDone) {
      CATEGORY_PAGE_EVENTS.CATEGORY_PAGE_DONE_BUTTON_CLICK(text.title);
    } else {
      CATEGORY_PAGE_EVENTS.CATEGORY_PAGE_BACK_BUTTON_CLICK(text.title);
    }

    navigate(`/${vrm}`);
  };

  const imageAssistModalHandler = ({ imageAssessmentStatus, photo }: ImageAssistModalHandler) => {
    const isStatusMapped = isMappedModalImageAssessmentStatus(imageAssessmentStatus);
    const assessmentStatus = isStatusMapped ? imageAssessmentStatus : 'default';
    setOpen(!open);
    setSelectedImageAssist({ assessmentStatus, photo });
  };

  return (
    <>
      <Content className={styles.component}>
        <CategoryPageHeader {...{ category, isDone, photoErrorTotal }} />
        <div className={styles.photosWrapper}>
          {Children.toArray(imageCategories[category]
            ?.map(({ kind }) =>
              <PhotoBlock
                category={category as DamageCategoryType}
                {...{ categoryKinds, imageAssistModalHandler, kind, uploadingQueue }}
              />))}
        </div>
      </Content>
      <Footer>
        <Button
          fullWidth
          reverse
          data-testid="done-button"
          icon={isDone ? undefined : 'chevron'}
          label={isDone ? text.footerBtnComplete : text.footerBtnIncomplete}
          onClick={handleClick}
          variant={isDone ? 'primary' : 'secondary'}
        />
      </Footer>

      {showAiImageAssistV2 && (
        <ImageAssistModal
          category={category}
          imageAssistModalHandler={() => setOpen(!open)}
          open={open}
          selectedImageAssist={selectedImageAssist}
        />
      )}
    </>
  );
};

export default CategoryPage;
