import React from 'react';
import cx from 'classnames';

import styles from './ErrorBlock.module.scss';

type ErrorBlockProps = {
  children?: React.ReactNode,
  className?: cx.ArgumentArray | string,
};

const ErrorBlock = ({ children, className }: ErrorBlockProps) => (
  <div className={cx(styles.component, className)}>
    <div className={styles.errorBox}>
      {children}
    </div>
  </div>
);

export default ErrorBlock;
