import React, { forwardRef } from 'react';

type SideSVGProps = {
  bodyType?: string,
  useHref?: string,
} & React.SVGProps<SVGSVGElement>;

const TopSideSVG = (props: SideSVGProps, ref: React.ForwardedRef<SVGSVGElement>) => {
  const { bodyType, useHref, ...rest } = props;

  return (
    <svg ref={ref} fill="none" height="238" viewBox="0 0 462 238" width="462" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_4029_102000)">
        <path d="M272.536 188.726L339.608 203.481C344.844 205.267 347.785 205.337 353.02 203.481C371.414 138.287 371.695 101.745 353.02 36.552C349.164 33.7107 346.49 32.5714 339.608 32.5292L272.536 40.5747L271.199 40.7294C281.656 98.5946 281.543 130.706 271.199 188.571L272.536 188.726Z" stroke="white"/>
        <path d="M29.0258 35.1985L53.1752 49.2781" stroke="white"/>
        <path d="M451.469 73.1276C433.934 40.1441 417.666 30.2421 380.513 23.8001H326.176C323.306 12.9838 319.478 7.86393 307.389 1H300.676C296.088 3.91155 299.48 11.8867 303.364 23.8001H118.908C47.1635 19.4257 17.9619 26.796 3.63546 73.1276C3.57917 73.3104 3.53695 73.5214 3.52288 73.7183C0.0890438 112.947 0.229775 135.072 3.52288 174.286C3.53695 174.525 3.59324 174.779 3.67768 175.004C19.5662 218.874 53.5668 221.152 118.922 220.913H296.018C292.331 232.194 292.443 236.877 296.018 236.666C304.532 238.256 312.624 232.32 319.492 220.913H380.527C425.898 219.577 439.831 206.496 456.761 175.102C456.915 174.807 457.028 174.483 457.07 174.16C462.643 135.1 462.08 112.975 451.722 73.7465C451.666 73.5355 451.582 73.3245 451.483 73.1416L451.469 73.1276Z" stroke="white"/>
        <path d="M320.948 26.4875H307.537C305.496 25.9108 304.623 25.32 303.512 23.801L298.811 7.03496C298.445 4.10935 298.825 2.94191 300.824 1.67603H307.537C316.768 5.71281 320.681 9.76365 325.649 19.7782C327.464 23.9979 326.873 25.6717 320.948 26.4875Z" stroke="white"/>
        <path d="M307.533 26.4875C307.435 25.5591 307.336 24.659 307.238 23.801M307.238 23.801C306.168 15.0663 304.479 9.89024 300.82 1.67603M307.238 23.801L326.321 24.4761" stroke="white"/>
        <path d="M352.354 36.5347C366.216 28.3064 390.14 36.4222 424.802 44.5801C461.688 71.4029 469.738 178.666 424.802 200.791C395.967 206.206 379.36 212.536 352.354 204.138" stroke="white"/>
        <path d="M29.0233 35.1985C4.70498 99.6183 2.62216 135.457 24.3369 198.78" stroke="white"/>
        <path d="M52.5176 175.979L24.3433 198.104" stroke="white"/>
        <path d="M218.201 189.407L113.554 186.059C98.3269 184.737 92.2051 185.524 87.3921 190.757L82.0302 199.477C79.5674 203.852 80.0037 205.976 82.0302 208.198L333.573 211.546C346.52 211.546 345.38 210.645 335.585 208.198C293.535 197.944 269.273 192.726 218.201 189.421V189.407Z" stroke="white"/>
        <path d="M82.025 208.166C94.7471 216.69 106.09 218.814 132.336 218.898H208.134M208.134 218.898H296.682M208.134 218.898L196.059 188.728" stroke="white"/>
        <path d="M333.569 211.53C336.088 217.775 334.048 219.899 320.157 219.575" stroke="white"/>
        <path d="M217.97 38.7442L113.253 40.2914C98.0115 40.9103 91.8897 40.5446 87.0767 38.1113L81.7008 34.0745C79.238 32.0491 79.6742 31.0645 81.7008 30.0377L333.412 28.4905C346.373 28.4905 345.219 28.9125 335.424 30.0377C293.346 34.7919 269.07 37.2111 217.956 38.7302L217.97 38.7442Z" stroke="white"/>
        <path d="M82.7014 29.7156C95.4235 25.5523 106.766 24.5114 133.013 24.4692H208.81M208.81 24.4692H303.396M208.81 24.4692L196.736 39.2239" stroke="white"/>
        <path d="M333.96 28.492C336.55 25.3835 334.917 24.4692 325.516 24.4692" stroke="white"/>
        <path d="M315.451 212.189H302.039C299.998 212.765 299.126 213.356 298.014 214.875L293.314 231.641C292.948 234.567 293.328 235.734 295.326 237H302.039C311.271 232.963 315.183 228.912 320.151 218.898C321.966 214.678 321.375 213.004 315.451 212.189Z" stroke="white"/>
        <path d="M302.044 212.189C301.946 213.117 301.847 214.017 301.749 214.875M301.749 214.875C300.679 223.624 298.991 228.786 295.332 237M301.749 214.875L320.832 214.2" stroke="white"/>
        <path d="M271.181 40.8317C281.637 98.6969 281.525 130.71 271.181 188.575L270.604 188.505C188.023 178.715 139.583 176.732 52.4991 176.001V49.285L271.181 40.7192V40.8176V40.8317Z" data-panel="roof" id={`${bodyType}-top-roof`} stroke="white" />
      </g>
      <defs>
        <clipPath id="clip0_4029_102000">
          <rect fill="white" height="238" width="462"/>
        </clipPath>
      </defs>
      <use href={useHref ? `#${bodyType}-top-${useHref}` : ''} id="mw-use-active-top-side" />
    </svg>
  );
};

export default forwardRef(TopSideSVG);
