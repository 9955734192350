import Worker from 'worker-loader!./worker.worker.js'; // eslint-disable-line

export const worker = new Worker();
// eslint-disable-next-line no-underscore-dangle
worker.postMessage(['init', { env: window.location.hostname, releaseVersion: window.__ENV__.releaseVersion }]);

export const QueryableWorker = {
  postMessage: ([workerFunction, data]) => {
    const key = performance.now();

    return new Promise((resolve, reject) => {
      const action = ({ data: [id, returnedData] }) => {
        if (key === id) {
          worker.removeEventListener('message', action);

          const dataAction = (returnedData.err) ? reject : resolve;
          dataAction(returnedData);
        }
      };

      worker.addEventListener('message', action);
      worker.postMessage([workerFunction, data, key]);
    });
  },
};
