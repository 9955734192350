import React, { useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MDText } from 'i18n-react';

import { Modal } from '@motorway/motorway-storybook-cra';

import { GA_EVENT_ACTIONS, GA_EVENTS } from '../../../utilities/analytics';
import { useToggleHeadDisplay } from '../../../utilities/hooks';
import { ImageCategoryType, KindType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { VEHICLE_DAMAGE_KIND_KEY } from '../../../utilities/vehiclePhotosCategories';
import { Context } from '../../context/context';

import { ElectricalMechanicalHelpDamagePanel } from './Damage/ElectricalMechanicalHelpDamagePanel';
import Example from './Example';
import Help from './Help';
import type { categoriesType, examplesType, PanelType } from './Help.types';
import { HELP_PANEL_GA_EVENT_LABEL, PANEL_TYPES } from './HelpPanel.helpers';
import LocalTexts from './HelpPanel.json';

import styles from './HelpPanel.module.scss';

const LocalT = new MDText(LocalTexts);

type HelpPanelProps = {
  category: ImageCategoryType | KindType,
  panelType: PanelType
}

const HelpPanel: React.FC<HelpPanelProps> = ({ category, panelType }) => {
  useToggleHeadDisplay();
  // @ts-expect-error: context will be described in another ticket
  const { setParentState, vehicleDetails: { vrm } } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const kind = searchParams.get('kind');
  const sectionKey = kind || category;

  const gaEventTitle = panelType === PANEL_TYPES.EXAMPLE
    ? LocalT.translate(`photos.examples.${sectionKey}.title`)
    : LocalT.translate(`photos.${sectionKey}.title`);

  const { BUTTON } = GA_EVENT_ACTIONS;
  const { CATEGORY, CLOSE_BUTTON, PAGE_LOADER_LABEL } = HELP_PANEL_GA_EVENT_LABEL;

  const categoryEvent = LocalT.translate(CATEGORY, ({ panel: panelType, title: gaEventTitle }));

  useEffect(() => {
    const { PAGE_LOADER } = GA_EVENT_ACTIONS;
    const pageLoaderEventLabel = LocalT.translate(PAGE_LOADER_LABEL, ({ panel: panelType, title: gaEventTitle }));

    GA_EVENTS.DEFAULT(PAGE_LOADER, categoryEvent, pageLoaderEventLabel);
  }, [gaEventTitle, panelType, categoryEvent, PAGE_LOADER_LABEL]);

  const closeHelpPage = (buttonType: string) => {
    const buttonEventLabel = LocalT.translate(
      CLOSE_BUTTON,
      ({ button: buttonType, panel: panelType, title: gaEventTitle }),
    );

    GA_EVENTS.DEFAULT(BUTTON, categoryEvent, buttonEventLabel);

    setParentState({
      panel: true,
    }, () => {
      const doesAnyHistoryEntryExist = location.key;
      if (doesAnyHistoryEntryExist) {
        navigate(-1);
      } else {
        navigate(`/${vrm}`); // If somehow you come into the app on a help page, we'll send you back to the hub.
      }
    });
  };

  const getHelpComponent = () => {
    let content = null;
    const isMechanicalElectricalDamage = sectionKey === VEHICLE_DAMAGE_KIND_KEY.DAMAGE_MECHANICAL_OR_ELECTRICAL;
    switch (panelType) {
      case PANEL_TYPES.EXAMPLE:
        content = <Example closeHelpPage={closeHelpPage} sectionKey={sectionKey as examplesType} />;
        break;
      case PANEL_TYPES.HELP:
        if (isMechanicalElectricalDamage) {
          content = <ElectricalMechanicalHelpDamagePanel closeHelpPage={closeHelpPage} kind={sectionKey} />;
        } else {
          content = <Help closeHelpPage={closeHelpPage} sectionKey={sectionKey as categoriesType} />;
        }
        break;
      default:
        break;
    }
    return { content };
  };

  const { content } = getHelpComponent();

  return (
    <Modal dialogClass={styles.dialog} dismissModal={() => closeHelpPage('X')} >
      {content}
    </Modal>
  );
};

export default HelpPanel;
