import { useEffect } from 'react';

import { dataURItoBlob, getPresetImage, PRESETS, s3toImgix } from '../../../../../utilities/helpers';
import type { Photo } from '../../../../../utilities/Types/photo.types';
import { useBreakpoint } from '../../../Breakpoints/Breakpoints';

const useBreakpointPreset = (): string => {
  const breakpoints = useBreakpoint();
  const tabletBreakpoint = breakpoints?.minWidth?.breakpointTabletMobile;

  return tabletBreakpoint ? PRESETS.DESKTOP : PRESETS.MOBILE;
};

export const useImageToURL = (imageURL: React.MutableRefObject<string | undefined>) => {
  const presetSize = useBreakpointPreset();

  const imageToURL = (image?: Photo | null) => {
    let url;

    if (!imageURL.current && image?.uploaded && image?.location) {
      url = s3toImgix(image.location);
    } else if (image?.dataURL) {
    // The reason why we're using the dataURL is that Safari can't render it's own blob URLs
    // `WebKitBlobResource error 1`
    // https://bugs.webkit.org/show_bug.cgi?id=190351
      imageURL.current = imageURL.current
      ?? URL.createObjectURL(dataURItoBlob(image?.dataURL));
      url = imageURL.current;
    }

    return url ?? getPresetImage(image?.presets, presetSize);
  };

  useEffect(() => () => {
    if (imageURL?.current) {
      URL.revokeObjectURL(imageURL.current);
    }
  }, [imageURL]);

  return { imageToURL, imageURL };
};
