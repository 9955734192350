import React from 'react';

import { MDText } from 'i18n-react';

import { Button } from '@motorway/mw-highway-code';

import { importAll } from '../../../utilities/helpers';

import ContactPanel from './ContactPanel';
import type { examplesType } from './Help.types';
import LocalTexts from './HelpPanel.json';

import styles from './HelpPanel.module.scss';

const LocalT = new MDText(LocalTexts);

const examplesImages = importAll(
  require.context('../../../assets/images/examples', false, /.jpg$/),
) as Record<string, string>;

type ExampleProps = {
  closeHelpPage: (buttonType: string) => void;
  sectionKey: examplesType;
}

const Example: React.FC<ExampleProps> = ({ closeHelpPage, sectionKey }) => {
  const section = LocalTexts.photos.examples[sectionKey] as Record<string, string>;
  const sectionImages = Object.entries(examplesImages).reduce<Record<string, string>>((acc, [key, val]) => {
    if (key.includes(sectionKey)) {
      acc[key] = val;
    }
    return acc;
  }, {});

  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <h3 className={styles.header}>
          {LocalT.translate(`photos.examples.${sectionKey}.title`)}
        </h3>
        {section.p && (
          <p className={styles.description}>
            {LocalT.translate(`photos.examples.${sectionKey}.p`)}
          </p>
        )}
        {section.subP && (
          <p className={styles.subParagraph}>
            {LocalT.translate(`photos.examples.${sectionKey}.subP`)}
          </p>
        )}
        <div className={styles.columns}>
          {Object.values(sectionImages).map((image, i) => {
            const index = i + 1;

            return (
              <div key={image} className={styles.column}>
                {section[`subTitle${index}`] && (
                  <h3>{LocalT.translate(`photos.examples.${sectionKey}.subTitle${index}`)}</h3>
                )}
                <div className={styles.imageWrapper}>
                  <img alt={`${sectionKey}-${index}`} src={image} />
                </div>
                {section[`p${index}`] && (
                  <p>{LocalT.translate(`photos.examples.${sectionKey}.p${index}`)}</p>
                )}
              </div>
            );
          })}
        </div>
        <ContactPanel />
      </div>
      <div className={styles.stickyButton}>
        <div className={styles.footer}>
          <Button
            fullWidth
            label={LocalT.translate('footerBtn')}
            onClick={() => closeHelpPage(LocalT.translate('footerBtn') as string)}
          />
        </div>
      </div>
    </div>
  );
};

export default Example;
