import React, { useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Button, CrossCircleIcon, CrossIcon, IconText, TickCircleIcon, WarningIcon } from '@motorway/mw-highway-code';

import { CATEGORY_PAGE_EVENTS } from '../../../utilities/analytics/events/categoryPage/categoryPageEvents';
import { handleCatchIfOnline } from '../../../utilities/api/helpers';
import { autoAssessStatusUpdateDB, updateAutoAssessmentStatus } from '../../../utilities/imageActions';
import { ContextValueTypes } from '../../../utilities/Types/contextTypes';
import { Context } from '../../context/context';
import { usePhotosContext } from '../../context/photos';
import { useImageToURL } from '../PhotosHub/CategoryPage/PhotoBlock/PhotoBlock.hooks';
import { CATEGORY_CAMERA_PATH } from '../PhotosHub/PhotosHub.helpers';

import { mapBodyTypeToImageAssist } from './ImageAssistModal.helpers';
import LocalTexts from './ImageAssistModal.json';
import type { ImageAssistModalProps } from './ImageAssistModal.types';

import styles from './ImageAssistModal.module.scss';

const LocalT = new MDText(LocalTexts);

export const ImageAssistModal = ({
  category,
  imageAssistModalHandler,
  open,
  selectedImageAssist,
}: ImageAssistModalProps) => {
  const imageURL = useRef();
  const navigate = useNavigate();
  const { offer, seller, setParentState, videoInfo: { imageCategories } } = useContext(Context) as ContextValueTypes;
  const { updatePhoto } = usePhotosContext();
  const vehicle = seller.vehicles.find(({ enquiry }) => enquiry?.id === offer.enquiryId);
  const { bodyCategory } = vehicle ?? {};

  const { assessmentStatus, photo } = selectedImageAssist ?? { assessmentStatus: 'default' };
  const { kind } = photo ?? {};

  const isDefaults = assessmentStatus === 'default';

  const showImages = bodyCategory && kind && !isDefaults;

  const imageAssistExample = showImages ? mapBodyTypeToImageAssist({ bodyCategory, kind }) : '';
  const imageDescription = showImages ? imageCategories[category]?.find((cat) =>
    cat.kind === kind)?.description.toLowerCase() : '';
  const { imageToURL } = useImageToURL(imageURL);

  const text = {
    description: LocalT.translate(`imageAssistModal.contents.${assessmentStatus}.description`),
    primaryBtn: LocalT.translate('imageAssistModal.buttons.primary'),
    secondaryBtn: LocalT.translate('imageAssistModal.buttons.secondary'),
    title: LocalT.translate(`imageAssistModal.contents.${assessmentStatus}.title`),
  } as Record<string, string>;

  const photoRetakeHandler = () => {
    setParentState({ imageToReplace: photo });
    CATEGORY_PAGE_EVENTS.CATEGORY_PAGE_IMAGE_ASSIST_RETAKE(assessmentStatus || '', `/${CATEGORY_CAMERA_PATH}`);

    navigate(CATEGORY_CAMERA_PATH);
  };

  const falsePositiveHandler = async () => {
    updatePhoto(photo?.id, { isAutoAssessStatusFalsePositive: true });
    const updatedPhoto = { ...photo, isAutoAssessStatusFalsePositive: true, updatedAt: new Date() };

    CATEGORY_PAGE_EVENTS.CATEGORY_PAGE_IMAGE_ASSIST_CORRECT(assessmentStatus || '', 'popup closed');

    await autoAssessStatusUpdateDB(updatedPhoto);
    updateAutoAssessmentStatus(updatedPhoto?.platformId ?? updatedPhoto?.id, seller?.auth_token)
      .catch((err) => handleCatchIfOnline(err, 'fetch-update-auto-assessment'));
    return imageAssistModalHandler();
  };

  const closeHandler = () => {
    CATEGORY_PAGE_EVENTS.CATEGORY_PAGE_IMAGE_ASSIST_POPUP_CLOSED(assessmentStatus || '');
    imageAssistModalHandler();
  };

  useEffect(() => {
    if (open) {
      CATEGORY_PAGE_EVENTS.CATEGORY_PAGE_IMAGE_ASSIST_POPUP_LOAD(assessmentStatus || '');
    }
  }, [open, assessmentStatus]);

  return (
    <>
      {ReactDOM.createPortal(
        (
          <>
            <div className={cx(styles.overlay, { [styles.isOpen]: open })} data-testid="image-assist-modal-overlay" onClick={closeHandler} />
            <div className={cx(styles.imageAssistModal, { [styles.isOpen]: open })} data-testid="image-assist-modal">
              <div className={styles.close}>
                <Button
                  icon={CrossIcon}
                  label="close"
                  onClick={closeHandler}
                  showLabel={false}
                  size="medium"
                  variant='tertiary'
                />
              </div>
              <div className={styles.content}>
                <div className={styles.text}>
                  <div className={styles.heading}>
                    {isDefaults ? (
                      // @ts-ignore For someone reason `medium--heading3` is set to excluded,
                      // TODO: Remove this, once this PR has been merged https://github.com/motorway/mw-highway-code/pull/237.
                      <IconText icon={WarningIcon} text={text.title} variant='medium--heading3'/>
                    ) : (
                      <h2>{text.title}</h2>
                    )}
                  </div>
                  <p>{text.description}</p>
                </div>
                {(showImages) && (
                  <div className={styles.images}>
                    <figure className={styles.image}>
                      <img
                        alt={`Incorrect photo of ${imageDescription}`}
                        src={imageToURL(photo)}
                      />
                      <figcaption className={cx(styles.icon, styles.crossIcon)}>
                        <CrossCircleIcon size="medium" />
                      </figcaption>
                    </figure>
                    <figure className={styles.image}>
                      <img
                        alt={`Correct photo of ${imageDescription}`}
                        src={imageAssistExample}
                      />
                      <figcaption className={cx(styles.icon, styles.tickIcon)}>
                        <TickCircleIcon size="medium" />
                      </figcaption>
                    </figure>
                  </div>
                )}
                <div className={styles.buttons}>
                  <Button
                    label={text.primaryBtn}
                    onClick={photoRetakeHandler}
                    showLabel={true}
                    size="medium"
                    variant='primary'
                  />
                  <Button
                    label={text.secondaryBtn}
                    onClick={falsePositiveHandler}
                    showLabel={true}
                    size="medium"
                    variant='tertiary'
                  />
                </div>
              </div>
            </div>
          </>
        ), document.body,
      )}
    </>
  );
};
