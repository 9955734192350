import { updatePhotoDamage, updateVehicleData } from '../../../../utilities/api';
import {
  updateDamageMetaDB, uploadedImageUpdateDB, uploadImage,
} from '../../../../utilities/imageActions';
import { OPTIONAL_CATEGORIES, TYRE_KINDS, WHEEL_KINDS } from '../../../../utilities/optionalCategories';
import type { ImageCategoriesByKindsType, VehicleDetailsTypes } from '../../../../utilities/Types/contextTypes';
import type { DamageMetaType } from '../../../../utilities/Types/damage.types';
import type { Offer } from '../../../../utilities/Types/offer.types';
import type { Photo } from '../../../../utilities/Types/photo.types';
import type { KindType } from '../../../../utilities/Types/vehiclePhotosCategories.types';
import { IMAGE_CATEGORIES, VEHICLE_DAMAGE_KIND_KEY } from '../../../../utilities/vehiclePhotosCategories';
import { TYRE_PROBLEMS_VALUE, WHEEL_DAMAGE_VALUE } from '../../MarkDamaged/MarkDamaged.helpers';

type HubDamageMetaType = DamageMetaType & { uploaded: boolean };
type PhotosType = Photo[];

export const triggerUpload = async (
  photo: Photo,
  token: string,
  updatePhoto: (id: number, data: Partial<Photo>) => void,
  updatePhotoDamageMeta: (id: number, data: HubDamageMetaType | null) => void,
) => {
  const {
    dataURL, id, kind, vehicleId,
  } = photo;

  try {
    // server api update
    const { detail } = await uploadImage({
      authToken: token,
      dataURL,
      id,
      kind,
      vehicleId,
    });

    const newDetail = {
      location: detail.location, platformId: detail.platformId, uploaded: true,
    };

    // update IndexedDB
    await uploadedImageUpdateDB(photo?.damageMeta ? {
      ...detail,
      damageMeta: { ...photo.damageMeta, uploaded: true },
    } : detail);

    // update context
    await updatePhoto(id, photo?.damageMeta ? {
      ...newDetail,
      damageMeta: { ...photo.damageMeta, uploaded: true },
    } : newDetail);

    if (photo?.damageMeta && !photo?.damageMeta?.uploaded) {
      await uploadDamageMeta({
        damageMeta: photo.damageMeta,
        id: detail.id,
        location: detail.location,
      }, updatePhotoDamageMeta);
    }

    return detail;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const uploadDamageMeta = async (
  { damageMeta, id, location, originalUrl }: Partial<Photo>,
  updatePhotoDamageMeta: (id: number, data: HubDamageMetaType | null) => void,
) => {
  const { uploaded, ...meta } = damageMeta as HubDamageMetaType;
  // server api update
  await updatePhotoDamage({ damageMeta: meta, location, originalUrl });
  const updatedDamageMeta = { ...damageMeta, uploaded: true } as HubDamageMetaType;
  // indexedDB update
  await updateDamageMetaDB({ damageMeta: updatedDamageMeta, id });
  // context update
  await updatePhotoDamageMeta(id || 0, updatedDamageMeta);
};

const tryUploadDamageMeta = (
  images: PhotosType,
  updatePhotoDamageMeta: (id: number, data: HubDamageMetaType | null) => void,
) => {
  const imagesToUpload = images.filter((image) => (image.uploaded && image.damageMeta?.uploaded === false));

  return imagesToUpload
    .map(async ({ damageMeta, id, location, originalUrl }) =>
      uploadDamageMeta({ damageMeta, id, location, originalUrl }, updatePhotoDamageMeta));
};

type UploadOutstandingImagesProps = {
  onError?: () => void,
  sortedPhotos: Record<string, PhotosType>,
  token: string,
  updatePhoto: (id: number, data: Partial<Photo>) => void,
  updatePhotoDamageMeta: (id: number, data: HubDamageMetaType | null) => void
};

export const uploadOutstandingImages = async ({
  onError,
  sortedPhotos,
  token,
  updatePhoto,
  updatePhotoDamageMeta,
}: UploadOutstandingImagesProps) => {
  let errors = 0;
  const filterAndUpload = async (images: PhotosType) => {
    const toUploadImages = images.filter((image) => (image.uploaded === false && !image.isBrokenImage));

    for await (const i of toUploadImages) {
      await triggerUpload(i, token, updatePhoto, updatePhotoDamageMeta)
        .catch(() => { // eslint-disable-line no-loop-func
          errors++;
        });
    }
  };

  const listToUpload = Object.entries(sortedPhotos).map(([, images]) => images);

  for await (const val of listToUpload) {
    await filterAndUpload(val);
  }

  if (errors > 0) {
    onError?.();
  }
};

type UploadOutstandingDamageMetaProps = {
  images: PhotosType,
  onError?: () => void,
  updatePhotoDamageMeta: (id: number, data: HubDamageMetaType | null) => void
};

export const uploadOutstandingDamageMeta = async ({
  images,
  onError,
  updatePhotoDamageMeta,
}: UploadOutstandingDamageMetaProps) => {
  try {
    const metaUploadPromises = tryUploadDamageMeta(images, updatePhotoDamageMeta);
    await Promise.all(metaUploadPromises);
  } catch (err) {
    onError?.();
  }
};

type UpdateActiveDamageCategoriesProps = {
  damageCategories: ImageCategoriesByKindsType,
  images: PhotosType,
  offer: Offer,
  onError?: () => void,
  tyreImages: PhotosType,
  updateVehicleCondition: (data: Record<string, unknown>) => void,
  vehicleDetails: VehicleDetailsTypes,
  wheelImages: PhotosType
};

export const updateActiveDamageCategories = async ({
  damageCategories, images, offer, onError, tyreImages, updateVehicleCondition, vehicleDetails, wheelImages,
}: UpdateActiveDamageCategoriesProps) => {
  try {
    const activeDamageCategories = images
      .filter((img) => img.category === OPTIONAL_CATEGORIES.damage || img.isDamaged)
      .map((img) => img.kind)
      .reduce<KindType[]>((acc, kind) => (acc.includes(kind) ? acc : [...acc, kind]), []);

    const updatedData = damageCategories.reduce((acc, categoryItem) => {
      let value = false;

      if (activeDamageCategories.includes(categoryItem.kind)) {
        value = true;
      }

      if (categoryItem.conditionKind === WHEEL_DAMAGE_VALUE
        && activeDamageCategories.some((category) => WHEEL_KINDS.includes(category))) {
        value = true;
      }

      if (categoryItem.conditionKind === TYRE_PROBLEMS_VALUE
        && activeDamageCategories.some((category) => TYRE_KINDS.includes(category))) {
        value = true;
      }

      return { ...acc, [categoryItem.conditionKind as keyof VehicleDetailsTypes]: value };
    }, {});

    const additionalData = damageCategories.reduce((acc, item) => {
      if (item.kind === VEHICLE_DAMAGE_KIND_KEY.DAMAGE_OTHER
          || item.kind === VEHICLE_DAMAGE_KIND_KEY.DAMAGE_MECHANICAL_OR_ELECTRICAL) {
        return {
          ...acc,
          [item.conditionKind as string]: vehicleDetails[item.conditionKind as keyof VehicleDetailsTypes],
          [item.conditionDesc as string]: vehicleDetails[item.conditionDesc as keyof VehicleDetailsTypes],
        };
      }

      return acc;
    }, {});

    const updatedWheel = wheelImages.filter((img) => img.uploaded).map((img) => ({
      id: img.id,
      isDamaged: img.isDamaged,
      location: img.location,
      vehicleImageId: img.platformId,
    }));

    await Promise.all(updatedWheel.map((it) => updatePhotoDamage(it, IMAGE_CATEGORIES.WHEELS)));

    const updatedTyre = tyreImages.filter((img) => img.uploaded).map((img) => ({
      id: img.id,
      isDamaged: img.isDamaged,
      location: img.location,
      vehicleImageId: img.platformId,
    }));

    await Promise.all(updatedTyre.map((it) => updatePhotoDamage(it, IMAGE_CATEGORIES.TYRES)));

    await updateVehicleData(
      offer.id,
      { ...updatedData, ...additionalData },
    );

    updateVehicleCondition({ ...updatedData, ...additionalData });
    return true;
  } catch {
    onError?.();
    return false;
  }
};
