import type { DamageKindType, ExteriorKindType, InteriorKindType, TyresKindType, WheelsKindType } from './Types/vehiclePhotosCategories.types';

export const VEHICLE_PHOTOS_CATEGORY_KEY = {
  DAMAGE: 'damage',
  EXTERIOR: 'exterior',
  INTERIOR: 'interior',
  WHEELS: 'wheels',
} as const;

export const IMAGE_CATEGORIES = {
  ...VEHICLE_PHOTOS_CATEGORY_KEY,
  TYRES: 'tyres',
} as const;

export const VEHICLE_DAMAGE_KIND_KEY = {
  DAMAGE_DENTS: 'damage_dents',
  DAMAGE_MECHANICAL_OR_ELECTRICAL: 'damage_mechanical_or_electrical',
  DAMAGE_MISSING_TRIMS: 'damage_missing_trims',
  DAMAGE_OTHER: 'damage_other',
  DAMAGE_PAINTWORK: 'damage_paintwork',
  DAMAGE_SCRATCHES: 'damage_scratches',
  DAMAGE_TYRES: 'damage_tyres',
  DAMAGE_WARNING_LIGHTS: 'damage_warning_lights',
  DAMAGE_WHEELS: 'damage_wheels',
  DAMAGE_WINDSCREEN: 'damage_windscreen',
} as const;

export const DAMAGE_KIND_LIST = Object.values(VEHICLE_DAMAGE_KIND_KEY) as DamageKindType[];

export const VEHICLE_PHOTO_CATEGORY_ORDER = [
  VEHICLE_PHOTOS_CATEGORY_KEY.EXTERIOR,
  VEHICLE_PHOTOS_CATEGORY_KEY.WHEELS,
  VEHICLE_PHOTOS_CATEGORY_KEY.INTERIOR,
  VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE,
] as const;

export const VEHICLE_DAMAGE_KIND_PATH = {
  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WHEELS]: { name: 'wheel', path: '/wheel' },
  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_TYRES]: { name: 'tyre', path: '/tyre' },
  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_SCRATCHES]: { name: 'scratches', path: '/scratches' },
  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_DENTS]: { name: 'dents', path: '/dents' },
  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_PAINTWORK]: { name: 'paintwork', path: '/paintwork' },
  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WINDSCREEN]: { name: 'windscreen', path: '/windscreen' },
  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_MISSING_TRIMS]: { name: 'missing-trims', path: '/missing-trims' },
  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WARNING_LIGHTS]: { name: 'warning-lights', path: '/warning-lights' },
  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_MECHANICAL_OR_ELECTRICAL]: { name: 'mechanical-or-electrical', path: '/mechanical-or-electrical' },
  [VEHICLE_DAMAGE_KIND_KEY.DAMAGE_OTHER]: { name: 'other', path: '/other' },
} as const;

export const VEHICLE_DAMAGE_KIND_ORDER = [
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WHEELS,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_TYRES,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WINDSCREEN,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_SCRATCHES,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_DENTS,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_PAINTWORK,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_MISSING_TRIMS,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WARNING_LIGHTS,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_MECHANICAL_OR_ELECTRICAL,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_OTHER,
] as const;

export const VEHICLE_PANEL_DAMAGE_KINDS = [
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WINDSCREEN,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_SCRATCHES,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_DENTS,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_PAINTWORK,
] as const;

export const HELP_PANEL_DAMAGE_KINDS = [
  ...VEHICLE_PANEL_DAMAGE_KINDS,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_MISSING_TRIMS,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WARNING_LIGHTS,
] as const;

export const VEHICLE_PHOTO_CATEGORY_PATH = {
  [VEHICLE_PHOTOS_CATEGORY_KEY.EXTERIOR]: { name: VEHICLE_PHOTOS_CATEGORY_KEY.EXTERIOR, path: `/${VEHICLE_PHOTOS_CATEGORY_KEY.EXTERIOR}` },
  [VEHICLE_PHOTOS_CATEGORY_KEY.WHEELS]: { name: VEHICLE_PHOTOS_CATEGORY_KEY.WHEELS, path: `/${VEHICLE_PHOTOS_CATEGORY_KEY.WHEELS}` },
  [VEHICLE_PHOTOS_CATEGORY_KEY.INTERIOR]: { name: VEHICLE_PHOTOS_CATEGORY_KEY.INTERIOR, path: `/${VEHICLE_PHOTOS_CATEGORY_KEY.INTERIOR}` },
  [VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE]: { name: VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE, path: `/${VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE}` },
} as const;

export const CATEGORIES_WITH_EXAMPLE_ROUTE = [
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_WHEELS,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_TYRES,
] as const;

export const CATEGORIES_WITHOUT_HELP_ROUTE = [
  VEHICLE_PHOTOS_CATEGORY_KEY.INTERIOR,
  VEHICLE_DAMAGE_KIND_KEY.DAMAGE_OTHER,
] as const;

export const WHEELS_KINDS = {
  WHEELS_FRONT_DRIVER: 'wheels_front_driver',
  WHEELS_FRONT_PASSENGER: 'wheels_front_passenger',
  WHEELS_REAR_DRIVER: 'wheels_rear_driver',
  WHEELS_REAR_PASSENGER: 'wheels_rear_passenger',
} as const;

export const WHEELS_KINDS_LIST = Object.values(WHEELS_KINDS) as WheelsKindType[];

export const TYRES_KINDS = {
  TYRE_TREAD_FRONT_DRIVER: 'tyre_tread_front_driver',
  TYRE_TREAD_FRONT_PASSENGER: 'tyre_tread_front_passenger',
  TYRE_TREAD_REAR_DRIVER: 'tyre_tread_rear_driver',
  TYRE_TREAD_REAR_PASSENGER: 'tyre_tread_rear_passenger',
} as const;

export const TYRES_KINDS_LIST = Object.values(TYRES_KINDS) as TyresKindType[];

export const INTERIOR_KINDS = {
  INSTRUMENT_CLUSTER: 'instrument_cluster',
  INTERIOR_BOOT: 'interior_boot',
  INTERIOR_DASHBOARD: 'interior_dashboard',
  INTERIOR_FRONT_SEATS: 'interior_front_seats',
  INTERIOR_REAR_SEATS: 'interior_rear_seats',
} as const;

export const INTERIOR_KINDS_LIST = Object.values(INTERIOR_KINDS) as InteriorKindType[];

export const EXTERIOR_KINDS = {
  EXTERIOR_FRONT_DRIVER: 'exterior_front_driver',
  EXTERIOR_FRONT_PASSENGER: 'exterior_front_passenger',
  EXTERIOR_REAR_DRIVER: 'exterior_rear_driver',
  EXTERIOR_REAR_PASSENGER: 'exterior_rear_passenger',
} as const;

export const EXTERIOR_KINDS_LIST = Object.values(EXTERIOR_KINDS) as ExteriorKindType[];

export const CONDITION_KINDS = {
  HAS_ADDITIONAL_DAMAGE: 'hasAdditionalDamage',
  HAS_DAMAGED_WHEELS: 'hasDamagedWheels',
  HAS_DENTS: 'hasDents',
  HAS_ELECTRICS_PROBLEMS: 'hasElectricsProblems',
  HAS_MISSING_PARTS: 'hasMissingParts',
  HAS_PAINT_PROBLEMS: 'hasPaintProblems',
  HAS_SCRATCHES: 'hasScratches',
  HAS_TYRE_PROBLEMS: 'hasTyreProblems',
  HAS_WARNING_LIGHTS: 'hasWarningLights',
  HAS_WINDSCREEN_PROBLEMS: 'hasWindscreenProblems',
} as const;

export const VEHICLE_PHOTOS_CATEGORIES = {
  [VEHICLE_PHOTOS_CATEGORY_KEY.DAMAGE]: [
    {
      category: 'damage',
      conditionKind: 'hasScratches',
      description: 'Scratches or scuffs',
      kind: 'damage_scratches',
      multiple: true,
      onboardingMessage: 'Take a photo of each scratch you can see on your car. Each scratch has to fully fit within the frame.',
    },
    {
      category: 'damage',
      conditionKind: 'hasDents',
      description: 'Dents',
      kind: 'damage_dents',
      multiple: true,
      onboardingMessage: 'Take a photo of each dent you can see on your car. Each dent has to fully fit within the frame.',
    },
    {
      category: 'damage',
      conditionKind: 'hasPaintProblems',
      description: 'Paintwork problems',
      kind: 'damage_paintwork',
      multiple: true,
      onboardingMessage: 'Take a photo of each paintwork problem you can see on your car. Each paintwork problem has to fully fit within the frame.',
    },
    {
      category: 'damage',
      conditionKind: 'hasWindscreenProblems',
      description: 'Windscreen damage',
      kind: 'damage_windscreen',
      multiple: true,
      onboardingMessage: 'Take a photo of the damage on your windscreen. The damage has to fully fit within the frame.',
    },
    {
      category: 'damage',
      conditionKind: 'hasMissingParts',
      description: 'Broken/missing fittings',
      kind: 'damage_missing_trims',
      multiple: true,
      onboardingMessage: 'Take a photo of  each type of damage separately. The damage has to fully fit within the frame.',
    },
    {
      category: 'damage',
      conditionKind: 'hasWarningLights',
      description: 'Warning lights',
      kind: 'damage_warning_lights',
      multiple: true,
      onboardingMessage: 'Take one photo of the dashboard showing all active warning lights.',
    },
    {
      category: 'damage',
      conditionKind: 'hasDamagedWheels',
      description: 'Wheel damage (scratches, cracks or dents)',
      kind: 'damage_wheels',
      multiple: true,
    },
    {
      category: 'damage',
      conditionKind: 'hasTyreProblems',
      description: 'Tyre damage (scratches, cracks or dents)',
      kind: 'damage_tyres',
      multiple: true,
    },
    {
      category: 'damage',
      conditionDesc: 'electricsProblemsDesc',
      conditionKind: 'hasElectricsProblems',
      description: 'Mechanical or electrical issues',
      kind: 'damage_mechanical_or_electrical',
      multiple: true,
    },
    {
      category: 'damage',
      conditionDesc: 'additionalDetails',
      conditionKind: 'hasAdditionalDamage',
      description: 'Additional info',
      kind: 'damage_other',
      multiple: true,
    },
  ],
  [VEHICLE_PHOTOS_CATEGORY_KEY.EXTERIOR]: [
    {
      category: 'exterior',
      description: 'Front driver corner',
      kind: 'exterior_front_driver',
    },
    {
      category: 'exterior',
      description: 'Rear driver corner',
      kind: 'exterior_rear_driver',
    },
    {
      category: 'exterior',
      description: 'Rear passenger corner',
      kind: 'exterior_rear_passenger',
    },
    {
      category: 'exterior',
      description: 'Front passenger corner',
      kind: 'exterior_front_passenger',
    },
  ],
  [VEHICLE_PHOTOS_CATEGORY_KEY.INTERIOR]: [
    {
      category: 'interior',
      description: 'Boot interior',
      kind: 'interior_boot',
      message: 'Don\'t have a boot? Tap \'Skip\'',
      skip: true,
    },
    {
      category: 'interior',
      description: 'Back seats',
      kind: 'interior_rear_seats',
      message: 'Don\'t have back seats? Tap \'Skip\'',
      skip: true,
    },
    {
      category: 'interior',
      description: 'Dashboard',
      kind: 'interior_dashboard',
      onboardingForceShow: true,
      onboardingMessage: 'Ensure whole dashboard is in frame with the steering wheel straight and engine running.',
    },
    {
      category: 'interior',
      description: 'Front seats',
      kind: 'interior_front_seats',
    },
    {
      category: 'interior',
      description: 'Instrument panel',
      kind: 'instrument_cluster',
      onboardingForceShow: true,
      onboardingMessage: 'Ensure the engine is running.',
    },
  ],
  [VEHICLE_PHOTOS_CATEGORY_KEY.WHEELS]: [
    {
      category: 'wheels',
      damageDescription: 'Front passenger side',
      description: 'Front passenger wheel',
      kind: 'wheels_front_passenger',
    },
    {
      category: 'tyres',
      damageDescription: 'Front passenger side',
      description: 'Front passenger tyre tread',
      kind: 'tyre_tread_front_passenger',
    },
    {
      category: 'wheels',
      damageDescription: 'Front driver side',
      description: 'Front driver wheel',
      kind: 'wheels_front_driver',
    },
    {
      category: 'tyres',
      damageDescription: 'Front driver side',
      description: 'Front driver tyre tread',
      kind: 'tyre_tread_front_driver',
    },
    {
      category: 'wheels',
      damageDescription: 'Rear driver side',
      description: 'Rear driver wheel',
      kind: 'wheels_rear_driver',
    },
    {
      category: 'tyres',
      damageDescription: 'Rear driver side',
      description: 'Rear driver tyre tread',
      kind: 'tyre_tread_rear_driver',
    },
    {
      category: 'wheels',
      damageDescription: 'Rear passenger side',
      description: 'Rear passenger wheel',
      kind: 'wheels_rear_passenger',
    },
    {
      category: 'tyres',
      damageDescription: 'Rear passenger side',
      description: 'Rear passenger tyre tread',
      kind: 'tyre_tread_rear_passenger',
    },
  ],
} as const;
