import React from 'react';

import { MDText } from 'i18n-react';

import LocalTexts from '../PhotoItem.json';

import styles from './LockedMessageOverlay.module.scss';

const LocalT = new MDText(LocalTexts);

export const LockedMessageOverlay = () => (
  <div className={styles.overlay}>
    <p className={styles.message}>{LocalT.translate('lockedMessageOverlay')}</p>
  </div>
);
