import { useContext, useEffect } from 'react';

import { captureDatadogException } from '../../../utilities/logger';
import type { Photo } from '../../../utilities/Types/photo.types';
import type { CategoryType } from '../../../utilities/Types/vehiclePhotosCategories.types';
import { Context } from '../../context/context';
import { usePhotosContext } from '../../context/photos';

import { getCurrentImageItem } from './Overlay.helpers';

export type UseGoToNextTimingsProps = {
  $videoOutlines: React.MutableRefObject<HTMLVideoElement | null>;
  currentCategory: CategoryType[];
  imageCategoriesIndex: number | null;
  setActivatePlayOverlay: (value: boolean) => void;
  setDisabled: (value: boolean) => void;
  setShowHintTooltip: (value: boolean) => void;
  videoReady: boolean;
}

export const useGoToNextTimings = ({
  $videoOutlines,
  currentCategory,
  imageCategoriesIndex,
  setActivatePlayOverlay,
  setDisabled,
  setShowHintTooltip,
  videoReady,
}: UseGoToNextTimingsProps) => {
  useEffect(() => {
    const category = getCurrentImageItem(currentCategory, imageCategoriesIndex);
    const $outlines = $videoOutlines.current;

    if (!$outlines || !videoReady) {
      return;
    }

    setActivatePlayOverlay(false);
    setDisabled(!!category.timings);
    setShowHintTooltip(false);

    const newIndex = imageCategoriesIndex ?? -1;
    // Start at the beginning if retaking
    const { timings: videoTimings } = newIndex >= 0 ? (currentCategory[newIndex] || {}) : { timings: [0, 0] };

    if (!videoTimings) {
      return;
    }
    setTimeout(async () => {
      const finishPlay = () => {
        // TODO - Chrome will pause the video on changing tab, so the pause event here is ignored
        // and the video will resume and keep playing after the tab is refocused
        // https://github.com/video-dev/hls.js/issues/1567
        // Example here doesn't work https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
        $outlines.pause();
        setDisabled(false);
      };

      const monitorTimeUpdate = ({ currentTarget }: Event) => {
        const { currentTime } = currentTarget as HTMLVideoElement;
        if (currentTime >= videoTimings[1]) {
          $outlines.removeEventListener('timeupdate', monitorTimeUpdate);
          finishPlay();
        }
      };

      const [currentTime] = videoTimings;
      $outlines.currentTime = currentTime;

      await $outlines.play() // https://goo.gl/LdLk22
        .catch((err) => {
          if ($outlines.paused) {
            $outlines.play();
          }
          captureDatadogException({
            error: err,
            errorTitle: 'Error during play car videoOutlines',
            fingerprint: 'play-video-outline',
          });
        });

      $outlines.addEventListener('timeupdate', monitorTimeUpdate);
      // Fallback if timeupdate event do not fire
      setTimeout(() => setDisabled(false), (videoTimings[1] - videoTimings[0] || 1) * 1000 + 40);
    }, 250);
  }, [imageCategoriesIndex, $videoOutlines.current, videoReady]);
};

export const useUpdateContextPhoto = () => {
  // TODO: Replace with photosContext type when usePhotosContext converted to TS
  const { addPhoto, removePhoto, updatePhoto }: any = usePhotosContext();
  // TODO: Replace with context type when context converted to TS
  const { imageToReplace }: any = useContext(Context);

  return (photoObj: Partial<Photo> | null, toReplace?: boolean) => {
    const replaceItem = toReplace ?? imageToReplace;

    if (photoObj && !replaceItem) {
      addPhoto(photoObj);
    }

    if (photoObj && replaceItem) {
      const id = imageToReplace ? imageToReplace.id : photoObj.id;
      updatePhoto(id, photoObj);
    }

    if (!photoObj && imageToReplace) {
      removePhoto(imageToReplace.id);
    }
  };
};
