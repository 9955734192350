import React, { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import io, { Socket } from 'socket.io-client';

import {
  addAction,
  addError,
  addTiming,
  SOCKET_ACTION_TYPES,
  SOCKET_IO_EVENTS,
  SOCKET_MW_SELLER_EVENTS,
  SOCKET_SERVER_URL,
  updateImageAssessment,
} from './Socket.helpers';
import type {
  Assessment,
  SocketProviderProps,
  SocketReducerTypes,
  SocketState,
} from './Socket.types';

const initialState: SocketState = {
  error: null,
  imageAssessments: {
    damage: [],
    exterior: [],
    interior: [],
    wheels: [],
  },
  refetch: false,
  resetRefetch: () => undefined,
};

const socketReducer: SocketReducerTypes = (state, action) => {
  switch (action.type) {
    case SOCKET_ACTION_TYPES.CONNECT:
      return { ...state, ...action.payload };
    case SOCKET_ACTION_TYPES.ERROR:
      return { ...state, error: action.payload };
    case SOCKET_ACTION_TYPES.UPDATE_IMAGE_ASSESSMENTS:
      return {
        ...state,
        imageAssessments: updateImageAssessment(state.imageAssessments, action.payload),
      };
    case SOCKET_ACTION_TYPES.RESET_REFETCH:
      return { ...state, refetch: action.payload };
    default:
      return state;
  }
};

const SocketContext = createContext<SocketState>(initialState);

const SocketProvider = ({ children, enquiryId, featureFlags, sellerAuthToken }: SocketProviderProps) => {
  const [state, dispatch] = useReducer(socketReducer, initialState);
  const { showAiImageAssist } = featureFlags || {};
  const socketIdRef = useRef<string | null>(null);

  const resetRefetch = () => {
    dispatch({ payload: false, type: SOCKET_ACTION_TYPES.RESET_REFETCH });
  };

  useEffect(() => {
    let socket: Socket;

    let connectionStart: number;
    let reconnectionStart: number;

    const pageInit: number = Date.now();

    if (showAiImageAssist && sellerAuthToken && enquiryId) {
      // WebSocket init
      socket = io(SOCKET_SERVER_URL, {
        auth: {
          token: sellerAuthToken,
        },
        transports: ['websocket'], // Configured to only use Websocket, prevents fall backing to long-polling when WebSocket is not available.
      });

      const initEnd = Date.now();

      addTiming('Initialization', pageInit, initEnd, { enquiryId });

      const subscribeToImageAssessment = () => {
        const emitStart = Date.now();
        socket.emit(SOCKET_MW_SELLER_EVENTS.VEHICLE_IMAGE_SUBSCRIBE, { data: { enquiryId }, type: 'enquiry' });
        addAction('Emit', { enquiryId, socketId: socket.id, type: 'Subscription' });
        const emitEnd = Date.now();
        addTiming('ServerProcessing', emitStart, emitEnd, { enquiryId });
      };

      // WebSocket connect
      socket.on(SOCKET_IO_EVENTS.CONNECT, () => {
        connectionStart = Date.now();

        const payload = {
          error: null,
          refetch: false,
        };

        // WebSocket emit
        if (socketIdRef.current === null) {
          subscribeToImageAssessment(); // Emit the event to subscribe to the image assessment channel.
        }

        if (socketIdRef.current !== null && (socketIdRef.current !== socket.id && socket.recovered === false)) {
          payload.refetch = true;
          subscribeToImageAssessment(); // We need to emit the event again otherwise we won't receive any data.
        }

        socketIdRef.current = socket.id;

        dispatch({ payload, type: SOCKET_ACTION_TYPES.CONNECT });
        addTiming('ConnectedSuccessfully', pageInit, connectionStart, { enquiryId });
      });

      // WebSocket error within connection
      socket.on(SOCKET_IO_EVENTS.CONNECT_ERROR, (err) => {
        addError({
          context: { ...err },
          description: 'connection-error',
          errorMessage: `connection error: ${err?.message}`,
          isWarning: true,
        });
        dispatch({ payload: err.message, type: SOCKET_ACTION_TYPES.ERROR });
      });

      // WebSocket receiving data
      socket.on(SOCKET_MW_SELLER_EVENTS.VEHICLE_IMAGE_AUTO_ASSESS, (data: Assessment) => {
        addAction('ReceivingAssessmentData', { ...data, socketId: socket.id });
        dispatch({ payload: data, type: SOCKET_ACTION_TYPES.UPDATE_IMAGE_ASSESSMENTS });
      });

      // WebSocket disconnection
      socket.on(SOCKET_IO_EVENTS.DISCONNECT, (reason) => {
        addAction('Disconnection', { enquiryId, reason });
        reconnectionStart = Date.now();
      });

      // WebSocket reconnection established
      socket.io.on(SOCKET_IO_EVENTS.RECONNECT, (attemptNumber) => {
        const reconnectionEnd = Date.now();

        addAction('ReconnectedSuccessfully', { attemptNumber, enquiryId, socketId: socket.id });
        addTiming('Reconnection', reconnectionStart, reconnectionEnd, { attemptNumber, enquiryId });
      });

      // WebSocket reconnection error
      socket.io.on(SOCKET_IO_EVENTS.RECONNECT_ERROR, (err) => {
        addError({
          context: { ...err },
          description: 'reconnection',
          errorMessage: `reconnection error: ${err?.message}`,
          isWarning: true,
        });
      });

      // WebSocket error within connection
      socket.on(SOCKET_IO_EVENTS.ERROR, (err) => {
        dispatch({ payload: err.message, type: SOCKET_ACTION_TYPES.ERROR });
        addError({
          context: { ...err },
          description: 'general-error',
          errorMessage: err?.message,
          isWarning: false,
        });
      });
    }

    return () => {
      if (socket) {
        socket.disconnect();

        const connectionEnd = Date.now();
        addTiming('DisconnectedSuccessfully', connectionStart, connectionEnd, { enquiryId });
      }
    };
  }, [showAiImageAssist, sellerAuthToken, enquiryId]);

  return (
    <SocketContext.Provider value={{ ...state, resetRefetch }}>
      {children}
    </SocketContext.Provider>
  );
};

const useSocket = (): SocketState => useContext(SocketContext);

export { SocketProvider, SocketContext, useSocket };
