import type { ImageCategoryType, KindType } from './Types/vehiclePhotosCategories.types';
import { VEHICLE_PHOTOS_CATEGORIES, VEHICLE_PHOTOS_CATEGORY_KEY } from './vehiclePhotosCategories';

export type OptionalCategoriesType = ImageCategoryType | 'docs_service_history';

export const OPTIONAL_CATEGORIES: Record<string, OptionalCategoriesType> = {
  damage: 'damage',
  serviceHistory: 'docs_service_history',
};

export const WHEEL_DAMAGE = 'damage_wheels';
export const WHEEL_KINDS = VEHICLE_PHOTOS_CATEGORIES[VEHICLE_PHOTOS_CATEGORY_KEY.WHEELS].reduce<string[]>((acc, { category, kind }) => (category === 'wheels' ? [...acc, kind] : acc), []);
export const TYRE_KINDS = VEHICLE_PHOTOS_CATEGORIES[VEHICLE_PHOTOS_CATEGORY_KEY.WHEELS].reduce<string[]>((acc, { category, kind }) => (category === 'tyres' ? [...acc, kind] : acc), []);

export const isOptionalCategory = (category: OptionalCategoriesType) => Object.values(OPTIONAL_CATEGORIES)
  .includes(category);
export const isOptionalCategorySub = (kind: KindType) => Object.values(OPTIONAL_CATEGORIES)
  .some((it) => kind.includes(it));
