import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import BrushSrc from '../../../../assets/images/loaders/brush.svg';

import LocalTexts from './Screens.json';

import styles from './Screens.module.scss';

const LocalT = new MDText(LocalTexts);

export const Screen2 = ({ scrollX }: {scrollX: number}) => {
  const [viewable, setViewable] = useState(false);
  const [animate, setAnimate] = useState(false);

  const getViewable = useCallback(
    (outerRange) =>
      scrollX >= window.innerWidth * 0.9
      && scrollX <= window.innerWidth * outerRange,
    [scrollX],
  );

  useEffect(() => {
    setViewable(getViewable(1.75));
  }, [getViewable, scrollX]);

  useEffect(() => {
    if (viewable !== animate) {
      setAnimate(viewable);
    } else {
      setViewable(getViewable(1.5));
    }
  }, [viewable, getViewable, animate]);

  useEffect(() => {
    let clipTimer: NodeJS.Timeout;
    if (animate) {
      const dirtyCar = document.getElementById('loader-dirty-car') as HTMLElement;
      const rect = dirtyCar.getBoundingClientRect();
      const pixelPerMS = window.innerWidth / 2000; // 2s animation plus some buffer
      const enter = rect.left / pixelPerMS;
      const leave = viewable ? (rect.width - 80) / pixelPerMS : 0;

      clipTimer = setTimeout(() => {
        const loaderDirtyCarStyles = document.getElementById('loader-dirty-car-styles');
        if (loaderDirtyCarStyles) {
          loaderDirtyCarStyles.innerHTML = `
            #loader-dirty-car {
              -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
              clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
              transition-property: ${(viewable) ? '-webkit-clip-path, clip-path' : 'none'};
              transition-timing-function: linear;
              transition-duration: ${leave}ms;
            }`;
        }
      }, enter);
    }

    return () => {
      clearTimeout(clipTimer);
    };
  }, [animate]);

  return (
    <section
      className={cx(styles.screen, styles.screen2, {
        [styles.animate]: animate,
      })}
      data-testid="screen-2"
    >
      {ReactDOM.createPortal(
        <>
          <img
            alt="brush left"
            className={cx(styles.brushBackGround, {
              [styles.animate]: animate,
            })}
            src={BrushSrc}
          />
          <img
            alt="brush right"
            className={cx(styles.brushForeGround, {
              [styles.animate]: animate,
            })}
            src={BrushSrc}
          />
        </>,
        document.body,
      )}
      <h2>{LocalT.translate('screen2.title', { br: <br /> })}</h2>
    </section>
  );
};
