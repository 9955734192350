import { updatePhotoDamage } from '../../../../utilities/api';
import { handleCatchIfOnline } from '../../../../utilities/api/helpers';
import { updateDamageMetaDB } from '../../../../utilities/imageActions';

import type { OnDamageMetaSubmitProps, UpdateDamageMetaProps } from './DamageSize.types';

export const updateDamageMeta = async ({ damageMeta, id, updatePhotoDamageMeta }: UpdateDamageMetaProps) => {
  // indexedDB update
  await updateDamageMetaDB({ damageMeta, id });
  // context update
  await updatePhotoDamageMeta(id, damageMeta, false);
};

export const onDamageMetaSubmit = async ({
  deleteLocalStorageDetails,
  updatedDamageImageDetails,
  updatePhotoDamageMeta,
}: OnDamageMetaSubmitProps) => {
  const { damageMeta, id, location } = updatedDamageImageDetails;

  await updateDamageMeta({ damageMeta: { ...damageMeta, uploaded: false }, id, updatePhotoDamageMeta });

  if (location) {
    try {
      // server api call
      const res = await updatePhotoDamage({ damageMeta, location });
      if (res?.ok) {
        await updateDamageMeta({ damageMeta: { ...damageMeta, uploaded: true }, id, updatePhotoDamageMeta });
        deleteLocalStorageDetails();
      }
    } catch (error) {
      handleCatchIfOnline(error, 'fetch-update-photo-damage');
      console.error(error);
    }
  }
};
