import React, { forwardRef } from 'react';

type SideSVGProps = {
  bodyType?: string,
  useHref?: string,
} & React.SVGProps<SVGSVGElement>;

const RearSideSVG = (props: SideSVGProps, ref: React.ForwardedRef<SVGSVGElement>) => {
  const { bodyType, useHref, ...rest } = props;

  return (
    <svg ref={ref} fill="none" height="238" viewBox="0 0 300 238" width="300" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_4029_101924)">
        <path d="M68.9774 3.21027L64.0361 15.9242C129.091 10.7115 165.572 11.0081 230.627 15.9242L218.627 3.21027" stroke="white"/>
        <path d="M42.1585 235.585L20.2757 236.998C5.04251 237.167 2.76952 228.691 1.21655 210.157V143.056C8.31786 109.307 23.9323 73.5952 39.3349 42.7568L57.6882 11.6783C61.6271 9.0649 64.366 7.08718 69.6884 3.20237C127.854 0.193402 160.467 0.334668 218.632 3.21649L233.456 11.6925L255.339 42.771L280.751 92.9204C288.008 113.051 292.3 124.394 296.281 143.776L297.693 174.148C301.646 200.141 296.041 221.232 292.751 228.536C289.038 232.816 285.933 233.819 279.339 234.186H253.221C247.927 233.565 246.233 231.672 244.751 226.417L241.927 212.996L52.7469 215.115L50.6293 229.242C49.4433 233.748 47.6221 235.175 42.1585 235.599V235.585Z" stroke="white"/>
        <path d="M24.5074 90.078L77.4495 97.8476C92.8804 108.895 96.1275 113.924 77.4495 117.625L47.096 124.688C32.6675 123.968 24.7897 124.745 9.85297 118.43C9.85297 118.43 14.0742 106.069 24.5074 90.078Z" stroke="white"/>
        <path d="M58.704 26.2135L30.1575 81.6039C124.479 85.9266 176.814 85.6017 269.456 81.6039L236.237 25.4083C164.151 38.2211 58.6898 26.2135 58.6898 26.2135H58.704Z" stroke="white"/>
        <path d="M274.512 90.078L221.569 97.8476C206.139 108.895 202.891 113.924 221.569 117.625L251.923 124.688C266.351 123.968 272.945 125.027 287.881 118.713C285.255 108.075 274.512 90.078 274.512 90.078Z" stroke="white"/>
        <path d="M47.0984 124.687L77.4519 117.624C96.1299 113.923 92.8828 108.894 77.4519 97.8465L24.5521 90.1052L24.5097 90.0769C26.4439 87.0821 27.8698 84.7794 30.1569 81.601C124.479 85.9237 176.814 85.5988 269.455 81.601C270.712 83.5928 272.717 87.1951 274.51 90.0769L221.568 97.8465C206.137 108.894 202.89 113.923 221.568 117.624L251.921 124.687C252.853 124.645 253.771 124.602 254.646 124.56H254.731L254.674 124.772L253.22 137.401C250.721 149.748 244.509 154.409 221.455 157.178H82.3931C60.5104 156.528 50.8961 154 48.5102 137.401L46.9854 124.701H47.0984" data-panel="boot" id={`${bodyType}-back-boot`} stroke="white"/>
        <path d="M287.876 118.716C273.942 124.607 267.278 124.084 254.727 124.578L254.671 124.79L253.217 137.419C250.718 149.766 244.506 154.428 221.451 157.197H82.3898C60.507 156.547 50.8927 154.018 48.5068 137.419L46.982 124.72H47.095C32.6665 123.985 24.7887 124.762 9.85193 118.447H9.64016C6.84482 125.285 4.16241 133.012 1 142.35V172.015L9.47075 205.919C14.9626 214.876 20.7086 215.257 33.4712 211.57C48.7609 204.478 58.6999 203.588 77.2367 203.8H226.887C245.183 203.136 253.951 202.585 266.417 209.451C278.869 211.443 283.994 212.361 289.711 205.919L297.476 172.015L296.064 142.35C292.958 133.167 290.403 125.482 287.876 118.716Z" data-panel="rear_bumper" id={`${bodyType}-back-rear_bumper`} stroke="white"/>
        <path d="M64.0366 15.9284C129.092 10.7157 165.573 11.0124 230.628 15.9284L236.416 25.704L236.247 25.4215C164.161 38.2343 58.7001 26.2267 58.7001 26.2267L64.0366 15.9284Z" data-panel="spoiler" id={`${bodyType}-back-spoiler`} stroke="white"/>
        <path d="M38.6273 42.7697C20.9799 42.7697 12.5092 57.6026 30.1566 60.4279" stroke="white"/>
        <path d="M256.185 42.7697C273.832 42.7697 282.303 57.6026 264.656 60.4279" stroke="white"/>
      </g>
      <defs>
        <clipPath id="clip0_4029_101924">
          <rect fill="white" height="238" width="300"/>
        </clipPath>
      </defs>
      <use href={useHref ? `#${bodyType}-back-${useHref}` : ''} id="mw-use-active-rear-side" />
    </svg>
  );
};

export default forwardRef(RearSideSVG);
